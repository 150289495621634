import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { getAllJobsFetch, getJobByIdFetch } from "../redux/thunks/jobsThunks";
import PageLoader from "../components/PageLoader";
import api from "../api";
import Editor from "../components/CKEditor";
import CreateSignatureModal from "../components/modals/CreateSignatureModal";
import { toast } from "react-toastify";
import './signLetter.css'
import IframeResizer from "@iframe-resizer/react";
import '@iframe-resizer/child'

// Reusable component for feature sections
const FeatureSection = ({ title, description, imageURL, altText="" }) => (
	<div className="flex gap-6 justify-between max-md:flex-wrap max-md:max-w-full">
		<img loading="lazy" src={imageURL} alt={altText} className="self-start w-[65px] h-[65px] object-contain" />
		<div className="flex flex-col flex-1 max-md:max-w-full">
			<div className="text-xl font-bold leading-8 max-md:max-w-full">{title}</div>
			<div className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full">{description}</div>
		</div>
	</div>
);

// Reusable component for qualification or requirement items
const QualificationItem = ({ imageURL, altText="", title }) => (
	<div className="flex lg:flex-col gap-3 max-lg:gap-5 items-center grow text-lg leading-8 whitespace-nowrap text-zinc-800">
		<img loading="lazy" src={imageURL} alt={altText} className="self-center w-[65px] h-[65px] object-contain" />
		<div>{title}</div>
	</div>
);
function VideoInterview() {

	const { candidateId, jobApplicationId, token } = useParams();
	console.log(candidateId, jobApplicationId);
	const [offerData, setOfferData] = useState(null);
	const [rteInstanse, setRteInstanse] = useState(null)
	useEffect(() => {})
	const [createSignatureModalOpen, setCreateSignatureModalOpen] = useState(false);
	const [base64Image, setBase64Image] = useState(null);
	console.log(base64Image)
	const [interviewData, setInterviewData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const insertImage = (blockId, imageUrl) => {
        var iframe = rteInstanse.iframe
        var iframeContent = iframe.contentDocument || iframe.contentWindow.document;
        var paragraph = iframeContent.getElementById(blockId);

        if (paragraph) {
            console.log(paragraph);
            rteInstanse.selectControl(paragraph)
			// var img= rteInstanse.insertByTagName("IMG");
			// img.src=imageUrl;
			// rteInstanse.insertHTML(`<img id='${blockQuery}' src='${imageUrl}'></img>`);
            rteInstanse.insertImageByUrl(imageUrl);
			var img = iframeContent.querySelector(`img[src="${imageUrl}"]`);
			console.log(img)
			img.id = blockId
			img.style = "width: 206px; height: 70px; object-fit: contain"
        }

    };
	const setSignatureFile = (imgBase64) => {
        function DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            return new Blob([ia], { type: mimeString })
        }

        const file = DataURIToBlob(imgBase64);

        return file;
    }
	useEffect(() => {
		if(!candidateId || !jobApplicationId) return
		setIsLoading(true);
		api.jobVideoInterview.getInterviewData({candidateId, jobApplicationId}).then(response => {
			console.log(response)
			if(!response.success) return
			setInterviewData(response.data)
		}).finally(() => setIsLoading(false))
	}, [candidateId, jobApplicationId])
	const breadcrumbItems = [
		{
			name: 'Job',
			to: '/AP_01',
			isCurrent: true
		},
	];

	const handleSign = async () => {
		const formdata = new FormData();
		const blobFile = setSignatureFile(base64Image);
		formdata.append("signature", blobFile);

		const response = await api.jobApplications.signSignatureApplicant(token, formdata)
		toast(response.message, {
			type: response.success ? 'success' : 'error'
		})
		if(response.success) {
			setShowButtons(false)
		}
	}
	const [showButtons, setShowButtons] = useState(true)

	return (<>
		{isLoading ? <PageLoader /> :
		interviewData?.publicURL ?
				<iframe
				// license="GPLv3"
				src={interviewData?.publicURL}
				style={{ width: '100%',  height: '100vh' }}
				// waitForLoad
				allow="camera; microphone"
			/> :
			<p>Error</p>
		}
	</>
	);
}

export default VideoInterview

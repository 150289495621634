import React, { useState } from 'react'
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import { Footer } from '../components/parts/Footer'

import { ReactComponent as ArrowRight } from "../assets/img/arrow-right.svg"
import { Link } from 'react-router-dom';


const OfferStats = ({ totalOffers, totalAccepted }) => (
    <table>
        <thead>
            <tr >
                <th className="w-1/2 relative px-8 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                    <div className="flex items-center text-center justify-center">
                        <span className="" >Total Offers</span>
                    </div>
                </th>
                <th className="w-1/2 relative px-8 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                    <div className="flex items-center text-center justify-center">
                        <span className="" >Total Number of Offer Accepted</span>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td
                    className={`text-center px-8 h-20 whitespace-nowrap`}
                >
                    {totalOffers}
                </td>
                <td
                    className={`text-center px-8 h-20 whitespace-nowrap`}
                >
                    {totalAccepted}
                </td>
            </tr>
        </tbody>
    </table>
);


const SourceRow = ({ rowData, isEven }) => (
    <tr>
        {rowData.map((children, idx) => (
            <td
                key={idx}
                className={`${idx > 0 ? "text-center" : ""} px-8 h-20 whitespace-nowrap border-b border-solid border-zinc-300 ${!isEven ? "bg-neutral-100" : ""}`}
            >
                {children}
            </td>
        ))}
    </tr>
);

const DoubleValue = ({ value1, value2 }) => {
    return (
        (<div className="flex gap-3 justify-center px-20 py-7 max-md:px-5">
            <div className="font-bold">{value1}%</div>
            <div>{value2}</div>
        </div>)
    )
}


const SBM04_4 = () => {
    const [job, setJob] = useState()
    const [timePeriod, setTimePeriod] = useState()
    const hadleSelectJob = () => {
        setJob()
    }
    const handleSelectTimePeriod = () => {
        setTimePeriod()
    }

    const jobs = [
        { value: 'cleaning_technician', label: 'Cleaning Technician' },
        { value: 'job_2', label: 'job 2' },
        { value: 'job_3', label: 'job 3' },
    ];

    const totalOffers = 100;
    const totalAccepted = 50;

    const sources = [
        {
            source: "Hirelogy Smart Post",
            applicants: 168,
            movedToCandidate: { percentage: 21, value: 42 },
            completedInterviews: { percentage: 21, value: 42 },
            hired: { percentage: 21, value: 42 },
        },
        {
            source: "Career Builder",
            applicants: 123,
            movedToCandidate: { percentage: 21, value: 42 },
            completedInterviews: { percentage: 21, value: 42 },
            hired: { percentage: 21, value: 42 },
        },
        {
            source: "Indeed - Organic",
            applicants: 234,
            movedToCandidate: { percentage: 21, value: 42 },
            completedInterviews: { percentage: 21, value: 42 },
            hired: { percentage: 21, value: 42 },
        },
        {
            source: "Carrer Site",
            applicants: 22,
            movedToCandidate: { percentage: 21, value: 42 },
            completedInterviews: { percentage: 21, value: 42 },
            hired: { percentage: 21, value: 42 },
        },
        {
            source: "Internal",
            applicants: 5,
            movedToCandidate: { percentage: 100, value: 8 },
            completedInterviews: { percentage: 21, value: 42 },
            hired: { percentage: 21, value: 42 },
        },
    ];



    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Insights', 'Offers']} />
            <section className="flex justify-center flex-auto py-9 lg:py-11 w-full text-zinc-800">
                <div className='flex flex-col gap-9 lg:gap-11 items-center w-full max-w-[1432px] px-4'>
                    <div className="flex flex-col w-full">
                        <div className="w-full flex max-lg:flex-col lg:gap-5 justify-between text-lg">
                            <div className="flex flex-col flex-1 max-w-[550px]">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="Job"
                                    name="job"
                                    id="job"
                                    value={job}
                                    onChange={hadleSelectJob}
                                    options={jobs}
                                />
                            </div>
                            <div className="flex flex-col flex-1 max-w-[550px] whitespace-nowrap">
                                <InputField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="Time Period"
                                    labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                    type="date" name="timePeriod" id="timePeriod"
                                    value={timePeriod}
                                    onChange={handleSelectTimePeriod}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col justify-center w-full">
                        <h1 className="w-full text-3xl text-zinc-800 max-md:max-w-full">
                            Career Site ROI
                        </h1>
                        <div className='flex flex-col overflow-hidden justify-center mt-6 w-full text-lg rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:max-w-full'>
                            <OfferStats totalOffers={totalOffers} totalAccepted={totalAccepted} />
                        </div>
                    </div>

                    <div className="flex flex-col justify-center w-full">
                        <div className="flex gap-5 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                            <div className="flex-auto my-auto text-3xl text-zinc-800">
                                Conversion by Sources
                            </div>
                            <Link to={"/SBM04_1_1"}
                                className="flex gap-2 items-center w-full lg:max-w-[150px] justify-center px-8 py-2 h-[54px] text-lg font-semibold text-center whitespace-nowrap bg-white rounded-md border border-solid border-zinc-300 text-stone-500 max-md:px-5"
                            >
                                <span className='flex items-center justify-center'>
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_268_2595)">
                                            <path d="M21.5 14.25V19.5C21.5 19.8978 21.342 20.2794 21.0607 20.5607C20.7794 20.842 20.3978 21 20 21H5C4.60218 21 4.22064 20.842 3.93934 20.5607C3.65804 20.2794 3.5 19.8978 3.5 19.5V14.25C3.5 14.0511 3.57902 13.8603 3.71967 13.7197C3.86032 13.579 4.05109 13.5 4.25 13.5C4.44891 13.5 4.63968 13.579 4.78033 13.7197C4.92098 13.8603 5 14.0511 5 14.25V19.5H20V14.25C20 14.0511 20.079 13.8603 20.2197 13.7197C20.3603 13.579 20.5511 13.5 20.75 13.5C20.9489 13.5 21.1397 13.579 21.2803 13.7197C21.421 13.8603 21.5 14.0511 21.5 14.25ZM11.9694 14.7806C12.039 14.8504 12.1217 14.9057 12.2128 14.9434C12.3038 14.9812 12.4014 15.0006 12.5 15.0006C12.5986 15.0006 12.6962 14.9812 12.7872 14.9434C12.8783 14.9057 12.961 14.8504 13.0306 14.7806L16.7806 11.0306C16.8856 10.9257 16.9572 10.792 16.9861 10.6465C17.0151 10.5009 17.0003 10.35 16.9435 10.2129C16.8867 10.0758 16.7904 9.95861 16.667 9.87621C16.5435 9.79381 16.3984 9.74988 16.25 9.75H13.25V3.75C13.25 3.55109 13.171 3.36032 13.0303 3.21967C12.8897 3.07902 12.6989 3 12.5 3C12.3011 3 12.1103 3.07902 11.9697 3.21967C11.829 3.36032 11.75 3.55109 11.75 3.75V9.75H8.75C8.60158 9.74988 8.45646 9.79381 8.33301 9.87621C8.20957 9.95861 8.11335 10.0758 8.05653 10.2129C7.99972 10.35 7.98487 10.5009 8.01385 10.6465C8.04284 10.792 8.11437 10.9257 8.21937 11.0306L11.9694 14.7806Z" fill="#37B34A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_268_2595">
                                                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span>Export</span>
                            </Link>
                        </div>
                        <div className="flex flex-col overflow-auto justify-center mt-6 w-full text-lg rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:max-w-full">
                            <table className="w-full">
                                <thead>
                                    <tr className='w-full'>
                                        <th className="relative px-8 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                                            <div className="text-left">Source</div>
                                        </th>
                                        <th className="relative px-8 h-[90px] min-w-[250px] w-[250px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                                            <div className="flex items-center text-center justify-center">
                                                <span className="max-w-24" >Applicants</span>
                                                <span className='absolute right-0 z-[1] translate-x-2/4 w-10 aspect-[2.22]'>
                                                    <ArrowRight />
                                                </span>
                                            </div>
                                        </th>
                                        <th className='relative px-8 h-[90px] min-w-[250px] w-[250px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="flex items-center text-center justify-center">
                                                <span className="max-w-24" >Moved to Candidate</span>
                                                <span className='absolute right-0 z-[1] translate-x-2/4 w-10 aspect-[2.22]'>
                                                    <ArrowRight />
                                                </span>
                                            </div>
                                        </th>
                                        <th className='relative px-8 h-[90px] min-w-[250px] w-[250px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="flex items-center text-center justify-center">
                                                <span className="max-w-24" >Completed Interviews</span>
                                                <span className='absolute right-0 z-[1] translate-x-2/4 w-10 aspect-[2.22]'>
                                                    <ArrowRight />
                                                </span>
                                            </div>
                                        </th>
                                        <th className='relative px-8 h-[90px] min-w-[250px] w-[250px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Hired</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sources.map((source, idx) => (
                                        <SourceRow
                                            key={idx}
                                            rowData={[
                                                source.source,
                                                source.applicants,
                                                <DoubleValue value1={source.movedToCandidate.percentage} value2={source.hired.value} />,
                                                <DoubleValue value1={source.completedInterviews.percentage} value2={source.completedInterviews.value} />,
                                                <DoubleValue value1={source.hired.percentage} value2={source.hired.value} />
                                            ]}
                                            isEven={idx % 2 === 0}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM04_4
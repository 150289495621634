import React from 'react'
import DropDown from './DropDown';
import { Link } from 'react-router-dom';
import api from '../../api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/slices/applicationSlice';
const ROLES = {
    admin: 'Admin User',
    member: 'Team Member User',
    interviewer: 'Committee Member',
    archived: 'Archived'
}

const BlueHedingTable = ({ tableData, companyId, deleteItem, updateItem }) => {
    console.log(tableData)
    const user = useSelector(getUser)
    console.log(user)
    const handleDelete = async (item) => {
        const response = await api.companyTeam.deleteMember(companyId, item.userId);
        console.log(response);
        toast(response.message, {
            type: response.success ? 'success' : 'error'
        })
        if (response.success) {
            deleteItem(item.id)
        }
    }
    const handleArchieve = async (item) => {
        const response = await api.companyTeam.updateTeamMembers(companyId, item.userId, {
            role: item?.role !== "archived" ? 'archived' : "interviewer"
        });
        console.log(response);
        toast(response.success ? 'Archieved Successfully' : response.message, {
            type: response.success ? 'success' : 'error'
        })
        if (response.success) {
            updateItem(response.data)
        }
    }
    console.log(user);
    return (
        <div className='max-lg:overflow-auto whitespace-nowrap'>
            <table className='w-full'>
                <thead>
                    <tr>
                        <th
                            className='px-[20px] md:px-[32px] text-start text-white text-[20px] font-bold py-[16px] md:py-[25px] border-l border-solid bg-[#003578] border-zinc-300'>Name</th>
                        <th
                            className='px-[20px] md:px-[32px] text-start text-white text-[20px] font-bold py-[16px] md:py-[25px] border-l border-solid bg-[#003578] border-zinc-300'>Email</th>
                        <th
                            className='px-[20px] md:px-[32px] text-start text-white text-[20px] font-bold py-[16px] md:py-[25px] border-l border-solid bg-[#003578] border-zinc-300'>Role</th>
                        <th
                            className='px-[20px] md:px-[32px] text-start text-white text-[20px] font-bold py-[16px] md:py-[25px] border-l border-solid bg-[#003578] border-zinc-300'>Confirmed</th>
                        <th
                            className='px-[20px] md:px-[32px] text-start text-white text-[20px] font-bold py-[16px] md:py-[25px] border-l border-solid bg-[#003578] border-zinc-300'
                            style={{
                                width: '10px'
                            }}
                        ></th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map(item => (
                        <tr key={item.id}>
                            <td className='px-[20px] md:px-[32px] text-start text-[18px] py-[16px] md:py-[25px] border border-solid bg-[#F7F7F7] border-zinc-300'>
                                {item.name}
                            </td>
                            <td className='px-[20px] md:px-[32px] text-start text-[18px] py-[16px] md:py-[25px] border border-solid bg-[#F7F7F7] border-zinc-300'>
                                {item.email}
                            </td>
                            <td className='px-[20px] md:px-[32px] text-start text-[18px] py-[16px] md:py-[25px] border border-solid bg-[#F7F7F7] border-zinc-300'>
                                {ROLES[item.role]}
                            </td>
                            <td className='px-[20px] md:px-[32px] text-start text-[18px] py-[16px] md:py-[25px] border border-solid bg-[#F7F7F7] border-zinc-300'>
                                {item.confirm ?
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 23.3809L10.0605 18.4414L7.93945 20.5624L15 27.6229L29.5605 13.0624L27.4395 10.9414L15 23.3809Z" fill="#37B34A" />
                                    </svg>
                                    :

                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 23.3809L10.0605 18.4414L7.93945 20.5624L15 27.6229L29.5605 13.0624L27.4395 10.9414L15 23.3809Z" fill="#7C877E" />
                                    </svg>
                                }
                            </td>
                            <td className='px-[20px] md:px-[32px] text-start text-[18px] py-[16px] md:py-[25px] border border-solid bg-[#F7F7F7] border-zinc-300'>
                                <DropDown disabled={item.editable}>
                                    <li>
                                        <Link to={item.editable?.edit ? item.editable?.edit : "/SBM05_3"}>Edit</Link>
                                    </li>
                                    <li className='dropdown-deliver'></li>
                                    {
                                        user?.id !== item?.userId && user?.companies[0]?.role !== "interviewer" ?
                                            <>
                                                <li>
                                                    <a href='' onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDelete(item)
                                                    }}>Delete...</a>
                                                </li>
                                                <li>
                                                    <a href="" onClick={e => {
                                                        e.preventDefault()
                                                        handleArchieve(item)
                                                    }}>
                                                        {
                                                            item.role === "archived" ? "Unarchieve..." : "Archieve..."
                                                        }
                                                    </a>
                                                </li>
                                            </>
                                            : null
                                    }
                                </DropDown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default BlueHedingTable

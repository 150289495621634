import {useSelector} from "react-redux";
import {getCurrentPage, getCurrentPageLoading} from "../../redux/slices/applicationSlice";
import findValue from "../../assets/helpers/findValue";
import PageLoader from "../../components/PageLoader";
import {AiTools} from "../../components/parts/publickPageParts/AiTools";
import {TotalBlock} from "../../components/parts/publickPageParts/TotalBlock";
import {TeamByCategory} from "../../components/parts/publickPageParts/TeamByCategory";
import HeroSection from "../../components/parts/publickPageParts/HeroSection";
import {RecruitSmarter} from "../../components/parts/publickPageParts/RecruitSmarter";
import {HowItWorks} from "../../components/parts/publickPageParts/HowItWorks";
import {ClientsComments} from "../../components/parts/publickPageParts/ClientsComments";
import {BlogsSection} from "../../components/parts/publickPageParts/BlogsSection";
import {WhyChose} from "../../components/parts/publickPageParts/WhyChose";
import React from "react";
import getImageUrl from "../../assets/helpers/getImageUrl";

const generateDataFromBlocks = (blocks) => {
    return {
        aiTools: {
            title: findValue(blocks, "features_label"),
            header: findValue(blocks, "features_title"),
            buttonText: findValue(blocks, "features_button"),
            tools: (findValue(blocks, "features_list")?.data || []).map(tool => ({
                title: tool.title,
                image: tool.image
            }))
        },
        teamCategories: {
            label: findValue(blocks, "jobs_label"),
            title: findValue(blocks, "jobs_title"),
            linkText: findValue(blocks, "jobs_link"),
            categories: (findValue(blocks, "jobs_list")?.data || []).map(category => ({
                image: getImageUrl(category.image),
                label: category.label,
                title: category.title,
                text: category.text
            }))
        },
        achievements: {
            stats: (findValue(blocks, "achievements_list")?.data || []).map(item => ({
                title: item.label,
                count: item.text,
            }))
        },
        howItWorks: {
            title: findValue(blocks, "how_label"),
            header: findValue(blocks, "how_title"),
            buttonText: findValue(blocks, "how_button"),
            steps: (findValue(blocks, "how_list")?.data || []).map((step, index) => ({
                step: "Step" + " " + (index + 1),
                icon: step.image,
                title: step.title,
                description: step.text,
            })),
            button: findValue(blocks, "how_button")
        },
        testimonials: {
            title: findValue(blocks, "testimonials_label"),
            header: findValue(blocks, "testimonials_title"),
            testimonials: (findValue(blocks, "testimonials_list")?.data || []).map(testimonial => ({
                image: getImageUrl(testimonial.image),
                text: testimonial.text,
                name: testimonial.name,
                job: testimonial.job
            })),
            button: findValue(blocks, "testimonials_button"),
        },
        pricing: {
            title: findValue(blocks, "our_pricing_label"),
            header: findValue(blocks, "our_pricing_title"),
            description: [findValue(blocks, "our_pricing_text")],
            button: findValue(blocks, "our_pricing_button"),
            features: (findValue(blocks, "our_pricing_list")?.data || []).map(item => ({
                img: item.icon,
                text: item.tilte,
            })),
            image: findValue(blocks, "our_pricing_image")
        },
        blogs: {
            title: findValue(blocks, "blog_title_home"),
            header: findValue(blocks, "blog_description_home"),
            button: findValue(blocks, "blog_button_home"),
        },
    };
};


const HOM1_1_new = () => {
    const currentPage = useSelector(getCurrentPage);
    const data = generateDataFromBlocks(currentPage?.blocks || []);

    return (
        <>
            {!currentPage ? <PageLoader/> :
                <div className="public-page">
                    <HeroSection blocks={currentPage?.blocks}/>
                    <RecruitSmarter
                        title={data.pricing.title}
                        header={data.pricing.header}
                        description={data.pricing.description}
                        button={data.pricing.button}
                        features={data.pricing.features}
                        image={data.pricing.image}
                    />
                    <WhyChose blocks={currentPage?.blocks}/>
                    <AiTools data={data.aiTools}/>
                    <TeamByCategory data={data.teamCategories}/>
                    <TotalBlock data={data.achievements}/>
                    <HowItWorks data={data.howItWorks} topSpacing={"pt-[50px] pb-[50px]"}/>
                    <ClientsComments
                        topSpacing="pt-[20px] pb-[50px]"
                        title={data.testimonials.title}
                        header={data.testimonials.header}
                        testimonials={data.testimonials.testimonials}
                        buttonText={data.testimonials.button}
                    />
                    {/*<PageFAQSecion/>*/}
                    <BlogsSection blocks={data?.blogs} withBlock={false} topSpacing={"pt-[30px] pb-[50px]"}/>
                </div>
            }
        </>
    )
}

export default HOM1_1_new

import React from "react";

const MilestoneBar = ({ percentage, colorClass, label, index }) => (
	<div
		className={`overflow-hidden relative flex flex-col justify-center items-center  max-w-full text-center min-h-[44px] text-white`}
		style={{ width: `${100 - (11 - index) * 7}%` }}
	>
		<div className="text-white" style={{ zIndex: 2, textShadow: '0px 1px 4px #00000052' }}>{label}</div>
		<div className="absolute inset-0 w-full h-full flex justify-center items-center">
			<div
				className={`w-full h-11 ${colorClass}`}
				style={{
					clipPath: window.innerWidth > 1200 ?
					'polygon(0px 0px, 100% 0px, calc(100% - 20px) 100%, 20px 100%)'
					: 'polygon(0px 0px, 100% 0px, calc(100% - 10px) 100%, 10px 100%)',
				}}
			></div>
		</div>

	</div>
);

const MilestoneVisualization = ({ data }) => {

	const milestones = [
		{ stageKey: 'clarifications', colorClass: 'bg-rose-800', index: 11 },
		{ stageKey: '1st-interview', colorClass: 'bg-red-600', index: 10 },
		{ stageKey: 'post_interview_survey', colorClass: 'bg-orange-400', index: 9 },
		{ stageKey: '2nd-interview', colorClass: 'bg-orange-300', index: 8 },
		{ stageKey: 'doc_review', colorClass: 'bg-amber-200', index: 7 },
		{ stageKey: 'rating_of_the_job', colorClass: 'bg-yellow-100', index: 6 },
		{ stageKey: 'ai_evaluation', colorClass: 'bg-lime-200', index: 5 },
		{ stageKey: 'final_hiring_decisions', colorClass: 'bg-lime-300', index: 4 },
		{ stageKey: 'training_insights', colorClass: 'bg-green-400', index: 3 },
		{ stageKey: 'offer_meeting', colorClass: 'bg-green-600', index: 2 },
		{ stageKey: 'training_milestones', colorClass: 'bg-emerald-800', index: 1 },
	];

	const calculateAverage = (arr) => {
		if (arr.length === 0) return 0;
		const sum = arr.reduce((acc, num) => acc + num, 0);
		return (sum / arr.length).toFixed(2);
	};

	return (
		<section className="flex flex-col flex-auto justify-center max-w-[320px] xl:max-w-[546px] items-center text-lg font-bold text-white whitespace-nowrap">
			<h2 className="text-xl leading-8 text-zinc-800 mb-4 montserrat max-md:text-[18px]">Candidates per Milestone</h2>
			{milestones.map((milestone, idx) => {
				const stageData = data[milestone.stageKey] || { count: 0, candidates: [] };
				const averageScore = stageData.candidates.length ? calculateAverage(stageData.candidates) + "%" : '';
				return (
					<MilestoneBar
						key={idx}
						index={milestone.index}
						percentage={stageData.count || 0}
						colorClass={milestone.colorClass}
						label={`${stageData.count || 0} ${averageScore}`}
					/>
				);
			})}
		</section>
	);
};

export default MilestoneVisualization;

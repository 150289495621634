import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const createAnhors = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const headings = [];

    for (let i = 1; i <= 6; i++) {
        const headingElements = doc.querySelectorAll(`h${i}`);
        headingElements.forEach(element => {
            const name = element.textContent.trim();
            const id = normalizeString(name)
            element.dataset.anhorId = id
            headings.push({ name, id });
            
        });
    }
    return headings;
}
const findElement = (value) => {
    for (let i = 1; i <= 6; i++) {
        const headingElements = document.querySelectorAll(`h${i}`);
        for (const element of headingElements) {
            const name = element.textContent.trim();
            // const id = name.toLowerCase().replaceAll(" ", "_");
            const id = normalizeString(name)
            if (id === value) {
                return element;
            }
        }
    }
    return null;
}
const normalizeString = (inputString) => {
    const sanitizedString = inputString.replace(/[^\w\s]/gi, '');
    return sanitizedString.replace(/\s+/g, '_').toLowerCase();
}

const ScrollToAnhor = () => {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const id = hash.split("#").pop()
            const element = findElement(id)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [pathname, hash]);

    return null;
}


const SidebarAnhors = ({ anhors, ordered = false, orderFrom = 0, htmlContent = "", style = "" }) => {
    const [activeAnhor, setActiveAnhor] = useState(null)
    const [isScrolling, setIsScrolling] = useState(false)
    const navigate = useNavigate()

    const sidebarAnhors = useMemo(() => anhors || createAnhors(htmlContent), [anhors, htmlContent]);

    const handleScroll = () => {
       
        const inViewportElement = sidebarAnhors.find(anhor => {
            const element = findElement(anhor.id)
            if (element) {
                const rect = element.getBoundingClientRect();
                return rect.top >= 0 && rect.bottom <= window.innerHeight
            }
            return false
        });
        if (inViewportElement) {
            setActiveAnhor(inViewportElement.id)
            navigate({
                hash: inViewportElement.id
            })
        }
    };

    useEffect(() => {
        if (!isScrolling) {
            window.addEventListener('scroll', handleScroll);
        } else {
            window.removeEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolling]);

    const timeout = useRef(null)
    const handleClick = (anhor) => {
        setActiveAnhor(anhor.id)
        navigate({
            hash: anhor.id
        })
        clearTimeout(timeout.current)
        setIsScrolling(true)
        timeout.current = setTimeout(() => {
            setIsScrolling(false)
        }, 1000)
    }

    if(!sidebarAnhors.length) {
        return null
    }

    return (
        <>
            {
                style === "dark" ?
                    <aside className="flex flex-col order-first md:order-last top-6 h-fit lg:sticky ml-5 mb-[80px] md:mb-[0px] w-[32%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col p-[36px] w-full text-lg font-bold text-white rounded-xl bg-neutral-700 max-md:px-5">
                            <div className="text-3xl leading-9">Contents</div>
                            <div className="flex flex-col mt-3 text-white top-[80px]">
                                {
                                    sidebarAnhors.map((anhor, index) => (
                                        <div
                                            onClick={() => handleClick(anhor)}
                                            key={index}
                                            className={`${anhor.id === activeAnhor ? "border-green-500 border-l-[6px]" : "border-[#EBEBEB] border-l-[3px] ml-[3px]"} cursor-pointer justify-center items-start py-3 pl-7 border-solid max-md:px-5`}>
                                            <span>{anhor.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </aside>
                    :
                    <div className="flex flex-col lg:text-xl text-lg font-bold leading-8 top-6 h-fit max-h-[calc(100vh-3rem)] overflow-y-auto lg:sticky text-zinc-800 w-full lg:w-[380px] lg:min-w-[380px]">
                        <div className="flex flex-col lg:mt-3 sticky top-[80px]">
                            {sidebarAnhors.map((anhor, idx) => (
                                <div
                                    onClick={() => handleClick(anhor)}
                                    key={idx}
                                    className={`${anhor.id === activeAnhor ? "border-green-500 border-l-[6px]" : "border-[#EBEBEB] border-l-[3px] ml-[3px]"} pl-[22px] cursor-pointer flex items-center py-3 min-h-[54px] border-solid`}>

                                    <span>{ordered && orderFrom <= idx ? <span>{idx + 1 - orderFrom}. </span> : null} {anhor.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
            }

            <ScrollToAnhor />

        </>
    );
}

export default SidebarAnhors
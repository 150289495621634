const base = 'api/job-application-documents';

export const jobApplicationDocuments = (instance) => ({
    async getAllDocuments({ applicationId, query = "" }) {
        const { data } = await instance.get(`${base}/${applicationId}?${query}`);
        return data;
    },
    async uploadDocumentsToAppication({ applicationId, body }) {
        const { data } = await instance.post(`${base}/${applicationId}`, body);
        return data;
    },
    async getAllDocumentsOfApplication({ applicationId, token }) {
        const { data } = await instance.get(`api/job-applications/docs/${applicationId}/${token}`);
        return data;
    },
    async sendToApplicantLinkOnDocumentsReview({ applicationId }) {
        const { data } = await instance.get(`api/job-applications/docs/send/${applicationId}`);
        return data;
    },
    async deleteDocumentById({ applicationId, documentId }) {
        const { data } = await instance.delete(`${base}/${applicationId}/${documentId}`);
        return { ...data, data: { id: documentId } };
    },
});


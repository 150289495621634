import React, { useEffect } from "react";
import { createPortal } from "react-dom";

const ModalTemplate = ({ open, onClick, modalSize = 'modal__dialog--840' , children}) => {
    useEffect(() => {
        document.body.classList.add('lock')
        return () => {
            document.body.classList.remove('lock')
        }
    }, [])
    return createPortal(
        <div className={`modal modal-1 ${ open ? 'modal--show' : ''}`} onClick={onClick}>
            <div className={`modal__dialog ${modalSize}`} >
                <div className="modal__content" onClick={(e) => e.stopPropagation()}>
                    {children}
                </div>
            </div>
        </div>
    , document.body)
}

export default ModalTemplate
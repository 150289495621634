import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchLogout} from "../../../redux/thunks/applicationThunks";
import {getUser} from "../../../redux/slices/applicationSlice";
import LogoBlack from "../../../assets/img/logo-black.png";
import {ReactComponent as LoginIcon} from "../../../assets/img/login.svg";
import {ReactComponent as BurgerIcon} from "../../../assets/img/burger.svg";

const userAvatarPlaceholder = "https://cdn.builder.io/api/v1/image/assets/TEMP/163c2d226fc8456e0b676e882ce1c6248ffa45a572786bc2c7f3da287c35a10b?apiKey=bbf94b715ef442f4991a974fece2b44a&";

const UserGreeting = ({userName, userAvatar, className}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    const handleOpen = () => setOpen(!open);

    useEffect(() => {
        const closeHandler = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };

        if (open) {
            document.addEventListener("click", closeHandler);
        }

        return () => {
            document.removeEventListener("click", closeHandler);
        };
    }, [open]);

    const logout = async () => {
        const res = await dispatch(fetchLogout());
        if (res.payload.success) {
            navigate("/HOM1_1");
        }
    };

    return (
        <div className="relative" ref={ref}>
            <a
                href="#"
                onClick={handleOpen}
                className={`flex gap-2 justify-between items-center py-3 font-bold whitespace-nowrap ${className}`}
            >
                <img
                    loading="lazy"
                    src={userAvatar || userAvatarPlaceholder}
                    alt="User avatar"
                    className="aspect-square w-[22px]"
                />
                <div className="grow">{`Hi, ${userName}`}</div>
            </a>
            <div className={`header-dropdown ${open ? "open" : ""}`}>
                <NavLink to="/SBM05_1_1">
                    <p className="text-center header-drop-links">Account</p>
                </NavLink>
                <p onClick={logout} className="text-center header-drop-links">Log Out</p>
            </div>
        </div>
    );
};

export const HeaderNew = ({colorStyle}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {pathname} = useLocation();
    const user = useSelector(getUser);

    useEffect(() => {
        setIsMenuOpen(false);
    }, [pathname]);

    useEffect(() => {
        document.querySelector("body").style.overflow = isMenuOpen ? "hidden" : "visible";
    }, [isMenuOpen]);

    return (
        <header
            className={`${colorStyle === "black" ? "text-black bg-white" : "text-white"} flex justify-center items-center self-stretch pb-[17px] pt-[34px] max-sm:pt-[17px] w-full text-lg whitespace-nowrap border-b border-solid border-b-[#000000] z-50 font-['Raleway'] sticky top-0`}
        >
            <nav className="flex items-center gap-5 justify-between full container">
                <NavLink to="/HOM1_1" className="flex" onClick={() => setIsMenuOpen(false)}>
                    <img
                        loading="lazy"
                        src={colorStyle === "black" ? LogoBlack : "https://cdn.builder.io/api/v1/image/assets/TEMP/93718b1fa9850a912acec012c7bea638ca293464e1ad432c61a7496242da640a?apiKey=9106ba04c02e4dceae488e75e2ac4862&"}
                        className="max-w-full aspect-[3.23] xl:w-[223px] w-[223px] max-h-[58px]"
                        alt="GreatHire Logo"
                    />
                </NavLink>

                <div
                    className={`ml-auto flex gap-[8px] flex-col xl:flex-row items-center my-auto xl:static fixed inset-0 top-[110px] transition-transform ${isMenuOpen ? "" : "-translate-x-full"} xl:translate-x-0 xl:p-0 py-7 px-4 xl:w-auto w-full ${colorStyle === "black" ? "bg-white" : "bg-[url(https://cdn.builder.io/api/v1/image/assets%2F9106ba04c02e4dceae488e75e2ac4862%2F14a2333ed5504c789df293d766cde515)]"} xl:bg-none`}
                >
                    <NavLink
                        to="/HOM1_1"
                        className={({isActive, isPending}) => `items-center flex self-stretch hover:text-[#003578] p-[10px] hover:text-shadow-lg ${isActive ? "text-[#003578] " : ""}`}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Home
                    </NavLink>
                    <NavLink
                        to="/HOM1_2"
                     className={({isActive, isPending}) => `items-center flex self-stretch hover:text-[#003578] p-[10px] hover:text-shadow-lg ${isActive ? "text-[#003578] " : ""}`}
                    >
                        Why EmployJoy
                    </NavLink>
                    {/*<NavLink*/}
                    {/*    to="/solutions"*/}
                    {/*   className={({isActive, isPending}) => `items-center flex self-stretch hover:text-[#003578] p-[10px] hover:text-shadow-lg ${isActive ? "text-[#003578] " : ""}`}*/}
                    {/*>*/}
                    {/*    Solutions*/}
                    {/*</NavLink>*/}
                           <NavLink
                        to="/blogs"
                       className={({isActive, isPending}) => `items-center flex self-stretch hover:text-[#003578] p-[10px] hover:text-shadow-lg ${isActive ? "text-[#003578] " : ""}`}
                    >
                        Blogs
                    </NavLink>
                    <NavLink
                        to="/career"
                         className={({isActive, isPending}) => `items-center flex self-stretch hover:text-[#003578] p-[10px] hover:text-shadow-lg ${isActive ? "text-[#003578] " : ""}`}
                    >
                        Career
                    </NavLink>
                    <NavLink
                        to="/HOM_1_10"
                    className={({isActive, isPending}) => `items-center flex self-stretch hover:text-[#003578] p-[10px] hover:text-shadow-lg ${isActive ? "text-[#003578] " : ""}`}
                    >
                        Contact Us
                    </NavLink>
                    {/*<NavLink*/}
                    {/*    to="/HOM_1_11"*/}
                    {/*className={({isActive, isPending}) => `items-center flex self-stretch hover:text-[#003578] p-[10px] hover:text-shadow-lg ${isActive ? "text-[#003578] " : ""}`}*/}
                    {/*>*/}
                    {/*    Terms & Conditions*/}
                    {/*</NavLink>*/}
                    {!user && (
                        <NavLink
                            to="/HOM1_6"
                            className="flex justify-center px-[10px] py-[8px] font-bold bg-[#0056B3]  hover:bg-blue-800 rounded-md text-white gap-1"
                        >
                            <LoginIcon/>
                            Login
                        </NavLink>
                    )}
                    <NavLink
                        to="/request-demo"
                        className="flex max-xl:hidden justify-center px-[10px] py-[8px] font-bold bg-[#37B34A] hover:bg-green-600 rounded-md text-white"
                    >
                        Book a Demo
                    </NavLink>
                    {user && <UserGreeting userName={user.firstName} userAvatar={null}/>}
                </div>

                <div className="flex items-center gap-[10px] xl:hidden">
                    <NavLink
                        to="/request-demo"
                        className="flex items-center justify-center px-[16px] h-11 text-sm font-bold bg-[#37B34A] hover:bg-green-600 rounded-md text-white max-sm:px-[10px] max-sm:h-9"
                    >
                        Book a Demo
                    </NavLink>
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="flex items-center justify-center rounded-full aspect-square h-11 border-solid border border-1 border-transparent hover:border-white hover:bg-white hover:bg-opacity-20 mr-[-10px]"
                    >
                        <span className="flex items-center justify-center aspect-square w-6">
                          <BurgerIcon/>
                        </span>
                    </button>
                </div>
            </nav>
        </header>
    );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const getCompaniesListFetch = createAsyncThunk(
    '/get-companies-list/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.companies.getCompaniesList(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getAllCompanies = createAsyncThunk(
    '/get-companies/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.companies.getAllCompanies(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getCompanyPublicInfo = createAsyncThunk(
    '/get-company-public-info/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.companies.getCompanyPublicInfo(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getCompanyInfoByMemberAccess = createAsyncThunk(
    '/get-company-info-member/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.companies.getCompanyInfoByMemberAccess(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const updateCompanyInfo = createAsyncThunk(
    '/update-company-info/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.companies.updateCompanyInfo(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const deleteCompany = createAsyncThunk(
    '/delete-company/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.companies.deleteCompany(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchAllIndustries = createAsyncThunk(
    '/fetch-industries/',
    async (_, { rejectWithValue }) => {
        try {
            const res = await api.companies.getAllIndustries();
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchDemoApplication = createAsyncThunk(
    '/fetch-demo/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.companies.createDemoApplication(payload);

            return res;

        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchBuildCompanies = createAsyncThunk(
    '/fetch-build-companies/',
    async (token, { rejectWithValue }) => {
        try {
            const res = await api.companies.getBuildCompanies(token);

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }

        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateBuildCompanies = createAsyncThunk(
    '/fetch-create-build-companies/',
    async (payload, { rejectWithValue }) => {
        try {
            const {token, ...data} = payload
            const res = await api.companies.createBuildCompanies(token, data);

            return res;

        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateCompanies = createAsyncThunk(
    '/fetch-create-companies/',
    async (payload, { rejectWithValue }) => {
        try {
            const {token, ...data} = payload
            const res = await api.companies.createBuildCompanies(token, data);

            return res;

        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUpdateBuildCompanies = createAsyncThunk(
    '/fetch-update-build-companies/',
    async (payload, { rejectWithValue }) => {
        try {
            const {id, ...data} = payload
            const res = await api.companies.updateBuildCompanies(id, data);

            return res;

        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getPublicSolutionPageByIdFetch } from '../../redux/thunks/solutionThunks'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PageLoader from '../../components/PageLoader'
import getImageUrl from '../../assets/helpers/getImageUrl'
import Breadcrumbs from '../../components/base/Breadcrumbs'
import { format } from 'date-fns'
import blocksImage from "../../assets/img/home/blocks.png";
import ReactPlayer from 'react-player'
const BlogSingle = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { article, isLoading } = useSelector(state => state.solution)
    useEffect(() => {
        if (!id) return
        dispatch(getPublicSolutionPageByIdFetch(id))
    }, [id])
    const breadcrumbs = [
        { name: 'Blogs', to: "/blogs" },
        { name: article?.name, to: "/", isCurrent: true }
    ]

    function isImageUrl(url) {
        return /\.(jpeg|jpg|gif|png|webp|bmp|svg|ico)$/i.test(url);
    }
    console.log(article)
    if (isLoading) return <PageLoader />
    if (!article) return <div className="flex items-center justify-center text-center flex-auto my-7">
        <h1 className="text-white text-7xl font-bold">Not Found</h1>
    </div>

    return (
        <>
            <section className='py-[10px] font-["Raleway"]'>
                <div className="container">
                    <Breadcrumbs links={breadcrumbs} colorStyle='secondary' />
                </div>
            </section>
            <section
                className='pb-[55px] pt-[20px] font-["Raleway"] bg-top bg-no-repeat'
                style={{ backgroundImage: `url(${blocksImage})` }}
            >
                <div className="container !max-w-[1000px]">
                    <div className={`flex flex-col gap-[17px] text-[18px] mb-[30px]`}>
                        <div className="flex flex-wrap">
                            <span className='px-[10px] bg-[#0056B3] rounded-[300px] text-white'>{article?.category?.name}</span>
                        </div>

                        <div className="flex justify-between items-center flex-wrap gap-4">
                            <div className="flex flex-col">
                                <div className='font-bold text-[24px] '>
                                    {article.user.firstName + " " + article.user.lastName}
                                </div>
                                <p>{format(article.createdAt, "MMMM dd yyyy")}</p>
                            </div>


                            <div className='flex flex-wrap gap-2'>
                                {article.tags?.length && article.tags.map((tag, index) => (
                                    <span key={index} className='px-[10px] bg-[#37B34A] rounded-[300px] text-white'>
                                        {tag?.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <h3 className="text-[45px] max-md:text-[32px] font-bold leading-[48px]">
                            {article?.name}
                        </h3>
                        <p>{article?.subTitle}</p>
                    </div>

                    <div className="flex items-center justify-center mb-[48px] max-h-[400px] overflow-hidden">
                        {isImageUrl(article.mainImage) ? <img
                            loading="lazy"
                            src={getImageUrl(article.mainImage)}
                            alt="Article Image"
                            className="object-contain w-full"
                        /> :
                            <div className="video-player">
                                <ReactPlayer
                                    light={<video src={getImageUrl(article.mainImage)}></video>}
                                    playing={true}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                    playIcon={
                                        <button className="bg-white rounded-full absolute w-[100px] h-[100px] max-md:w-[50px] max-md:h-[50px] flex items-center justify-center">
                                            <span className="ml-3 max-md:ml-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-[47px] h-[54px] max-md:w-[23px] max-md:h-[27px]" viewBox="0 0 47 54" fill="none">
                                                    <path d="M47 27L0.499997 53.8468L0.5 0.15321L47 27Z" fill="#A2A1A1" />
                                                </svg>
                                            </span>
                                        </button>
                                    }
                                    url={getImageUrl(article.mainImage)}
                                />
                            </div>
                        }
                    </div>
                    <div className="mb-[40px]">
                        <h3 className='text-[32px] max-md:text-[24px] font-bold mb-[10px]'>Post Summary</h3>
                        <p>{article?.postSummary}</p>
                    </div>
                    <div className="article" dangerouslySetInnerHTML={{ __html: article?.postBody }}></div>
                </div>
            </section>
        </>
    )
}

export default BlogSingle

import blocksImage from "../../../assets/img/home/blocks.png";
import arrowImage from "../../../assets/img/home/arrow.svg";
import CategoryCard from "./CategoryCard";
import React from "react";

export const TeamByCategory = ({ data }) => {

    return (
        <section className="w-full py-[110px] flex flex-col gap-9 relative font-['Raleway']">
            <div
                className="absolute top-0 left-0 w-full h-[140px] box-border bg-center"
                style={{ backgroundImage: `url(${blocksImage})` }}
            ></div>
            <div className="w-full container">
                <div className="flex flex-col">
                    <span className="text-lg font-semibold text-[#0056B3]">{data.label}</span>
                    <p className="flex w-full justify-between max-md:flex-col max-md:items-center">
                        <span className="text-5xl font-bold mt-2 inline-block max-w-[500px]">{data.title}</span>
                        <div className="self-end cursor-pointer max-md:self-start hover:opacity-90 max-md:mt-5">
                            <span className="text-3xl font-semibold underline underline-offset-8">{data.linkText}</span>
                            <img src={arrowImage} alt="arrow" className="ml-4" />
                        </div>
                    </p>
                </div>

                <div className="flex mt-9">
                    <div className="grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-5 m-auto h-307px">
                        {data.categories.map((category, idx) => (
                            <CategoryCard
                                key={idx}
                                img={category.image}
                                jobs={category.label}
                                title={category.title}
                                description={category.text}
                                withButton={false}
                                className={"min-h-[280px] h-full w-full"}
                                idx={idx}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

import { useCallback, useState, createRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import LoadIcon from "../../assets/img/load-icon.svg";
import ReplaceIcon from "../../assets/img/replace-icon.svg";
import DeleteIcon from "../../assets/img/delete-icon.svg";
import { Link } from 'react-router-dom';

const ButtonWithLabel = ({ onClick, label, altText }) => (
    <button
        className="flex gap-2 items-center justify-center px-8 py-2.5 rounded-md bg-neutral-200 hover:bg-neutral-300 focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2"
        onClick={onClick}
    >
        <img loading="lazy" src={label.src} alt={altText} className="w-6 h-6" />
        <span>{label.text}</span>
    </button>
);

const FileInputFieldMulty = ({ filesProp, boxClassName = "", loadBtnShow = false, handleChange = () => { }, acceptProp }) => {
    const inputRef = createRef(null);

    const [files, setFiles] = useState(filesProp || []);

    useEffect(() => {
        if (filesProp) {
            setFiles(Object.assign(filesProp, {
                preview: URL.createObjectURL(filesProp)
            }));
        }
    }, [filesProp]);

    useEffect(() => {
        handleChange && handleChange(files)
    }, [files]);


    const onDrop = useCallback(acceptedFiles => {
        const files = acceptedFiles.map(file => (
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        ))
        setFiles(files);

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptProp ? acceptProp : {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
        multiple: true,
    });

    return (
        <div className={`box-border flex flex-col items-start justify-center w-full ${boxClassName ? boxClassName : ''}`} ref={inputRef}>
            <div {...getRootProps()} className={`flex flex-col items-center h-52 justify-center rounded-[6px] p-[8px] md:p-[20px] cursor-pointer hover:bg-neutral-50 border border-solid border-neutral-300 mb-[16px] w-full`}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <div className="flex items-center gap-[20px] min-h-[44px]">
                        <p className="text-neutral-700">Drop the images here ...</p>
                    </div>
                ) : (
                    <div className="flex items-center w-full">
                        <p className="text-neutral-700 flex-1 text-center px-2">

                            {!files ? "No file choses" : "Drag 'n' drop some files here, or click to select files"}
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
};

export default FileInputFieldMulty;
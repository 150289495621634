import React, { useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from '../components/parts/Footer';
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import Checkbox from '../components/base/Checkbox';
import TextareaField from '../components/base/TextareaField';
import magicWandIcon from "../assets/img/magic-wand.svg";
import { Link } from 'react-router-dom';
import { HiringProcess } from '../components/base/ProgressStepsSBM';

// const ProgressBar = ({ completed, containerClasses = "" }) => {
//     const containerStyles = {
//         height: 4,
//         width: '100%',
//         backgroundColor: "#EFEEF0",
//     }

//     const fillerStyles = {
//         height: '100%',
//         width: `${completed}%`,
//         backgroundColor: '#003578',
//         borderRadius: 'inherit',
//         textAlign: 'right',
//         transition: 'width 1s ease-in-out',
//     }

//     const labelStyles = {
//         padding: 5,
//         color: 'white',
//         fontWeight: 'bold'
//     }

//     return (
//         <div style={containerStyles} className={containerClasses}>
//             <div style={fillerStyles}>
//                 <span style={labelStyles}>{`${completed}%`}</span>
//             </div>
//         </div>
//     );
// }

// const StepIndicatorItem = ({ stepNumber, label, isActive }) => {
//     const baseStyles = "justify-center items-center self-center w-10 h-10 text-xl leading-8 font-bold text-white border-4 border-white border-solid shadow-md rounded-[360px]";
//     const activeStyles = "bg-green-500";
//     const inactiveStyles = "bg-neutral-200";

//     return (
//         <div className="flex flex-col flex-auto max-w-40 px-2">
//             <div className={`${baseStyles} ${isActive ? activeStyles : inactiveStyles}`}>
//                 {stepNumber}
//             </div>
//             <div className="mt-3.5 text-base font-semibold text-neutral-600">
//                 {label.split("\n").map((line, index, arr) => (
//                     <React.Fragment key={index}>
//                         {line}{index < arr.length - 1 && <br />}
//                     </React.Fragment>
//                 ))}
//             </div>
//         </div>
//     );
// };

// const HiringProcess = () => {
//     const mob = document.body.offsetWidth < 768;
//     const steps = [
//         { stepNumber: "1", label: "New Job Details", isActive: true },
//         { stepNumber: "2", label: "Description", isActive: true },
//         { stepNumber: "3", label: "Preview", isActive: true },
//         { stepNumber: "4", label: "Pipelines", isActive: false },
//         { stepNumber: "5", label: "Qualification Attributes", isActive: false },
//         { stepNumber: "6", label: "Interview Questions", isActive: false },
//         { stepNumber: "7", label: "Application Form", isActive: false },
//         { stepNumber: "8", label: "Disposition Status", isActive: false },
//     ];
//     const activeCount = (mob ? steps.slice(2, 4) : steps).filter(step => step.isActive)
//     const progress = (activeCount.length / (mob ? steps.slice(2, 4) : steps).length) * 100;

//     return (
//         <div className="relative flex shrink-0 flex-auto justify-between text-center">
//             <ProgressBar
//                 containerClasses='absolute z-[-1] top-[18px]'
//                 completed={progress}
//             />
//             {(mob ? steps.slice(2, 4) : steps).map((step, index) => (
//                 <StepIndicatorItem
//                     key={index}
//                     stepNumber={step.stepNumber}
//                     label={step.label}
//                     isActive={step.isActive}
//                 />
//             ))}
//         </div>
//     );
// };

const NextStepSection = () => {
    return (
        <section>
            <hr className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className="flex gap-5 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <Link to={'/SBM02_1_2'} className="flex items-center justify-center px-12 h-[52px] bg-gray-400 rounded-md max-md:px-5 max-md:flex-1">
                    Back
                </Link>
                <Link to={'/SBM02_2'} className="flex items-center justify-center px-16 h-[52px] bg-green-500 rounded-md max-md:px-5 max-md:flex-1">
                    Next
                </Link>
            </div>
        </section>
    );
};


const SBM02_1_4 = () => {
    const steps = [
        { stepNumber: "1", label: "New Job Details", isActive: true },
        { stepNumber: "2", label: "Description", isActive: true },
        { stepNumber: "3", label: "Preview", isActive: true },
        { stepNumber: "4", label: "Pipelines", isActive: false },
        { stepNumber: "5", label: "Qualification Attributes and Interview Questions", isActive: false },
        // { stepNumber: "5", label: "Qualification Attributes", isActive: false },
        // { stepNumber: "6", label: "Interview Questions", isActive: false },
        { stepNumber: "6", label: "Application Form", isActive: false },
        { stepNumber: "7", label: "Disposition Status", isActive: false },
    ];
    const [formData, setFormData] = useState({
        jobTitle: '',
        manageRole: '',
        employeeType: '',
        country: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
    });
    const manageRoles = [
        { value: "no", label: "No - will not manage other employees" }
    ];
    const employeeTypes = [
        { value: "full_time", label: "Full Time - Salary" }
    ];
    const countries = [
        { value: "united_states", label: "United States" }
    ];
    const cities = [
        { value: "chicago", label: "Chicago" }
    ];
    const states = [
        { value: "illionis", label: "Illionis" }
    ];
    const zipCodes = [
        { value: "76567", label: "76567" }
    ];


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    const [description, setDescription] = useState('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi. Mauris lobortis condimentum nisi, et fermentum risus luctus eget. Cras scelerisque enim et massa scelerisque in dapibus justo rutrum. Duis sit amet mauris et dui dignissim posuere. Sed quis massa et risus pulvinar rhoncus quis sit amet magna. Vivamus ac rhoncus arcu. \n\nQuisque sit amet tortor nulla, sit amet tempus metus. Vivamus placerat scelerisque sapien vitae aliquet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris massa eros, porttitor sed tempus in, pretium mattis sapien. Praesent fringilla odio vitae est vehicula facilisis. Vestibulum accumsan molestie dictum. Integer nec sodales leo. Phasellus tincidunt massa vel purus malesuada id dapibus tortor iaculis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris interdum, dui eget congue venenatis, libero neque porta quam, nec facilisis enim nisl in tortor. Suspendisse suscipit ligula id ipsum gravida euismod. Duis mollis tempor neque a pulvinar.')

    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Job Setup', "New Job Details", "Description", "Preview"]} />
            <div className="flex flex-col md:gap-11 max-w-[1432px] px-4 w-full pt-[24px] lg:pt-14 pb-24 mx-auto max-md:pb-[80px]">
                <section className="flex w-full text-center overflow-auto py-4">
                    <HiringProcess stepsData={steps} />
                </section>
                <div className="flex flex-col items-center my-10 w-full">
                    <div className='mx-auto text-center flex flex-col gap-2 mb-11'>
                        <div className="text-4xl font-bold text-zinc-800">Preview</div>
                        <p className='max-w-[783px] mx-auto'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. </p>
                    </div>
                    <div className="flex flex-col px-[60px] py-[50px] text-lg w-full bg-white rounded-md border border-solid border-zinc-300 max-md:px-[32px] max-md:py-[36px] max-md:py-[20px]">
                        <h2 className='text-3xl font-bold text-zinc-800 mb-12 max-md:text-[28px] max-md:mb-[36px]'>Job Details</h2>
                        <div className='flex flex-col gap-10'>
                            <div className='md:grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 max-md:flex-col'>
                                <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3'>
                                    <InputField
                                        inputBg="bg-white rounded-[0px] border border-solid border-zinc-300"
                                        labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                        label="Job Title"
                                        type="text"
                                        name="jobTitle"
                                        id="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={handleChange}
                                        placeholder='Administrative Assistant'
                                    />
                                </div>
                                <div>
                                    <SelectField
                                        inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                        label="Manage Role"
                                        name="manageRole"
                                        id="manageRole"
                                        value={formData.manageRole}
                                        onChange={handleChange}
                                        options={manageRoles}
                                    />
                                </div>
                                <div>
                                    <SelectField
                                        inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                        label="Employee Type"
                                        name="employeeType"
                                        id="employeeType"
                                        value={formData.employeeType}
                                        onChange={handleChange}
                                        options={employeeTypes}
                                    />
                                </div>
                            </div>
                            <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 max-md:mt-[-18px]'>
                                <div className="flex gap-3 whitespace-nowrap">
                                    <Checkbox />
                                    <div className="grow my-auto">Remote Job or Not</div>
                                </div>
                            </div>
                            <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3'>
                                <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3'>
                                    <SelectField
                                        inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                        label="Country"
                                        name="country"
                                        id="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        options={countries}
                                    />
                                </div>
                                <div className="grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3">
                                    <div className='col-span-2'>
                                        <InputField
                                            inputBg="bg-white rounded-[0px] border border-solid border-zinc-300"
                                            labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                            label="Adress"
                                            type="text"
                                            name="adress"
                                            id="adress"
                                            value={formData.address}
                                            onChange={handleChange}
                                            placeholder='Lorem ipsum dolor 327'
                                        />
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3">
                                    <div>
                                        <SelectField
                                            inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                            label="City"
                                            name="city"
                                            id="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            options={cities}
                                        />
                                    </div>
                                    <div>
                                        <SelectField
                                            inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                            label="States"
                                            name="state"
                                            id="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                            options={states}
                                        />
                                    </div>
                                    <div>
                                        <SelectField
                                            inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                            label="Zip Code"
                                            name="zipCode"
                                            id="zipCode"
                                            value={formData.zipCode}
                                            onChange={handleChange}
                                            options={zipCodes}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-between max-md:flex-col max-md:items-start'>
                                <h2 className='text-[30px] font-bold text-[#333333] max-md:mb-[36px] max-md:text-[28px]'>Job Description</h2>
                                <div className='flex items-center gap-[10px]'>
                                    <img src={magicWandIcon} alt='wand icon'/>
                                    <p className='text-lg text-[#37B34A] max-md:text-[16px]'>Use our Job Description Wizard</p>
                                </div>
                            </div>
                            <TextareaField
                                className='!bg-white py-[40px] px-[36px] border border-solid border-[#D6D6D6] rounded-none max-md:p-[20px]'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                height={410}
                            />
                        </div>
                    </div>
                </div>
                <NextStepSection />
            </div>
        </>
    )
}

export default SBM02_1_4

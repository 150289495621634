import {FourStepsWrapper} from "../../parts/FourStepsWrapper";
import React, {useEffect, useState} from "react";
import {crumbsConfig} from "../../config";

export const AssessmentStep = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.history,
        ["Assessment", '']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            <FourStepsWrapper
                crumbs={crumbs}
                onNext={nextComponent}
                onBack={prevComponent}
                activePin={3}
            >
                <StepOne />
            </FourStepsWrapper>
        </>
    )
}

const StepOne = () => {

    return (
        <>
            <div>
                <p className="text-[28px] md:text-3xl font-bold">Assessment 1</p>

                <hr className=" border-b-[#E2E2E2] my-[24px] md:my-9"/>

                <div className="flex flex-col gap-6 w-full max-w-[400px]">
                    {new Array(6).fill('').map((_, idx) => <div key={idx} className="flex items-center justify-between">
                        <span className="text-xl font-bold">Category</span>
                        <input type="text" className="grey-input-custom !w-[96px] !text-[#333333] text-center"
                               value={'90%'} onChange={() => {}}/>

                    </div>)}

                    <div className="flex items-center justify-between gap-[20px]">
                        <button
                            className="flex font-semibold justify-center py-[15px] px-[15px] w-full bg-[#F7F7F7] rounded-md">Total
                            Score
                        </button>
                        <input type="text" className="grey-input-custom !w-[96px] !text-[#333333] text-center"
                               value={'90%'} onChange={() => {}}/>
                    </div>

                </div>

            </div>

            <div>
                <p className="text-[28px] md:text-3xl font-bold">Assessment 2</p>

                <hr className=" border-b-[#E2E2E2] my-[24px] md:my-9"/>

                <div className="flex flex-col gap-6 w-full max-w-[400px]">
                    {new Array(6).fill('').map((_, idx) => <div key={idx} className="flex items-center justify-between">
                        <span className="text-xl font-bold">Category</span>
                        <input type="text" className="grey-input-custom !w-[96px] !text-[#333333] text-center"
                               value={'90%'} onChange={() => {}}/>

                    </div>)}

                    <div className="flex items-center justify-between gap-[20px]">
                        <button
                            className="flex font-semibold justify-center py-[15px] px-[15px] w-full bg-[#F7F7F7] rounded-md">Total
                            Score
                        </button>
                        <div className="input-green-point">
                            <input type="text" className="grey-input-custom !w-[96px] !text-[#333333] text-center"
                                   value={'90%'} onChange={() => {}}/>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
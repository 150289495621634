const hiringAlgorithmBase = 'api/hiring-algorithm';

export const hiringAlgorithm = (instance) => ({
    async getResult(jobApplicationId) {
        try {
            const { data } = await instance.get(`${hiringAlgorithmBase}/${jobApplicationId}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    },
    async getFields(jobApplicationId) {
        try {
            const { data } = await instance.get(`${hiringAlgorithmBase}/processing-test/${jobApplicationId}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    },

    async getScores(jobApplicationId) {
        try {
            const { data } = await instance.get(`${hiringAlgorithmBase}/scores/${jobApplicationId}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    },

    async sendProcessRequest(jobApplicationId) {
        try {
            const { data } = await instance.get(`${hiringAlgorithmBase}/processing/${jobApplicationId}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    },
});


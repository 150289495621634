import React from 'react';
import ReactPlayer from 'react-player';

const WatchEmployJoyAction = ({ data, topBackSpase = '' }) => {
    return (
        <section className={`py-[55px] ${topBackSpase} font-['Raleway']`}>
            <div className="container">
                <div className="flex flex-col gap-[7px] text-[18px] mb-[32px]">
                    {data.title && (
                        <span className="text-lg font-semibold text-[#0056B3]">{data.title}</span>
                    )}
                    <div className="flex justify-between flex-wrap gap-[20px]">
                        <h3 className="text-[45px] max-md:text-[32px] font-bold md:max-w-[550px] leading-[48px]">
                            {data.header}
                        </h3>
                        <div className="md:max-w-[385px]">
                            {data.description}
                        </div>
                    </div>
                </div>
                <div className="video-player wow fadeInUp">
                    <ReactPlayer
                        light={require('../../assets/img/video-poster-solution.png')}
                        playing={true}
                        controls={true}
                        width="100%"
                        height="100%"
                        playIcon={
                            <button className="bg-white rounded-full w-[100px] h-[100px] max-md:w-[50px] max-md:h-[50px] flex items-center justify-center">
                                <span className="ml-3 max-md:ml-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-[47px] h-[54px] max-md:w-[23px] max-md:h-[27px]" viewBox="0 0 47 54" fill="none">
                                        <path d="M47 27L0.499997 53.8468L0.5 0.15321L47 27Z" fill="#A2A1A1" />
                                    </svg>
                                </span>
                            </button>
                        }
                        url={data.videoUrl}
                    />
                </div>
            </div>
        </section>
    );
};

export default WatchEmployJoyAction;

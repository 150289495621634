import React,{ useState } from 'react'
import { Link } from 'react-router-dom';
import RejectCandidate from '../modals/RejectCandidate'

const MarcSprctorLinksGroup = ({activeLink = 'report-card'}) => {

    const [rejectPopup, setRejectPopup] = useState(false);
    console.log(activeLink === 'report-card')
    return (
        <>
            <nav className="flex mt-9 overflow-auto w-full gap-5 items-center self-start text-xl font-semibold text-center whitespace-nowrap text-zinc-800">
                <Link to={'/SBM01_10_14(2)'}
                    className={`flex items-center justify-center px-5 h-[46px] ${activeLink === 'report-card' ? 'border-green-500' : 'border-white' }  border-solid border-b-[5px] hover:bg-green-200`}
                >
                    Report Card
                </Link>
                <Link to={'/SBM01_2_1'}
                    className={`flex items-center justify-center px-5 h-[46px] ${activeLink === 'history' ? 'border-green-500' : 'border-white' }  border-solid border-b-[5px] hover:bg-green-200`}
                >
                    History
                </Link>
                <Link to={'/SBM01_10_14_4'}
                    className={`flex items-center justify-center px-5 h-[46px] ${activeLink === 'reference' ? 'border-green-500' : 'border-white' }  border-solid border-b-[5px] hover:bg-green-200`}
                >
                    Reference
                </Link>
                <button type='button' onClick={() => setRejectPopup(true)}
                    className={`flex items-center justify-center px-5 h-[46px] border-white  border-solid border-b-[5px] hover:bg-green-200`}
                >
                    Reject
                </button>
                <Link to={'/SBM01_10_14_3'}
                    className={`flex items-center justify-center px-5 h-[46px] ${activeLink === 'offer-letter' ? 'border-green-500' : 'border-white' }  border-solid border-b-[5px] hover:bg-green-200`}
                >
                    Offer Letter
                </Link>
            </nav>
            {rejectPopup && <RejectCandidate open={rejectPopup} handleClose={() => setRejectPopup(false)} />}
              
        </>
    )
}

export default MarcSprctorLinksGroup

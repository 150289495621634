import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import FileInputField from "../../components/base/FileInputField";
import InputField from "../../components/base/InputField";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";


export const ResumeUploadSection = ({ payload, setPayload, }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        })
    };
    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.you_must_copy_and_paste_your_resume_here_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        you_must_copy_and_paste_your_resume_here_active: !payload.applicationForm.you_must_copy_and_paste_your_resume_here_active
                    }
                });
            }
        },
    ]
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsEditing(false);
    }

    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <div className='flex flex-col gap-8 max-w-[764px] w-full'>
                    <div>
                        <EditableInput
                            className="lg:text-[32px] text-[24px] font-bold"
                            type="text"
                            name="you_must_copy_and_paste_your_resume_here_title"
                            id="you_must_copy_and_paste_your_resume_here_title"
                            value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <EditableInput
                            className="text-lg text-[#6D6D6D] leading-[30px] w-full"
                            type="text"
                            name="you_must_copy_and_paste_your_resume_here_text"
                            id="you_must_copy_and_paste_your_resume_here_text"
                            value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_text}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                    </div>
                    <div className='flex flex-col items-stretch w-full max-w-[670px]'>
                        <EditableInput
                            className="lg:text-[22px] text-[18px] font-bold mb-5"
                            type="text"
                            name="you_must_copy_and_paste_your_resume_here_please_copy_and_paste"
                            id="you_must_copy_and_paste_your_resume_here_please_copy_and_paste"
                            value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_please_copy_and_paste}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <textarea className="grey-textarea !h-[280px] focus:outline-none focus:ring-2 focus:ring-green-500" ></textarea>
                        <EditableInput
                            className="mt-6 mb-4 font-semibold leading-[156%] "
                            type="text"
                            name="you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached"
                            id="you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached"
                            value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <FileInputField
                            acceptProp={{ 'application/pdf': ['.pdf'] }}
                        />
                        <EditableInput
                            className="mt-6 font-semibold leading-[156%] max-md:max-w-full mb-1"
                            type="text"
                            name="you_must_copy_and_paste_your_resume_here_linkedin_profile_url"
                            id="you_must_copy_and_paste_your_resume_here_linkedin_profile_url"
                            value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_linkedin_profile_url}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <InputField
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            type="text"
                            name="link"
                            id="link"
                        />
                    </div>
                </div>
            </ModalForm>
        )
    }


    return (
        <form onSubmit={handleSubmit} >
            <div className="flex justify-between mb-2">
                <EditableInput
                    className="lg:text-[32px] text-[24px] font-bold"
                    type="text"
                    name="you_must_copy_and_paste_your_resume_here_title"
                    id="you_must_copy_and_paste_your_resume_here_title"
                    value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton> :
                        <ButtonOptions options={options} />
                }

            </div>
            {payload.applicationForm.you_must_copy_and_paste_your_resume_here_active &&
                <div className='flex flex-col gap-8 max-w-[764px] w-full'>
                    <div>
                        <EditableInput
                            className="text-lg text-[#6D6D6D] leading-[30px] w-full"
                            type="text"
                            name="you_must_copy_and_paste_your_resume_here_text"
                            id="you_must_copy_and_paste_your_resume_here_text"
                            value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_text}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                    </div>
                    <div className='flex flex-col items-stretch w-full max-w-[670px]'>
                        <EditableInput
                            className="lg:text-[22px] text-[18px] font-bold mb-5"
                            type="text"
                            name="you_must_copy_and_paste_your_resume_here_please_copy_and_paste"
                            id="you_must_copy_and_paste_your_resume_here_please_copy_and_paste"
                            value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_please_copy_and_paste}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <textarea
                            className="grey-textarea !h-[280px] focus:outline-none focus:ring-2 focus:ring-green-500"></textarea>
                        <EditableInput
                            className="mt-6 mb-4 font-semibold leading-[156%] "
                            type="text"
                            name="you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached"
                            id="you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached"
                            value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <FileInputField
                            acceptProp={{'application/pdf': ['.pdf']}}
                        />
                        <EditableInput
                            className="mt-6 font-semibold leading-[156%] max-md:max-w-full"
                            type="text"
                            name="you_must_copy_and_paste_your_resume_here_linkedin_profile_url"
                            id="you_must_copy_and_paste_your_resume_here_linkedin_profile_url"
                            value={payload.applicationForm.you_must_copy_and_paste_your_resume_here_linkedin_profile_url}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <InputField
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            type="text"
                            name="link"
                            id="link"
                        />
                    </div>
                </div>
            }

        </form>
    );
};

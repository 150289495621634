import React from "react";
import logo from "../assets/img/logo.png"
import Breadcrumbs from "../components/base/Breadcrumbs";

// Reusable component for feature sections
const FeatureSection = ({ title, description, imageURL, altText }) => (
    <div className="flex gap-6 justify-between max-md:flex-wrap max-md:max-w-full">
        <img loading="lazy" src={imageURL} alt={altText} className="self-start w-[65px] h-[65px] object-contain" />
        <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="text-xl font-bold leading-8 max-md:max-w-full">{title}</div>
            <div className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full">{description}</div>
        </div>
    </div>
);

// Reusable component for qualification or requirement items
const QualificationItem = ({ imageURL, altText, title }) => (
    <div className="flex lg:flex-col gap-3 max-lg:gap-5 items-center grow text-lg leading-8 whitespace-nowrap text-zinc-800">
        <img loading="lazy" src={imageURL} alt={altText} className="self-center w-[65px] h-[65px] object-contain" />
        <div>{title}</div>
    </div>
);
function SBM02_8() {
    const featureData = [
        {
            title: "No Experience Necessary",
            description: "No experience? No problem! We train you from day one. You’ll also learn many helpful tips and tricks along the way.",
            imageURL: require('../assets/img/book.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "Awesome Pay",
            description: "Pay starts at $18.50 per cleaning hour after training, with room to grow to $24.50.",
            imageURL: require('../assets/img/pay.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "Amazing Growth Potential",
            description: "We have seven levels of Cleaning Technician. We reward your great performance with higher pay and bonuses.",
            imageURL: require('../assets/img/rocket.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "Life Friendly Scheduling",
            description: "Whether you’re a parent or a student, we work to accommodate your schedule. We offer part time, half time, and full time positions.",
            imageURL: require('../assets/img/calendar.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "Regular Hours",
            description: "Say goodbye to crazy shifts and irregular hours. We will never ask you to clean on weekends or holidays, and your job will never stray beyond 8 AM to 5 PM.",
            imageURL: require('../assets/img/clock.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "Tips Are 100% Yours",
            description: "Our maids earn an average of $2,500 in tips each year. The best part? You don’t have to tell us about it.",
            imageURL: require('../assets/img/money.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "No Experience Necessary",
            description: "No experience? No problem! We train you from day one. You’ll also learn many helpful tips and tricks along the way.",
            imageURL: require('../assets/img/dollar.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "CoVID-19 Conscious",
            description: "Your health is our first priority. That’s why we supply you with free PPE and on the clock bi-weekly CoVID-19 tests. We also offer a $50 bonus once you get vaccinated.",
            imageURL: require('../assets/img/covid.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "A Company that Truly Cares",
            description: "We are not a referral service or franchise. As a locally owned and operated business in Chicago, we put our people first.",
            imageURL: require('../assets/img/company-cares.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "Fantastic Team Members",
            description: "We love who we work with, and you will too. Get to know your colleagues at our weekly  meetings and fun quarterly events.",
            imageURL: require('../assets/img/fantastic-team.png'),
            altText: "Icon for no experience necessary"
        },
    ];

    const qualificationData = [
        {
            imageURL: require('../assets/img/like.png'),
            altText: "Icon representing amazing attitude",
            title: "Amazing Attitude"
        },
        {
            imageURL: require('../assets/img/heart.png'),
            altText: "Icon representing amazing attitude",
            title: "Loves Physical Work"
        },
        {
            imageURL: require('../assets/img/speak.png'),
            altText: "Icon representing amazing attitude",
            title: "Fluent English"
        },
        {
            imageURL: require('../assets/img/drug-free.png'),
            altText: "Icon representing amazing attitude",
            title: "Drug Free Lifestyle"
        },
        {
            imageURL: require('../assets/img/diploma.png'),
            altText: "Icon representing amazing attitude",
            title: "Authorized to Work in US"
        },
    ];
    const locationData = [
        {
            title: "Arlington Heights",
            description: "- Must own car \n- Live within 15 miles",
            imageURL: require('../assets/img/car.png'),
            altText: "Icon for no experience necessary"
        },
        {
            title: "Chicago",
            description: "- Own car or use public transportation \n- Live within 1 hour of our Chicago headquarters by public transport",
            imageURL: require('../assets/img/heart.png'),
            altText: "Icon for no experience necessary"
        },
    ];
    const breadcrumbItems = [
        {
            name: 'Subscriber',
            to: '',
            isCurrent: false
        },
        {
            name: 'Dashboard',
            to: '',
            isCurrent: false
        },
        {
            name: 'Job Setup',
            to: '',
            isCurrent: false
        },
        {
            name: 'Job Page',
            to: '',
            isCurrent: true
        },
    ]

    return (<>
        <Breadcrumbs links={breadcrumbItems} colorStyle="secondary" />
        <div className="mt-[24px] mb-[70px] flex gap-x-[60px] gap-y-20 max-lg:flex-col">
            <header className="max-w-[390px] flex flex-col w-full text-zinc-800">
                <h2 className="text-5xl font-bold leading-[60px] max-lg:text-[40px] max-lg:leading-[49px]">Chemical Free Cleaning Technician</h2>
                <div className="mt-6 h-2 bg-[#06D6A0] w-[82px]" />
            </header>
            <main className="flex flex-col w-full">
                <section className="flex flex-col grow">
                    <h2 className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800">Chemical Free Home Cleaning Technician Job Description</h2>
                    <p className="mt-6 text-lg font-light leading-8 text-zinc-800 max-md:max-w-full">
                        Do you strive for great customer service? Do you wish that you could find a company which values your hard work? As a Cleaning Technician, you work with some of the most talented and thoughtful people in the industry. We prepare you for success from day one, with one on one training from our certified technicians. Along the way, you amaze clients with your strong cleaning skills and empathetic nature. Finally, you always take special care to adhere to all CoVID-19 precautions and guidelines. <br /><br />
                        When it comes to finding a great place to work, Fresh Tech Maid sweeps the competition! As a company which specializes in chemical free cleaning, our Cleaning Technicians are at the heart of what we do. We have a dedicated office team to help manage your schedule. We cover many of the costs that are usually passed on to cleaners, like supplies, uniforms, and parking. Finally, we offer many perks which are unheard of in the service industry, such as paid time off and health insurance.
                    </p>
                </section>
                <section className="flex flex-col pt-11 mt-24 border-t border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
                    <h2 className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800">Why work with us as a Chemical Free Home Cleaning Technician?</h2>
                    <div className="mt-11 max-md:mt-10 max-md:max-w-full flex gap-5 flex-col">
                        {/* Loop through qualifications */}
                        {featureData.map((qual, index) => (
                            <FeatureSection key={index} {...qual} />
                        ))}
                    </div>
                </section>
                <section className="flex flex-col pt-11 mt-24 border-t border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
                    <h2 className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800">Qualifications</h2>
                    <div className="grid grid-cols-3 gap-10 mt-10 w-full max-md:grid-cols-1">
                        {/* Loop through qualifications */}
                        {qualificationData.map((qual, index) => (
                            <QualificationItem key={index} {...qual} />
                        ))}
                    </div>
                </section>
                <section className="flex flex-col items-start pt-11 mt-11 text-lg leading-8 border-t border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
                    <h2 className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800">Location Requisites</h2>
                    <div className="mt-8 max-md:max-w-full flex gap-[26px] flex-col">
                        {locationData.map((qual, index) => (
                            <FeatureSection key={index} {...qual} />
                        ))}
                    </div>
                    <p className="mt-[32px] text-lg text-[#333333] font-normal">Morbi tellus sapien, luctus eu sodales eget, malesuada ut mi. Maecenas vestibulum, magna ac cursus laoreet, nisl lacus pharetra lorem, eget dapibus ante arcu et augue. Vestibulum at lacus a nunc pretium tincidunt a vel metus. Curabitur vel lectus in nulla venenatis bibendum.</p>
                </section>
                <section className="pt-[40px] mt-[44px] border-t border-solid border-[#CFCFCF]">
                    <button
                        className='ml-auto w-full md:max-w-[210px] h-[52px] flex justify-center items-center text-lg whitespace-nowrap bg-[#06D6A0] rounded-md text-white font-bold hover:bg-[#002E67]'
                    >
                        Apply Now
                    </button>
                </section>
            </main>
        </div>
    </>
    );
}

export default SBM02_8
import blocksImage from "../../../assets/img/home/blocks.png";
import userImage from "../../../assets/img/home/user.png";
import {ReactComponent as ArrowIcon} from "../../../assets/img/home/arrowToFill.svg";
import {Navigation, Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../../../assets/css/Swiper/styles.css';
import React, {useEffect, useRef} from "react";
import {AboutBlock} from "./AboutBlock";
import {NavLink} from "react-router-dom";
import getImageUrl from "../../../assets/helpers/getImageUrl";

export const JoinTeam = ({title, header, description, sliderCards}) => {
    const swiperRef = useRef(null);
    const adjustSlideHeights = () => {
        const slides = document.querySelectorAll('.swiper-slide');
        let maxHeight = 0;

        slides.forEach(slide => {
            slide.style.height = 'auto';
            if (slide.offsetHeight > maxHeight) {
                maxHeight = slide.offsetHeight;
            }
        });

        slides.forEach(slide => {
            slide.style.height = `${maxHeight}px !important`;
        });
    };
        useEffect(() => {
        adjustSlideHeights();

        window.addEventListener('resize', adjustSlideHeights);
        return () => window.removeEventListener('resize', adjustSlideHeights);
    }, [sliderCards]);
    return (
        <section className={`w-full relative bg-[#EBF0F3] font-['Raleway'] pt-[70px] pb-[30px]`}>
            <div className="flex flex-col items-center container relative z-10">
                <AboutBlock
                    title={title}
                    header={header}
                    description={[description]}
                />
                <div className="flex flex-col justify-center w-full items-center mt-[29px] gap-[35px]">
                    <Swiper
                        spaceBetween={35}
                        slidesPerView="3"
                        autoplay={{delay: 3000, pauseOnMouseEnter: true}}
                        loop={true}
                        modules={[Navigation, Autoplay]}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                        className="swiper-custom-join"
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {sliderCards.map((card, idx) => (
                            <SwiperSlide key={idx}>
                                <div
                                    className="w-full flex flex-col bg-white rounded-[21px] py-[35px] px-[28px] card-shadow relative h-full">
                                    <div className="flex justify-between w-full mb-4">
                                        <div className="min-h-[110px]">
                                            <p className="text-[28px] font-bold mb-[8px]">{card?.career_roles_list_item_title}</p>
                                            <span
                                                className="text-xl italic font-semibold text-[#5F5D5D]"> {card?.career_roles_list_item_location}
                                        </span>
                                        </div>
                                        <img
                                            src={getImageUrl(card?.career_roles_list_item_img)}
                                            alt="blocks"
                                            className="w-[75px] h-[75px] object-cover"
                                        />
                                    </div>

                                    <div className="flex flex-col flex-1">
                                        <div>
                                            <span
                                                className="underline font-semibold text-xl">{card?.career_roles_list_item_sub1}</span>
                                            <ul className="py-[10px]">
                                                {JSON.parse(card?.career_roles_list_item_sub1_arr)?.map((item, index) => (
                                                    <li className="text-lg font-medium text-[#5F5D5D] flex" key={index}>
                                                        <span
                                                            className="inline-block w-1 h-1 rounded-full bg-[#5F5D5D] ml-4 mr-3 mt-3"></span>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div>
                                            <span
                                                className="underline font-semibold text-xl">{card?.career_roles_list_item_sub2}</span>
                                            <ul className="py-[10px] max-w-[307px]">
                                                {JSON.parse(card?.career_roles_list_item_sub2_arr)?.map((item, index) => (
                                                    <li className="text-lg font-medium text-[#5F5D5D] flex" key={index}>
                                                        <span
                                                            className="inline-block w-1 h-1 rounded-full bg-[#5F5D5D] ml-4 mr-3 mt-3"></span>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <NavLink
                                        to={card?.career_roles_list_item_url}
                                        className="flex justify-center items-center font-semibold text-[20px] max-md:text-sm p-[10px] bg-[#36B34A] hover:bg-green-600 mt-5 rounded-md text-white w-[168px] h-fit"
                                    >
                                        {card?.career_roles_list_item_btn}
                                    </NavLink>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="flex gap-4">
                        <div
                            className="bg-[#A2A1A1] p-3 rounded-full cursor-pointer w-[41px] h-[41px] hover:opacity-90 hover:scale-95 flex justify-center items-center"
                            onClick={() => swiperRef.current?.slidePrev()}
                        >
                            <ArrowIcon fill="#000"/>
                        </div>
                        <div
                            className="bg-[#A2A1A1]  rounded-full cursor-pointer w-[41px] h-[41px] hover:opacity-90 hover:scale-95 flex justify-center items-center p-3 rotate-180"
                            onClick={() => swiperRef.current?.slideNext()}
                        >
                            <ArrowIcon fill="#000"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

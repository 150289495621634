const publicSolutionBase = 'api/public-solution-pages';
const baseCategiries = 'api/public-solution-categories';

export const publicSolution = (instance) => ({
    async getPublicSolutionPages(pageId = "") {
        const {data} = await instance.get(`${publicSolutionBase}/public/${pageId}`);
        return data;
    },
    async getPublicCategories(pageId = "") {
        const {data} = await instance.get(`${baseCategiries}/?no_limit=true`);
        return data;
    },
});

import React, { useEffect, useRef, useState } from 'react'
import blocksImage from "../../assets/img/home/blocks.png";
import { useLocation, useNavigate } from 'react-router-dom';
import findValue from '../../assets/helpers/findValue';


const FAQSection = ({ faqcontent, title, description }) => {
    const navigate = useNavigate();
    const [activeQuestion, setActiveQuestion] = useState(0)
    const contentRef = useRef()
    const { hash } = useLocation();
    useEffect(() => {
        if (contentRef.current && hash) {
            contentRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (faqcontent) {
            const activeIndex = faqcontent.findIndex((item) => item.title === decodeURI(hash.substring(1)))
            if (activeIndex !== -1) {
                setActiveQuestion(activeIndex)
            }
        }
    }, [hash])

    const anhorClick = (item, index) => {
        navigate({
            hash: item.title
        })
        setActiveQuestion(index)
    }

    const sanitizeContent = (htmlContent) => {
        if (!htmlContent) return '';
        return htmlContent
            .replace(/class="[^"]*"/g, '')
            .replace(/style="[^"]*"/g, '');
    }

    return (
        <section
            className={`py-[56px] bg-top bg-no-repeat font-['Raleway']`}
            style={{ backgroundImage: `url(${blocksImage})` }}
            ref={contentRef}
        >
            <div className="container">
                <div className={`flex flex-col gap-[17px] text-[18px] mb-[48px] items-center text-center`}>
                    <h3 className="text-[45px] max-md:text-[32px] font-bold max-w-[830px] leading-[48px]" >
                        {title}
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <div className="flex gap-[20px] items-start max-lg:flex-col" >
                    <div className="max-w-[432px] max-xl:max-w-[400px] w-full flex basis-[432px] max-xl:basis-[400px] grow-0 shrink-0 lg:flex-col gap-[15px] lg:sticky lg:top-[50px] max-lg:basis-full max-lg:max-w-full max-lg:flex-wrap bg-white">
                        {faqcontent && faqcontent?.map((item, index) => (
                            <button
                                type={'button'}
                                className={`text-[24px] max-lg:text-[16px] font-medium border-b-[2px] text-start max-w-max ${index === activeQuestion ? ' border-[#007BFF]' : 'border-[transparent]'}`}
                                key={index}
                                onClick={() => { anhorClick(item, index) }}
                            >
                                {item?.title}
                            </button>
                        ))}
                    </div>
                    <div className="flex-auto p-[60px] bg-[#EBF0F3] rounded-[20px] max-xl:p-[40px] max-lg:p-[20px] self-stretch" >
                        {faqcontent && <div className="classic-styles">
                            <h2 className='text-[36px] max-lg:text-[32px] font-semibold mb-3'>{faqcontent[activeQuestion]?.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: sanitizeContent(faqcontent[activeQuestion]?.content) }}></div>
                        </div>}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQSection

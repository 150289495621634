import React, {useEffect, useState} from "react";
import {ElevenStepsWrapper} from "../../parts/ElevenStepsWrapper";
import InputField from "../../../../components/base/InputField";
import {crumbsConfig} from "../../config";

export const FinalHiringDecisionSteps = ({nextComponent, prevComponent}) => {

    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
        ["Document Review", ''],
        ["AI Evaluation", ''],
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            <ElevenStepsWrapper
                crumbs={crumbs}
                onNext={() => nextComponent()}
                onBack={() => prevComponent()}
                activePin={8}
            >
                <StepOne />
            </ElevenStepsWrapper>

        </>
    )
}

const StepOne = () => {

    return (
        <>
            <div className="border-b border-neutral-200 pb-9">
                <span className="text-3xl font-bold">Final Hiring Decisions</span>
            </div>

            <div className="pb-[60px] border-b border-neutral-200">
                <span className="block text-[22px] font-bold mb-4">Top 3 Strengths:</span>

                <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end">
                    <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                        <div className="green-box mr-4"></div>
                        Category 1:
                    </div>

                    <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                        <InputField label={'Positive Variance %'}
                                    labelClassName={'font-semibold text-[#6F6F6F]'}
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                        />
                    </div>

                </div>
                <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
                    <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                        <div className="green-box mr-4"></div>
                        Category 1:
                    </div>

                    <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                        <InputField label={'Positive Variance %'}
                                    labelClassName={'font-semibold text-[#6F6F6F]'}
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                        />
                    </div>

                </div>
                <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
                    <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                        <div className="green-box mr-4"></div>
                        Category 1:
                    </div>

                    <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                        <InputField label={'Positive Variance %'}
                                    labelClassName={'font-semibold text-[#6F6F6F]'}
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                        />
                    </div>

                </div>


            </div>

            <div className="pb-[60px] border-b border-neutral-200">
                <span className="block text-[22px] font-bold mb-4">Top 3 Weaknesses:</span>

                <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end">
                    <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                        <div className="green-box mr-4"></div>
                        Category 1:
                    </div>

                    <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                        <InputField label={'Positive Variance %'}
                                    labelClassName={'font-semibold text-[#6F6F6F]'}
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                        />
                    </div>

                </div>
                <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
                    <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                        <div className="green-box mr-4"></div>
                        Category 1:
                    </div>

                    <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                        <InputField label={'Positive Variance %'}
                                    labelClassName={'font-semibold text-[#6F6F6F]'}
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                        />
                    </div>

                </div>
                <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
                    <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                        <div className="green-box mr-4"></div>
                        Category 1:
                    </div>

                    <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                        <InputField label={'Positive Variance %'}
                                    labelClassName={'font-semibold text-[#6F6F6F]'}
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                        />
                    </div>

                </div>


            </div>

            <div className="pb-[60px] border-b border-neutral-200">
                <span className="block text-3xl font-bold mb-7">AI Recommendations:</span>

                <div className="flex items-center">
                    <div className="lg:text-xl text-base font-bold w-[240px]">
                        High Standard:
                    </div>

                    <div className="flex items-center gap-3">
                        <button
                            className="bg-[#37B34A] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                            <span className="font-bold text-[#FFFFFF]">Hire</span>
                        </button>
                        <div className="italic text-[#999999] font-light">or</div>
                        <button
                            className="bg-[#B2B2B2] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                            <span className="font-bold text-[#FFFFFF]">Not Hire</span>
                        </button>
                    </div>

                </div>
                <div className="flex items-center mt-7">
                    <div className="lg:text-xl text-base font-bold w-[240px]">
                        Medium Standard:
                    </div>

                    <div className="flex items-center gap-3">
                        <button
                            className="bg-[#37B34A] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                            <span className="font-bold text-[#FFFFFF]">Hire</span>
                        </button>
                        <div className="italic text-[#999999] font-light">or</div>
                        <button
                            className="bg-[#B2B2B2] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                            <span className="font-bold text-[#FFFFFF]">Not Hire</span>
                        </button>
                    </div>

                </div>
                <div className="flex items-center mt-7">
                    <div className="lg:text-xl text-base font-bold w-[240px]">
                        Average Standard:
                    </div>

                    <div className="flex items-center gap-3">
                        <button
                            className="bg-[#37B34A] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                            <span className="font-bold text-[#FFFFFF]">Hire</span>
                        </button>
                        <div className="italic text-[#999999] font-light">or</div>
                        <button
                            className="bg-[#B2B2B2] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                            <span className="font-bold text-[#FFFFFF]">Not Hire</span>
                        </button>
                    </div>

                </div>
                <div className="flex items-center mt-7">
                    <div className="lg:text-xl text-base font-bold w-[240px]">
                        Final Recommendation:
                    </div>

                    <div className="flex items-center gap-3">
                        <button
                            className="bg-[#37B34A] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                            <span className="font-bold text-[#FFFFFF]">Hire</span>
                        </button>
                        <div className="italic text-[#999999] font-light">or</div>
                        <button
                            className="bg-[#B2B2B2] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                            <span className="font-bold text-[#FFFFFF]">Not Hire</span>
                        </button>
                    </div>

                </div>


            </div>
        </>
    )
}
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api';
import InputFieldHorisontal from '../../../components/base/InputFieldHorisontal';
import PrimaryButton from '../../../components/base/PrimaryButton';
import { EvaluationInterview } from '../../../components/parts/EvaluationsInterview';
import { getApplicationByIdFetch, getJobByIdFetch } from '../../../redux/thunks/jobsThunks';
import { QuestionTypes } from '../../AP_01_Form/parts/questionTypes';
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';

const calculateTotalScore = (clarifications, answersScores, maxScore = 7) => {
    const statuses = [
        "Completed",
        "On-going",
        "Not Started",
    ];

    if (!clarifications?.length || !answersScores?.length) return {};

    const totalQuestions = clarifications.length;

    const totalQuestionsAnswered = answersScores.filter(score => !isNaN(parseFloat(score))).length;

    const totalScoreSum = answersScores.reduce((sum, score) => !isNaN(parseFloat(score)) ? sum + parseFloat(score) : sum, 0);

    const maxPossibleTotalScore = totalQuestionsAnswered * maxScore;

    const totalPercentage = totalQuestionsAnswered > 0
        ? Math.floor((totalScoreSum / maxPossibleTotalScore) * 100)
        : 0;

    let totalStatus = statuses[2];
    if (totalQuestionsAnswered === totalQuestions) {
        totalStatus = statuses[0];
    } else if (totalQuestionsAnswered > 0) {
        totalStatus = statuses[1];
    }

    return {
        total: {
            score: totalScoreSum,
            percentage: totalPercentage,
            status: totalStatus,
            name: "Total",
            key: "total"
        }
    };
};

const Score = ({ title = "Score", onChange, value }) => (
	<div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
		<div className="grow flex justify-center items-center px-16 lg:h-[54px] h-[52px] font-semibold bg-neutral-100 w-fit text-[#333333]">
			{title}
		</div>
		<InputFieldHorisontal
			className="flex gap-2 justify-center items-center px-5 py-1 min-w-[96px] lg:h-[54px] h-[52px] bg-white border border-solid border-zinc-300"
			error={null}
			min={0}
			max={10}
			type="number"
			onChange={onChange}
			value={parseInt(value) || null}
		/>
	</div>
);

const PostFirstInterviewSurvey = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()

	// const [score, setScore] = useState(application?.assessments?.postInterview || { post_interview_survey_score: { value: 50, title: "Total Score" } })

	const [isSending, setIsSending] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [totalScore, setTotalScore] = useState(null);

	useEffect(() => {
		setActiveStep(3)
	}, [])

	const [questionsData, setQuestionsData] = useState([])

	const [answersScores, setAnswersScores] = useState([])
	const [transcript, setTranscript] = useState([])
	const [answersConfig, setAnswersConfig] = useState({})

	useEffect(() => {
		const post_interview_survey = application?.criticalRequirements?.post_interview_survey
		if (!post_interview_survey?.length) return
		const qualityQuestions = post_interview_survey.map(item => ({ qualityAttribute: item?.question?.qualityAttribute }))

		if (!qualityQuestions.length) return
		const obj = {}
		const arr = qualityQuestions.reduce((res, curr, index) => {
			const key = res.find(r => r.key === curr?.qualityAttribute?.key)
			const score = { ...curr, score: answersScores?.[index] }
			if (key) {
				key.stack.push(score)
			} else {
				res.push({
					key: curr?.qualityAttribute?.key,
					stack: [score],
					name: curr?.qualityAttribute?.name,
				})
			}
			return res
		}, []).map(item => {
			let avg = (item?.stack || []).reduce((r, it) => r + parseFloat(it.score), 0) / (item?.stack || []).length;
			return { ...item, avg };
		});
		arr?.forEach((item) => {
			obj[item?.key] = {
				score: item?.avg,
				name: item?.name,
				key: item?.key
			}
		})

		setAnswersConfig(obj)

	}, [application, answersScores])

	useEffect(() => {
		setTranscript(application?.assessments?.["post_interview_survey"]?.transcript)
		setAnswersScores(application?.assessments?.["post_interview_survey"]?.scores ||
			Array.from({ length: questionsData?.length || 0 }).fill("NONE"))
	}, [application])

	const handleNext = () => {
		navigate(`/SBM01_10_3/${jobId}/${applicationId}`)
	}
	const handlePrev = () => {
		navigate(`/SBM01_10_1/${jobId}/${applicationId}`)
	}


	const dispatch = useDispatch()

	const handleSave = async () => {
		if (answersScores.some(score => score > 7 || score < 0)) {
			toast.error("Scores must be from 0 to 7")
			return
		}
		setIsLoading(true)

		const scores = new Array(questionsData?.length)?.fill(null)?.map((one, index) => answersScores?.[index] || one)

		try {
			const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
				assessments: {
					...application?.assessments || {},
					post_interview_survey: {
						...application?.assessments?.post_interview_survey,
						scores: scores
					}
				}
			})
			if (resp.success) {
				Promise.all([
					dispatch(getJobByIdFetch(jobId)),
					dispatch(getApplicationByIdFetch({ applicationId, jobId }))
				]);
				toast.success("Saved")
			} else {
				toast.error(resp.message || "Something went wrong")
			}
		} catch (err) {
			console.log(err)
			toast.error("Something went wrong")
		}
		setIsLoading(false)
	}


	const getQuantitativeAnalysis = async () => {

		const postInterview = application?.criticalRequirements?.post_interview_survey
		if (postInterview?.length) {
			setQuestionsData(postInterview)
		} else {
			const res = await api.quantitativeAnalysis.getQuantitativeAnalysis()
			if (res?.success && res?.data?.length) {
				const initialAnswers = res?.data.map(question => ({
					question,
					answer: ''
				}))
				setQuestionsData(initialAnswers)
			}
		}
	}
	useEffect(() => {
		getQuantitativeAnalysis()
	}, [application])

	useEffect(() => {
		const clarifications = application?.criticalRequirements?.post_interview_survey || [];
		const total = calculateTotalScore(clarifications, answersScores);
		setTotalScore(total);
	}, [answersScores, application]);

	const handlePostInterviewSend = async () => {
		setIsSending(true)
		const res = await api.jobApplications.sendPostInterviewToApplicant({ applicationId })
		if (res?.success) {
			toast.success(res?.message || "Post interview send to applicant successfully!")
		} else {
			toast.error(res?.message || "Something went wrong!")
		}
		setIsSending(false)
	}

	const changeAnswerHandler = (index, val) => {
		const copy = [...answersScores]
		copy[index] = val
		setAnswersScores(copy)
	}

	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-11 justify-between max-w-[1432px] px-4 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
				{
					!application?.criticalRequirements?.post_interview_survey ? (
						<>
							<div className="flex justify-end w-full">
								<PrimaryButton isLoading={isSending} className={"px-4 !h-14"} onClick={handlePostInterviewSend}>Send Survey</PrimaryButton>
							</div>
							<div className="flex justify-end w-full">
								<p>Status: <span className={`text-yellow-500`}>Pending</span></p>
							</div>
						</>
					) :
						<div className="flex justify-end w-full">
							<p>Status: <span className={`text-green-500`}>Completed</span></p>
						</div>
				}
				<div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
					<div className="flex flex-col items-stretch">
						<div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
							Post Interview Survey
						</div>
						<div className="self-stretch mt-9 max-md:mt-[24px] w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
						<div className="mt-[32px] flex flex-col gap-4">
							{questionsData.map((item, index) => {
								const Question = QuestionTypes["text"];
								if (!Question) return null
								return (
									<div className='flex flex-col'>
										<Question
											key={item.id}
											error={null}
											config={item.question}
											number={index + 1}
											value={item.answer || null}
											className="mt-[0px]"
											labelClassName="!text-[16px] !leading-[20px]"
											disabled
										/>

										<Score
											onChange={(e => changeAnswerHandler(index, e.target.value))}
											value={parseInt(answersScores[index]) || null}
										/>
									</div>
								)

							})}
						</div>
					</div>
				</div>
				<EvaluationInterview config={answersConfig} totalOnly propsTotal={totalScore?.total?.percentage} />
				{
					// application?.criticalRequirements?.post_interview_survey && (
						<div className="flex justify-end w-full my-6">
							<PrimaryButton
								onClick={handleSave}
								className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
							>
								Save
							</PrimaryButton>
						</div>
					// )
				}
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
		</div>
	)
}

export default PostFirstInterviewSurvey

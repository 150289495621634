import React from 'react'
import {BlogsSection} from '../../components/parts/publickPageParts/BlogsSection'
import InfoSection from '../../components/parts/publickPageParts/InfoSection'
import {AboutBlock} from '../../components/parts/publickPageParts/AboutBlock'
import {RecruitSmarter} from '../../components/parts/publickPageParts/RecruitSmarter';
import SolutionProblems from '../../components/parts/SolutionProblems';
import WatchEmployJoyAction from '../../components/parts/WatchEmployJoyAction';
import JobsCategoriesSlider from '../../components/parts/JobsCategoriesSlider';
import HeroSolutions from '../../components/parts/publickPageParts/HeroSolutions';
import {useSelector} from "react-redux";
import {getCurrentPage, getCurrentPageLoading} from "../../redux/slices/applicationSlice";
import PageLoader from "../../components/PageLoader";
import findValue from "../../assets/helpers/findValue";
import getImageUrl from "../../assets/helpers/getImageUrl";


const generateDataFromBlocks = (blocks) => {
    return {
        hero: {
            header: findValue(blocks, "hero_title"),
            description: [findValue(blocks, "hero_text")],
            total: {
                title: findValue(blocks, "hero_hiring_title"),
                subTitle: findValue(blocks, "hero_hiring_text"),
                candidates: findValue(blocks, "hero_hiring_count"),
            },
            button: findValue(blocks, "hero_button"),
            image: findValue(blocks, "hero_image") ? getImageUrl(findValue(blocks, "hero_image")) : null,
        },
        solutions: {
            title: findValue(blocks, "solutions_label "),
            header: findValue(blocks, "solution_title"),
            items: (findValue(blocks, "solution_list")?.data || []).map(item => ({
                title: item.title,
                text: item.text,
                image: getImageUrl(item.image),
                button: item.button
            }))
        },
        watch: {
            title: findValue(blocks, "watch_label"),
            description: findValue(blocks, "watch_text"),
            header: findValue(blocks, "watch_title"),
            videoUrl: getImageUrl(findValue(blocks, "watch_video"))
        },
        jobs: {
            title: findValue(blocks, "jobs_label"),
            header: findValue(blocks, "jobs_title"),
            categories: (findValue(blocks, "jobs_list")?.data || []).map(category => ({
                title: category.title,
                jobs: category.label,
                description: category.text,
                image: getImageUrl(category.image),
                button: category.button
            }))
        },
        selectCandidate: {
            title: findValue(blocks, "select_label"),
            header: findValue(blocks, "select_title"),
            description: [findValue(blocks, "select_text")],
            button: findValue(blocks, "select_button"),
            image: getImageUrl(findValue(blocks, "select_image")),
        },
        pricing: {
            title: findValue(blocks, "pricing_label"),
            header: findValue(blocks, "pricing_title"),
            description: [findValue(blocks, "pricing_text")],
            button: findValue(blocks, "picing_button"),
            features: (findValue(blocks, "pricing_list")?.data || []).map(feature => ({
                text: feature.text,
                image: getImageUrl(feature.image)
            })),
            image: findValue(blocks, "picing_image")
        },
    };
};

const Resourses = () => {
    const currentPage = useSelector(getCurrentPage);
    const isLoading = useSelector(getCurrentPageLoading);
    const data = generateDataFromBlocks(currentPage?.blocks || []);

    if (isLoading) return <PageLoader/>;

    return (
        <>
            <HeroSolutions
                header={data.hero.header}
                description={data.hero.description}
                total={data.hero.total}
                button={data.hero.button}
                image={data.hero.image}
            />

            <SolutionProblems
                bottomSpase='pb-[800px] max-lg:pb-[55px]'
                data={data.solutions}
            />

            <div className="relative">
                <WatchEmployJoyAction
                    topBackSpase='mt-[-762px] max-lg:mt-0'
                    data={data.watch}
                />

                <JobsCategoriesSlider
                    data={data.jobs}
                />
            </div>

            <InfoSection topSpacing="py-[55px]">
                <AboutBlock
                    title={data.selectCandidate.title}
                    header={data.selectCandidate.header}
                    description={data.selectCandidate.description}
                    button={data.selectCandidate.button}
                />
                <div className="flex items-center justify-center h-auto max-md:h-[320px] wow bounceInUp">
                    <img src={data.selectCandidate.image} alt="candidate" className="absolute bottom-0"/>
                </div>
            </InfoSection>
            <RecruitSmarter
                title={data.pricing.title}
                header={data.pricing.header}
                description={data.pricing.description}
                button={data.pricing.button}
                features={data.pricing.features}
                image={data.pricing.image}
            />
            <BlogsSection
                topSpacing=''
                hasBgSection={false}
                showSubTitle={false}
            />
        </>
    );
}

export default Resourses;

import React, { useEffect } from "react";
import { useState } from "react";
import ModalTemplate from '../base/ModalTemplate';
import MultiSelect from "../base/MultiSelect";
import Button from "../base/Button";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api";
import { useParams } from "react-router-dom";
import { updateApplicantTags } from "../../redux/slices/jobsSlice";


const AddTagModal = ({ open, handleClose, modalSize, tableHedingButton = false, footerBorderTop = true, data, selectedApplicant }) => {
    const { job, isLoading, candidates, applicants } = useSelector(state => state.jobs);
    const { id, tags: contenderTags } = selectedApplicant

    const [tags, setTags] = useState([])

    const { jobId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!contenderTags) return

        setTags(contenderTags.map(tag => ({
            label: tag,
            value: tag
        })))

    }, [selectedApplicant])

    const options = [
        { value: 'Topic Categories', label: 'Topic Categories' },
        { value: 'Meta Title', label: 'Meta Title' },
        { value: 'Meta Description', label: 'Meta Description' },
        { value: 'Main Image Meta Description', label: 'Main Image Meta Description' },
        { value: 'Thumbnail Image Meta Description', label: 'Thumbnail Image Meta Description' },
    ];
    const handleSubmit = async () => {
        const resp = await api.jobApplications.updateApplication(jobId, id, {
            tags: tags.map(({ value }) => value)
        })
        if (!resp.success) {
            return;
        }
        dispatch(updateApplicantTags(resp.data))
        handleClose()
        console.log(resp)
    }
    return (
        <>
            <ModalTemplate open={open} modalSize={modalSize} onClick={handleClose}>
                <div className="modal__header">
                    <h2 className="text-[16px] md:text-[20px] font-bold">ADD TAG</h2>
                    <button className="btn btn-close" onClick={handleClose}>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className="modal__body">
                    <div className="w-full">
                        <div className="flex flex-col gap-[16px] mb-[32px]">
                            <p className="text-gray-400 text-[20px]">To attach a special tag to this customer, type a tag name and press enter or selec from available tags</p>
                            <div className="select-multi-value-container--secondary">
                                <MultiSelect
                                    options={options}
                                    selectStyle="secondary"
                                    placeholder='Add a tag'
                                    value={tags}
                                    onChange={async (val) => {
                                        setTags(val)
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                    <Button className={'text-gray-400 min-w-[122px] min-h-[52px] text-[18px]'} onClick={handleClose} >
                        <span className="underline" >Cancel</span>
                    </Button>
                    <Button onClick={handleSubmit} className={'text-white bg-green-500 min-w-[122px] min-h-[52px] text-[18px]'}> Submit </Button>
                </div>
            </ModalTemplate>
        </>
    )
};

export default AddTagModal
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { getAllJobsFetch, getJobByIdFetch } from "../redux/thunks/jobsThunks";
import PageLoader from "../components/PageLoader";

// Reusable component for feature sections
const FeatureSection = ({ title, description, imageURL, altText="" }) => (
	<div className="flex gap-6 justify-between max-md:flex-wrap max-md:max-w-full">
		<img loading="lazy" src={imageURL} alt={altText} className="self-start w-[65px] h-[65px] object-contain" />
		<div className="flex flex-col flex-1 max-md:max-w-full">
			<div className="text-xl font-bold leading-8 max-md:max-w-full">{title}</div>
			<div className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full">{description}</div>
		</div>
	</div>
);

// Reusable component for qualification or requirement items
const QualificationItem = ({ imageURL, altText="", title }) => (
	<div className="flex lg:flex-col gap-3 max-lg:gap-5 items-center grow text-lg leading-8 whitespace-nowrap text-zinc-800">
		<img loading="lazy" src={imageURL} alt={altText} className="self-center w-[65px] h-[65px] object-contain" />
		<div>{title}</div>
	</div>
);
function AP_01() {
	const { job, isLoading } = useSelector(state => state.jobs);
	console.log('job:', job);
	
	const breadcrumbItems = [
		{
			name: 'Job',
			to: '/AP_01',
			isCurrent: true
		},
	];

	const whyWorkWithUsImages = [
        require('../assets/img/book.png'),
        require('../assets/img/pay.png'),
        require('../assets/img/rocket.png'),
        require('../assets/img/calendar.png'),
        require('../assets/img/clock.png'),
        require('../assets/img/money.png'),
        require('../assets/img/dollar.png'),
        require('../assets/img/covid.png'),
        require('../assets/img/company-cares.png'),
        require('../assets/img/fantastic-team.png'),
    ];

    const qualificationImages = [
        require('../assets/img/like.png'),
        require('../assets/img/heart.png'),
        require('../assets/img/speak.png'),
        require('../assets/img/drug-free.png'),
        require('../assets/img/diploma.png'),
    ];

    const locationImages = [
        require('../assets/img/car.png'),
        require('../assets/img/heart.png'),
    ];

	return (<>
		<Breadcrumbs links={breadcrumbItems} colorStyle="secondary" />
		<div className="mt-[24px] mb-[70px] flex gap-x-[60px] gap-y-20 max-lg:flex-col">
			<header className="max-w-[390px] flex flex-col w-full text-zinc-800">
				<h2 className="text-5xl font-bold leading-[60px] max-lg:text-[40px] max-lg:leading-[49px]">{job.jobTitle}</h2>
				<div className="mt-6 h-2 bg-[#06D6A0] w-[82px]" />
			</header>
			<main className="flex flex-col w-full">
				<section className="flex flex-col grow">
					<h2 className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800">{job.jobTitle} Job Description</h2>
					<p className="mt-6 text-lg font-light leading-8 text-zinc-800 max-md:max-w-full whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: job.description }} ></p>
				</section>
				{ job?.mainInfo?.whyWorkWithUsTitle && job?.mainInfo?.whyWorkWithUsItems?.length ?
					<section className="flex flex-col pt-11 mt-24 border-t border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
						<h2 className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800">
							{ job?.mainInfo?.whyWorkWithUsTitle }
						</h2>
						<div className="mt-11 max-md:mt-10 max-md:max-w-full flex gap-5 flex-col">
							{ job?.mainInfo?.whyWorkWithUsItems.map((item, index) => (
								<FeatureSection key={index} imageURL={whyWorkWithUsImages[index]} {...item} />
							)) }
						</div>
					</section>
				: null }
				{ job?.mainInfo?.qualificationsTitle && job?.mainInfo?.qualificationsItems?.length ?
					<section className="flex flex-col pt-11 mt-24 border-t border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
						<h2 className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800">
							{ job?.mainInfo?.qualificationsTitle }
						</h2>
						<div className="grid grid-cols-3 gap-10 mt-10 w-full max-md:grid-cols-1">
							{ job?.mainInfo?.qualificationsItems.map((item, index) => (
								<QualificationItem key={index} title={item} imageURL={qualificationImages[index]} />
							)) }
						</div>
					</section>
				: null }
				{ job?.mainInfo?.locationRequisitesTitle && job?.mainInfo?.locationRequisitesItems?.length ?
					<section className="flex flex-col items-start pt-11 mt-11 text-lg leading-8 border-t border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
						<h2 className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800">
							{ job?.mainInfo?.locationRequisitesTitle }
						</h2>
						<div className="mt-8 max-md:max-w-full flex gap-[26px] flex-col">
							{ job?.mainInfo?.locationRequisitesItems.map((item, index) => (
								<FeatureSection key={index} imageURL={locationImages[index]} {...item} />
							))}
						</div>
						<p className="mt-[32px] text-lg text-[#333333] font-normal">
							{ job?.mainInfo?.locationRequisitesDescription }
						</p>
					</section>
				: null }
				<section className="pt-[40px] mt-[44px] border-t border-solid border-[#CFCFCF]">
					<Link
						to={"it-takes-x-minutes"}
						className='ml-auto w-full md:max-w-[210px] h-[52px] flex justify-center items-center text-lg whitespace-nowrap bg-[#06D6A0] rounded-md text-white font-bold hover:bg-[#002E67]'
					>
						Apply Now
					</Link>
				</section>
			</main>
		</div>
	</>
	);
}

export default AP_01
import { useState } from 'react';
import InputField from "../base/InputField"
import PrimaryButton from "../base/PrimaryButton"
import SelectBox from "../base/SelectBox"
import TextareaField from "../base/TextareaField"
import api from "../../api";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const ContactForm = ({ onSubmit, companyId = null, setContactFormOpen, areas = "Current Job Details", setContactFormOpenSended }) => {
	const locationData = useLocation();

	const [payload, setPayload] = useState({
		firstName: "", // "John"
		lastName: "", // "Smith"
		email: "", // "john@gmail.com"
		message: "", // "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
		companyId,
		areas,
	});

	const handleSubmit = async(e) => {
		e.preventDefault();

		let erroredValue = false;

		[
			"email",
			"message",
			"firstName",
			"lastName",
		]?.forEach((item)=>{
			if(!payload?.[item]?.length){
				erroredValue = true;
				return false;
			}
		});

		if(erroredValue){
			toast.error("All field is require");
		} else {
			onSubmit && onSubmit();
			let res = await api.users.contactUsSend(payload);

			if(res?.success){
				toast.success(res?.message || "Message send successfully");

				setContactFormOpenSended && setContactFormOpenSended(true);
			} else {
				toast.error(res?.message || "Something went wrong");
			}
		}
	}

	return (
		<form onSubmit={handleSubmit} className='contact-form max-w-[1432px] px-[16px] py-[40px] md:px-4 w-full  md:pt-[60px] md:pb-[90px] mx-auto'>
			<h1 className="text-[36px] md:text-[40px] font-bold text-[#333333] text-center">How can we help you?</h1>
			<h2 className="mt-2 text-lg font-normal text-[#333333] text-center">Let us know and we’ll get you what you need.</h2>
			<div className='max-w-[1066px] w-full mx-auto px-[32px] md:px-[44px] py-[36px] md:pt-[44px] md:pb-[70px] bg-[#F7F7F7] rounded-md border border-solid border-[#D6D6D6] mt-[60px] flex flex-col md:gap-[14px]'>
				<div className='flex max-md:flex-col md:gap-[40px]'>
					<InputField
						label='First Name'
						className='bg-[#FFFFFF] border border-solid border-[#DEDED9]'
						value={payload.firstName}
						onInput={(e)=>{
							setPayload((prev)=>{
								return {
									...prev,
									firstName: e.target.value
								}
							})
						}}
					/>
					<InputField
						label='Last Name'
						className='bg-[#FFFFFF] border border-solid border-[#DEDED9]'
						value={payload.lastName}
						onInput={(e)=>{
							setPayload((prev)=>{
								return {
									...prev,
									lastName: e.target.value
								}
							})
						}}
					/>
				</div>
				{/* <InputField
					label='Company' className='bg-[#FFFFFF] border border-solid border-[#DEDED9]'
				/> */}
				<InputField
					label='Email' type='email'
					className='bg-[#FFFFFF] border border-solid border-[#DEDED9]'
					value={payload.email}
					onInput={(e)=>{
						setPayload((prev)=>{
							return {
								...prev,
								email: e.target.value
							}
						})
					}}
				/>
				<div className='mb-[16px]'>
					<p className='mb-[16px] text-[16px] md:text-lg font-semibold text-[#333333]'>Areas Help Needed</p>
					<SelectBox
						options={[
							(locationData?.pathname === '/new-job' ?
								{ label: 'New Job Details', value: "New Job Details" } :
								{ label: 'Current Job Details', value: "Current Job Details" }
							),
							{ label: 'Description', value: "Description" },
							{ label: 'Pipelines', value: "Pipelines" },
							// { label: 'Qualification Attributes', value: "Qualification Attributes" },
							{ label: 'Qualification Attributes and Interview Questions', value: "Qualification Attributes and Interview Questions" },
							// { label: 'Interview Questions', value: "Interview Questions" },
							{ label: 'Application Form', value: "Application Form" },
							{ label: 'Disposition Status', value: "Disposition Status" },
						]}
						svg={2} className='!h-[54px] !border-[#DEDED9]'
						value={payload.areas}
						onSelect={(value)=>{
							// console.log('Areas Help Needed e:', e);
							setPayload((prev)=>{
								return {
									...prev,
									areas: value
								}
							})
						}}
					/>
				</div>
				<TextareaField
					label='Message' height={160}
					className='bg-[#FFFFFF] border border-solid border-[#DEDED9]'
					value={payload.message}
					onInput={(e)=>{
						setPayload((prev)=>{
							return {
								...prev,
								message: e.target.value
							}
						})
					}}
				/>
				<PrimaryButton className='h-[54px] text-lg font-bold mt-[20px]'>SUBMIT</PrimaryButton>
			</div>
		</form>
	)
}

export default ContactForm;

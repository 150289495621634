import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { LoginedHeader } from "../components/parts/LoginedHeader";
import { Footer } from "../components/parts/Footer";

const Copyright = ({ className = '' }) =>{
    return <div className={`bg-[#F7F7F7] flex items-center h-[90px] lg:h-[110px] self-center text-[16px] text-base font-light text-center whitespace-nowrap text-neutral-400 py-4 w-full mt-auto ${className}`}>
        <p className='max-lg:max-w-60 text-balance text-center mx-auto'>Copyright © 2024 EmployJoy.ai, All rights reserved.</p>
    </div>
}

export const LoginedLayout = ({ fullFooterOnMob = false }) => {
    return (
		<>
            <div className="box-border flex relative flex-col shrink-0 min-h-screen overflow-x-hidden">
                <LoginedHeader />
                <Outlet/>
                { 
                    fullFooterOnMob ? 
                    <>
                        <div className="md:hidden">
                            <Footer />
                        </div>
                        <Copyright className="max-md:hidden" />
                    </> : 
                <Copyright /> }
            </div>
		</>
	)
}

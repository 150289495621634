import React, { useState } from 'react';
import Step7 from "../NewJobDetails/Step7";
import NextStepSection from "./NextStepSection";

const ApplicationForm = ({
    payload,
    setPayload,
    setContactFormOpen,
    industriesList,
    qaPresetAttributesSelected,
    setQaPresetAttributesSelected,
    save,
    statusToggle,
}) => {
    return (
        <>
            <Step7
                payload={payload}
                setPayload={setPayload}
                setContactFormOpen={setContactFormOpen}
                industriesList={industriesList}
                qaPresetAttributesSelected={qaPresetAttributesSelected}
                setQaPresetAttributesSelected={setQaPresetAttributesSelected}
                bottomPaste={<div>
                    <hr className="mb-[50px] border-b border-neutral-200 max-md:hidden" />
                    <NextStepSection
                        save={save}
                        statusToggle={statusToggle}
                        payload={payload}
                    />
                </div>}
            />
        </>
    )
}

export default ApplicationForm
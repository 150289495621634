import React, { useState } from 'react';
import Step8 from "../NewJobDetails/Step8";
import NextStepSection from "./NextStepSection";

const DispositionStatus = ({
    payload,
    setPayload,
    setContactFormOpen,
    industriesList,
    qaPresetAttributesSelected,
    setQaPresetAttributesSelected,
    save,
    statusToggle,
}) => {
    return (
        <>
            <Step8
                payload={payload}
                setPayload={setPayload}
                setContactFormOpen={setContactFormOpen}
                industriesList={industriesList}
                qaPresetAttributesSelected={qaPresetAttributesSelected}
                setQaPresetAttributesSelected={setQaPresetAttributesSelected}
                bottomPaste={<div className="mb-[50px]">
                    <NextStepSection
                        save={save}
                        statusToggle={statusToggle}
                        payload={payload}
                    />
                </div>}
            />
        </>
    )
}

export default DispositionStatus
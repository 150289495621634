import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SelectBox from '../base/SelectBox'
import Search from '../base/Search'
import { fetchAllIndustries } from '../../redux/thunks/companiesThunks'

const SupportFilters = ({ data, setData, industry, location, status, search, searchField = "name", category }) => {

	const dispatch = useDispatch()

	const [industryOptions, setIndustryOptions] = useState([
		{ label: "Industries", value: '' }
	])
	const [categoryOptions, setCategoryOptions] = useState([
		{ label: "All", value: '' },
		{ label: "Press Release", value: 'Press Release' },
		{ label: "Training", value: 'Training' },
		{ label: "Demo", value: 'Demo' },
		{ label: "Weekly Demo", value: 'Weekly Demo' },
		{ label: "Webinar", value: 'Webinar' },
		{ label: "Podcast", value: 'Podcast' },
		{ label: "Product", value: 'Product' },
		{ label: "Blog", value: 'Blog' },
	])
	const [locationOptions, setLocationOptions] = useState([
		{ label: "All", value: '' },
		{ label: "Location 1", value: 'Location 1' },
		{ label: "Location 2", value: 'Location 2' },
	])
	const statusOptions = [
		{ label: "All", value: '' },
		{ label: "Active", value: 'active' },
		{ label: "Publish", value: 'publish' },
		{ label: "Draft", value: 'draft' },
	]

	const [filters, setFilters] = useState({
		industry: "",
		location: "",
		status: "",
		search: "",
		category: ""
	})

	const applyFilters = () => {

		let filteredData = data?.filter(item => {
			if (industry) {
				const indsId = item.industry ? industryOptions.find(el => el.label === item.industry)?.value : ''
				if (filters.industry && indsId !== filters.industry) {
					return false;
				}
			}
			if (filters.category && item.category !== filters.category) {
				return false;
			}
			if (filters.location && item.location !== filters.location) {

				return false;
			}

			if (filters.status && item.status !== filters.status) {

				return false;
			}

			if (filters.search && !item[searchField].toLowerCase().includes(filters.search.toLowerCase())) {
				return false;
			}

			return item;
		});
		setData && setData(filteredData)
	};

	useEffect(() => {

		if (!industry) return;

		const req = async () => {

			const inds = await dispatch(fetchAllIndustries())

			if (inds?.payload && inds.payload.length > 0) {
				setIndustryOptions([{ label: "All", value: '' }, ...inds.payload.map(el => ({ label: el.name, value: el.id }))])
			}
		}
		req()


	}, []);

	useEffect(() => {
		applyFilters()
	}, [filters])

	return (
		<>
			<div className="flex gap-5 w-full max-md:flex-col">
				{
					category &&
					<div className="w-full md:max-w-[256px]">
						<SelectBox options={categoryOptions}
							onSelect={val => setFilters(prev => ({ ...prev, category: val }))}
							value={filters.category} svg={2}
							placeholder={"Category"}
						/>
					</div>
				}
				{
					industry &&
					<div className="w-full md:max-w-[256px]">
						<SelectBox options={industryOptions}
							onSelect={val => setFilters(prev => ({ ...prev, industry: val }))}
							value={filters.industry} svg={2}
							placeholder={"Industry"}
						/>
					</div>
				}
				{
					location &&
					<div className="w-full md:max-w-[256px]">
						<SelectBox options={locationOptions}
							onSelect={val => setFilters(prev => ({ ...prev, location: val }))}
							value={filters.location} svg={2}
							placeholder={"Location"}
						/>
					</div>
				}
				{
					status &&
					<div className="w-full md:max-w-[256px]">
						<SelectBox options={statusOptions}
							onSelect={val => setFilters(prev => ({ ...prev, status: val }))}
							value={filters.status} svg={2}
							placeholder={"Status"}
						/>
					</div>
				}
				{
					search &&
					<Search
						onSubmit={(val) => setFilters(prev => ({ ...prev, search: val }))}
						parentClassName="w-full h-[54px] max-w-[420px] ml-auto"
					/>
				}
			</div>

		</>
	)
}

export default SupportFilters

import React, { useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from '../components/parts/Footer';
import ModalGrop from '../components/parts/ModalGrop';
const NextStepSection = () => {
    return (
        <section>
            <hr className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className="flex gap-4 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap">
                <button className="flex justify-center items-center px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px] hover:opacity-70">
                    Back
                </button>
                <button className="flex justify-center items-center px-16 py-1 h-[52px] bg-green-500 rounded-md w-full lg:max-w-[162px] hover:opacity-70">
                    Next
                </button>
            </div>
        </section>
    );
};

const ActionButton = ({ text, onClick }) => (
    <button type="button" className="justify-center text-green-500 text-lg h-[50px] w-[110px] font-semibold rounded-md border border-green-500 border-solid" onClick={onClick}>
        {text}
    </button>
);

const ProgressBar = ({ completed, containerClasses = "" }) => {
    const containerStyles = {
        height: 4,
        width: '100%',
        backgroundColor: "#EFEEF0",
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: '#003578',
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    return (
        <div style={containerStyles} className={containerClasses}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${completed}%`}</span>
            </div>
        </div>
    );
}

const StepIndicatorItem = ({ stepNumber, label, isActive }) => {
    const baseStyles = "justify-center items-center self-center w-10 h-10 text-xl leading-8 font-bold text-white border-4 border-white border-solid shadow-md rounded-[360px]";
    const activeStyles = "bg-green-500";
    const inactiveStyles = "bg-neutral-200";

    return (
        <div className="flex flex-col flex-auto max-w-40 px-2">
            <div className={`${baseStyles} ${isActive ? activeStyles : inactiveStyles}`}>
                {stepNumber}
            </div>
            <div className="mt-3.5 text-base font-semibold text-neutral-600">
                {label.split("\n").map((line, index, arr) => (
                    <React.Fragment key={index}>
                        {line}{index < arr.length - 1 && <br />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

const HiringProcess = () => {

    const steps = [
        { stepNumber: "1", label: "Clarifications", isActive: true },
        { stepNumber: "2", label: "1st Interview", isActive: true },
        { stepNumber: "3", label: "Post 1st Interview\nSurvey", isActive: true },
        { stepNumber: "4", label: "2nd Interview", isActive: true },
        { stepNumber: "5", label: "Document\nReview", isActive: false },
        { stepNumber: "6", label: "Rating of\nThe Job", isActive: false },
        { stepNumber: "7", label: "AI\nRecommendation", isActive: false },
        { stepNumber: "8", label: "Final Hiring Decision", isActive: false },
        { stepNumber: "9", label: "Training Insights", isActive: false },
        { stepNumber: "10", label: "Offer Meeting", isActive: false },
        { stepNumber: "11", label: "Training\nMilestones", isActive: false },
    ];
    const activeCount = steps.filter(step => step.isActive)
    const progress = (activeCount.length / steps.length) * 100;

    return (
        <div className="relative flex shrink-0 justify-between text-center">
            <ProgressBar
                containerClasses='absolute z-[-1] top-[18px]'
                completed={progress}
            />
            {steps.map((step, index) => (
                <StepIndicatorItem
                    key={index}
                    stepNumber={step.stepNumber}
                    label={step.label}
                    isActive={step.isActive}
                />
            ))}
        </div>
    );
};

const Question = ({ question, score, index }) => {
    return (
        <div className="flex flex-nowrap gap-0 justify-between mt-3 text-xl leading-8 text-zinc-800 w-full">
            <div className="flex-auto whitespace-normal max-md:text-[18px] max-md:leading-[28px]">Question {index} : {question}</div>
            <div className="text-end md:text-center basis-1/5 flex-shrink-0 max-md:text-[18px] max-md:leading-[28px]">{score}</div>
        </div>
    )
}

const CandidateDetails = () => {
    const questionsData = [
        { question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?", score: 6 },
        { question: "Quisque sit amet tortor nulla?", score: 7 },
        { question: "Nullam laoreet tincidunt purus vel volutpat?", score: 8 },
        { question: "Suspendisse eget enim odio, quis congue dolor?", score: 9 },
        { question: "Nullam in ante nunc, eu auctor erat?", score: 4 },
        { question: "Pellentesque habitant morbi tristique senectus et netus?", score: 2 },
        { question: "Vivamus magna justo, lacinia eget consectetur sed?", score: 5 },
        { question: "Curabitur non nulla sit amet nisl tempus?", score: 8 },
        { question: "Praesent sapien massa, convallis a pellentesque nec?", score: 5 },
        { question: "Sed porttitor lectus nibh, vel egestas nulla?", score: 5 },
        { question: "Pellentesque habitant morbi tristique senectus et netus?", score: 2 },
        { question: "Vivamus magna justo, lacinia eget consectetur sed?", score: 5 },
        { question: "Curabitur non nulla sit amet nisl tempus?", score: 8 },
        { question: "Praesent sapien massa, convallis a pellentesque nec?", score: 5 },
        { question: "Sed porttitor lectus nibh, vel egestas nulla?", score: 5 },
        { question: "Pellentesque habitant morbi tristique senectus et netus?", score: 2 },
        { question: "Vivamus magna justo, lacinia eget consectetur sed?", score: 5 },
        { question: "Curabitur non nulla sit amet nisl tempus?", score: 8 },
        { question: "Praesent sapien massa, convallis a pellentesque nec?", score: 5 },
        { question: "Sed porttitor lectus nibh, vel egestas nulla?", score: 5 },
    ];
    return (
        <section className="flex flex-col w-full">
            <article>
                <h3 className="w-full text-[28px] md:text-3xl font-bold leading-8 text-zinc-800">2<sup>st</sup> Interview</h3>
                <div className="mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full"></div>
                <div className="flex gap-0 justify-between mt-9 text-[22px] md:text-2xl font-bold leading-7 whitespace-nowrap text-zinc-800 max-md:flex-wrap">
                    <div className="flex-auto">Questions</div>
                    <div className="text-center basis-1/5 max-md:text-right">Score</div>
                </div>
                <div className='w-full mt-3'>
                    {questionsData.map((question, index) => (
                        <Question
                            key={index}
                            {...question}
                            index={index + 1}
                        />
                    ))}
                </div>
            </article>
        </section>
    );
};

const TabButton = ({ onClick = () => { }, isActive, label, id }) => {
    return (
        <button
            type='button'
            onClick={() => onClick(id)}
            className={`grow justify-center px-5 py-[7px] ${isActive ? 'border-green-500' : 'border-white'}  border-solid border-b-[5px] hover:bg-green-200`}
        >
            {label}
        </button>
    )
}

const SBM01_10_3_5 = () => {
    const tabsData = [
        { label: 'Report Card', id: 1 },
        { label: 'History', id: 2 },
        { label: 'Reference', id: 3 },
        { label: 'Reject', id: 4 },
        { label: 'Offer Letter', id: 5 },
    ];
    
    const [activeTab, setActiveTab] = useState(tabsData.length ? tabsData[0].id : null);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    
    return (
        <>
            <BreadCrumbWhite
                crumbs={[
                    'Subscriber Dashboard',
                    'Jobs',
                    'Applicants',
                    "Candidates",
                    "Marc Spector",
                    "Report Card",
                    "1st Interview",
                    "Post 1st Interview Survey",
                    "2nd Interview"
                ]}
            />
            <div className='flex-auto'>
                <div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
                    <header className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                        <div>
                            <h2 className="text-4xl font-bold leading-10 text-zinc-950">Marc Spector</h2>
                        </div>
                        <div className="flex flex-wrap gap-2 my-auto">
                            <ModalGrop tagModal={false} />
                        </div>
                    </header>
                    <nav className="flex overflow-auto w-full gap-2 items-center self-start text-xl font-semibold text-center whitespace-nowrap text-zinc-800 max-w-[670px]">
                        {tabsData.map(tab => (
                            <TabButton
                                key={tab.id}
                                id={tab.id}
                                label={tab.label}
                                isActive={activeTab === tab.id}
                                onClick={handleTabClick}
                            />
                        ))}
                    </nav>
                    <section className="flex w-full text-center overflow-auto py-4">
                        <HiringProcess />
                    </section>
                    <CandidateDetails />
                    <NextStepSection />
                </div>
            </div>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM01_10_3_5
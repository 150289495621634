import blocksImage from "../../../assets/img/home/blocks.png";
import { ReactComponent as ArrowIcon } from "../../../assets/img/home/arrowToFill.svg";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../../../assets/css/Swiper/styles.css';
import React, { useRef, useEffect } from "react";
import { AboutBlock } from "./AboutBlock";
import { NavLink } from "react-router-dom";

export const ClientsComments = ({ topSpacing = "pb-[110px]", withBlock = false, title, header, testimonials, buttonText, contentStyle="" }) => {
    const swiperRef = useRef(null);

    const adjustSlideHeights = () => {
        const slides = document.querySelectorAll('.swiper-slide');
        let maxHeight = 0;

        slides.forEach(slide => {
            slide.style.height = 'auto';
            if (slide.offsetHeight > maxHeight) {
                maxHeight = slide.offsetHeight;
            }
        });

        slides.forEach(slide => {
            slide.style.height = `${maxHeight}px !important`;
        });
    };

    useEffect(() => {
        adjustSlideHeights();

        window.addEventListener('resize', adjustSlideHeights);
        return () => window.removeEventListener('resize', adjustSlideHeights);
    }, [testimonials]);

    return (
        <section className={`w-full relative ${topSpacing} bg-white font-['Raleway'] bg-top bg-no-repeat`}
            style={{ backgroundImage: `url(${withBlock && blocksImage})` }}
        >
            <div className="flex flex-col items-center container relative z-10">
                <AboutBlock
                    title={title}
                    header={header}
                    contentCentered
                    headerStyle={"max-w-[670px]"}
                />

                <div className="flex flex-col justify-center w-full items-center mt-[40px] gap-[20px]">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
                        loop={true}
                        modules={[Navigation, Autoplay]}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                        className="swiper-with-buttons"
                        breakpoints={{
                            0: { slidesPerView: 1 },
                            640: { slidesPerView: 2 },
                            1024: { slidesPerView: 3 },
                        }}
                        onSlideChange={() => adjustSlideHeights()}
                    >
                        {testimonials?.map((testimonial, idx) => (
                            <SwiperSlide key={idx}>
                                <div className={`flex flex-col items-center text-center bg-white rounded-[21px] p-9 pt-[50px] shadow-lg relative h-full justify-between ${contentStyle}`}>
                                    <img
                                        src={testimonial.image}
                                        alt={testimonial.name}
                                        className="w-[71px] h-[71px] rounded-full mb-4 absolute top-[-35px] left-[50%] translate-x-[-50%]"
                                    />
                                    <p className="text-xl font-medium">"{testimonial.text}"</p>
                                    <div className="flex flex-col">
                                        <p className="text-[27px] font-bold mt-[18px] text-[#0056B3]">{testimonial.name}</p>
                                        <p className="text-base font-semibold flex-1 flex items-center justify-center">{testimonial.job}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="flex gap-4">
                        <div
                            className="bg-[#D5F284] p-3 rounded-full cursor-pointer w-[41px] hover:opacity-90 hover:scale-95 h-[41px] flex justify-center items-center"
                            onClick={() => swiperRef.current?.slidePrev()}
                        >
                            <ArrowIcon fill="#000" />
                        </div>
                        <div
                            className="bg-[#D5F284] rounded-full cursor-pointer w-[41px] h-[41px] hover:opacity-90 hover:scale-95 flex justify-center items-center p-3 rotate-180"
                            onClick={() => swiperRef.current?.slideNext()}
                        >
                            <ArrowIcon fill="#000" />
                        </div>
                    </div>
                    {buttonText && <NavLink
                        to="/request-demo"
                        className="flex justify-center mt-[20px] items-center font-semibold text-lg sm:text-xl md:text-2xl bg-[#37B34A] hover:bg-green-600 rounded-md text-white h-[47px] w-[206px]"
                    >
                        {buttonText}
                    </NavLink>}
                </div>
            </div>
        </section>
    );
};

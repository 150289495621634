import React, {useEffect, useState} from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import {Footer} from '../components/parts/Footer';
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {getUser} from "../redux/slices/applicationSlice";
import {getJobByIdFetch, getJobsByCompanyFetch, getJobStats} from "../redux/thunks/jobsThunks";

const calculateTotalScore = (clarifications, answersScores, maxScore = 7) => {
    if (!clarifications?.length || !answersScores?.length) return;

    const totalQuestions = clarifications.length;

    const totalQuestionsAnswered = answersScores.filter(score => !isNaN(parseFloat(score))).length;

    const totalScoreSum = answersScores.reduce((sum, score) => !isNaN(parseFloat(score)) ? sum + parseFloat(score) : sum, 0);

    const maxPossibleTotalScore = totalQuestionsAnswered * maxScore;

    const totalPercentage = totalQuestionsAnswered > 0
        ? Math.floor((totalScoreSum / maxPossibleTotalScore) * 100)
        : 0;

    return {
        total: {
            score: totalScoreSum,
            percentage: totalPercentage,
            name: "Total",
            key: "total"
        }
    };
};

const exportToCSV = (sources, filename) => {
    const headers = [
        'Full Name', 'Source', 'Status', 'Date Job Ad Started', 'Date Hired', 'Date of Application',
        'Stage Scores', 'Total Activities', 'Total Calls', 'Total Texts', 'Total Emails',
        'Phone', 'Email', 'Link'
    ];

    const csvRows = sources.map(source => [
        source.fullName,
        source.source,
        source.status,
        source.dateAdStarted,
        source.dateHired,
        source.dateApplication,
        source.stageScores.length > 1 ? `"${source.stageScores.join('\n')}"` : source.stageScores.join(', '),
        source.activities,
        source.calls,
        source.texts,
        source.emails,
        source.phone,
        source.email,
        source.link
    ].join(','));

    const csvContent = [headers.join(','), ...csvRows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const SBM04_1_1 = () => {
    const dispatch = useDispatch();
    const {jobStats, companyJobs, isLoading} = useSelector(state => state.jobs);
    const {job} = useSelector(state => state.jobs);
    const [searchParams, setSearchParams] = useSearchParams();
    const [formData, setFormData] = useState({
        jobId: "18",
        limit: 1000,
        start: '',
        end: '',
    });
    const user = useSelector(getUser);

    useEffect(() => {
        if (!user?.companies?.length || !user?.companies[0]?.company?.id) return;
        dispatch(getJobsByCompanyFetch({companyId: user.companies[0].company.id, filters: searchParams.toString()}));
    }, [user]);

    useEffect(() => {
        if (formData.jobId) {
            const data = {
                jobId: formData.jobId
            };

            if (formData.start && formData.end) {
                const formattedStart = new Date(formData.start).toISOString();
                const formattedEnd = new Date(formData.end).toISOString();
                setSearchParams(prev => {
                    prev.set('start', formattedStart);
                    prev.set('end', formattedEnd);
                    return prev;
                });

                data.filters = searchParams.toString();
            }

            dispatch(getJobStats(data));
            dispatch(getJobByIdFetch(formData.jobId));
        }
    }, [formData.jobId]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
        setSearchParams(prev => {
            prev.set(name, value);
            return prev;
        });
    };

    const calculateCandidateStageScores = (candidate, milestones) => {
        const stageScores = [];
        const passedStages = [];
        let totalScoreSum = 0;
        let stagesWithScores = 0;

        milestones.forEach(milestone => {
            if (candidate.assessments && candidate.assessments[milestone.stageKey]) {
                const scores = candidate.assessments[milestone.stageKey]?.scores || [];
                const candidateScore = calculateTotalScore(candidate?.criticalRequirements?.[milestone.stageKey], scores);
                if (candidateScore?.total?.score) {
                    stageScores.push(`${milestone.text}: ${candidateScore.total.percentage}%`);
                    passedStages.push(milestone.text);
                    totalScoreSum += candidateScore.total.score;
                    stagesWithScores++;
                }
            }
        });

        const overallAverageScore = stagesWithScores > 0 ? (totalScoreSum / stagesWithScores).toFixed(2) : "N/A";

        return {stageScores, passedStages, overallAverageScore};
    };

    const milestones = [
        {text: "Clarification", stageKey: 'clarifications'},
        {text: "1st Interview", stageKey: '1st-interview'},
        {text: "Post Interview Survey", stageKey: 'post_interview_survey'},
        {text: "2nd Interview", stageKey: '2nd-interview'},
        {text: "Doc Review", stageKey: 'doc_review'},
        {text: "Rating of the Job", stageKey: 'rating_of_the_job'},
        {text: "AI Evaluation", stageKey: 'ai_evaluation'},
        {text: "Final Hiring Decisions", stageKey: 'final_hiring_decisions'},
        {text: "Training Insights", stageKey: 'training_insights'},
        {text: "Offer Meeting", stageKey: 'offer_meeting'},
        {text: "Training Milestones", stageKey: 'training_milestones'},
    ];

    const sources = jobStats?.map((stat, idx) => {
        const {stageScores} = calculateCandidateStageScores(stat, milestones);

        return {
            fullName: stat?.fullName,
            source: stat?.source,
            status: stat?.status,
            dateAdStarted: new Date(job?.createdAt).toLocaleDateString(),
            dateHired: stat?.offerMeeting?.date ? new Date(stat?.offerMeeting?.date).toLocaleDateString() : "-",
            dateApplication: new Date(stat.createdAt).toLocaleDateString(),
            stageScores,
            activities: stat.countsStat.sms + stat.countsStat.calls + stat.countsStat.emails + stat.countsStat.aiAgentMessages,
            calls: stat.countsStat.calls,
            texts: stat.countsStat.aiAgentMessages,
            emails: stat.countsStat.emails,
            phone: stat?.phone,
            email: stat?.email,
            link: `https://ej.webstaginghub.com/SBM01_10_14(2)/${stat.jobId}/${stat.id}`
        };
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        exportToCSV(sources, 'job_stats');
    };

    return (
        <>
            <BreadCrumbWhite crumbs={['Insights', 'Pipeline Summary', 'Export CSV']}/>
            <section className="flex flex-auto justify-center lg:py-12 w-full text-zinc-800">
                <div className="flex flex-col items-center lg:my-10 mt-9 mb-20 max-w-[1432px] px-4 w-full">
                    <form onSubmit={handleSubmit}
                          className="flex flex-col w-full py-9 px-8 lg:p-16 bg-white rounded-md shadow-def">
                        <div
                            className="lg:text-3xl text-[28px] leading-[36px] font-bold lg:leading-10 text-zinc-800 w-full pb-[18px] border-b-2 border-solid border-green-500 montserrat">
                            Choose your Customers
                        </div>
                        <div
                            className="w-full flex flex-wrap lg:gap-5 justify-between mt-9 lg:mt-11 text-lg max-md:flex-wrap lg:max-w-[66.66%] max-lg:flex-col">
                            <div className="flex flex-col flex-1 min-w-48">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="Job"
                                    name="jobId"
                                    id="jobId"
                                    value={formData.jobId}
                                    onChange={handleChange}
                                    options={companyJobs ? companyJobs.map(one => ({
                                        label: one.jobTitle,
                                        value: one.id
                                    })) : []}
                                />
                            </div>
                            <div className="flex flex-col flex-1 min-w-48 whitespace-nowrap">
                                <InputField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="From"
                                    labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px] max-md:text-[16px]"
                                    type="date" name="start" id="start"
                                    value={formData.start} onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col flex-1 min-w-48 whitespace-nowrap">
                                <InputField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="To"
                                    labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px] max-md:text-[16px]"
                                    type="date" name="end" id="end"
                                    value={formData.end} onChange={handleChange}
                                />
                            </div>
                        </div>
                        <button
                            className="justify-center lg:max-w-[236px] px-4 lg:h-16 h-[60px] mt-5 lg:mt-[24px] lg:text-[22px] text-[19px] font-bold text-center text-white uppercase whitespace-nowrap bg-green-500 rounded-md hover:opacity-70"
                            type='submit'
                            disabled={!sources}
                        >
                            Download
                        </button>
                    </form>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default SBM04_1_1;

import React from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import Checkbox from "../components/base/Checkbox";
import {HiringProcess} from "../components/base/ProgressStepsSBM";
import ContactUsLinkBlock from "../components/parts/ContactUsLinkBlock";
import {Link} from "react-router-dom";

const NextStepSection = () => {
    return (<section>
            <hr className="self-stretch w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full"/>
            <div
                className="flex gap-4 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap">
                <Link to={'/SBM02_2'}
                      className="flex justify-center items-center px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px]">
                    Back
                </Link>
                <Link to={'/SBM02_5'}
                      className="flex justify-center items-center px-16 py-1 h-[52px] bg-green-500 rounded-md w-full lg:max-w-[162px]">
                    Next
                </Link>
            </div>
        </section>);
};

const mockData = {
    stepTitle: "Qualification Attributes and Interview Questions",
    stepDescription: "We evaluate candidates based on 20+ Qualification Attributes (QA), the key qualifications required by the position. For each QA, we have some highly tested interview questions to evaluate the candidate’s level of competence. These QAs and interview questions are battle tested by our AI algorithm to maximize its effectiveness.",
    title: "QAs and Interview questions:",
    items: [{
        title: "Sample QA: Client Service",
        description: "Sample interview question: From a scale of 1 to 7 (1 is totally disagree and 7 is totally agree), how much you agree with this statement: Mary spends 15 min with a customer but fails to make a sale. Her time was wasted."
    }, {
        title: "Sample QA: Safety",
        description: "Sample interview question: From a scale of 1 to 7 (1 is totally disagree and 7 is totally agree), how much you agree with this statement: I believe many workplace safety rules exist merely to satisfy government regulations"
    }, {
        title: "Sample QA: Stability", description: "Sample interview question:", questions: [{
            title: "Which situation applies to you the most?",
            options: [{a: "Relocating/ Moving in 6 to 8 months"}, {b: "Graduating from school in 6 to 8 months"}, {c: "Availability to work will decrease due to personal situations in 6 to 8 months."}, {d: "None of these apply"}]
        }]
    },

    ]
}

const SBM02_3with2_4 = () => {

    const stepsData = [{stepNumber: "1", label: "New Job Details", isActive: true}, {
        stepNumber: "2",
        label: "Description",
        isActive: true
    }, {stepNumber: "3", label: "Preview", isActive: true}, {
        stepNumber: "4",
        label: "Pipelines",
        isActive: true
    }, {
        stepNumber: "5",
        label: "Qualification Attributes and Interview Questions",
        isActive: true
    }, // { stepNumber: "6", label: "Interview Questions", isActive: false },
    {stepNumber: "6", label: "Application Form", isActive: false}, {
        stepNumber: "7",
        label: "Disposition Status",
        isActive: false
    }];


    return (<>
            <BreadCrumbWhite
                crumbs={['Subscriber Dashboard', 'Jobs', 'New Job Details', "Description", "Preview", "Pipelines", 'Qualification Attributes and Interview Questions']}/>

            <div className='flex-auto'>
                <div className='flex flex-col max-w-[1432px] px-4 w-full pb-20 pt-10 lg:pt-14 lg:pb-24 mx-auto'>
                    <section className="flex w-full text-center overflow-auto mb-10 lg:mb-[60px]">
                        <HiringProcess stepsData={stepsData}/>
                    </section>

                    <div className='text-center flex gap-3 items-center justify-center flex-col lg:mb-[60px]'>
                        <span
                            className='lg:text-[40px] text-4xl font-bold'>{mockData.stepTitle}</span>
                        <span className='lg:text-[18px] text-base leading-[22px] lg:leading-[30px]'>
                            {mockData.stepDescription}
                        </span>
                    </div>

                    <div className="lg:border border-neutral-200 lg:px-[60px] lg:py-[50px]">
                        <section
                            className="flex flex-col border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
                            <h2
                                className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800"
                            >
                                {mockData.title}
                            </h2>
                            <div className="mt-11 max-md:mt-10 max-md:max-w-full flex gap-5 flex-col">
                                {mockData.items.map((item, index) => (
                                    <div className="flex gap-6 justify-between max-md:flex-wrap max-md:max-w-full"
                                         key={index}>
                                        <div className="flex flex-col flex-1 max-md:max-w-full">
                                            <div
                                                className="text-xl font-bold leading-8 max-md:max-w-full"
                                            >
                                                {item.title}
                                            </div>
                                            <div
                                                className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full"
                                            >
                                                {item.description}
                                            </div>
                                            {item.questions && item.questions.map((question, index) => <div
                                                className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full"
                                                key={index}
                                            >
                                        <span className='lg:text-[18px] text-base leading-[22px] lg:leading-[50px]'>
                                            {question.title}
                                        </span>
                                                {question.options && question.options.map((option, index) => <div
                                                    className=" lg:leading-[30px] whitespace-pre-wrap mt-1.5 ml-3 text-lg font-light leading-8 max-md:max-w-full"
                                                    key={index}
                                                >

                                                    {Object.keys(option) + ") "}
                                                    {Object.values(option)}
                                                </div>)}
                                            </div>)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                    <NextStepSection/>
                </div>
            </div>
    </>)
}

export default SBM02_3with2_4

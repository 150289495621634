import {FourStepsWrapper} from "../../parts/FourStepsWrapper";
import React, {useEffect, useState} from "react";
import {crumbsConfig} from "../../config";

export const ResumeStep = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
        ["Resume", '']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            {step === 1 && <FourStepsWrapper
                crumbs={crumbs}
                activePin={4}
                onNext={nextComponent}
                onBack={prevComponent}
            >
                <StepOne />
            </FourStepsWrapper>}

        </>
    )
}

const StepOne = () => {

    return (
        <>
            <textarea className="grey-textarea !p-[18px] !h-[500px]"></textarea>
        </>
    )
}
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {NextStepSectionSBM01} from "../base/NextStepSectionSBM01";
import api from "../../../api";
import {average} from "../../../utils";


const Assessment = () => {
    const navigate = useNavigate()
    const {job: jobData, application: applicationData, jobId, applicationId, setActiveStep} = useOutletContext()


    const [isLoading, setIsLoading] = useState(false);
    const getJobApplicationById = async () => {
        setIsLoading(true)
        await api.jobs.getJobsById(jobId);
        await api.jobApplications.getJobApplicationById(jobId, applicationId);
    }
    useEffect(() => {
        setActiveStep(3)
        getJobApplicationById();
    }, [])

    const avgAssessments = average(applicationData?.hiringAssessments?.assessments?.assessments, 'score')
    const avgXScores = average(applicationData?.hiringAssessments?.assessments?.xscores, 'score')
    const avgAttitude = average(applicationData?.hiringAssessments?.assessments?.attitudes, 'score')
    const asigmantsData = [

        {
            title: 'Fit Survey (1-10)',
            id: '67878',
            category: applicationData?.hiringAssessments?.assessments?.xscores.map(item => ({
                id: item.id,
                categoryName: item.description,
                value: item.score
            })),
            isActive: true,
            totalScore: avgXScores === 'N/A' ? avgXScores : avgXScores * 10 + '%',
            color: avgXScores < 4 ? 'bg-[#D43234]' : avgXScores >= 8 ? 'bg-[#37B34A]' : 'bg-[#FFCC00]',
        },
    ]
    if (avgAttitude !== "N/A") {
        asigmantsData.push({
            title: 'Attitude Survey (0-10)',
            id: '423424',
            category: applicationData?.hiringAssessments?.assessments?.attitudes?.map((item, index) => ({
                id: index,
                categoryName: item.name,
                value: item.score / 2
            })) || [],
            isActive: true,
            totalScore: avgAttitude === 'N/A' ? avgAttitude : avgAttitude * 5 + '%',
            color: avgAttitude < 8 ? 'bg-[#D43234]' : avgAttitude >= 16 ? 'bg-[#37B34A]' : 'bg-[#FFCC00]',
        })
    }


    const CategoryScore = ({category, score}) => (
        <div className="flex gap-5 justify-between mt-6 max-w-full whitespace-nowrap w-[400px]">
            <div
                className="flex-auto my-auto text-xl font-bold leading-7 overflow-hidden text-ellipsis">{category}</div>
            <div
                className="flex items-center justify-center px-2 py-1 lg:h-[54px] h-[52px] text-lg min-w-[96px] text-center bg-white border border-solid border-zinc-300 max-md:px-5">
                {score}
            </div>
        </div>
    );
    const TotalScore = ({title, score, isGreen, color}) => (
        <div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
            <div
                className="grow flex justify-center items-center px-16 lg:h-[54px] h-[52px] font-semibold bg-neutral-100 w-fit">
                {title}
            </div>
            <div
                className="flex gap-2 justify-center items-center px-5 py-1 min-w-[96px] lg:h-[54px] h-[52px] bg-white border border-solid border-zinc-300"
            >
                {isGreen && (
                    <div className={`shrink-0 my-auto w-3 h-3 ${color} rounded-[360px]`}/>
                )}
                <div>{score}</div>
            </div>
        </div>
    );
    const Assessment = ({title, categories, totalScore, isGreen, color}) => (
        <>
            <div className="self-stretch mt-16 text-[28px] font-bold leading-8 max-md:mt-10 max-md:max-w-full">
                {title}
            </div>
            <div
                className="shrink-0 self-stretch mt-9 h-px border border-solid bg-neutral-200 border-neutral-200 max-md:max-w-full"/>
            {categories.map((item, index) => (
                <CategoryScore key={item.id} category={item.categoryName} score={item.value}/>
            ))}
            <TotalScore title="Total Score" score={totalScore} isGreen={isGreen} color={color}/>
        </>
    );


    const handlePrev = () => {
        navigate(`/SBM01_10_14_2_2/${jobId}/${applicationId}`)
    }
    const handleNext = () => {
        navigate(`/SBM01_10_14_2_12/${jobId}/${applicationId}`)
    }

    return (
        <div className='flex-auto'>
            <section
                className="flex justify-center flex-auto pb-9 lg:pb-11 w-full text-zinc-800 max-md:max-w-full max-md:pb-[80px]">
                <div className="flex flex-col w-full max-w-[1432px] px-4 max-md:max-w-full">
                    {applicationData?.hiringAssessments ?
                        asigmantsData.map(item => (
                            <Assessment key={item.id} title={item.title} categories={item.category}
                                        isGreen={item.isActive} totalScore={item.totalScore} color={item.color}/>
                        )) : <p className="text-center text-3xl">No Hiring Assessments Data</p>}
                </div>
            </section>
            <NextStepSectionSBM01 prev={handlePrev} next={handleNext}/>
        </div>
    )
}

export default Assessment




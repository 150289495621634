import {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import api from "../../../api";
import TextareaField from "../../../components/base/TextareaField";
import {NextStepSectionSBM01} from "../base/NextStepSectionSBM01";
import {EditableInput} from "../../SBM02_5/EditableInput";

const Resume = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const {job: jobData, application, jobId, applicationId, setActiveStep} = useOutletContext()
    const [applicationData, setApplicationData] = useState(application);

    useEffect(() => {
        setActiveStep(4)
    }, [])


    const handlePrev = () => {
        navigate(`/SBM01_10_14_2_3/${jobId}/${applicationId}`)
    }
    const handleSubmit = async () => {
        if (!applicationData?.resume?.length) return
        setIsLoading(true)
        const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
            resume: applicationData?.resume
        })
        if (!resp.success) {
            toast.error(resp?.message || "Something went wrong")
            return;
        }
        toast.success("updated")
        setIsLoading(false)
    }

    console.log()

    return (
        <div className='flex-auto'>
            <div className="flex flex-col  text-zinc-800 max-md:mt-10 lg:max-w-[720px]">
                {jobData.applicationForm.you_must_copy_and_paste_your_resume_here_active && <>
                    <div className="flex flex-col max-md:max-w-full">
                        <h2 className="text-[22px] md:text-3xl font-bold leading-7 lg:leading-10 max-md:max-w-full">
                            {"Resume"}
                        </h2>
                        {applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_please_copy_and_paste_answer &&
                            <EditableInput
                                disabled
                                className="grey-textarea mt-[24px] min-h-[110px]"
                                value={applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_please_copy_and_paste_answer}
                            />
                        }
                        <div className="font-[500] text-[18px] flex flex-col gap-[16px] mb-[32px]">
                            {
                                applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached_answer && (
                                    <iframe
                                        src={process.env.REACT_APP_API_URL + applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached_answer}
                                        width="100%"
                                        height="1200px"
                                        className="pdf-viewer mt-[36px] w-full h-[calc(100vh-100px)]"
                                        title="Document Viewer"
                                    ></iframe>
                                )
                            }
                        </div>
                        {
                            applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_linkedin_profile_url_answer && (
                                <div>
                                    <p className="mb-[16px] text-[18px] md:text-lg font-semibold leading-[30px] max-md:max-w-full">
                                        {"LinkedIn profile"}
                                    </p>
                                    <a href={applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_linkedin_profile_url_answer}
                                       target="_blank"
                                       className="cursor-pointer underline font-medium text-[#06D6A0]">{applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_linkedin_profile_url_answer}</a>
                                </div>
                            )
                        }
                    </div>
                </>}
            </div>
            <NextStepSectionSBM01 prev={handlePrev}/>
        </div>
    )
}

export default Resume

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api';
import InputFieldHorisontal from '../../../components/base/InputFieldHorisontal';
import MultiSelect from '../../../components/base/MultiSelect';
import PrimaryButton from '../../../components/base/PrimaryButton';
import PageLoader from '../../../components/PageLoader';
import { EvaluationInterview } from "../../../components/parts/EvaluationsInterview";
import { getApplicationByIdFetch, getJobByIdFetch } from '../../../redux/thunks/jobsThunks';
import { QuestionTypes } from '../../AP_01_Form/parts/questionTypes';
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';

const calculateTotalScore = (clarifications, answersScores, maxScore = 7) => {
    const statuses = [
        "Completed",
        "On-going",
        "Not Started",
    ];

    if (!clarifications?.length || !answersScores?.length) return {};

    const totalQuestions = clarifications.length;

    const totalQuestionsAnswered = answersScores.filter(score => !isNaN(parseFloat(score))).length;

    const totalScoreSum = answersScores.reduce((sum, score) => !isNaN(parseFloat(score)) ? sum + parseFloat(score) : sum, 0);

    const maxPossibleTotalScore = totalQuestionsAnswered * maxScore;

    const totalPercentage = totalQuestionsAnswered > 0
        ? Math.floor((totalScoreSum / maxPossibleTotalScore) * 100)
        : 0;

    let totalStatus = statuses[2];
    if (totalQuestionsAnswered === totalQuestions) {
        totalStatus = statuses[0];
    } else if (totalQuestionsAnswered > 0) {
        totalStatus = statuses[1];
    }

    return {
        total: {
            score: totalScoreSum,
            percentage: totalPercentage,
            status: totalStatus,
            name: "Total",
            key: "total"
        }
    };
};


const Score = ({ title = "Score", onChange, value }) => (
    <div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
        <div
            className="grow flex justify-center items-center px-16 lg:h-[54px] h-[52px] font-semibold bg-neutral-100 w-fit text-[#333333]">
            {title}
        </div>
        <InputFieldHorisontal
            className="flex gap-2 justify-center items-center px-5 py-1 min-w-[96px] lg:h-[54px] h-[52px] bg-white border border-solid border-zinc-300"
            error={null}
            min={0}
            max={10}
            type="number"
            onChange={onChange}
            value={parseInt(value) || null}
        />
    </div>
);

const InterviewSection = ({
    questions,
    companyId,
    job = null,
    selectedInterviewers = [],
    handleSelect = () => {
    }
}) => {
    const [interviewers, setInterviewers] = useState([]);

    useEffect(() => {
        if (!companyId) return;
        api.companyTeam.getTeamMembers(companyId).then(response => {
            if (!response.success || !response?.data?.length) {
                return;
            }
            setInterviewers(response.data.map(member => ({
                value: member?.id,
                label: `${member?.user?.firstName} ${member?.user?.lastName}`
            })))
        })
    }, [companyId])

    return (
        <div className="flex flex-col items-stretch">
            <div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
                {/*2<sup>nd</sup> Interview*/}
                {job?.pipeline?.id === 1 ? "In Person Interview" : "Virtual Interview"}
            </div>
            <div
                className="self-stretch mt-9 max-md:mt-[24px] w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full mb-9" />
            {/* <div className="flex max-lg:flex-col justify-start gap-2 lg:gap-24 self-stretch mt-9 w-full mb-9">
                <div className="my-auto lg:text-lg font-semibold text-zinc-800">
                    List of recruiter names
                </div>
                <div className='select-multi-value-container--secondary w-full max-w-[815px]'>
                    <MultiSelect
                        selectStyle="secondary w-full"
                        placeholder='Add a recruiter '
                        value={selectedInterviewers}
                        onChange={handleSelect}
                        options={interviewers}
                    />
                </div>
            </div> */}
            {questions}
        </div>
    );
};

const SecondInterview = () => {

    const navigate = useNavigate()
    const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
    useEffect(() => {
        setActiveStep(4)
    }, [])
    const [videoInterview, setVideoInterview] = useState(null)
    const [inteviewDetails, setInteviewDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false);


    const [answersScores, setAnswersScores] = useState([])
    const [transcript, setTranscript] = useState([])
    const [answersConfig, setAnswersConfig] = useState({})
    const [questions, setQuestions] = useState([])
    const [totalScore, setTotalScore] = useState(null);


    // const questionsData = job && application
    // 	? job.applicationForm.critical_requirements_of_the_job_questions
    // 		.map((item, index) => ({ ...item, answerValue: application.applicationForm.critical_requirements_of_the_job_answers[index] }))
    // 		.filter(item => item.questionCode.includes('IP'))
    // 	: [];
    // const filter = job.applicationForm.critical_requirements_of_the_job_questions.filter(item => item.questionCode.includes('IP'))
    // const questionsData = job && application && filter?.length ? filter : questions;
    const questionsData = questions;

    const initialAnswers = questionsData.map(question => ({
        question,
        answer: ''
    }))

    const [answers, setAnswers] = useState(application?.criticalRequirements?.["2nd-interview"] || initialAnswers)
    const [selectedInterviewers, setSelectedInterviewers] = useState(application?.assessments?.["2nd-interview"]?.listRecruiter || [])
    // const statusColors = {
    // 	'pending': 'yellow-500',
    // 	'completed': 'green-500'
    // }


    const getQuestions = async () => {
        setIsLoading(true);
        try {
            const res = await api.jobVideoInterview.getInterviewQuestions('In Person Interview');

            if (!res.success) {
                toast.error(res?.message || "An error occurred while fetching the interview questions");
            }


            //need remove this filter for questions
            if (res?.data) {
                const validQuestionIds = [83, 151, 85, 86, 87, 88];

                const filteredQuestions = res.data.filter(question =>
                    validQuestionIds.includes(question.id)
                );

                const question151 = filteredQuestions.find(q => q.id === 151);
                const otherQuestions = filteredQuestions.filter(q => q.id !== 151);

                const reorderedQuestions = [
                    otherQuestions[0],
                    question151,
                    ...otherQuestions.slice(1)
                ];

                setQuestions(reorderedQuestions);

                setAnswers(
                    application?.criticalRequirements?.["2nd-interview"] ||
                    reorderedQuestions.map(question => ({
                        question,
                        answer: ''
                    }))
                );
            }
            //need remove this filter for questions

            // setQuestions(res.data)
            // setAnswers(application?.criticalRequirements?.["2nd-interview"] || res.data.map(question => ({
            //     question,
            //     answer: ''
            // })))

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getQuestions()
    }, [])


    useEffect(() => {
        setIsLoading(true)
        api.jobVideoInterview.getVideoInterview({
            jobId,
            type: '2nd-interview'
        }).then(resp => {
            if (resp.success) {
                setVideoInterview(resp.data)
                const videoId = resp.data.id
                api.jobVideoInterview.getListVideoInterview(applicationId).then(resp => {
                    if (!resp.success || !resp.data.length) {
                        setIsLoading(false)
                        return
                    }
                    const interviewItem = resp.data.find(item => item.jobVideoInterview.id === videoId && item.jobVideoInterview.type === '2nd-interview')
                    if (!interviewItem) {
                        setIsLoading(false)
                        return
                    }
                    api.jobVideoInterview.getInterviewDetails({
                        jobApplicationId: applicationId,
                        interviewId: interviewItem.id
                    }).then(response => {
                        setIsLoading(false)

                        if (!response.success) return
                        console.log(response, "response");
                        setInteviewDetails(response.data)
                    })
                })
            } else {
                setIsLoading(false)
            }
        })

    }, [jobId, applicationId])


    useEffect(() => {
        const details = application?.criticalRequirements?.["2nd-interview"]
        const qualityQuestions = inteviewDetails
            ? inteviewDetails?.qualityQuestions
            : details?.map(item => ({ qualityAttribute: item?.question?.qualityAttribute }))

        if (!qualityQuestions?.length) return

        const obj = {}
        const arr = qualityQuestions.reduce((res, curr, index) => {
            const key = res.find(r => r.key === curr?.qualityAttribute?.key)
            const score = { ...curr, score: answersScores?.[index] }
            if (key) {
                key.stack.push(score)
            } else {
                res.push({
                    key: curr?.qualityAttribute?.key,
                    stack: [score],
                    name: curr?.qualityAttribute?.name,
                })
            }
            return res
        }, []).map(item => {
            let avg = (item?.stack || []).reduce((r, it) => r + parseFloat(it.score), 0) / (item?.stack || []).length;
            return { ...item, avg };
        });
        arr?.forEach((item) => {
            obj[item?.key] = {
                score: item?.avg,
                name: item?.name,
                key: item?.key
            }
        })
        setAnswersConfig(obj)
    }, [application, answersScores, inteviewDetails])

    useEffect(() => {
        setTranscript(application?.assessments?.["2nd-interview"]?.transcript)
        setAnswersScores(
            application?.assessments?.["2nd-interview"]?.scores ||
            Array.from({ length: inteviewDetails ? inteviewDetails?.questions?.length : questionsData?.length || 0 }).fill("NONE"))
    }, [application, inteviewDetails])

    const changeAnswerHandler = (index, val, key) => {
        // const updatedAnswers = [...answers]
        // updatedAnswers[index].answer = val
        // setAnswers(updatedAnswers)

        console.log("val", val)


        setAnswers((prev) => prev.map((item, itemIndex) => index === itemIndex ? { ...item, answer: val } : item))
    }
    const changeAnswerScoreHandler = (index, val, key) => {
        const copy = [...answersScores]
        copy[index] = val
        setAnswersScores(copy)
    }

    const handlePrev = () => {
        navigate(`/SBM01_10_2/${jobId}/${applicationId}`)
    }
    const handleNext = () => {
        navigate(`/SBM01_10_16/${jobId}/${applicationId}`)
    }
    // const [loading, setLoading] = useState(false);

    // const handleSendInterview = async () => {
    // 	setLoading(true)
    // 	const response = await api.jobVideoInterview.sendVideoInterview({
    // 		applicationId,
    // 		interviewId: videoInterview.id
    // 	})
    // 	setLoading(false)
    // 	toast(response.message, {
    // 		type: response.success ? 'success' : 'error'
    // 	})
    // }


    // const sendToAIRecomendationRequestFetch = async () => {
    // 	const body = {
    // 		"type": "2nd_interview" // 'clarification', '1st_interview', '2nd_interview'
    // 	}
    // 	try {
    // 		const res = await api.jobApplications.sendToAIRecomendationRequest({ applicationId, body })
    // 		if (res) {
    // 			console.log(res);
    // 		}
    // 	} catch (error) {
    // 		console.log(error);
    // 	}
    // }

    const dispatch = useDispatch()

    const handleSave = async () => {
        if (answersScores.some(score => score > 7 || score < 0)) {
            toast.error("Scores must be from 0 to 7")
            return
        }

        const scores = new Array(application?.criticalRequirements?.["2nd-interview"]?.length)?.fill(null)?.map((one, index) => answersScores?.[index] || one)

        setIsLoading(true)
        try {
            const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
                assessments: {
                    ...application?.assessments || {},
                    "2nd-interview": {
                        ...application?.assessments?.["2nd-interview"],
                        scores: scores,
                        listRecruiter: selectedInterviewers
                    }
                },
                criticalRequirements: {
                    "2nd-interview": answers
                },

                // ...(videoInterview
                // 	? {}
                // 	: {
                // 		criticalRequirements: {
                // 			"2nd-interview": answers
                // 		},
                // 	}
                // )
            })
            if (resp.success) {
                Promise.all([
                    dispatch(getJobByIdFetch(jobId)),
                    dispatch(getApplicationByIdFetch({ applicationId, jobId }))
                ]);
                toast.success("Saved")
            } else {
                toast.error(resp.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        setIsLoading(false)
    }


    useEffect(() => {
        const clarifications = application?.criticalRequirements?.["2nd-interview"] || [];
        const total = calculateTotalScore(clarifications, answersScores);
        setTotalScore(total);
    }, [answersScores, application]);

    return (
        <div className='flex-auto'>
            <div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
                {/* {
					isLoading
						? <PageLoader />
						: videoInterview
							? (
								<div>
									<div className="flex justify-end w-full mb-6">
										<PrimaryButton
											onClick={sendToAIRecomendationRequestFetch}
											className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
										>
											AI Video agent
										</PrimaryButton>
									</div>
									<div className='flex items-center justify-between'>
										<div className="text-3xl font-bold leading-8 text-zinc-800">
											<p>2<sup>nd</sup> Video Interview</p>
										</div>
										{inteviewDetails ? <p>Status: <span className={`text-${statusColors[inteviewDetails.status]}`}>{inteviewDetails.status}</span></p> :
											<PrimaryButton onClick={handleSendInterview} className='px-6'>
												{loading ? <PageLoader color='light' className={'h-[40px]'} /> : 'Send Video Interview to Candidate'}
											</PrimaryButton>}

									</div>
									<p className='my-6 text-2xl font-bold leading-8 text-zinc-800'>{videoInterview?.name}</p>
									{videoInterview?.intro && <><p className='my-6 text-2xl font-bold leading-8 text-zinc-800'>Intro</p>
										<video width="auto" height="auto" controls className='max-h-[600px]'>
											<source src={videoInterview?.intro?.url} type="video/mp4" />
											Your browser does not support the video tag.
										</video></>}
									<p className='my-8 text-2xl font-bold leading-8 text-zinc-800'>Questions</p>
									{
										inteviewDetails
											?
											<ol className='flex flex-col gap-6'>
												{inteviewDetails?.candidateHireflix?.questions.map((item, index) => {
													const currentQuestion = inteviewDetails?.qualityQuestions?.[index]
													return <div key={index}>
														<div className='flex flex-col gap-2'>
															<h4 className='text-2xl font-bold leading-8 text-zinc-800'>{index + 1}. {item.title}</h4>
															<p className='text-xl'>{item.description}</p>
															<p className='text-xl'>{currentQuestion?.question}</p>
														</div>
														<div className='flex items-center gap-8 mt-4'>
															{item?.media?.url && <div>
																<p className='text-xl font-bold leading-8 text-zinc-800'>Question: </p>
																<video width="auto" height="auto" controls className='max-h-[300px]'>
																	<source src={item?.media?.url} type="video/mp4" />
																	Your browser does not support the video tag.
																</video>
															</div>}
															{item?.answer?.url && <div>
																<p className='text-xl font-bold leading-8 text-zinc-800'>Answer: </p>
																<video width="auto" height="auto" controls className='max-h-[300px]'>
																	<source src={item?.answer?.url} type="video/mp4" />
																	Your browser does not support the video tag.
																</video>
															</div>}
														</div>
														<Score
															onChange={(e => changeAnswerHandler(index, e.target.value, currentQuestion?.qualityAttribute?.key))}
															value={parseInt(answersScores[index]) || null}
														/>
													</div>
												})}
											</ol>
											:
											<ol className='flex flex-col gap-4'>
												{videoInterview?.questions.map((item, index) => <div>
													<p className='text-xl font-bold leading-8 text-zinc-800'>{index + 1}. {item.title}</p>
													<p>{item.description}</p>
												</div>)}
											</ol>
									}
									{videoInterview?.outro?.title &&
										<p className='my-6 text-2xl font-bold leading-8 text-zinc-800'>{videoInterview?.outro?.title}</p>}
									{videoInterview?.outro?.description &&
										<p className='mb-2 text-xl leading-8 text-zinc-800'>{videoInterview?.outro?.description}</p>}
									{videoInterview?.outro?.media?.url && <video width="auto" height="auto" controls className='max-h-[600px]'>
										<source src={videoInterview?.outro?.media?.url} type="video/mp4" />
										Your browser does not support the video tag.
									</video>}
								</div>
							)
							: (
								<div>
									<div className='flex justify-end w-full'>
										{!application?.criticalRequirements?.["2nd-interview"]
											&& (
												<p>Status: <span className={`text-yellow-500`}>Pending</span></p>
											)
										}
									</div>
									<InterviewSection
										companyId={job?.companyId}
										questions={
											<div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
												{answers.map((item, index) => {
													const Question = QuestionTypes["text"];
													if (!Question) return null
													return (
														<div className='flex flex-col'>
															<Question
																key={item.question.id}
																error={null}
																config={item.question}
																number={index + 1}
																onChange={val => setAnswers((prev) => prev.map((item, itemIndex) => index === itemIndex ? { ...item, answer: val } : item))}
																value={item.answer || null}
																className="mt-[0px]"
																labelClassName="!text-[16px] !leading-[20px]" />
															<Score
																onChange={(e => changeAnswerHandler(index, e.target.value))}
																value={parseInt(answersScores[index]) || null}
															/>
														</div>
													)
												})}
											</div>
										}
									/>
								</div>
							)

				} */}

                {
                    !isLoading ? (
                        <div>
                            <div className='flex justify-end w-full'>
                                {/* {!application?.criticalRequirements?.["2nd-interview"]
									&& (
										<p>Status: <span className={`text-yellow-500`}>Pending</span></p>
									)
								} */}
                            </div>
                            <InterviewSection
                                companyId={job?.companyId}
                                job={job}
                                selectedInterviewers={selectedInterviewers}
                                handleSelect={setSelectedInterviewers}
                                questions={
                                    <div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
                                        {answers.map((item, index) => {
                                            const Question = QuestionTypes["text"];
                                            if (!Question) return null
                                            return (
                                                <div className='flex flex-col'>
                                                    <Question
                                                        key={item.question.id}
                                                        error={null}
                                                        config={item.question}
                                                        number={index + 1}
                                                        onChange={val => changeAnswerHandler(index, val)}
                                                        value={item.answer || null}
                                                        className="mt-[0px]"
                                                        labelClassName="!text-[16px] !leading-[20px]" />
                                                    <Score
                                                        onChange={(e => changeAnswerScoreHandler(index, e.target.value))}
                                                        value={parseInt(answersScores[index]) || null}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            />
                        </div>
                    ) : <PageLoader />
                }

                <EvaluationInterview transcript={transcript} config={answersConfig} totalOnly
                    propsTotal={totalScore?.total?.percentage} />
                <div className="flex justify-end w-full my-6">
                    <PrimaryButton
                        onClick={handleSave}
                        className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
                    >
                        Save
                    </PrimaryButton>
                </div>
            </div>
            <NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
        </div>
    )
}

export default SecondInterview

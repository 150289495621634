import arrowListImage from "../../../assets/img/arrowList.svg";
import prisingImage from "../../../assets/img/pricingN.png";
import { AboutBlock } from "./AboutBlock";
import InfoSection from "./InfoSection";
import React from "react";
import getImageUrl from "../../../assets/helpers/getImageUrl";

export const RecruitSmarter = ({
    spacing = "py-[100px] max-md:py-[80px]",
    withBlock = false,
    title,
    header,
    description,
    button,
    features,
    image
}) => {

    return (
        <InfoSection
            background={"bg-white"}
            topSpacing={spacing}
            withBlock={withBlock}
        >
            <AboutBlock
                title={title}
                header={header}
                description={description}
                headerStyle={"max-w-[750px]"}
                button={button}
            >
                <div className="flex flex-col gap-4 lg:gap-6 text-[#646262] text-sm lg:text-lg my-4">
                    {features.map((item, index) => (
                        <p key={index} className="flex items-center gap-2 lg:gap-3">
                            <div className="min-w-[30px] flex justify-center items-center">
                                <img src={getImageUrl(item.img)}
                                     className="h-[30px] w-[30px]" alt="arrow"/>
                            </div>
                            <span>{item.text}</span>
                        </p>
                    ))}
                </div>
            </AboutBlock>
            <img src={ getImageUrl(image)} alt="pricing"
                className="w-full lg:w-[600px] max-md:w-[80%] m-auto h-auto wow fadeInRight"
                data-wow-delay={"100ms"}
            />
        </InfoSection>
    );
};

import {useEffect, useState} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';
import InputField from '../../../components/base/InputField';
import {NextStepSectionSBM01} from '../base/NextStepSectionSBM01';
import api from "../../../api";
import {getApplicationByIdFetch, getJobByIdFetch} from "../../../redux/thunks/jobsThunks";
import {toast} from "react-toastify";
import PrimaryButton from "../../../components/base/PrimaryButton";
import {useDispatch} from "react-redux";


const PerformanceReview = ({data}) => {
    return (
        <main className="flex flex-col items-start font-semibold">
            <div className="pb-[60px] border-b border-neutral-200">
                <span className="block text-[22px] font-bold mb-4">Top 3 Strengths:</span>
                {data?.top_variables && data?.top_variables.sort((a, b) => Number(b.value) - Number(a.value)).slice(0, 3).map(({
                                                                                                                                   name,
                                                                                                                                   value
                                                                                                                               }, idx) => (

                    <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mb-4">
                        <div
                            className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center min-w-[350px]">
                            <div className="green-box mr-4"></div>
                            {name
                                ?.replace(/_/g, ' ')
                                ?.replace(/\b\w/g, char => char.toUpperCase())}:
                        </div>

                        <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                            <InputField
                                labelClassName={'font-semibold text-[#6F6F6F]'}
                                inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                parentClassName={'!mb-0'}
                                value={value}
                                disabled={true}
                            />
                        </div>

                    </div>
                ))}
            </div>
            <div className="pb-[60px] border-b border-neutral-200 mt-10">
                <span className="block text-[22px] font-bold mb-4">Top 3 Weaknesses:</span>

                {data?.bottom_variables &&
                    data?.bottom_variables
                        .sort((a, b) => Number(b.value) - Number(a.value))
                        .slice(0, 3)
                        .map(({name, value}) => (

                            <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mb-4">
                                <div
                                    className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center min-w-[350px]">
                                    <div className="green-box mr-4"></div>
                                    {name
                                        ?.replace(/_/g, ' ')
                                        ?.replace(/\b\w/g, char => char.toUpperCase())}:
                                </div>

                                <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                                    <InputField
                                        labelClassName={'font-semibold text-[#6F6F6F]'}
                                        inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                        parentClassName={'!mb-0'}
                                        value={value}
                                        disabled={true}
                                    />
                                </div>

                            </div>
                        ))}
            </div>
        </main>
    );
};



const TrainingInsights = () => {
    const navigate = useNavigate();
    const { job, application, jobId, applicationId, setActiveStep } = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();

    const [trainingRecommendations, setTrainingRecommendations] = useState([
        { title: 'Recommendation 1', value: '' },
        { title: 'Recommendation 2', value: '' },
        { title: 'Recommendation 3', value: '' }
    ]);

    const dispatch = useDispatch();

    useEffect(() => {
        setActiveStep(8);
        setIsLoading(true);
        api.hiringAlgorithm.getResult(applicationId).then(response => {
            if (response.success) {
                setData(response.data);
            }
        }).finally(() => setIsLoading(false));
    }, [applicationId, setActiveStep]);

    useEffect(() => {
        setActiveStep(10);
    }, [setActiveStep]);

    useEffect(() => {
        if (application.assessments?.["training-insights"]) {
            const recommendations = application.assessments["training-insights"].recommendations || {};
            const updatedRecommendations = [
                { title: 'Recommendation 1', value: recommendations.recommendation_1 || '' },
                { title: 'Recommendation 2', value: recommendations.recommendation_2 || '' },
                { title: 'Recommendation 3', value: recommendations.recommendation_3 || '' },
            ];
            setTrainingRecommendations(updatedRecommendations);
        }
    }, [application.assessments]);

    const handlePrev = () => {
        navigate(`/SBM01_10_7/${jobId}/${applicationId}`);
    };

    const handleNext = () => {
        navigate(`/SBM01_10_8/${jobId}/${applicationId}`);
    };

    const handleRecommendationChange = (index, value) => {
        const updatedRecommendations = [...trainingRecommendations];
        updatedRecommendations[index].value = value;
        setTrainingRecommendations(updatedRecommendations);
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            const recommendations = trainingRecommendations.reduce((acc, rec, index) => {
                acc[`recommendation_${index + 1}`] = rec.value;
                return acc;
            }, {});

            const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
                assessments: {
                    ...application?.assessments || {},
                    "training-insights": {
                        ...application?.assessments?.["training-insights"],
                        recommendations
                    }
                },
                criticalRequirements: {
                    "training-insights": {
                        ...application?.criticalRequirements?.["training-insights"],
                        recommendations
                    }
                },
            });

            if (resp.success) {
                await Promise.all([
                    dispatch(getJobByIdFetch(jobId)),
                    dispatch(getApplicationByIdFetch({ applicationId, jobId }))
                ]);
                toast.success("Recommendations saved successfully.");
            } else {
                toast.error(resp.message || "Something went wrong");
            }
        } catch (err) {
            console.error(err);
            toast.error("Error saving data");
        }
        setIsLoading(false);
    };

    return (
        <div className='flex-auto'>
            <div className="border-b border-neutral-200 pb-9">
                <span className="text-3xl font-bold">Training Insights</span>
            </div>
            <div className='flex flex-col gap-11 max-w-[1440px] px-5 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
                <PerformanceReview data={data} />
                <div>
                    <section
                        className="self-stretch w-full text-[22px] leading-7 font-bold text-zinc-800 max-md:mb-[14px]">
                        Training Recommendations:
                    </section>
                    {trainingRecommendations.map((recommendation, index) => (
                        <section key={index} className="self-stretch mt-[10px] lg:mt-7 w-full max-w-[770px]">
                            <InputField
                                onChange={(e) => handleRecommendationChange(index, e.target.value)}
                                labelClassName={"text-lg font-semibold text-zinc-800"}
                                label={recommendation.title}
                                inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300"
                                type="text"
                                name={`recommendation-${index}`}
                                id={`recommendation-${index}`}
                                value={recommendation.value}
                            />
                        </section>
                    ))}
                </div>
                <div className="flex justify-end w-full my-6">
                    <PrimaryButton
                        onClick={handleSave}
                        className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
                    >
                        {isLoading ? "Saving..." : "Save"}
                    </PrimaryButton>
                </div>
            </div>
            <NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
        </div>
    );
};

export default TrainingInsights;

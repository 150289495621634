import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import { getAllDocumentsOfApplicationFetch } from "../redux/thunks/jobApplicationDocumentsThunks";

const ReviewDocuments = () => {
    const { token, applicationId } = useParams();
    const dispatch = useDispatch()
    const { documents, isLoading } = useSelector(state => state.jobApplicationDocuments)

    useEffect(() => {
        if (!token || !applicationId) return
        dispatch(getAllDocumentsOfApplicationFetch({ applicationId, token }))
    }, [token, applicationId])

    return (
        <>
            {
                !isLoading && documents?.length
                    ?
                    <div className="mt-[24px] mb-[70px] flex gap-x-[60px] gap-y-20 max-lg:flex-col w-full">
                        <div className="flex flex-col grow self-stretch w-full">
                            <h1 className="text-center text-3xl font-bold leading-10 text-zinc-800 max-md:max-w-full">Documents</h1>
                            {
                                documents.map((document, index) => (
                                    <div className='flex flex-col gap-1 mt-11' key={index}>
                                        <div className='flex justify-between gap-6 flex-wrap'>
                                            <div>
                                                <h4 key={index} className="flex items-start font-semibold text-xl flex-wrap">{document?.title}</h4>
                                                <p>{document?.description}</p>
                                            </div>
                                        </div>
                                        {
                                            document?.url ?
                                                <iframe
                                                    src={process.env.REACT_APP_API_URL + document?.url}
                                                    width="100%"
                                                    height="1200px"
                                                    className="pdf-viewer mt-[36px] w-full h-[calc(100vh-100px)]"
                                                    title="Document Viewer"
                                                ></iframe>
                                                : null
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    : isLoading ?
                        <PageLoader /> :
                        null

            }
        </>
    );
}

export default ReviewDocuments

import { startsWith } from 'lodash'
import React, { forwardRef, useRef } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

export const InputPhoneCodeMask = forwardRef(({ label, wrapperClasses = '', inputClasses = '', type = 'text', placeholder, value = '', error, onChange, inputProps = {}, ...props }, ref) => {
	return (
		<label className={`input-phone-wrapper flex flex-col gap-[10px] ${wrapperClasses} `}>
			{label && <div className="text-[22px] font-bold leading-[24px] max-md:text-[16px] max-md:leading-[18px]">{label}</div>}
			<PhoneInput
				country={'us'}
				type={type}
				enableSearch={true}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				{...props}
				// inputProps={{
				// 	ref: ref,
				// 	...inputProps
				// }}
			/>
			{error && error.length && <p className="text-red-500 text-sm mt-1">{error}</p>}
		</label>
	)
})

import React from 'react';

export const TotalBlock = ({ data }) => {
    return (
        <div className="bg-[#EBF0F3] py-10 w-full mx-auto font-['Raleway']">
            <div className="container grid grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-wrap lg:justify-center lg:items-center">
                {data.stats.map((stat, index) => (
                    <div key={index}
                         className={`text-center h-[126px] flex flex-col justify-center 
                         ${index !== 0 ? "border-b-2 sm:border-b-0 lg:border-l-[5px] border-gray-300" : ""} 
                         px-6 md:px-10 lg:px-[55px]`}>
                        <p className="text-lg lg:text-xl font-semibold">{stat.title}</p>
                        <p className="text-5xl md:text-6xl lg:text-7xl font-bold">{stat.count}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

import classNames from 'classnames'
import React, { forwardRef } from 'react'

const InputGroup = forwardRef(({
    type = "text", 
    label,
    icon,  
    value, 
    onChange, 
    disabled = false, 
    placeholder, 
    inputBg = 'bg-[#EBF0F3]',
    error , 
    inputClassName,
    ...props }, ref) => {
  return (
    <label className='flex flex-col gap-[10px]'>
        { label && <div className='text-[22px] font-bold leading-[24px] max-md:text-[16px] max-md:leading-[18px]'>{label}</div>}
        <div className="relative">
            {icon && <span className='absolute top-0 h-full w-[68px] flex items-center justify-center'>
                {icon}
            </span>}
            <input 
                type="text" 
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={classNames(`w-full py-[25px] text-[20px] leading-[22px] focus:outline-none focus:ring-2 focus:ring-green-500 rounded-[10px] px-[35px] max-md:text-[16px] max-md:py-[15px]`, inputClassName, inputBg, {
                    'pl-[68px]': icon
                })}
                disabled={disabled}
                ref={ref}
                {...props}
            />
        </div>
        {error && error.length && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </label>
  )
})

export default InputGroup

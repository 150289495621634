
import React, {useEffect, useState} from "react";
import { ReactComponent as CalendarIcon } from "../../../../assets/img/calendar-ico.svg";
import {FourStepsWrapper} from "../../parts/FourStepsWrapper";
import InputField from "../../../../components/base/InputField";
import SelectField from "../../../../components/base/SelectField";
import TextareaField from "../../../../components/base/TextareaField";
import FileInputField from "../../../../components/base/FileInputField";
import {NextStepSectionSBM01} from "../../base/NextStepSectionSBM01";
import {crumbsConfig} from "../../config";


export const ApplicationStep = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.history,
        ["Application", '']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            {step === 1 && <FourStepsWrapper
                crumbs={crumbs}
                onNext={nextComponent}
                onBack={prevComponent}
                hideTabs
                activePin={1}
            >
                <StepOne/>
            </FourStepsWrapper>}
        </>
    )
}

const StepOne = () => {

    return (
        <>
            <DescriptionSection />
            <ConsentFormSection />
            <JobApplicationSection />
            <RequirementsSection />
            <AvailabilityFormSection />
            <ResumeUploadSection />
        </>
    )
}

const CheckboxInput = ({ label, checked = false, onChange }) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = () => {
        const newValue = !isChecked
        setIsChecked(newValue)
        onChange && onChange(newValue)
    }
    return (
        <label className="flex gap-3.5">
            <div className="flex justify-center items-center px-0.5 my-auto border border-solid bg-stone-50 border-stone-300 h-[18px] w-[18px]">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleChange}
                    className="hidden"
                />
                {
                    isChecked ?
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1499 2.5461L11.1844 1.88985C10.9173 1.70896 10.5513 1.77838 10.3725 2.0434L5.63995 9.02237L3.46507 6.84749C3.23791 6.62032 2.86771 6.62032 2.64055 6.84749L1.81393 7.67411C1.58676 7.90127 1.58676 8.27146 1.81393 8.50073L5.15828 11.8451C5.34548 12.0323 5.63995 12.1753 5.90497 12.1753C6.17 12.1753 6.43712 12.0091 6.6096 11.7588L12.3055 3.3559C12.4864 3.09088 12.417 2.72699 12.1499 2.5461Z" fill="#37B34A" />
                        </svg>
                        :
                        null
                }
            </div>
            <div className="flex-auto text-lg leading-8 text-neutral-500">
                {label}
            </div>
        </label>
    )
};

const RadioButton = ({ label, id, name, onChange, checked, value, parentClass }) => {
    return (
        <label
            className={`flex gap-2.5 pr-5 cursor-pointer items-center ${parentClass ? parentClass : ""}`}
        >
            <div
                className="flex flex-col justify-center items-center px-1 border border-solid bg-stone-50 border-stone-300 h-[18px] rounded-[360px] w-[18px] cursor-pointer"
            >
                {checked && (
                    <div className="shrink-0 w-2.5 h-2.5 bg-green-500 rounded-[360px]" />
                )}
                <input
                    className="hidden"
                    type="radio"
                    name={name}
                    id={id}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                />
            </div>
            <div className="my-auto text-lg font-semibold text-zinc-800">{label}</div>
        </label>
    )
};

const TextWithLeading = ({ children, className }) => (
    <div className={`text-base font-normal leading-8 text-neutral-500 ${className}`}>
        {children}
    </div>
);

const SectionTitle = ({ children, className }) => (
    <div className={`text-2xl whitespace-nowrap ${className}`}>
        {children}
    </div>
);

const DescriptionSection = () => {
    const requirements = [
        "Resume",
        "Reference",
        "Contact Details",
    ];

    return (
        <main className="flex flex-col items-start font-bold text-zinc-800">
            <header className='max-lg:text-center'>
                <h1 className="text-4xl">Marc Spector</h1>
                <h2 className="lg:mt-8 mt-5 text-3xl">It takes X minutes</h2>
                <TextWithLeading className="mt-3">
                    Tell Applicants what to expect on the job Application Form. You may use up to 10 lines of copy.
                </TextWithLeading>
            </header>
            <hr className="self-stretch mt-11 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:mt-10 max-md:max-w-full" />
            <section>
                <SectionTitle className="mt-11 max-md:mt-10">What you need:</SectionTitle>
                <TextWithLeading className="mt-3">
                    {requirements.map((requirement, index) => (
                        <React.Fragment key={index}>
                            <span>
                                {index + 1}.
                                {" "}
                            </span>
                            {requirement}
                            <br />
                        </React.Fragment>
                    ))}
                </TextWithLeading>
            </section>
            <section>
                <SectionTitle className="mt-11 max-md:mt-10 max-md:max-w-full">
                    How long it takes?
                </SectionTitle>
                <TextWithLeading className="mt-3 text-lg max-md:max-w-full">
                    It takes 15 minutes to complete this application. Very important to <br /> copy and paste your resume. This might be the most important <br /> 15 minutes for your future!
                </TextWithLeading>
            </section>
        </main>
    );
};


const ConsentFormSection = () => {
    return (
        <div className="flex flex-col items-start">
            <h1 className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
                Consent
            </h1>
            <div className="self-stretch mt-10 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:mt-10 max-md:max-w-full" />
            <h2 className="mt-11 text-2xl font-bold whitespace-nowrap text-zinc-800 max-md:mt-10">
                What you need:
            </h2>
            <div className="flex gap-5 justify-between mt-6">
                <RadioButton onChange={() => { }} checked label="Yes" />
                <RadioButton onChange={() => { }} label="No" />
            </div>
            <h2 className="self-stretch mt-11 w-full text-[22px] font-bold text-zinc-800 max-md:mt-10 max-md:max-w-full">
                Please review the following Terms, Disclosures and Consent
            </h2>
            <p className="self-stretch mt-6 w-full text-base leading-8 text-neutral-500 max-md:max-w-full">
                By checking this box and clicking the Save & Continue' button below, I confirm that i read, agree, and
                consent to the information described in the link(s) below:
            </p>
            <div className="flex gap-3 mt-6 text-lg font-semibold leading-8">
                <div className="text-zinc-800 max-lg:w-2/5 flex-shrink-0">EmployJoy.ai</div>
                <a href="#" className="flex-auto text-green-500 underline">
                    E-Signature Disclosures & Consent
                </a>
            </div>
            <div className="flex gap-3 mt-4 text-lg font-semibold leading-8">
                <div className="text-zinc-800 max-lg:w-2/5 flex-shrink-0">[Company]</div>
                <a href="#" className="flex-auto text-green-500 underline">
                    E-Signature Disclosures & Consent
                </a>
            </div>
            <div className="flex gap-3 mt-4 text-lg font-semibold leading-8">
                <div className="text-zinc-800 max-lg:w-2/5 flex-shrink-0">EmployJoy.ai's</div>
                <a href="#" className="flex-auto text-green-500 underline">
                    General Terms of Use Job Seeker Terms
                </a>
            </div>
            <div className="flex gap-3.5 mt-6">
                <CheckboxInput label={"Yes, I have read and agree with the above terms."} />
            </div>
            <div className="flex gap-3 mt-6 text-lg font-semibold whitespace-nowrap text-zinc-800">
                <p htmlFor="eSignature" className="grow my-auto">
                    E Signature
                </p>
                <label className='shrink-0 h-9 border-b border-solid border-stone-300 w-[230px]'>
                    <input
                        id="eSignature"
                        type="file"
                        className="hidden"
                    />
                </label>
            </div>
            <div className="flex gap-3 items-center mt-6 max-w-full text-lg whitespace-nowrap text-zinc-800 w-[350px]">
                <label htmlFor="date" className="grow self-stretch my-auto font-semibold">
                    Date
                </label>
                <input
                    id="date"
                    type="date"
                    className="grow justify-center items-center self-stretch px-16 py-2 border-b border-solid border-stone-300 max-md:px-5"
                />
                {/* <span>
                    <CalendarIcon />
                </span> */}

            </div>
        </div>
    );
};


const JobApplicationSection = () => {
    const [formData, setFormData] = React.useState({
        firstName: "Marc",
        lastName: "Lord",
        spector: "Spector",
        email: "marc@google.com",
        address: "1542 E New York St, Aurora, IL",
        secondAddress: "-",
        state: "Illinois",
        city: "New Yourk",
        zip: "6541",
        phone: "123-443-2234",
        jobsCount: 5,
        receiveTexts: true,
        terminated: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };
    return (
        <div className="flex flex-col items-start">
            <div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
                You! Yes, You!
            </div>
            <div className="self-stretch mt-6 lg:mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px]" />
            <div className="w-full items-stretch max-w-[560px]">
                <div className="flex flex-col w-full mt-9 lg:mt-11">
                    <InputField
                        onChange={handleChange}
                        labelClassName="hidden"
                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={formData.firstName}
                    />
                    <InputField
                        onChange={handleChange}
                        labelClassName="hidden"
                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={formData.lastName}

                    />
                    <InputField
                        onChange={handleChange}
                        labelClassName="hidden"
                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                        type="text"
                        name="spector"
                        id="spector"
                        value={formData.spector}
                    />
                    <InputField
                        onChange={handleChange}
                        labelClassName="hidden"
                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                        type="text"
                        name="address"
                        id="address"
                        value={formData.address}
                    />
                    <InputField
                        onChange={handleChange}
                        labelClassName="hidden"
                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                        type="text"
                        name="secondAddress"
                        id="secondAddress"
                        value={formData.secondAddress}
                    />
                    <div className="flex max-lg:flex-col lg:gap-4 w-full text-lg text-neutral-500">
                        <InputField
                            onChange={handleChange}
                            labelClassName="hidden"
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            type="text"
                            name="state"
                            id="state"
                            value={formData.state}
                        />
                        <SelectField
                            inputBg='bg-white rounded-md mb-[18px] max-lg:mb-[14px] border border-solid border-zinc-300'
                            options={[{ value: "new_york", label: "New York" }]}
                            name="city"
                            id="city"
                            onChange={handleChange}
                            labelClassName="hidden"
                            parentClass='items-center'
                        />
                        <InputField
                            onChange={handleChange}
                            labelClassName="hidden"
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            type="text"
                            name="zip"
                            id="zip"
                            value={formData.zip}
                        />
                    </div>
                    <InputField
                        onChange={handleChange}
                        labelClassName="hidden"
                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                    />
                    <InputField
                        onChange={handleChange}
                        labelClassName="hidden"
                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                        type="tel"
                        name="phone"
                        id="phone"
                        value={formData.phone}
                    />
                </div>
                <div className="flex gap-3.5 lg:mt-6">
                    <CheckboxInput
                        checked={formData.receiveTexts}
                        onChange={(value) => setFormData((prevState) => ({ ...prevState, receiveTexts: value }))}
                        label={"I would like to receive texts for my application."} />
                </div>
                <div className="mt-11 max-lg:mt-9 mb-4 text-[22px] leading-6 font-bold text-zinc-800">
                    How Many jobs have you help wihin the last two years?
                </div>
                <SelectField
                    onChange={handleChange}
                    value={formData.jobsCount}
                    name={"jobsCount"}
                    parentClass=''
                    inputBg='bg-white rounded-md border border-solid border-zinc-300 w-full'
                    options={[{ value: "5", label: "5" }]}
                />
                <div className="mt-11 max-lg:mt-9  text-[22px] leading-6 font-bold text-zinc-800">
                    Have you ever been terminated from a job?
                </div>
                <div className='flex gap-5 lg:mt-6 mt-4'>
                    <RadioButton
                        onChange={() => setFormData((prevState) => ({ ...prevState, terminated: "Yes" }))}
                        checked={formData.terminated}
                        label="Yes"
                    />
                    <RadioButton
                        onChange={() => setFormData((prevState) => ({ ...prevState, terminated: "" }))}
                        checked={!formData.terminated}
                        label="No"
                    />
                </div>
                <TextareaField
                    className={"max-lg:!h-[136px] -mb-4"}
                    labelClassName={"text-base text-neutral-500 mt-6"}
                    label={"If so, please explain"}
                    onChange={handleChange}
                    inputBg="bg-white rounded-md border border-solid border-zinc-300 w-full"
                    type="text"
                    name="terminated"
                    id="terminated"
                    value={formData.terminated}
                />
            </div>
        </div>
    );
};

const FitScaleEvaluationTable = ({ value = 1, onChange, maxOptions = 6 }) => {
    const createRatingScale = (selectedIdx) => Array.from({ length: maxOptions }).map((_, idx) => {
        return idx + 1 === selectedIdx;
    });

    const [selectedRatings, setSelectedRatings] = useState(createRatingScale(value));


    const handleRatingClick = (index) => {
        setSelectedRatings(createRatingScale(index));
        onChange && onChange(index)
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex gap-5 max-md:gap-[8px] justify-between mt-4 text-base font-semibold text-center whitespace-nowrap">
                {selectedRatings.map((isSelected, idx) => (
                    <div
                        key={idx}
                        className={`cursor-pointer`}
                        onClick={() => handleRatingClick(idx + 1)}
                    >
                        <div
                            key={idx}
                            className={`flex justify-center items-center px-4  border border-solid ${isSelected ? "border-white border-[3px] bg-green-500 box-shadow text-white" : "bg-white border-zinc-300"} rounded-[460px]  h-9 w-9 md:h-[42px] md:w-[42px]`}>{idx + 1}</div>
                    </div>
                ))}
            </div>
            <div className="flex gap-5 justify-between mt-5 w-full text-base">
                <div className="flex-auto">Totally Disagree</div>
                <div className="flex-auto text-right">Totally Agree</div>
            </div>
        </div>
    );
}

const RequirementsSection = () => {
    // const [data, setData] = useState([]);
    const requirements = [
        {
            question: "1. Can you drive with a valid driver's license?",
            options: [
                { value: "no", label: "No" },
                { value: "yes", label: "Yes" },
            ],
            required: true,
        },
        {
            question: "2. Do you have a reliable and insured vehicle?",
            fitRating: 0,
            limitOprions: 4,
            required: true,
        },
        {
            question: "3. Are you legally permitted to work in the U.S.?",
            fitRating: 0,
            limitOprions: 7,
            required: true,
        },
        {
            question: "4. Can you pass drug test?",
            options: [
                { value: "no", label: "No" },
                { value: "yes", label: "Yes" },
            ],
            required: true,
        },
        {
            question: "5. Are you within 50 min drive from Fresh Tech Maid's office in Chicago or in Arlington Heights?",
            fitRating: 0,
            limitOprions: 4,
            required: true,
        },
        {
            question: "6. Can you work in a dusty environment with a mask on?",
            fitRating: 0,
            limitOprions: 7,
            required: true,
        },
        {
            question: "7. Can you be safely exposed to pet dander and household cleaning chemicals during cleaning and vacuuming with a mask on?",
            options: [
                { value: "no", label: "No" },
                { value: "yes", label: "Yes" },
            ],
            required: true,
        },
        {
            question: "8. Can you safely wear backpack vacuum weighted up to 17 lbs?",
            fitRating: 0,
            limitOprions: 4,
            required: true,
        },
        {
            question: "9. Can you safely condust extensive wrist movements during wiping, scraping, vacuuming, mopping, and other cleaning activities?",
            fitRating: 0,
            limitOprions: 7,
            required: true,
        },
        {
            question: "10. Can you safety stand, bend, kneel or climb stairs during cleaning and vacuuming?",
            options: [
                { value: "no", label: "No" },
                { value: "yes", label: "Yes" },
            ],
            required: true,
        },
        {
            question: "11. Are you comfortable to be exposed to friendly dogs, cats, and other pets in a home environment?",
            fitRating: 0,
            limitOprions: 4,
            required: true,
        },
        {
            question: "12. Are you able to comply with spending 6-9 hours on you feet time at a time?",
            fitRating: 0,
            limitOprions: 6,
            required: true,
        },
    ];

    return (
        <main className="flex flex-col items-start">
            <h1 className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800">Critical Requirements of the Job</h1>
            <div className="self-stretch mt-6 lg:mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            {/* <div className='flex flex-col gap-9 mt-9 lg:mt-11'>
                {requirements.map((requirement, index) => (
                    <RequirementItem key={index} onChange={handleChange} {...requirement} />
                ))}
            </div> */}
            <div className='flex flex-col gap-9 py-8 max-w-[560px] w-full'>
                {requirements.map((requirement, index) => {
                    if (requirement.options) {
                        return <div key={index} className='flex gap-[24px] w-full flex-col max-lg:gap-[16px]'>
                            <div className="flex-auto my-auto text-lg font-semibold leading-7 text-zinc-800">
                                <span>{requirement.question}</span>
                                <span className="text-green-500">*</span>
                            </div>
                            <div className="flex items-center justify-between gap-[16px]">
                                {requirement.options.map((option, index) => (
                                    <label key={option.label} className="cursor-pointer custopm-radio flex items-center font-medium">
                                        <input
                                            type="radio"
                                            className="radio-green-sub aspect-square w-5 mr-[10px]" name={requirement.question} hidden />
                                        {index === 0 && <span className="check-box-fake mr-[12px]"></span>}
                                        <div className="my-auto text-lg font-semibold text-zinc-800">{option.label}</div>
                                        {index > 0 && <span className="check-box-fake ml-[12px]"></span>}
                                    </label>
                                ))}
                            </div>
                        </div>
                    } else {
                        return <div key={index} className='flex gap-[24px] w-full flex-col max-lg:gap-[16px]'>
                            <div className="flex-auto my-auto text-lg font-semibold leading-7 text-zinc-800">
                                <span>{requirement.question}</span>
                                <span className="text-green-500">*</span>
                            </div>
                            <FitScaleEvaluationTable value={requirement.fitRating} maxOptions={requirement.limitOprions} onChange={() => { }} />
                        </div>
                    }
                })}
            </div>
        </main>
    );
};

const ResumeUploadSection = () => {
    const [formData, setFormData] = React.useState({
        text: "",
        link: "",
        file: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };
    return (
        <section className="flex flex-col items-start text-lg text-zinc-800">
            <h2 className="self-stretch w-full text-3xl font-bold leading-8">
                You must copy and paste your resume here
            </h2>
            <hr className="self-stretch mt-6 lg:mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className='flex flex-col items-stretch w-full max-w-[720px] mt-9 lg:mt-11'>
                <TextareaField
                    labelClassName={"hidden"}
                    onChange={handleChange}
                    inputBg="bg-white rounded-md border border-solid border-zinc-300 w-full"
                    type="text"
                    name="text"
                    id="text"
                    value={formData.text}
                />
                <div className="lg:mt-2 mb-4 font-semibold leading-[156%] max-md:max-w-full">
                    Alternatively, you can also attached the PDF version of your resume here:
                </div>
                <FileInputField
                />
                <div className="lg:mt-2 font-semibold leading-[156%] max-md:max-w-full">
                    LinkedIn Profile URL:
                </div>
                <InputField
                    onChange={handleChange}
                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                    type="text"
                    name="link"
                    id="link"
                    value={formData.link}
                />
            </div>
        </section>
    );
};


const AvailabilityInput = ({ label, placeholder }) => (
    <div className="flex flex-col flex-1 px-4 h-16 bg-white rounded-md border border-solid border-zinc-300">
        <div className="justify-center px-2 text-sm lg:text-base font-light whitespace-nowrap bg-white -translate-y-1/2">
            {label}
        </div>
        <div className="text-lg">{placeholder}</div>
    </div>
);

const DayAvailability = ({ day }) => (
    <div className="flex lg:gap-5 gap-4 justify-between max-lg:flex-col">
        <div className="grow my-auto text-xl font-bold leading-8 uppercase">
            {day}
        </div>
        <div className='flex gap-4'>
            <AvailabilityInput
                label="Available Beginning at"
                placeholder="8:30 AM"
            />
            <AvailabilityInput
                label="Available Ending at"
                placeholder="8:30 AM"
            />
        </div>
    </div>
);


const AvailabilityFormSection = () => {
    const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    const [date, setDate] = useState({
        month: "",
        day: "",
        year: "",
    })

    const handleChangeDate = (field, value) => {
        setDate(prev => ({ ...prev, [field]: value }))
    }

    return (
        <div className="flex flex-col items-start text-zinc-800">
            <h1 className="self-stretch w-full text-3xl font-bold leading-8 max-md:max-w-full">
                Your Availability for Work
            </h1>
            <div className="self-stretch mt-6 lg:mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className='flex flex-col mt-9 lg:mt-11 lg:gap-8 gap-6 max-w-[580px] w-full items-stretch'>
                {days.map((day) => (
                    <DayAvailability key={day} day={day} />
                ))}
                <div>
                    <h2 className="self-stretch w-full text-[22px] font-bold leading-7 max-md:max-w-full">
                        What is your earliest date to start?
                    </h2>
                    <div className="flex items-center gap-3.5 mt-6 text-lg whitespace-nowrap text-neutral-400">
                        <InputField
                            label="Month"
                            inputBg='shrink-0 mt-2 bg-white rounded-md border border-solid border-zinc-300 h-[54px]'
                            labelClassName="font-normal -mb-5"
                            onChange={(e) => handleChangeDate("month", e.target.value)}
                            value={date.month}
                        />
                        <div className="shrink-0 mt-4 w-2 h-px bg-zinc-300" />
                        <InputField
                            label="Day"
                            inputBg='shrink-0 mt-2 bg-white rounded-md border border-solid border-zinc-300 h-[54px]'
                            labelClassName="font-normal -mb-5"
                            onChange={(e) => handleChangeDate("day", e.target.value)}
                            value={date.day}
                        />
                        <div className="shrink-0 mt-4 w-2 h-px bg-zinc-300" />
                        <InputField
                            label="Year"
                            inputBg='shrink-0 mt-2 bg-white rounded-md border border-solid border-zinc-300 h-[54px]'
                            labelClassName="font-normal -mb-5"
                            onChange={(e) => handleChangeDate("year", e.target.value)}
                            value={date.year}
                        />
                        <div className='flex mb-[18px] mt-9'>
                            <CalendarIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../api";
import PrimaryButton from "../components/base/PrimaryButton";
import PageLoader from "../components/PageLoader";
import { QuestionTypes } from "./AP_01_Form/parts/questionTypes";

const PostInterview = () => {
    const { token, applicationId } = useParams();
    const [isErrorShown, setIsErrorShown] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [questionsData, setQuestionsData] = useState([])

    const [formData, setFormData] = useState({})

    const initFormData = (data) => {
        return data.map(el => ({
            id: el.id,
            value: ""
        }))
    }

    const getQuestions = async () => {
        setIsLoading(true)
        const res = await api.jobApplications.getQuestionsForJobApplications({ applicationId, token })
        if (res?.success && res?.data) {
            setQuestionsData(res.data)
        } else {
            toast.error(res?.message || "Something went wrong")
        }
        setIsLoading(false)
    }
    useEffect(() => {
        const initialFormData = initFormData(questionsData);
        setFormData(initialFormData)
    }, [questionsData])



    useEffect(() => {
        getQuestions()
    }, [])

    const hasError = () => {
        return formData.some(el => !el.value)
    }
    const handleSubmit = async () => {
        setIsErrorShown(true)
        if (hasError()) {
            toast.error("All fields must be filled in!")
            return;
        }
        const res = await api.jobApplications.sendAnswersToPostInterviewQuestions({ applicationId, token, body: { answers: formData } })
        if (res?.success) {
            toast.success(res?.message || "Answers send succesfully!")
        } else {
            toast.error(res?.message || "Something went wrong")
        }

    }

    const handleChange = (id, value) => {
        const copy = [...formData]
        const index = copy.findIndex(el => el.id === id)
        copy[index].value = value
        setFormData(copy)
    }

    return (
        <>
            {
                !isLoading && questionsData?.length
                    ?
                    <div className="mt-[24px] mb-[70px] flex gap-x-[60px] gap-y-20 max-lg:flex-col w-full">
                        <div className="flex flex-col grow self-stretch w-full">
                            <h1 className="text-center text-3xl font-bold leading-10 text-zinc-800 max-md:max-w-full">Post Interview Survey</h1>
                            <div className="flex flex-col gap-4 mt-[32px]">
                                {questionsData.map((item, index) => {
                                    const Question = QuestionTypes[item.answerType];
                                    if (!Question) return null
                                    return <Question
                                        key={item.id}
                                        config={item}
                                        number={index + 1}
                                        error={isErrorShown && !formData[index]?.value}
                                        value={formData[item.id]?.value || null}
                                        onChange={(value) => handleChange(item.id, value)}
                                        className="mt-[0px]"
                                        labelClassName="!text-[16px] !leading-[20px]"
                                    />
                                })}
                            </div>
                            <PrimaryButton className={"mt-6 max-w-80 w-full px-6 ml-auto"} onClick={handleSubmit}>Submit</PrimaryButton>
                        </div>
                    </div>
                    : isLoading ?
                        <PageLoader /> :
                        null

            }
        </>
    );
}

export default PostInterview
import { NavLink } from "react-router-dom";
import { AboutBlock } from "./AboutBlock";
import React from "react";
import getImageUrl from "../../../assets/helpers/getImageUrl";
import blocksImage from "../../../assets/img/home/blocks.png";

export const AiTools = ({ background = "bg-[#EBF0F3]", data, withBlock = false }) => {

    return (
        <section className={`w-full py-[53px] flex flex-col gap-9 items-center ${background} font-['Raleway'] bg-top bg-no-repeat`}
         style={{ backgroundImage: `url(${withBlock && blocksImage})` }}
        >
            <AboutBlock
                title={data.title}
                header={data.header}
                contentCentered
                headerStyle={"max-w-[670px]"}
            />

            <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-6 container">
                {data.tools.map((tool, idx) => (
                    <div
                        key={idx}
                        className={`flex items-center gap-6 rounded-xl bg-white p-5 wow ${idx < 3 ? "fadeInLeft" : "fadeInRight"}`}
                    >
                        <div
                            className="flex items-center justify-center w-[81px] min-w-[81px] h-[79px] bg-[#EBF0F3] rounded-lg"
                        >
                            <img src={getImageUrl(tool.image)} alt={tool.title} />
                        </div>
                        <span className="text-2xl font-semibold">{tool.title}</span>
                    </div>
                ))}
            </div>

            <NavLink
                to="/request-demo"
                className="flex justify-center items-center font-semibold text-lg md:text-2xl bg-[#37B34A] hover:bg-green-600 rounded-md text-white h-[49px] w-[206px]"
            >
                {data.buttonText}
            </NavLink>
        </section>
    );
};

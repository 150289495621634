import { createSlice } from '@reduxjs/toolkit';
import {
    fetchCreateSupSolCategories,
    fetchCreateSupSolPage, fetchCreateSupSolTags,
    fetchDeleteSupSolPage,
    fetchSupportSolutionsPages,
    fetchSupSolCategories, fetchSupSolTags,
} from '../thunks/supportThunks';

const initialState = {
    pages: null,
    categories: null,
    tags: null,
}

const supportSolutionsSlice = createSlice({
    name: 'supportSolution',
    initialState,
    reducers: {
    },

    extraReducers(builder) {
        builder.addCase(fetchSupSolCategories.fulfilled, (state, action) => {
            state.categories = action.payload
        });

        builder.addCase(fetchSupportSolutionsPages.fulfilled, (state, action) => {
            state.pages = action.payload
        });

        builder.addCase(fetchCreateSupSolPage.fulfilled, (state, action) => {
            state.pages = state.pages.length ? [...state.pages, action.payload] : [action.payload];
        });
        
        builder.addCase(fetchCreateSupSolCategories.fulfilled, (state, action) => {
            state.categories = [...state.categories, action.payload];
        });
        
        builder.addCase(fetchDeleteSupSolPage.fulfilled, (state, action) => {
            state.pages = state.pages.filter((page) => +page.id !== +action.payload);
        });

        builder.addCase(fetchSupSolTags.fulfilled, (state, action) => {
            state.tags = action.payload;
        });

        builder.addCase(fetchCreateSupSolTags.fulfilled, (state, action) => {
            state.tags = [...state.tags, action.payload];
        });
        
        

    }
})

export const {
} = supportSolutionsSlice.actions;

export const getSupportSolutionsPages = (state) => state.supportSolutions.pages;
export const getSupportSolutionsCategories = (state) => state.supportSolutions.categories;
export const getSupportSolutionsTags = (state) => state.supportSolutions.tags

export default supportSolutionsSlice.reducer
import { createSlice } from '@reduxjs/toolkit';
import { getPageById, getPagesList, updatePageDataById, updatePagePresetById } from '../thunks/supportThunks';

const initialState = {
    pages: [],
    currentPage: null,
    isLoading: false,
    isPageUpdating: false,
}

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
    },

    extraReducers(builder) {
        builder.addCase(getPagesList.fulfilled, (state, action) => {
            state.pages = action.payload;
            state.isLoading = false
        });

        builder.addCase(getPagesList.pending, (state, action) => {
            state.isLoading = true
            state.pages = null
        });

        builder.addCase(getPagesList.rejected, (state, action) => {
            state.isLoading = false
            state.pages = null
        });
        builder.addCase(getPageById.fulfilled, (state, action) => {
            state.currentPage = action.payload;
            state.isLoading = false
        });

        builder.addCase(getPageById.pending, (state, action) => {
            state.isLoading = true
            state.currentPage = null
        });

        builder.addCase(getPageById.rejected, (state, action) => {
            state.isLoading = false
            state.currentPage = null
        });
        builder.addCase(updatePagePresetById.fulfilled, (state, action) => {
            state.isLoading = false
            const blockPresetId = action.payload.id
            const data = JSON.parse(JSON.stringify(state.currentPage))
            data.blocks = data.blocks.map(bl => {
                bl.blockPresets = (bl.blockPresets || []).map(blockPreset => {
                    if (blockPreset.id === blockPresetId) {
                        return action.payload;
                    }

                    return blockPreset;
                });

                return bl;
            });
            state.currentPage = data
        });

        builder.addCase(updatePagePresetById.pending, (state, action) => {
            state.isLoading = true
        });

        builder.addCase(updatePagePresetById.rejected, (state, action) => {
            state.isLoading = false
            state.currentPage = null
        });
        
        builder.addCase(updatePageDataById.fulfilled, (state, action) => {
            state.isLoading = false
        });

        builder.addCase(updatePageDataById.pending, (state, action) => {
            state.isLoading = true
        });

        builder.addCase(updatePageDataById.rejected, (state, action) => {
            state.isLoading = false
        });
    }
})

export const {
} = supportSlice.actions;

export default supportSlice.reducer
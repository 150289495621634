import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getCurrentPageLoading} from '../../redux/slices/applicationSlice';
import PageLoader from '../PageLoader';
import findValue from '../../assets/helpers/findValue';
import getImageUrl from '../../assets/helpers/getImageUrl';

const generateFooterData = (blocks) => ({
    socialsTitle: findValue(blocks, "socials_title"),
    socialLinks: (findValue(blocks, "social_links")?.data || []).map(link => ({
        title: link.title,
        link: link.link,
    })),
    contactInfo: {
        title: findValue(blocks, "contact_us_title"),
        address: findValue(blocks, "contact_us_address"),
        email: findValue(blocks, "contact_us_email"),
        phone: findValue(blocks, "contact_us_phone"),
    },
    footerText: findValue(blocks, "footer_rights"),
    aboutUs: findValue(blocks, "footer_about_us"),
});

const PublickPagesFooter = ({bg=""}) => {
    const currentPage = useSelector((state) => state?.application?.pages?.find(page => page.url === "/footer"));
    const data = generateFooterData(currentPage?.blocks || []);


    const navLinks = [
        {
            header: 'Navigation Links',
            links: [
                {to: '/HOM1_1', title: 'Home'},
                {to: '/HOM1_2', title: 'Why EmployJoy'},
                {to: '/blogs', title: 'Blog'},
                {to: '/career', title: 'Career'},
                {to: '/HOM_1_10', title: 'Contact Us'},
                {to: '/HOM1_15', title: 'Support Login'}
            ]
        },
        {
            header: 'Legal Links',
            links: [
                {to: '/HOM_1_11', title: 'Terms & Conditions'},
                {to: '/HOM_1_12', title: 'Privacy Policy'},
                // {to: 'HOM_1_13', title: 'Security'},
                // {to: '#', title: 'Cookie Policy'}
            ]
        },
        {
            header: data.socialsTitle,
            links: data.socialLinks.map((socialLink) => ({
                to: socialLink.link,
                title: socialLink.title
            }))
        },
        {
            header: data.contactInfo.title,
            isContactInfo: true,
            address: data.contactInfo.address,
            email: data.contactInfo.email,
            phone: data.contactInfo.phone
        },
    ];

    if (!currentPage) {
        return
    }

    return (
        <footer
            className={`border-t-[1px] border-t-[#67676780] text-[18px] text-[#000] pt-[35px] pb-[35px] max-sm:text-center ${bg}`}>
            <div className="pb-[20px] font-['Raleway'] leading-normal">
                <div className="container">
                    <div className="flex gap-[20px] md:gap-[50px] max-xl:flex-col">
                        <div
                            className="xl:max-w-[280px] w-full flex flex-col gap-[15px] max-xl:items-center max-xl:text-center">
                            <Link to="/HOM1_1" className="flex">
                                <img
                                    loading="lazy"
                                    src={require('../../assets/img/footer-logo.png')}
                                    className="max-w-full"
                                    alt="GreatHire Logo"
                                />
                            </Link>
                            <p className="font-medium">{data.aboutUs || 'We help companies streamline their hiring processes and find the best talent faster.'}</p>
                            <Link
                                to="/request-demo"
                                className="lg:flex hidden justify-center px-[12px] py-[8px] max-w-[159px] w-full bg-[#37B34A] hover:bg-green-600 rounded-md text-white"
                            >
                                Book a Demo
                            </Link>
                        </div>
                        <div className="flex flex-auto flex-wrap justify-between gap-[25px]">
                            {navLinks.map((linksBlock, index) => (
                                <div className="flex-auto flex flex-col gap-[15px] max-sm:items-center" key={index}>
                                    <h5 className="text-[20px] font-bold">{linksBlock.header}</h5>
                                    {!linksBlock.isContactInfo ? (
                                        <ul className="font-medium">
                                            {linksBlock.links.map((link, idx) => (
                                                <li key={idx}>
                                                    <Link
                                                        to={link.to}
                                                        className="flex items-center gap-2 hover:text-green-600"
                                                    >
                                                        <span
                                                            className="inline-block w-1 h-1 bg-black rounded-full"></span>
                                                        {link.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <>
                                            {linksBlock.address && <p className="italic max-w-[250px]">{linksBlock.address}</p>}
                                            <a href={`mailto:${linksBlock.email}`} className="flex gap-1">
                                                <span className="font-bold">Email:</span>
                                                <span className="italic underline">{linksBlock.email}</span>
                                            </a>
                                            {/*<a href={`tel:${linksBlock.phone}`} className="flex gap-1">*/}
                                            {/*    <span className="font-bold">Phone:</span>*/}
                                            {/*    <span className="italic underline">{linksBlock.phone}</span>*/}
                                            {/*</a>*/}
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-[15px] border-t-[1px] border-t-[#67676780]">
                <div className="container">
                    <div className="flex justify-between flex-wrap gap-3 max-sm:justify-center">
                        <p> {data.footerText || '2024 Diff Checker. All Rights Reserved.'}</p>
                        <div>
                            {/*<NavLink to="/HOM_1_11" className="hover:opacity-60">Terms of Service</NavLink>*/}
                            {/*<span> and </span>*/}
                            {/*<NavLink to="/HOM_1_12" className="hover:opacity-60">Privacy Policy</NavLink>.*/}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default PublickPagesFooter;

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5with6 from "./Step5with6";
import Step7 from "./Step7";
import Step8 from "./Step8";

export default {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5with6,
    Step7,
    Step8,
}

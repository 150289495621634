import {NavLink} from "react-router-dom";
import blocksImage from "../../../assets/img/home/blocks.png";
import {CandidateCards} from "./CandidateCards";
import {useSelector} from "react-redux";
import {getCurrentPage} from "../../../redux/slices/applicationSlice";
import findValue from "../../../assets/helpers/findValue";

const HeroSection = ({blocks}) => {
    const getValue = (key) => findValue(blocks, key);

    return (
        <section
            className="box-border w-full relative pt-[57px] pb-[83px] font-['Raleway'] leading-tight overflow-hidden"
            style={{background: 'linear-gradient(180deg, #FFF 0%, #E5ECF0 100%)', opacity: 0.9}}
        >
            <div
                className="absolute top-0 left-0 w-full h-[140px] box-border bg-center bg-no-repeat bg-cover"
                style={{backgroundImage: `url(${blocksImage})`}}
            ></div>

            <div className="w-full  flex gap-12 container max-lg:flex-col">
                <div className="flex justify-center relative flex-col w-full max-w-[635px] gap-5 mx-xl:mx-auto z-[2] xl:px-0">
                    <h2 className="font-bold text-8xl max-xl:text-5xl">
                        {getValue("home_top_title")}
                    </h2>
                    <div className="font-semibold text-5xl max-xl:text-3xl">
                        {getValue("home_top_subtitle")}
                    </div>

                    <div className="font-medium text-2xl max-w-[513px]  max-xl:text-sm">
                        {getValue("home_top_text")}
                    </div>
                    <NavLink to="/request-demo"
                             className="flex justify-center items-center font-semibold text-[20px] max-md:text-sm p-[10px] bg-[#37B34A] hover:bg-green-600 rounded-md text-white w-fit h-fit">
                        {getValue("home_top_button")}
                    </NavLink>
                </div>
                <div className="relative lg:right-[-180px]">
                    <CandidateCards data={getValue("home_slider")?.data}/>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;

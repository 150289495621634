import React, {useEffect, useState} from "react";
import {ElevenStepsWrapper} from "../../parts/ElevenStepsWrapper";
import SelectBox from "../../../../components/base/SelectBox";
import {crumbsConfig} from "../../config";

export const ClarificationsStep = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
        ["Clarification", '']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            {step === 1 && <ElevenStepsWrapper
                crumbs={crumbs}
                activePin={1}
                onNext={nextComponent}
                onBack={prevComponent}
            >
                <StepOne />
            </ElevenStepsWrapper>}
        </>
    )

}

const StepOne = () => {
    const [radioButtonsOptions, setRadioButtonsOptions] = useState([
        {
            id: 0,
            name: 'Applicant’s car commute expectation is reasonable.',
            options: { labels: ['yes', 'no'], isCheck: 'yes' }
        },
        {
            id: 1,
            name: 'Applicant’s car commute expectation is reasonable.',
            options: { labels: ['yes', 'no'], isCheck: 'yes' }
        },
        {
            id: 2,
            name: 'Applicant’s public transportation commute history is reasonable.',
            options: { labels: ['yes', 'no'], isCheck: 'yes' }
        },
        {
            id: 3,
            name: 'Applicant’s public transportation expectation is reasonable.',
            options: { labels: ['yes', 'no'], isCheck: 'yes' }
        },
        {
            id: 4,
            name: 'Applicant’s pay expectation is reasonable.',
            options: { labels: ['yes', 'no'], isCheck: 'yes' }
        },
        {
            id: 5,
            name: 'Applicant reaction to the job’s pay is reasonable.',
            options: { labels: ['yes', 'no'], isCheck: 'yes' }
        },
        {
            id: 6,
            name: 'Applicant reaction to the job’s pay details is reasonable.',
            options: { labels: ['yes', 'no'], isCheck: 'yes' }
        },
    ])

    const handleChangeOption = (id, label) => {
        setRadioButtonsOptions(prev => prev.map((el,ind) => {
            if (el.id === id) {
                return {...el, options: {...el.options, isCheck: label}}
            } else {
                return el
            }
        }))
    }


    const tabel = [
        {
            date: '04/17/2023',
            channel: 'Call',
            time: '14:00',
            content: 'replay'
        },
        {
            date: '04/17/2023',
            channel: 'Call',
            time: '14:00',
            content: 'replay'
        },
        {
            date: '04/17/2023',
            channel: 'Call',
            time: '14:00',
            content: 'replay'
        },
        {
            date: '04/17/2023',
            channel: 'Call',
            time: '14:00',
            content: 'details'
        },

        {
            date: '04/17/2023',
            channel: 'SMS',
            time: '14:00',
            content: 'details'
        },
        {
            date: '04/17/2023',
            channel: 'SMS',
            time: '14:00',
            content: 'details'
        },
        {
            date: '04/17/2023',
            channel: 'Email',
            time: '14:00',
            content: 'details'
        },
        {
            date: '04/17/2023',
            channel: 'Email',
            time: '14:00',
            content: 'details'
        },
    ]


    return (
        <>
            <div className="flex flex-col gap-[36px]">
                {radioButtonsOptions.map((item, index) => (
                    <div key={index} className="flex flex-col gap-[16px]">
                        <p>Applicant actually have the full access to the car.</p>
                        <div className="lg:flex grid grid-cols-2 gap-5">
                            {item.options.labels.map((label, idx) => (
                                <RadioButton key={label} value={label}
                                             onChange={() => handleChangeOption(item.id, label)}
                                             checked={item.options.isCheck === label} name={item.id} label={label}/>
                            ))}
                        </div>
                    </div>
                ))}

            </div>


            <div className="flex flex-col gap-7">
                <h1 className="self-stretch w-full text-3xl font-bold leading-8 max-md:max-w-full">Clarification</h1>
                <div
                    className="self-stretch w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full"></div>
                <p className="text-2xl font-bold">Communication History</p>

                <div className="flex max-md:flex-col md:items-center gap-[12px] md:gap-7">
                    <span className="text-[16px] md:text-xl font-bold ">Sort by :</span>
                    <div className="w-full md:max-w-[276px]">
                        <SelectBox options={[{label: 'Date (most recent first)'}]} svg={2}/>
                    </div>
                </div>
                <div className="overflow-auto">
                    <table className="table-auto w-[892px] text-center table-10_14">
                        <thead>
                        <tr>
                            <th className="font-semibold px-4 py-3">Date</th>
                            <th className="font-semibold px-4 py-3">Channel</th>
                            <th className="font-semibold px-4 py-3">Time</th>
                            <th className="font-semibold px-4 py-3">Content</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tabel.map((el, idx) =>
                            <tr key={idx}>
                                <td className="px-4 py-3">{el.date}</td>
                                <td className="px-4 py-3 font-bold">{el.channel}</td>
                                <td className="px-4 py-3">{el.time}</td>
                                <td className="px-4 py-3 font-bold">
                                    {el.content === 'replay' ?
                                        <div className="flex items-center justify-center gap-[6px]">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.625 11V8.25L11.9167 9.625L14.2083 11L11.9167 12.375L9.625 13.75V11Z"
                                                    fill="#37B34A" stroke="#37B34A" strokeWidth="1.5"
                                                    strokeLinejoin="round"/>
                                                <path
                                                    d="M5.16633 16.8337C5.93157 17.6009 6.84088 18.2093 7.84202 18.624C8.84316 19.0387 9.91638 19.2514 11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44371 15.5563 2.75 11 2.75C8.72208 2.75 6.65958 3.67354 5.16633 5.16633C4.40642 5.92625 2.75 7.79167 2.75 7.79167"
                                                    stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path d="M2.75 4.125V7.79167H6.41667" stroke="#37B34A" strokeWidth="1.5"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            Replay
                                        </div>
                                        : <div className="flex items-center justify-center gap-[6px]">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_341_11182)">
                                                    <path
                                                        d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z"
                                                        stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                    <path
                                                        d="M10.3125 10.3125C10.4948 10.3125 10.6697 10.3849 10.7986 10.5139C10.9276 10.6428 11 10.8177 11 11V14.4375C11 14.6198 11.0724 14.7947 11.2014 14.9236C11.3303 15.0526 11.5052 15.125 11.6875 15.125"
                                                        stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                    <path
                                                        d="M10.6562 8.25C11.2258 8.25 11.6875 7.78829 11.6875 7.21875C11.6875 6.64921 11.2258 6.1875 10.6562 6.1875C10.0867 6.1875 9.625 6.64921 9.625 7.21875C9.625 7.78829 10.0867 8.25 10.6562 8.25Z"
                                                        fill="#37B34A"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_341_11182">
                                                        <rect width="22" height="22" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            Details
                                        </div>

                                    }
                                </td>
                            </tr>)}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

const RadioButton = ({ label, id, name, onChange, checked, value }) => {
    return (
        <label
            className="flex gap-2.5 pr-5 cursor-pointer items-center"
        >
            <span
                className="flex flex-col justify-center items-center px-1 border border-solid bg-stone-50 border-stone-300 h-[18px] rounded-[360px] w-[18px] cursor-pointer"
            >
                {checked && (
                    <div className="shrink-0 w-2.5 h-2.5 bg-green-500 rounded-[360px]" />
                )}
                <input
                    className="hidden"
                    type="radio"
                    name={name}
                    id={id}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                />
            </span>
            <div className="my-auto text-lg font-semibold text-zinc-800 capitalize">{label}</div>
        </label>
    )
};
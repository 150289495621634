import React from "react";

export const TotalScore = ({ title, score, isGreen }) => (
    <div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
        <div className="grow flex justify-center items-center px-16 lg:h-[54px] h-[52px] font-semibold bg-neutral-100 w-fit text-[#333333]">
            {title}
        </div>
        <div
            className="flex gap-2 justify-center items-center px-5 py-1 min-w-[96px] lg:h-[54px] h-[52px] bg-white border border-solid border-zinc-300"
        >
            {isGreen && (
                <div className="shrink-0 my-auto w-3 h-3 bg-green-500 rounded-[360px]" />
            )}
            <div>{score}%</div>
        </div>
    </div>
);
import React, {useEffect, useState} from 'react'
import blocksImage from "../../assets/img/home/blocks.png";
import blogImage from "../../assets/img/home/blog.png";
import blogImageBig from "../../assets/img/blog-image-big.png"
import ReactPaginate from 'react-paginate';
import {NavLink} from "react-router-dom";
import findValue from '../../assets/helpers/findValue';
import {useSelector} from 'react-redux';
import {getCurrentPage, getCurrentPageLoading} from '../../redux/slices/applicationSlice';
import PageLoader from '../../components/PageLoader';
import {getPublicSolutionPagesFetch, getSupportSolutionPagesFetch} from '../../redux/thunks/solutionThunks';
import {useDispatch} from 'react-redux';
import getImageUrl from '../../assets/helpers/getImageUrl';
import {format} from 'date-fns';
import api from "../../api";


const BlogCard = ({date, title, description, image, id}) => {
    const isImageUrl = (url) => {
        return /\.(jpeg|jpg|gif|png|webp|bmp|svg|ico)$/i.test(url);
    }

    return (
        <div className="w-full overflow-hidden flex flex-col">
            {isImageUrl(image) ?
                <img className="w-full rounded-[14px] object-cover aspect-[4/3]" src={image} alt="Article"/>
                :
                <video
                    className="w-full rounded-[14px] object-cover aspect-[4/3]"
                    autoPlay={false}
                    controls={false}
                >
                    <source src={image} type="video/mp4"/>
                </video>

            }
            <div className="mt-2 flex flex-col flex-auto">
                <span className="text-base font-medium">Date: {date}</span>
                <h3 className="mt-2 text-2xl leading-7 font-semibold">{title}</h3>
                <p className="mt-2 text-base font-medium flex-auto">{description}</p>
                <NavLink
                    to={`/blogs/${id}`}
                    className="flex justify-center items-center font-semibold text-[18px] max-md:text-sm p-[8px] bg-[#37B34A] hover:bg-green-600 rounded-md text-white w-fit h-fit mt-3">
                    Read More
                </NavLink>
            </div>
        </div>
    );
};
// const blogsTop = [
//     {
//         date: "10th Oct, 2024",
//         title: "Seamless Connectivity for Smarter Recruitment",
//         description: "Empower your hiring process with reliable data systems and real-time connectivity. With our AI-driven platform, recruiters can effortlessly manage candidates and access critical information anytime, anywhere.",
//         image: blogImageBig,
//         url: "/blogs/1"
//     },
//     {
//         date: "10th Oct, 2024",
//         title: "Seamless Connectivity for Smarter Recruitment",
//         description: "Empower your hiring process with reliable data systems and real-time connectivity. With our AI-driven platform, recruiters can effortlessly manage candidates and access critical information anytime, anywhere.",
//         image: blogImageBig,
//         url: "/blogs/1"
//     },
// ];

const Blogs = () => {
    const currentPage = useSelector(getCurrentPage);
    const isLoading = useSelector(getCurrentPageLoading);

    const getValue = (key) => findValue(currentPage?.blocks, key);
    const {data, isLoading: blogsLoading} = useSelector(state => state.solution)
    const [categories, setCategories] = useState([])
    const [blogsTop, setBlogsTop] = useState([])
    const [activeCategory, setActiveCategory] = useState(null);


    const dispatch = useDispatch()
    console.log(data)
    const [filteredData, setFilteredData] = useState(data);


    const getCategories = async () => {
        const res = await api.publicSolution.getPublicCategories();


        if (res?.success) setCategories(res.data)
    }

    useEffect(() => {
        dispatch(getPublicSolutionPagesFetch())
        getCategories()
    }, [])
    // useEffect(() => {
    //     if (categories) setActiveCategory(categories[0]?.id)
    // }, [categories])
    useEffect(() => {
        if (data) {
            setFilteredData((prevData) => {
                return activeCategory ? data.filter(item => {
                    return item?.category?.id === activeCategory
                }) : data;
            })
        }
    }, [activeCategory, data])

    useEffect(() => {
        if (data) {
            setBlogsTop(data.filter((item, index) => index <= 1))
        }
    }, [data])
    if (isLoading && blogsLoading) return <PageLoader/>
    if (!data) return null

    return (
        <section
            className={`pt-[56px] pb-[110px] bg-top bg-no-repeat font-['Raleway']`}
            style={{backgroundImage: `url(${blocksImage})`}}
        >
            <div className="container">
                <div className={`flex flex-col gap-[17px] text-[18px] mb-[48px] items-center text-center`}>
                    <h3 className="text-[45px] max-md:text-[32px] font-bold max-w-[830px] leading-[48px]">
                        {getValue('blogs_title')}
                    </h3>
                    <p>{getValue('blogs_text')}</p>
                </div>
                <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-[38px] mb-[60px]">
                    {blogsTop.map((article, index) => (
                        <BlogCard
                            id={article.id}
                            date={format(article.createdAt, "dd, MM, yyyy")}
                            title={article.postTitle}
                            description={article.name}
                            image={getImageUrl(article.mainImage)}
                            key={index}
                        />
                    ))}
                </div>
                <div
                    className="grid grid-cols-4 max-xl:grid-cols-3 max-lg:grid-cols-2  max-sm:grid-cols-1 gap-[30px_20px]">
                    <div
                        className="lg:row-start-1 lg:row-end-3 sm:col-start-1 sm:col-end-3 lg:col-end-1 p-[25px] bg-[#EBF0F3] rounded-[20px] flex flex-col gap-[7px]">
                        <h5 className='text-[32px] font-bold'>Categories</h5>
                        <ul className='flex-auto overflow-auto max-h-[780px] scroll-style max-lg:flex max-lg:flex-wrap max-lg:gap-[15px]'>
                            {categories.map((category, index) => (
                                <li key={category.id}>
                                    <button
                                        onClick={() => {
                                            if (category.id === activeCategory) {
                                                setActiveCategory(null)
                                            } else {
                                                setActiveCategory(category.id)
                                            }
                                        }}
                                        className={`block text-[20px] lg:py-[20px] hover:text-[#28A745] text-left ${category.id === activeCategory ? 'text-[#28A745]' : ''}`}>
                                        {index + 1}. {category.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {filteredData.map((article, index) => (
                        <BlogCard
                            id={article.id}
                            date={format(article.createdAt, "dd, MM, yyyy")}
                            title={article.postTitle}
                            description={article.name}
                            image={getImageUrl(article.mainImage)}
                            key={index}
                        />
                    ))}
                </div>
                {/* <ReactPaginate
                    breakLabel="..."
                    nextLabel=""
                    previousLinkClassName='!hidden'
                    nextLinkClassName='!hidden'
                    // onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    containerClassName='pagination-style mt-[50px]'
                    pageCount={20}
                    previousLabel=""
                    renderOnZeroPageCount={null}
                /> */}
            </div>
        </section>
    )
}

export default Blogs

import * as React from "react";
import HomePage from "./SUP1_5";
import {useState} from "react";
import SUP1_5 from "./SUP1_5";
import HOM1_5_1 from "./HOM1_5_1";
import Breadcrumbs from "../components/base/Breadcrumbs";
import Confirmation from "../components/Confirmation";

const ContentBlock = ({ imageUrl, imageAlt, aspectRatio, text }) => (
	<section className="flex flex-col grow justify-between max-lg:pb-6 px-14 py-11 w-full text-base font-light leading-7 text-center border border-solid bg-[#F5F9FF] border-slate-200 text-zinc-800 md:px-5 md:mt-10">
		<img
			loading="lazy"
			src={imageUrl}
			alt={imageAlt}
			className={`self-center mt-7 mt-[10px] max-w-full ${aspectRatio}`}
		/>
		<p className="mt-11 md:mt-10">{text}</p>
	</section>
);
  
const InfoSection = () => {
	const contentBlocks = [
		{
			imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/b0faaeec427315bb3bf2338dd3341875722aec16c7bf548a936aca183abd8216?apiKey=9106ba04c02e4dceae488e75e2ac4862&",
			imageAlt: "First content image",
			aspectRatio: "aspect-[4.35] w-[257px] mt-[10px] lg:mt-[40px]",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		},
		{
			imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/9000b9677320c4da627697ced8df3b0b133a67e6d2f9eff2678fe63ab1a26d59?apiKey=9106ba04c02e4dceae488e75e2ac4862&",
			imageAlt: "Second content image",
			aspectRatio: "aspect-[6.25] w-[281px] mt-[10px] lg:mt-[45px]",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		},
		{
			imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/972922bcfb964e93025eca34c3eacfd8a123219f884bee2bc95b9b7f3e9533ed?apiKey=9106ba04c02e4dceae488e75e2ac4862&",
			imageAlt: "Third content image",
			aspectRatio: "aspect-[1.96] w-52",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		},
	];
  
	return (
		<section className="mt-20 md:mt-10 md:max-w-full max-md:mt-[44px]">
			<div className="flex gap-5 flex-col md:gap-10 lg:flex-row max-lg:px-2">
				{contentBlocks.map((block, index) => (
					<div key={index} className="flex flex-col lg:w-[33%] md:ml-0 md:w-full">
						<ContentBlock {...block} />
					</div>
				))}
			</div>
		</section>
	);
};

const TextSection = () => {
	return (
		<section className="self-center mt-20 lg:text-4xl text-2xl leading-9 max-lg:px-1 font-light text-center lg:leading-[52px] text-zinc-800 max-md:mt-10 max-md:max-w-full">
			<article>
				<p className="max-w-[980px] mx-auto">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget
					blandit urna. Ut vel nibh dui. In at dui quam.
				</p>
			</article>
		</section>
	);
}

const TestimonialCard = ({ quote, author, position, imageUrl, altText }) => {
	return (
		<article className="flex items-start">
			<div className="text-[60px] leading-[52px] font-[700]">“ </div>
			<div className="flex flex-col ml-[10px]">
				<p className="lg:text-4xl text-[28px] leading-9 lg:leading-[52px] font-[700]">{quote}</p>
				<div className="lg:mt-14 mt-8">
					<div className="lg:text-[24px] text-lg font-[700] text-[#333333]">{author}</div>
					<div className="lg:mt-[10px] mt-2 font-light max-md:text-lg text-[#333333]">{position}</div>
				</div>
				<img
					loading="lazy"
					src={imageUrl}
					alt={altText}
					className="lg:mt-14 mt-8 max-w-full aspect-[4.35] w-[257px] max-md:w-full max-md:max-w-[171px]"
				/>
			</div>
		</article>
	);
}
  
const TestimonialsSection = () => {
	const testimonials = [
		{
			quote: "In eget malesuada augue. Nam porta, metus nec scelerisque convallis, turpis tellus accumsan sapien, quis consequat ipsum dolor quis risus. Sed ullamcorper luctus nulla, vel ultricies elit interdum vel.",
			author: "Shauna Geraghty",
			position: "SVP, Head of Global People and Operations",
			imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/b0faaeec427315bb3bf2338dd3341875722aec16c7bf548a936aca183abd8216?apiKey=9106ba04c02e4dceae488e75e2ac4862&",
			altText: "Portrait of Shauna Geraghty",
		},
	];
  
	return (
		<section className="mt-20">
			<div className="flex flex-col lg:flex-row ">
				<div className="flex flex-col lg:w-6/12 lg:max-w-[590px] -mx-4 lg:mx-0">
					<img
						loading="lazy"
						src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a75166b640f7c5ac29962386e495ef1ee08a9fdc13cccfef920346ba01683ce?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
						alt="Large decorative image"
						className="w-full aspect-[0.76] md:mt-10 max-md:max-w-full"
					/>
				</div>
				<div className="flex flex-col lg:w-6/12 w-full grow lg:p-16 py-9 px-1 lg:pl-[56px] lg:pr-[36px] lg:max-w-[595px]">
					{ testimonials.map((testimonial, index) => (
						<TestimonialCard
							key={index}
							quote={testimonial.quote}
							author={testimonial.author}
							position={testimonial.position}
							imageUrl={testimonial.imageUrl}
							altText={testimonial.altText}
						/>
					))}
				</div>
			</div>
		</section>
	);
}


const SUP1_5_2 = () => (
	<HomePage>
		<TestimonialsSection />
		<TextSection />
		<InfoSection />
	</HomePage>
);

export default SUP1_5_2;
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import React from "react";
import {HiringProcess} from "../components/base/ProgressStepsSBM";

const NextStepSection = () => {
    return (
        <section>
            <div className="flex gap-5 justify-between self-stretch w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <button className="flex justify-center py-[15px] px-[64px] bg-[#B2B2B2] rounded-md max-md:px-5 max-md:flex-1 hover:opacity-70">
                    Back
                </button>
                <button className="flex justify-center items-center py-[15px] px-[64px] bg-green-500 rounded-md max-md:px-5 max-md:flex-1 hover:opacity-70">
                    Next
                </button>
            </div>
        </section>
    );
};
const SBM02_4_V2 = () => {
    const stepsData = [
        { stepNumber: "1", label: "New Job Details", isActive: true },
        { stepNumber: "2", label: "Description", isActive: true },
        { stepNumber: "3", label: "Preview", isActive: true },
        { stepNumber: "4", label: "Pipelines", isActive: true },
        { stepNumber: "5", label: "Qualification Attributes and Interview Questions", isActive: true },
        { stepNumber: "6", label: "Application Form", isActive: false },
        { stepNumber: "7", label: "Disposition Status", isActive: false }
    ];

    const data = [
        [
            'Stability',
            'Physicality',
            'Quality',
            `Client Service<br />- Empathy`,
            'Team Work',
            'World View',
            'Manageability',
            `Trustworthiness<br/>Integrity`,
            `Attitude towards<br/>Cleaning`
        ],
        [
            `Fitness for <br/>the Job`,
            `Open<br/>Mindedness`,
            'Coachability',
            'Organization',
            `Travel<br/>Sensitivity`,
            `Comfortable<br/>with Pets`,
            'Team or Solo',
            `Criminal<br/>Background`,
            'Feedbacks',
            'Job Outlook'
        ]
    ]

    const questions = [
        'Interview Question 1 : Tell me about yourself:',
        'Interview Question 2 : What is the biggest challenge you have faced?',
        'Interview Question 3 : Who is your best boss?',
        'Interview Question 4 : Who is your worst boss?',
        'Interview Question 5 : Has you pay increased?',
        'Interview Question 6 : How would you rate your performance in this job?'
    ]

    return (
        <>
            <BreadCrumbWhite crumbs={[
                'Subscriber Dashboard',
                'Jobs Setup',
                'New Job Details',
                "Description",
                "Preview",
                "Pipelines",
                "Qualification Attributes and Interview Questions",
                // "Interview Questions"
            ]} />

            <div className='flex-auto'>
                <div className='flex flex-col gap-[60px] max-md:gap-[40px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto max-md:pb-[80px] max-md:pt-[24px]'>
                    <section className="flex w-full text-center overflow-auto py-4 max-md:pb-[0px]">
                        <HiringProcess stepsData={stepsData} />
                    </section>

                    <div className='text-center flex gap-3 items-center justify-center flex-col'>
                        <span className='text-[40px] font-bold max-md:text-[36px]'>Interview Questions</span>
                        <span className='text-[18px] leading-[30px] max-md:text-[16px] max-md:leading-[22px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet,<br className="max-md:hidden" />
                            bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut<br className="max-md:hidden" />
                            consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi.
                        </span>
                    </div>

                    <div className="lg:border border-neutral-200 px-[60px] py-[50px] max-md:p-[0px]">
                        <div className="flex flex-col gap-6">
                            <hr className="mt-[10px] mb-[15px] border-b border-neutral-200 md:hidden" />

                            <label htmlFor="check" className="checkbox-green-container bigger">
                                <input type="checkbox" hidden id="check"  />
                                <div className="checkmark-green scale-150"></div>
                                <span className="checkmark-label-optional font-semibold text-[26px] flex-1 max-md:text-[18px]">Highly Recommended Qualification Attributes</span>
                            </label>

                            { data.map((el,idx) =>
                                // className="pl-12 flex"
                                <div className="lg:pl-12 flex max-lg:overflow-x-auto max-lg:overflow-y-hidden whitespace-nowrap" key={idx}>
                                    {el.map((itm, i) => <div key={i}
                                        className="description-tip-modal-sbm-container relative bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7] h-[68px] flex flex-auto items-center justify-center text-center max-md:px-[16px]"
                                    >
                                        <span className='text-[#333333] font-semibold'
                                            dangerouslySetInnerHTML={{__html: itm}}
                                        >
                                        </span>
                                        { idx === 0 && i === 0 && <div className="description-tip-modal-sbm max-lg:!hidden">
                                            <p className="text-2xl font-bold">Interview Questions for Stability</p>
                                            {questions.map((el, idx) => <span className="text-[18px]">{el}</span>)}
                                        </div>}
                                        { idx === 0 && i === 0 && <div
                                            className="description-tip-modal-sbm text-left max-lg:flex lg:!hidden"
                                            style={{ position: 'fixed', width: 'calc(100% - 44px)', left: '22px', whiteSpace: 'initial' }}
                                        >
                                            <p className="text-2xl font-bold">Interview Questions for Stability</p>
                                            {questions.map((el, idx) => <span className="text-[18px]">{el}</span>)}
                                        </div>}

                                    </div>)}
                                </div>
                            )}

                        </div>

                        <hr className="my-[50px] max-md:my-[40px] border-b border-neutral-200" />

                        <div className='text-center text-3xl italic text-[#666666] max-md:text-[18px]'>
                            <span>
                                <span className="text-[#37B34A] underline">CONTACT</span> your onboards specialist if you decide to customize further
                            </span>
                        </div>

                        <hr className="mt-[80px] border-b border-neutral-200 md:hidden" />
                    </div>

                    <NextStepSection />
                </div>
            </div>
        </>
    )
}

export default SBM02_4_V2

import React, { useState, useEffect } from "react";
import ModalTemplate from '../base/ModalTemplate';
import Button from "../base/Button";
import PrimaryButton from "../base/PrimaryButton";
import html2canvas from 'html2canvas';

const CreateSignatureModal = ({ open, handleClose, footerBorderTop = true, setSignature, name = 'John Doe' }) => {
    const [activeTab, setActiveTab] = useState("Type");
    const tabs = ["Type", "Draw"];
    const [typeString, setTypeString] = useState(name);

    const selectedFontItem = (index) => {
        html2canvas(document.querySelector(`.sign-font-item-${index}`)).then(function(canvas) {
            var myImage = canvas.toDataURL("img/png");
            setSignature(myImage);
            handleClose();
        });
    }

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const selectFileImage = async(e) => {
        let resultBase64 = await toBase64(e.target.files[0]);
        setSignature(resultBase64);
        handleClose();
    }

    const initDrawer = () => {
        window.requestAnimFrame = (function(callback) {
        return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            window.msRequestAnimaitonFrame ||
            function(callback) {
            window.setTimeout(callback, 1000 / 60);
            };
        })();

        var canvas = document.getElementById("sig-canvas");
        var ctx = canvas.getContext("2d");
        ctx.strokeStyle = "#222222";
        ctx.lineWidth = 4;

        var drawing = false;
        var mousePos = {
            x: 0,
            y: 0
        };
        var lastPos = mousePos;

        canvas.addEventListener("mousedown", function(e) {
            drawing = true;
            lastPos = getMousePos(canvas, e);
        }, false);

        canvas.addEventListener("mouseup", function(e) {
            drawing = false;
        }, false);

        canvas.addEventListener("mousemove", function(e) {
            mousePos = getMousePos(canvas, e);
        }, false);

        // Add touch event support for mobile
        canvas.addEventListener("touchstart", function(e) {
        }, false);

        canvas.addEventListener("touchmove", function(e) {
            var touch = e.touches[0];
            var me = new MouseEvent("mousemove", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            canvas.dispatchEvent(me);
        }, false);

        canvas.addEventListener("touchstart", function(e) {
            mousePos = getTouchPos(canvas, e);
            var touch = e.touches[0];
            var me = new MouseEvent("mousedown", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            canvas.dispatchEvent(me);
        }, false);

        canvas.addEventListener("touchend", function(e) {
            var me = new MouseEvent("mouseup", {});
            canvas.dispatchEvent(me);
        }, false);

        function getMousePos(canvasDom, mouseEvent) {
            var rect = canvasDom.getBoundingClientRect();
            return {
                x: mouseEvent.clientX - rect.left,
                y: mouseEvent.clientY - rect.top
            }
        }

        function getTouchPos(canvasDom, touchEvent) {
            var rect = canvasDom.getBoundingClientRect();
            return {
                x: touchEvent.touches[0].clientX - rect.left,
                y: touchEvent.touches[0].clientY - rect.top
            }
        }

        function renderCanvas() {
            if (drawing) {
                ctx.moveTo(lastPos.x, lastPos.y);
                ctx.lineTo(mousePos.x, mousePos.y);
                ctx.stroke();
                lastPos = mousePos;
            }
        }

        // Prevent scrolling when touching the canvas
        document.body.addEventListener("touchstart", function(e) {
            if (e.target == canvas) {
                e.preventDefault();
            }
        }, false);
        document.body.addEventListener("touchend", function(e) {
            if (e.target == canvas) {
                e.preventDefault();
            }
        }, false);
        document.body.addEventListener("touchmove", function(e) {
            if (e.target == canvas) {
                e.preventDefault();
            }
        }, false);

        (function drawLoop() {
            window.requestAnimFrame(drawLoop);
            renderCanvas();
        })();

        function clearCanvas() {
            canvas.width = canvas.width;
            ctx.strokeStyle = "#222222";
            ctx.lineWidth = 4;
        }

        var clearBtn = document.getElementById("sig-clearBtn");
        var submitBtn = document.getElementById("sig-submitBtn");

        clearBtn.addEventListener("click", function(e) {
            clearCanvas();
        }, false);

        submitBtn.addEventListener("click", function(e) {
            var dataUrl = canvas.toDataURL();
            setSignature(dataUrl);
            handleClose();
        }, false);
    }

    useEffect(()=>{
        if(activeTab === "Draw"){
            initDrawer();
        }
    }, [activeTab])

    return (
        <ModalTemplate open={open} onClick={handleClose} modalSize="max-w-[772px]">
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">Create Signature</h2>
                <button className="btn btn-close" onClick={handleClose}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            <div className="modal__body">
                <div className="flex gap-5 justify-between my-auto text-xl font-semibold text-center whitespace-nowrap text-zinc-800">
                    {
                        tabs?.map((item, index)=>{
                            return <a
                                href="#" className={`grow justify-center px-5 pt-[5px] h-[46px]  border-solid border-b-[5px] hover:bg-green-200 ${activeTab === item ? 'border-green-500' : 'border-white'}`}
                                key={index} onClick={(e)=>{e.preventDefault(); setActiveTab(item); }}
                            >
                                { item }
                            </a>
                        })
                    }
                    <label className={`grow justify-center px-5 pt-[5px] h-[46px] border-solid border-b-[5px] hover:bg-green-200 border-white cursor-pointer`}>
                        Image
                        <input
                            type="file" name="" id="" hidden accept="image/png, image/jpeg"
                            onChange={(e)=>{
                                selectFileImage(e);
                            }}
                        />
                    </label>
                </div>
                <div className="w-full pt-[16px]">
                    { activeTab === 'Type' ? <div>
                        <div className="flex flex-col gap-[16px] max-md:gap-[12px] flex-1 mb-[18px]">
                            <input
                                type="text" className="rounded-md px-[18px] text-[18px] focus:outline-none focus:ring-2 focus:ring-green-500  bg-white rounded-[0px] border border-solid border-zinc-300 h-[54px] w-full"
                                placeholder="John Doe" value={typeString} onInput={(e)=>{ setTypeString(e.target.value); }}
                            />
                            <div className="flex flex-wrap gap-[14px] justify-center">
                                {
                                    [
                                        "Gamja Flower",
                                        "Indie Flower",
                                        "Charmonman",
                                        "Pacifico",
                                        "Gloria Hallelujah",
                                        "Amatic SC",
                                        "Shadows Into Light",
                                        "Dancing Script",
                                        "Dokdo",
                                        "Permanent Marker",
                                        "Patrick Hand",
                                        "Courgette",
                                    ]?.map((font, index)=>{
                                        return <div
                                            key={index}
                                            className={`text-[35px] min-h-[60px] rounded-[6px] hover:bg-[#37B34A] hover:text-[#ffffff] px-[10px] cursor-pointer leading-[60px] sign-font-item-${index}`} style={{ fontFamily: font }}
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                selectedFontItem(index);
                                            }}
                                        >
                                            { typeString }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div> : null }
                    { activeTab === 'Draw' ? <div className="w-full">
                        <canvas id="sig-canvas" width="676" height="160" className="border w-[676px] h-[160px]">
                            Get a better browser.
                        </canvas>
                        <div class="flex gap-5 justify-start pt-10 max-md:flex-wrap max-md:max-w-full text-lg font-bold text-[#ffffff]">
                            <button class="flex items-center h-[52px] justify-center px-12 py-5 bg-gray-500 rounded-md max-md:px-5 max-md:flex-1 hover:opacity-70" id="sig-submitBtn">Submit Signature</button>
                            <button class="flex items-center h-[52px] justify-center px-9 py-5 bg-teal-500 rounded-md max-md:px-5 max-md:flex-1 hover:opacity-70" id="sig-clearBtn">Clear Signature</button>
                        </div>
                    </div> : null }
                </div>
            </div>
            {/* <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                <Button className={'text-gray-400 max-md:w-full min-w-[122px] min-h-[52px] text-[18px]'} onClick={handleClose} >
                    <span className="underline">Cancel</span>
                </Button>
                <PrimaryButton className={'w-[122px] !h-[52px] !text-[18px]'} onClick={handleClose} >
                    Submit
                </PrimaryButton>
            </div> */}
        </ModalTemplate>
    )
};

export default CreateSignatureModal

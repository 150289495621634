import React from 'react'
import { ReactComponent as Loader } from "../assets/img/loader.svg";
import classNames from 'classnames';

const PageLoader = ({ color = "", className }) => {
  return (
    <div className='flex-auto flex items-center justify-center'>
      <Loader className={classNames(`m-auto my-40 ${color === "light" ? "invert" : ""}`, className)} />
    </div>
  )
}

export default PageLoader
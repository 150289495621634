const feedbacksBase = 'api/feedbacks';

export const feedbacks = (instance) => ({
    async sendFeedback(payload) {
        const { data } = await instance.post(`${feedbacksBase}`, payload);
        return data;
    },
    async sendAnswerFeedback(payload) {
        const { data } = await instance.post(`${feedbacksBase}/answer`, payload);
        return data;
    },
    async getAllFeedbacks(payload) {
        const { data } = await instance.get(`${feedbacksBase}`, payload);
        return data;
    },
    async getFeedbackById(feedbackId, payload) {
        const { data } = await instance.get(`${feedbacksBase}/${feedbackId}`, payload);
        return data;
    },
});


import arrowUpIcon from "../../../assets/img/home/arrowUp.svg";
import {NavLink} from "react-router-dom";
import React from "react";

const CategoryCard = ({img, jobs, title, description, button, className, withButton = true , idx= 0}) => {
    return (
        <div
            className={`p-6 border rounded-[21px] flex flex-col card-shadow hover:border-black hover:bg-[#EBF0F3] bg-white justify-between justify-self-center ${className}  wow ${idx % 2 === 0  ? "fadeInUp" : "fadeInDown"}`}
            data-wow-delay={"300ms"}
        >
            <div className="flex justify-between items-start mb-5">
                <div className="flex gap-5 items-center">
                    <img src={img} alt={title} className="w-[67px] h-[67px]"/>
                    <span className="text-[17px] font-semibold inline-block max-w-[80px]">
                        {jobs}
                    </span>
                </div>
                <img src={arrowUpIcon} alt="arrow" className="cursor-pointer"/>
            </div>
            <h3 className="text-[33px] font-bold mb-2 !leading-[38px] w-[90%] ">{title}</h3>
            <div className="text-base font-normal mb-[20px] flex-auto">{description}</div>
            {withButton && <NavLink
                to="/request-demo"
                className="flex justify-center items-center font-semibold text-[21px] py-[10px] px-[35px]  bg-[#0056B3] hover:opacity-90 rounded-md text-white w-fit h-fit">
                {button}
            </NavLink>}
        </div>
    );
};

export default CategoryCard;

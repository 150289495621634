const NextStepSection = ({ save, statusToggle, payload, }) => {
    return (
        <section>
            <div className="flex gap-5 justify-center self-stretch w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <button
                    className="flex justify-center py-[14px] w-[166px] bg-[#37B34A] rounded-md max-md:px-5 max-md:w-full hover:opacity-70"
                    onClick={(e)=>{
                        e.preventDefault();
                        save();
                    }}
                >
                    Save
                </button>
                <button
                    className="flex justify-center items-center py-[14px] w-[166px] bg-[#003578] rounded-md max-md:px-5 max-md:w-full hover:opacity-70"
                    onClick={(e)=>{
                        e.preventDefault();
                        statusToggle();
                    }}
                >
                    {payload.status === "active" ? "Archive" : "Active"} Job
                </button>
            </div>
        </section>
    );
};

export default NextStepSection;
const companyTeamBase = 'api/company-team';

export const companyTeam = (instance) => ({
    async addUserInCompany(companyId, payload) {
        const { data } = await instance.post(`${companyTeamBase}/${companyId}`, payload);
        return data;
    },
    async deleteMember(companyId, userId) {
        const { data } = await instance.delete(`${companyTeamBase}/delete/${companyId}/${userId}`);
        return data;
    },
    async getTeamMembers(companyId) {
        const { data } = await instance.get(`${companyTeamBase}/${companyId}`);
        return data;
    },
    async updateTeamMembers(companyId, userId, payload) {
        const { data } = await instance.put(`${companyTeamBase}/${companyId}/${userId}`, payload);
        return data;
    },

    async confirmEmail({ userId, token }) {
        const { data } = await instance.get(`${companyTeamBase}/confirm-email/${token}?userId=${userId}`);
        return data;
    },
});

import React from 'react'
import GetInTouchWithOurSupport from '../../components/parts/GetInTouchWithOurSupport'
import PageFAQSecion from '../../components/parts/PageFAQSecion'
import ContactSection from '../../components/parts/ContactSection'
import {useSelector} from "react-redux";
import {getCurrentPageLoading} from "../../redux/slices/applicationSlice";
import findValue from "../../assets/helpers/findValue";
import PageLoader from "../../components/PageLoader";
import getImageUrl from "../../assets/helpers/getImageUrl";

const generateDataFromBlocks = (blocks) => {
    return {
        hero: {
            header: findValue(blocks, "contact_title"),
            title: findValue(blocks, "contact_text"),
            cards: (findValue(blocks, "contact_cards")?.data || []).map(card => ({
                title: card.title,
                icon: getImageUrl(card.icon),
                text: card.text,
                link: card.link,
            }))
        },
        contact: {
            header: findValue(blocks, "contact_form_title"),
            title: findValue(blocks, "contact_form_label"),
            description: findValue(blocks, "contact_form_text"),
            form: {
                fullName: {
                    label: findValue(blocks, "full_name"),
                    placeholder: findValue(blocks, "full_name_placeholder"),
                },
                email: {
                    label: findValue(blocks, "email"),
                    placeholder: findValue(blocks, "email_placeholder"),
                },
                phone: {
                    label: findValue(blocks, "phone"),
                    placeholder: findValue(blocks, "phone_placeholder"),
                },
                company: {
                    label: findValue(blocks, "company"),
                    placeholder: findValue(blocks, "company_placeholder"),
                },
                message: {
                    label: findValue(blocks, "message"),
                    placeholder: findValue(blocks, "message_placeholder"),
                },
                buttonText: findValue(blocks, "contact_form_button"),
                policy: findValue(blocks, "contact_form_policy"),
            }
        },
    };
};

const ContactUs = () => {
    const currentPage = useSelector((state) => state.application.pages.find(page => page.url === "/contact-us"));
    const isLoading = useSelector(getCurrentPageLoading);
    const data = generateDataFromBlocks(currentPage?.blocks || []);

    console.log("currentPage", currentPage)

    if (isLoading) return <PageLoader/>;

    return (
        <>
            <GetInTouchWithOurSupport contentCenter={true} topSpacing='pt-[56px] pb-[0px]' data={data.hero} withCards={false}/>
            {/*<PageFAQSecion/>*/}
            <ContactSection data={data.contact} withBlock={false} topSpacing={"pt-[0px] pb-[110px]"}/>
        </>
    )
}

export default ContactUs

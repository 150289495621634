import {ElevenStepsWrapper} from "../../parts/ElevenStepsWrapper";
import React, {useEffect, useState} from "react";
import SelectBox from "../../../../components/base/SelectBox";
import InputField from "../../../../components/base/InputField";
import Checkbox from "../../../../components/base/Checkbox";
import PrimaryButton from "../../../../components/base/PrimaryButton";
import {crumbsConfig} from "../../config";

export const OfferMeetingStep = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
        ['Offer Meeting']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            {step === 1 && <ElevenStepsWrapper
                onNext={() => nextComponent()}
                onBack={() => prevComponent()}
                crumbs={crumbs}
                activePin={10}
            >
                <StepOne />
            </ElevenStepsWrapper>}
        </>
    )
}

const StepOne = () => {

    return (
        <div className='flex flex-col gap-11 lg:gap-[60px]'>
            <InterviewSetup/>
            <OfferLetterPage/>
        </div>
    )
}


const TimeSlot = ({ time }) => (
    <div className="h-[54px] flex items-center justify-center bg-white border border-solid border-zinc-300 max-md:px-5">
        {time}
    </div>
);

const InterviewSetup = () => {
    const timeSlots = ["10:30 am", "10:30 am", "10:30 am", "10:30 am", "10:30 am", "10:30 am", "10:30 am", "10:30 am", "10:30 am", "10:30 am", "10:30 am", "10:30 am"];
    return (
        <main className="flex flex-col py-9 px-8 lg:p-14 rounded-md border border-solid border-zinc-300">
            <section className="max-md:max-w-full">
                <header className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                        <section className="flex flex-col justify-center max-md:max-w-full">
                            <h2 className="text-xl font-bold text-zinc-800 max-md:max-w-full">Finalize the interview details</h2>
                            <h3 className="lg:mt-8 mt-4 text-lg font-semibold text-zinc-800 max-md:max-w-full">Interview Type</h3>
                            <SelectBox
                                className='mt-[16px] rounded-none'
                                options={[
                                    {
                                        label: 'test'
                                    },
                                    {
                                        label: 'test 2'
                                    }
                                ]}
                                svg={2}
                            />
                            <h3 className="lg:mt-8 mt-4 text-lg font-semibold text-zinc-800 max-md:max-w-full">Duration</h3>
                            <SelectBox
                                className='mt-[16px] rounded-none'
                                options={[
                                    {
                                        label: 'test'
                                    },
                                    {
                                        label: 'test 2'
                                    }
                                ]}
                                svg={2}
                            />
                            <h3 className="lg:mt-8 mt-4 text-lg font-semibold text-zinc-800 max-md:max-w-full">Interviewer</h3>
                            <SelectBox
                                className='mt-[16px] rounded-none'
                                options={[
                                    {
                                        label: 'test'
                                    },
                                    {
                                        label: 'test 2'
                                    }
                                ]}
                                svg={2}
                            />
                        </section>
                    </div>
                    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                        <section className="flex flex-col grow justify-center text-lg text-stone-500 max-md:mt-10 max-md:max-w-full">
                            <h2 className="text-xl font-bold text-zinc-800 max-md:max-w-full">
                                <span className='font-normal'>Calendar for:</span> Marc Spector
                            </h2>
                            <h3 className="mt-8 font-semibold text-zinc-800 max-md:max-w-full">Select available start times</h3>
                            <InputField type='date' inputBg='bg-white border border-solid border-[#DEDED9]' />
                            <div className="flex gap-4 items-center self-start p-1 mt-[14px] whitespace-nowrap">
                                <div className="shrink-0 w-3 h-3 bg-green-500 rounded-[360px]"></div>
                                <div className="grow">30 Minute Meeting</div>
                            </div>
                            <section className="flex gap-[22px] lg:gap-5 justify-between mt-[22px] lg:mt-6 w-full max-md:flex-wrap max-md:max-w-full">
                                <div className="flex gap-3 whitespace-nowrap">
                                    <Checkbox />
                                    <div className="grow my-auto">Show outside ‘business hours’</div>
                                </div>
                                <div className="flex gap-3">
                                    <Checkbox />
                                    <div className="my-auto">Select All</div>
                                </div>
                            </section>
                            <section className="grid grid-cols-2 lg:grid-cols-4 gap-[13px] mt-8 whitespace-nowrap text-neutral-400 max-md:flex-wrap">
                                {timeSlots.map((time, index) => (
                                    <TimeSlot key={index} time={time} />
                                ))}
                            </section>

                        </section>
                    </div>
                </header>
            </section>
            <footer className="flex flex-col items-end pt-8 lg:pl-16 mt-8 text-lg font-bold text-center text-white whitespace-nowrap border-t border-solid border-zinc-300 w-full">
                <PrimaryButton className='w-full lg:w-[120px] !h-[52px] !text-lg'>Confirm</PrimaryButton>
            </footer>
        </main>
    );
};

const employmentTypes = [
    {
        label: 'Full Time'
    },
    {
        label: 'Part Time'
    },
    {
        label: 'Contract'
    },
];

const payPeriods = [
    {
        label: 'Hour'
    },
    {
        label: 'Day'
    },
    {
        label: 'Week'
    },
    {
        label: 'Month'
    }
];

const payFrequencies = [
    {
        label: 'Daily'
    },
    {
        label: 'Weekly'
    },
    {
        label: 'Biweekly'
    },
    {
        label: 'Monthly'
    }
];


function OfferLetterPage() {
    return (
        <div className="flex gap-12 max-md:flex-col max-md:gap-0">
            <aside className="flex flex-col w-[30%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col px-8 lg:py-14 py-9 text-lg rounded-md border border-solid border-zinc-300">
                    <h2 className="text-xl font-bold text-zinc-800">Employment Type</h2>
                    <SelectBox
                        options={employmentTypes}
                        className="mt-4 !h-[54px]"
                    />

                    <hr className="shrink-0 mt-8 h-px bg-zinc-300" />

                    <h2 className="mt-8 text-xl font-bold text-zinc-800">Start Date</h2>
                    <InputField type='date' inputBg='bg-white border border-solid border-[#DEDED9] mt-3.5' />

                    <hr className="shrink-0 mt-8 h-px bg-zinc-300" />

                    <h2 className="mt-8 text-xl font-bold text-zinc-800">Compensation</h2>
                    <div className="flex gap-3.5 justify-center mt-8">
                        <div className="flex flex-col flex-1 justify-center">
                            <div className="text-neutral-500">Pay Rate</div>
                            <InputField inputBg='bg-white border border-solid border-[#DEDED9]' />
                        </div>
                        <div className="mb-[14px] flex items-center lg:mb-[18px] h-[54px] mt-auto text-neutral-500">per</div>
                        <div className="flex flex-col flex-1 justify-center">
                            <div className="text-neutral-500">Pay Period</div>
                            <SelectBox
                                options={payPeriods}
                                className="mb-[18px] !h-[54px]"
                            />
                        </div>
                    </div>

                    <div className="text-neutral-500">Pay Frequency</div>
                    <SelectBox
                        options={payFrequencies}
                        className="mt-3.5 !h-[54px]"
                    />


                    <div className="mt-4 lg:mt-6 text-neutral-500">Recruiter Name</div>
                    <SelectBox
                        options={[{label: 'Marc Spector'}]}
                        className="mt-3.5 !h-[54px]"
                    />

                    <hr className="shrink-0 mt-8 h-px bg-zinc-300" />

                    <button className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5">
                        Save Offer Letter
                    </button>
                    <button className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-3.5 font-bold text-center text-white whitespace-nowrap bg-sky-900 rounded-md max-md:px-5">
                        Email Offer Letter
                    </button>
                </div>
            </aside>

            <main className="flex flex-col lg:w-[70%] w-full max-lg:mt-11">
                <div className="flex flex-col grow self-stretch w-full">
                    <h1 className="text-3xl font-bold leading-10 text-zinc-800 max-md:max-w-full">Offer Letter</h1>
                    <article className="flex flex-col lg:px-9 lg:py-10 p-[22px] mt-5 text-lg rounded-md border border-solid bg-neutral-100 border-zinc-300 w-full">
                        <p className="leading-8 text-stone-500 max-md:max-w-full">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi. Mauris lobortis condimentum nisi, et fermentum risus luctus eget. Cras scelerisque enim et massa scelerisque in dapibus justo rutrum. Duis sit amet mauris et dui dignissim posuere. Sed quis massa et risus pulvinar rhoncus quis sit amet magna. Vivamus ac rhoncus arcu.
                            <br />
                            Quisque sit amet tortor nulla, sit amet tempus metus. Vivamus placerat scelerisque sapien vitae aliquet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris massa eros, porttitor sed tempus in, pretium mattis sapien. Praesent fringilla odio vitae est vehicula facilisis. Vestibulum accumsan molestie dictum. Integer nec sodales leo. Phasellus tincidunt massa vel purus malesuada id dapibus tortor iaculis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris interdum, dui eget congue venenatis, libero neque porta quam, nec facilisis enim nisl in tortor. Suspendisse suscipit ligula id ipsum gravida euismod. Duis mollis tempor neque a pulvinar.
                            <br />
                            In eget malesuada augue. Nam porta, metus nec scelerisque convallis, turpis tellus accumsan sapien, quis consequat ipsum dolor quis risus. Sed ullamcorper luctus nulla, vel ultricies elit interdum vel. Vivamus iaculis sollicitudin mi pulvinar interdum. Suspendisse dolor velit, sollicitudin sed auctor non, blandit id mauris. Vivamus ligula mi, cursus ultrices commodo tincidunt, porta ullamcorper nisi. Nunc feugiat ante vel dolor consequat in scelerisque leo molestie. Nunc vitae purus ligula. Ut blandit venenatis eleifend. Integer faucibus semper gravida. Ut facilisis scelerisque quam id lobortis. Cras volutpat arcu sed orci porta a fermentum tellus placerat.
                            <br />
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget blandit urna. Ut vel nibh dui. In at dui quam. Vivamus massa purus, dapibus nec lobortis ut, lacinia quis risus. Morbi libero odio, dapibus sit amet suscipit consequat, hendrerit nec eros. Aliquam ornare pharetra vehicula. Curabitur blandit, sem sit amet auctor congue, erat turpis facilisis urna, non rhoncus ipsum ligula et velit. Donec ligula purus, ornare sed pellentesque at, viverra eget nisl. Phasellus bibendum vulputate purus, non consectetur leo rutrum eu. Nullam facilisis sollicitudin elit eu posuere. Sed vulputate ligula non ipsum placerat sodales.
                            <br />
                            Nullam laoreet tincidunt purus vel volutpat. Sed auctor diam ac lorem elementum id malesuada lorem euismod. Fusce ullamcorper orci vitae enim condimentum et luctus libero iaculis. Fusce ut mauris mi, et luctus lorem. Donec ut pharetra ligula. Donec eu nisl nisi. Etiam mollis orci in arcu egestas semper. Donec aliquam pharetra libero et semper.
                        </p>
                        <div className="flex gap-5 max-lg:text-center justify-between mt-2.5 font-bold leading-[167%] text-zinc-800 max-lg:flex-col w-full">
                            <div className="flex-auto self-end mt-32 max-md:mt-10">Company Representative Signature</div>
                            <div className='mt-5 aspect-[2.94] w-[206px]'>

                            </div>
                            <div className="flex flex-col justify-center items-center pb-3 whitespace-nowrap">
                                <div>Marc Spector</div>
                                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1258e57f53b63edd652cc0fc86ee4fab71d967d8da1ca39e33f3c7782e5548ae?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&" alt="Candidate signature" className="mt-5 aspect-[2.94] w-[206px]" />
                                <div className="mt-5">Candidate</div>
                            </div>
                        </div>
                    </article>
                </div>
            </main>
        </div>

    );
}

import {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import InputField from "../../../components/base/InputField";
import {NextStepSectionSBM01} from "../base/NextStepSectionSBM01";
import api from "../../../api";
import {toast} from "react-toastify";
import {getApplicationByIdFetch, getJobByIdFetch} from "../../../redux/thunks/jobsThunks";
import {useDispatch} from "react-redux";

const FinalHiringDecision = () => {
    const navigate = useNavigate()
    const {job, application, jobId, applicationId, setActiveStep} = useOutletContext()
    const [finalRecommendation, setFinalRecommendation] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState()


    useEffect(() => {
        setActiveStep(8)
        setIsLoading(true)
        api.hiringAlgorithm.getResult(applicationId).then(response => {
            console.log(response)
            if (!response.success) {
                setError(response.message)
                return
            }
            setData(response.data)
        }).finally(() => setIsLoading(false))
    }, [applicationId])


    useEffect(() => {
        setActiveStep(9)
    }, [])

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, []);

    const handlePrev = () => {
        navigate(`/SBM01_10_5/${jobId}/${applicationId}`)
    }
    const handleNext = () => {
        navigate(`/SBM01_10_7/${jobId}/${applicationId}`)
    }

    const aiReacomendation = [
        {
            label: "High Standard",
            hire: data?.high_result || false
        },
        {
            label: "Medium Standard",
            hire: data?.medium_result || false
        },
        {
            label: "Average Standard",
            hire: data?.average_result || false
        },
    ]

    const dispatch = useDispatch();

    const handleSave = async (value) => {
        setIsLoading(true)
        try {
            const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
                assessments: {
                    ...application?.assessments || {},
                    "final-hiring": {
                        ...application?.assessments?.["final-hiring"],
                        finalRecommendation: value
                    }
                },
                criticalRequirements: {
                    "final-hiring": {
                        finalRecommendation: true
                    }
                },
            })
            if (resp.success) {
                Promise.all([
                    dispatch(getJobByIdFetch(jobId)),
                    dispatch(getApplicationByIdFetch({applicationId, jobId}))
                ]);
                toast.success("Saved")
            } else {
                toast.error(resp.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (application.assessments?.["final-hiring"]) {
            setFinalRecommendation(application.assessments?.["final-hiring"].finalRecommendation)
        }
    }, [application.assessments?.["final-hiring"]]);

    return (
        <div className="flex-auto">
            <div className="flex flex-col gap-11 flex-auto">
                <div className="border-b border-neutral-200 pb-9">
                    <span className="text-3xl font-bold">Final Hiring Decisions</span>
                </div>

                <div className="pb-[60px] border-b border-neutral-200">
                    <span className="block text-[22px] font-bold mb-4">Top 3 Strengths:</span>


                    {data?.top_variables && data?.top_variables.sort((a, b) => Number(b.value) - Number(a.value)).slice(0, 3).map(({
                                                                                                                                       name,
                                                                                                                                       value
                                                                                                                                   }, idx) => (

                        <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mb-4">
                            <div
                                className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center min-w-[350px]">
                                <div className="green-box mr-4"></div>
                                {name
                                    ?.replace(/_/g, ' ')
                                    ?.replace(/\b\w/g, char => char.toUpperCase())}:
                            </div>

                            <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                                <InputField
                                    labelClassName={'font-semibold text-[#6F6F6F]'}
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                                    value={value}
                                    disabled={true}
                                />
                            </div>

                        </div>
                    ))}
                </div>

                <div className="pb-[60px] border-b border-neutral-200">
                    <span className="block text-[22px] font-bold mb-4">Top 3 Weaknesses:</span>

                    {data?.bottom_variables && data?.bottom_variables.sort((a, b) => Number(b.value) - Number(a.value)).slice(0, 3).map(({
                                                                                                                                             name,
                                                                                                                                             value
                                                                                                                                         }, idx) => (

                        <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mb-4">
                            <div
                                className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center min-w-[350px]">
                                <div className="green-box mr-4"></div>
                                {name
                                    ?.replace(/_/g, ' ')
                                    ?.replace(/\b\w/g, char => char.toUpperCase())}:
                            </div>

                            <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                                <InputField
                                    labelClassName={'font-semibold text-[#6F6F6F]'}
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                                    value={value}
                                    disabled={true}
                                />
                            </div>

                        </div>
                    ))}
                </div>

                <div className="pb-4 flex flex-col gap-7">
                    <span className="block text-3xl font-bold">AI Recommendations:</span>
                    {
                        aiReacomendation?.length ? aiReacomendation.map((item, index) => (
                                <div className="flex items-center" key={index}>
                                    <div className="lg:text-xl text-base font-bold w-[240px]">
                                        {item?.label}
                                    </div>
                                    <div className="flex items-center gap-3">
									<span
                                        className={`${item?.hire ? "bg-[#37B34A]" : "bg-[#B2B2B2]"} rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center`}
                                    >
										<span className="font-bold text-[#FFFFFF]">Hire</span>
									</span>
                                        <span
                                            className={`${!item?.hire ? "bg-[#37B34A]" : "bg-[#B2B2B2]"} rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center`}
                                        >
										<span className="font-bold text-[#FFFFFF]">Not Hire</span>
									</span>
                                    </div>

                                </div>
                            ))
                            : null
                    }
                </div>
                <div className="pb-[60px]">
                    <span className="block text-3xl font-bold mb-7">Final Recommendation:</span>
                    <div className="flex items-center mt-7">
                        <div className="flex items-center gap-3">
                            <label
                                className={`${finalRecommendation ? "bg-[#37B34A]" : "bg-[#B2B2B2]"} rounded-[4px] w-24 cursor-pointer lg:w-[120px] h-[54px] flex items-center justify-center`}
                            >
                                <input
                                    type="radio"
                                    hidden
                                    className="hidden"
                                    checked={finalRecommendation}
                                    name="finalRecommendation"
                                    onChange={(e) => handleSave(true)}
                                >
                                </input>
                                <span className="font-bold text-[#FFFFFF]">Hire</span>
                            </label>
                            <div className="italic text-[#999999] font-light">or</div>
                            <label
                                className={`${!finalRecommendation ? "bg-[#37B34A]" : "bg-[#B2B2B2]"} rounded-[4px] w-24 cursor-pointer lg:w-[120px] h-[54px] flex items-center justify-center`}
                            >
                                <input
                                    type="radio"
                                    hidden
                                    checked={!finalRecommendation}
                                    className="hidden"
                                    name="finalRecommendation"
                                    onChange={(e) => handleSave(false)}
                                >
                                </input>
                                <span className="font-bold text-[#FFFFFF]">Not Hire</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <NextStepSectionSBM01 prev={handlePrev} next={handleNext}/>
        </div>
    )
}


export default FinalHiringDecision

import React, {useId} from "react";
import {Tooltip} from "react-tooltip";
import { ReactComponent as SettingsIcon } from "../../assets/img/settings.svg";

export const ButtonOptions = ({ options, className }) => {
    const id = useId()
    // console.log(id);
    return (
        <div className={`relative ${className ? className : ""}`}>
            <SettingsIcon name={`my-anchor-element-${id}`} className='cursor-pointer' />
            <Tooltip
                anchorSelect={`[name^='my-anchor-element-${id}']`}
                clickable
                openOnClick
                noArrow
                style={{
                    width: 280,
                    borderRadius: 12,
                    padding: 0,
                    background: 'white',
                    boxShadow: '0px 4px 8px 0px #5B68713D, 0px 0px 1px 0px #1A202452',
                    marginTop: -15,
                    opacity: 1,
                    zIndex: 50
                }}
                // isOpen
                place='right-start'
                offset={14}
                opacity='inherit'
            >
                <ul>
                    {options.map((option, idx) => (
                        <li key={idx}>
                            {
                                option?.loadFile ? <label className={`flex items-center text-gray-700 ${idx === options.length - 1 ? "border-none" : ""} border-b border-solid 
                                    border-[#D6D6D6] h-[54px] block w-full px-7 text-left py-1
                                    text-sm hover:bg-gray-100 hover:text-gray-900`}
                                    role="menuitem"
                                    tabIndex="-1"
                                    id="menu-item-0"
                                >
                                    <input type="file" name="" id="" className="hidden" onChange={(e)=>{
                                        option?.handler && option.handler(e);
                                    }} />
                                    { option.label }
                                </label> :
                                <button
                                    className={`text-gray-700 ${idx === options.length - 1 ? "border-none" : ""} border-b border-solid 
                                    border-[#D6D6D6] h-[54px] block w-full px-7 text-left py-1
                                    text-sm hover:bg-gray-100 hover:text-gray-900`}
                                    role="menuitem"
                                    tabIndex="-1"
                                    id="menu-item-0"
                                    onClick={() => {
                                        option?.handler && option.handler()
                                    }}
                                >
                                    {option.label}
                                </button>
                            }
                        </li>
                    ))}
                </ul>
            </Tooltip>
        </div>
    );
}
import React, { useState, useEffect } from "react";
import SelectBox from "../base/SelectBox";
import ModalTemplate from '../base/ModalTemplate';
import Button from "../base/Button";
import { ReactComponent as CalendarIcon } from "../../assets/img/calendar-green-icon.svg"
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { getApplicantsFetch, getCandidatesFetch } from "../../redux/thunks/jobsThunks";
import api from "../../api";
import { toast } from 'react-toastify';
import PageLoader from "../PageLoader";

const RejectCandidate = ({ open, rejectPopupPayload, handleClose, footerBorderTop = true }) => {
    const dispatch = useDispatch();
    const [payload, setPayload] = useState({
        reason: null,
        dateOption: null,
        date: null
    });
    const [rejectReasons, setRejectReasons] = useState([])
    const [isRejectReasonsLoading, setIsRejectReasonsLoading] = useState(false)

    const rejectDates = [
        'Now',
        'Tomorrow at 9am',
        'In 2 Days at 9am',
    ].map(item => ({ label: item, value: item }))

    const actionReject = async () => {
        const res = await api.jobApplications.setApplicationStatus(rejectPopupPayload.jobId, rejectPopupPayload.applicationId, {
            status: 'rejected',
            reason: payload.reason,
            date: payload.date.toISOString(),
        });

        if (res?.success) {
            dispatch(getCandidatesFetch(rejectPopupPayload.jobId));
            dispatch(getApplicantsFetch(rejectPopupPayload.jobId));

            if (res?.message) {
                toast.success(res?.message);
            }

            handleClose();
        } else {
            toast.error(res?.message);
        }
    }

    useEffect(() => {
        let dateValue = new Date;

        switch (payload.dateOption) {
            case "Tomorrow at 9am":
                dateValue = new Date(`${new Date().toISOString().slice(0, 10)}T09:00:00`);
                dateValue.setDate(dateValue.getDate() + 1);
                break;
            case "In 2 Days at 9am":
                dateValue = new Date(`${new Date().toISOString().slice(0, 10)}T09:00:00`);
                dateValue.setDate(dateValue.getDate() + 2);
                break;
            default:
                break;
        }

        setPayload((prev) => ({
            ...prev,
            date: dateValue
        }));
    }, [payload.dateOption])

    const getRejectionReasons = async () => {
        setIsRejectReasonsLoading(true)
        const res = await api.jobApplications.getRejectionReasons()
        if (res?.data) {
            setRejectReasons(res.data)
        }
        setIsRejectReasonsLoading(false)
    }
    useEffect(() => {
        getRejectionReasons()
    }, [])

    return (
        <ModalTemplate open={open} onClick={handleClose} >
            <div className="modal__header">
                <h2 className="text-[20px] font-bold">Reject this candidate</h2>
                <button className="btn btn-close" onClick={handleClose}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            {
                !isRejectReasonsLoading ? (
                    <>
                        <div className="modal__body">
                            <div className="w-full">
                                <div className="flex flex-col gap-[16px] mb-[32px]">
                                    <label className="text-[16px]">Rejection reason</label>
                                    <SelectBox
                                        options={
                                            rejectReasons?.map(item => ({
                                                label: item.text,
                                                value: item.id
                                            }))
                                        }
                                        value={payload.reason}
                                        onSelect={(value) => {
                                            setPayload((prev) => ({
                                                ...prev,
                                                reason: value
                                            }))
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col gap-[16px]">
                                    <label className="text-[16px]">When to send email</label>
                                    <SelectBox
                                        options={[...rejectDates, {
                                            className: 'flex items-center gap-[8px] border-t border-[#DEDED9]',
                                            customOption: () => {
                                                return <OptionDatePicker handleSelectOption={(value) => {
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        date: value
                                                    }))
                                                }} />
                                            }
                                        }]}
                                        value={payload.dateOption}
                                        onSelect={(value) => {
                                            setPayload((prev) => ({
                                                ...prev,
                                                dateOption: value
                                            }))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                            <Button className={'text-gray-400 min-w-[122px] min-h-[52px] text-[18px] hover:opacity-70'} onClick={handleClose} >
                                <span className="underline">Cancel</span>
                            </Button>
                            <Button className={'text-white bg-green-500 min-w-[122px] min-h-[52px] text-[18px] hover:opacity-70'} onClick={(e) => {
                                e.preventDefault();
                                actionReject();
                            }}>Submit</Button>
                        </div>
                    </>
                ) : (
                    <PageLoader />
                )
            }
        </ModalTemplate>
    )
};

const OptionDatePicker = ({ handleSelectOption }) => {
    return <label className="flex items-center w-full cursor-pointer">
        <input
            type="datetime-local"
            className="size-0"
            onFocus={(e) => e?.target?.showPicker()}
            onBlur={(e) => {
                if (!e.target.value) return;
                // handleSelectOption(format(e.target.value, 'dd-MM-yyyy - hh:mm aa'))
                handleSelectOption(new Date(e.target.value));
            }}
        // onChange={(e) => console.log(e.target.value)}
        />
        <CalendarIcon className="mr-[8px]" />
        Pick a Date & Time
    </label>
}

export default RejectCandidate
const pagesBase = 'api/pages';

export const pages = (instance) => ({
    async getPages() {
        const { data } = await instance.get(`${pagesBase}/public`);
        return data;
    },
    async getPageByPath(path) {
        const { data } = await instance.get(`${pagesBase}/by-path?path=${path}`);
        return data;
    },
});
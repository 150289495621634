import {NavLink} from "react-router-dom";
import polygonIcon from "../../../assets/img/home/polygon.svg";

export const CandidateCard = ({
    image,
    status,
    label,
    name,
    position,
    description,
    experience,
    experienceTitle,
    skills = [],
    skillsTitle
}) => {
    return (
        <div className="flex w-full relative text-black font-['Raleway']">
            <div
                className="max-w-[503px] flex flex-col rounded-[18px] bg-white shadow-[0px_0px_31.15px_0px_rgba(0,0,0,0.15)]">
                <div className="px-[27px] py-5 mt-[37px] border-t-[#707072] border-t-[0.3px]">
                    <div className="flex w-full justify-between mb-[16px]">
                        <img src={image} alt={name} className="w-[75px] k-[103px]"/>
                        <div className="flex flex-col gap-[5px] items-center">
                            <div
                                className="flex justify-center items-center text-[12px] font-bold bg-[#37B34A] rounded-[3px] px-[10px] py-[5px] text-white w-fit h-fit">
                                {status}
                            </div>
                            <span className="font-semibold text-[9px]">{label}</span>
                        </div>
                    </div>
                    <p className="text-[40px] font-extrabold">{name}</p>
                    <p className="text-[28px] font-normal">{position}</p>
                    <p className="text-[12px] font-medium mt-[10px] mb-[16px]">{description}</p>
                    <div className="flex gap-[22px]">
                        <div className="flex flex-col">
                            <p className="flex items-center">
                                <img src={polygonIcon} alt="polygonIcon" className="mr-2"/>
                                <span className="font-bold text-[15px]">{experienceTitle || 'Experience'}</span>
                            </p>
                            <span className="ml-5 font-medium text-[12px]">{experience}</span>
                        </div>

                        <div className="flex flex-col">
                            <p className="flex items-center">
                                <img src={polygonIcon} alt="polygonIcon" className="mr-2"/>
                                <span className="font-bold text-[15px]">{skillsTitle || 'Skills'}</span>
                            </p>
                            <span className="ml-5 font-medium text-[12px]">{skills.map(skill => skill).join(', ')}</span>
                        </div>
                    </div>
                    <div className="my-[22px] flex gap-[14px]">
                        <NavLink
                            to="/HOM1_5"
                            className="flex justify-center items-center font-bold text-[12px] p-[8px] bg-[#007BFF] hover:opacity-90 rounded-md text-white w-fit h-fit">
                            View Profile
                        </NavLink>
                        <NavLink
                            to="/HOM1_5"
                            className="flex justify-center items-center font-semibold text-[12px] p-[8px] bg-[#37B34A]  hover:opacity-90 rounded-md text-white w-fit h-fit">
                            Shortlist Candidate
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React from 'react'
import blocksImage from "../../../assets/img/home/blocks.png";
import heroImage from "../../../assets/img/home/hero.png";
import defaultImage from "../../../assets/img/home/default.png";
import {NavLink} from "react-router-dom";
import {Navigation, Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../../../assets/css/Swiper/styles.css';
import getImageUrl from "../../../assets/helpers/getImageUrl";

const HeroWithSlider = ({
                            topSpacing = 'pt-[56px]',
                            className = '',
                            header = "",
                            description = "",
                            buttons = null,
                            withText = "max-w-[950px]",
                            images = null,
                            children,
                        }) => {
    return (
        <section
            className={`bg-top bg-no-repeat font-['Raleway'] ${topSpacing} max-md:pt-[55px] ${className} border-b-[1px] border-black`}
            style={{backgroundImage: `url(${blocksImage})`}}
        >
            <div className="container">
                <div className="flex flex-col items-center text-center gap-[17px] text-[18px] mb-[24px] relative">
                    {header && (
                        <h3
                            className={`text-5xl max-md:text-[32px] font-bold ${withText}`}
                            dangerouslySetInnerHTML={{__html: header}}
                        />
                    )}

                    {description && <p dangerouslySetInnerHTML={{__html: description}}></p>}
                    {buttons && <div className="flex gap-4">
                        {buttons?.map((button, index) => (
                            button?.text && <NavLink key={index} to={button?.link}
                                                      className={`flex justify-center items-center font-semibold text-[20px] max-md:text-sm p-[10px] ${button?.bgColor || "bg-[#36B34A"} hover:opacity-85 rounded-md text-white w-fit h-fit`}>
                                {button?.text}
                            </NavLink>
                            ))}
                    </div>}
                    {children}
                </div>
                <div className="pt-[35px] pb-[28px]">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView="5"
                        autoplay={{delay: 0, pauseOnMouseEnter: true}}
                        speed={5000}
                        loop={true}
                        modules={[Autoplay, Navigation]}
                        className="swiper-custom"
                        breakpoints={{
                            0: {slidesPerView: 1,},
                            640: {slidesPerView: 3,},
                            1024: {slidesPerView: 5,},
                        }}
                    >
                        {images?.data?.map((img, idx) => (
                            <SwiperSlide key={idx}>
                                <img src={getImageUrl(img.corusel_item_image)} alt="default" className="h-[82px]"/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default HeroWithSlider

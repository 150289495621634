const supportBase = 'api';

export const support = (instance) => ({
    async pages(pageId = '') {
        const { data } = await instance.get(`${supportBase}/pages/${pageId}?limit=100`);
        if (data?.data && data.data?.token) {
            instance.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${data.data.token}`
                return config;
            })


        }
        return data;
    },
    async updatePagePreset(payload) {
        const { data } = await instance.put(`${supportBase}/page-presets/${payload.presetId}`, payload.body);
        if (data?.data && data.data?.token) {
            instance.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${data.data.token}`
                return config;
            })


        }
        return data;
    },
    async updatePageData(payload) {
        const { data } = await instance.put(`${supportBase}/pages/${payload.pageId}`, payload.body);
        if (data?.data && data.data?.token) {
            instance.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${data.data.token}`
                return config;
            })
        }
        return data;
    },
});

export const supportSolutions = (instance) => ({
    async getSupSolPages() {
        const { data } = await instance.get(`api/support-solution-pages`);
        return data
    },

    async getSupSolCategories(payload) {
        const { data } = await instance.get(`api/support-solution-categories`);
        return data
    },

    async getSupSolOnePage(id) {
        const { data } = await instance.get(`api/support-solution-pages/${id}`);
        return data
    },

    async createSupSolPage(payload) {
        const { data } = await instance.post(`/api/support-solution-pages`, payload)
        return data;
    },

    async updateSupSolPage(payload, id) {
        const { data } = await instance.put(`/api/support-solution-pages/${id}`, payload)
        return data;
    },

    async deleteSupSolPage(id) {
        const { data } = await instance.delete(`/api/support-solution-pages/${id}`);
        return data;
    },

    async createSupSolCategory(payload) {
        const { data } = await instance.post(`api/support-solution-categories`, payload)
        return data;
    },

    async getSupSolTags() {
        const { data } = await instance.get(`/api/support-solution-tags`);
        return data
    },

    async createSupSolTag(payload) {
        const { data } = await instance.post(`api/support-solution-tags`, payload)
        return data;
    }

})
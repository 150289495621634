import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from 'react';

const AvailabilityInput = ({ label, value, onChange, min=null, max=null }) => {
    const [selectedTime, setSelectedTime] = useState(value);

    const getDate = (time) => new Date(`01/01/1970 ${time}`);

    const handleUnavailable = () => {
        setSelectedTime(null);
        onChange(null);
    };

    return (
        <div className='flex flex-col gap-1'>
            <div className="flex justify-start relative border-[#DEDED9] border bg-white border-solid rounded-md h-[64px] max-md:w-[50%] cursor-pointer">
                <DatePicker
                    wrapperClassName='!flex'
                    className="text-lg px-[18px] time-picker bg-transparent text-[#333333] outline-none h-full w-full lg:w-[190px] cursor-pointer"
                    selected={selectedTime ? getDate(selectedTime) : null}
                    onChange={(date) => {
                        const time = format(date, "hh:mm a");
                        setSelectedTime(time);
                        onChange(time);
                    }}
                    minTime={min ? getDate(min) : new Date('01/01/1970 00:00')}
                    maxTime={max ? getDate(max) : new Date('01/01/1970 23:59')}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    timeCaption="Time"
                    dateFormat="h:mm aaa"
                    placeholderText='Not available'
                />

                {/* <input
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        type="time"
                        className="indicator-hide text-lg px-[18px] bg-transparent text-[#333333] outline-none h-full w-full lg:w-[190px] cursor-pointer"
                    /> */}
                <span className="absolute top-[-5.5px] bg-white leading-[11px] whitespace-nowrap lg:px-[8px] left-1/2 -translate-x-1/2 text-[14px] cursor-default">{label}</span>
            </div>
            {
                value && <button className='bg-slate-100 px-2 py-1 rounded-sm' onClick={handleUnavailable}>Make not available</button>
            }
        </div>
    );
}

export default AvailabilityInput;

import React from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { useState } from "react";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import { Footer } from "../components/parts/Footer";


import { ReactComponent as EyeSlashIcon } from "../assets/img/EyeSlash.svg"
const RadioButton = ({ label, id, name, onChange, checked, value }) => {
    return (
        <label
            className="flex gap-2.5 pr-5 cursor-pointer items-center"
        >
            <span
                className="flex flex-col justify-center items-center px-1 border border-solid bg-stone-50 border-stone-300 h-[18px] rounded-[360px] w-[18px] cursor-pointer"
            >
                {checked && (
                    <div className="shrink-0 w-2.5 h-2.5 bg-green-500 rounded-[360px]" />
                )}
                <input
                    className="hidden"
                    type="radio"
                    name={name}
                    id={id}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                />
            </span>
            <div className="my-auto text-lg font-semibold text-zinc-800">{label}</div>
        </label>
    )
};

const SBM05_3 = () => {
    const [formData, setFormData] = useState({
        currentEmail: 'hello@freshtechmaids.com',
        newEmail: 'hello@freshtechmaids.com',
        confirmEmail: 'hello@freshtechmaids.com',
        firstName: '',
        lastName: '',
        role: '',
        currentPassword: '•••••••••••••',
        newPassword: '',
        confirmPassword: '',
        address: '',
        city: '',
        postalCode: '',
        phone: '',
    });

    const roleOptions = [
        {
            id: 1,
            name: "Committee Member",
            // description: "Best for interviewers",
        },
        {
            id: 2,
            name: "Best for interviewers",
        },
    ];
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validateCardNumber = (number) => {
        const re = /^\d{16}$/;
        return re.test(number.replace(/\s+/g, ''));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Reset errors
        setErrors({});

        let tempErrors = {};

        if (!validateEmail(formData.email)) {
            tempErrors.email = 'Invalid email format';
        }

        if (!validateCardNumber(formData.cardNumber)) {
            tempErrors.cardNumber = 'Invalid card number, should be 16 digits';
        }

        // Here you can extend the validations for other fields as needed

        if (Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
        } else {
            // Submit your form
            console.log('Form data:', formData);
        }
    };


    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Committee Member Setting', 'Committee Member Setting Details']} />

            <main className="flex relative flex-col py-[90px] px-4 z-[1] max-w-[1432px] w-full m-auto flex-1 max-md:pt-[40px] max-md:pb-[80px]">
                <div className="flex flex-col justify-center bg-white rounded-md shadow-lg">
                    <header className="justify-center items-center px-16 py-9 w-full text-3xl font-bold text-white uppercase whitespace-nowrap bg-[#003578] max-md:px-5 max-md:max-w-full max-md:py-[12px] max-md:text-[18px] max-md:text-center max-md:rounded-t-[10px]">
                        Contact info
                    </header>
                    <main className="flex flex-col px-20 py-16 w-full bg-white max-md:px-5 max-md:max-w-full max-md:px-[32px] max-md:py-[36px]">
                        <h2 className="pb-6 text-3xl mb-12 font-bold whitespace-nowrap border-b-2 border-green-500 border-solid text-zinc-800 max-md:max-w-full max-md:text-[24px] max-md:leading-[29px] max-md:mb-[36px]">
                            Name
                        </h2>
                        <section className="grid md:grid-cols-2 gap-8 max-md:gap-[0px]">
                            <div>
                                <InputField
                                    label={<p>Current <br className="max-md:hidden" /> Email</p>}
                                    labelClassName={"min-w-[100px] text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                    parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:gap-[12px]"
                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                    type="email"
                                    name="currentEmail"
                                    id="currentEmail"
                                    placeholder="youremailaddress@mail.com"
                                    value={formData.currentEmail}
                                    onChange={handleChange}
                                    error={errors.currentEmail}
                                    disabled={true}
                                />
                            </div>
                            <button className="justify-center px-12 items-center h-[54px] font-semibold text-white whitespace-nowrap bg-red-600 rounded-md w-fit max-md:w-full hover:opacity-70">
                                Change
                            </button>
                        </section>
                        <section className="grid md:grid-cols-2 gap-8 mt-[10px] max-md:mt-[26px]">
                            <div>
                                <InputField
                                    label={<p>New <br className="max-md:hidden" /> Email</p>}
                                    labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                    parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:m-[0px]"
                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                    type="email"
                                    name="newEmail"
                                    id="newEmail"
                                    placeholder="youremailaddress@mail.com"
                                    value={formData.newEmail}
                                    onChange={handleChange}
                                    error={errors.newEmail}
                                />
                            </div>
                            <div>
                                <InputField
                                    label={<p>Confirm <br className="max-md:hidden" /> Email</p>}
                                    labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                    parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start"
                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                    type="email"
                                    name="confirmEmail"
                                    id="confirmEmail"
                                    placeholder="youremailaddress@mail.com"
                                    value={formData.confirmEmail}
                                    onChange={handleChange}
                                    error={errors.confirmEmail}
                                />
                            </div>
                        </section>
                        <div className="flex gap-3 justify-center self-start mt-[10px] text-lg font-semibold whitespace-nowrap max-md:flex-col max-md:w-full">
                            <button className="grow justify-center px-9 items-center h-[54px] text-white bg-green-500 rounded-md max-md:px-5 hover:opacity-70">
                                Save Changes
                            </button>
                            <button className="grow justify-center px-12 items-center h-[54px] rounded-md bg-zinc-100 text-zinc-800 max-md:px-5 hover:opacity-70">
                                Cancel
                            </button>
                        </div>
                        <section className="grid md:grid-cols-2 gap-8 mt-[50px] max-md:gap-[26px]">
                            <div className="">
                                <InputField
                                    label={"Name"}
                                    labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                    parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:m-[0px]"
                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="youremailaddress@mail.com"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    error={errors.firstName}
                                />
                            </div>
                            <div>
                                <InputField
                                    parentClassName="!flex-row items-center"
                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    placeholder="youremailaddress@mail.com"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    error={errors.lastName}
                                />
                            </div>
                        </section>
                        <section className="flex gap-[16px] justify-between mt-[10px] max-md:flex-col">
                            <label className="flex min-w-[100px] md:h-[70px] items-center md:text-right md:justify-end text-lg font-bold leading-6">
                                Role
                            </label>
                            <div className="flex max-md:flex-col grow shrink-0 justify-start basis-0 w-fit max-md:max-w-full max-md:w-full">
                                {roleOptions.map((option, index) => (
                                    <div
                                        key={option.id}
                                        className={`flex gap-5 md:h-[70px] md:justify-between items-center px-6 border-solid border-zinc-300 max-md:px-[0px] max-md:gap-[12px] max-md:py-[16px] ${index === 0 ? 'border-t' : 'md:border-t'}`}
                                    >
                                        <RadioButton
                                            checked={option.name.toLocaleLowerCase() === formData.role}
                                            onChange={() => setFormData(prevState => ({ ...prevState, role: option.name.toLocaleLowerCase() }))}
                                        />
                                        <div className={`min-w-44 self-stretch my-auto text-lg text-zinc-800 max-md:min-w-[initial] max-md:w-full ${option.name.toLocaleLowerCase() === formData.role ? 'font-semibold' : ''}`}>
                                            {option.name}
                                        </div>
                                        {/* <div className="flex-auto self-stretch my-auto text-lg text-zinc-800 max-md:max-w-full max-md:w-full max-md:text-[16px] max-md:leading-[19px]">
                                            {option.description}
                                        </div> */}
                                    </div>
                                ))}
                            </div>
                        </section>
                        <section className="grid md:grid-cols-2 gap-8 max-md:gap-[12px]">
                            <div>
                                <InputField
                                    label={<p>Current <br className="max-md:hidden" /> Password</p>}
                                    labelClassName={"min-w-[100px] text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                    parentClassName="!flex-row items-center relative max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                    type="password"
                                    name="currentPassword"
                                    id="currentPassword"
                                    placeholder="youremailaddress@mail.com"
                                    value={formData.currentPassword}
                                    onChange={handleChange}
                                    error={errors.currentPassword}
                                    disabled={true}
                                    afterLabel={<button className="absolute right-4 z-[1] bottom-[15px]"><EyeSlashIcon /></button>}
                                />
                            </div>
                            <div className="flex gap-3 max-md:flex-col">
                                <button className="justify-center md:px-12 items-center h-[54px] font-semibold text-white whitespace-nowrap bg-red-600 rounded-md md:w-fit max-md:w-full hover:opacity-70">
                                    Change
                                </button>
                                <button className="justify-center md:px-12 items-center h-[54px] font-semibold text-white whitespace-nowrap rounded-md md:w-fit bg-sky-900 max-md:w-full hover:opacity-70">
                                    2-Factor Authentication
                                </button>
                            </div>
                        </section>
                        <section className="grid md:grid-cols-2 gap-8 mt-[10px] max-md:mt-[26px] max-md:gap-[26px]">
                            <div>
                                <InputField
                                    label={<p>New <br className="max-md:hidden" /> Password</p>}
                                    labelClassName={"min-w-[100px] text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                    parentClassName="!flex-row items-center relative max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    placeholder=""
                                    value={formData.newPassword}
                                    onChange={handleChange}
                                    error={errors.newPassword}
                                    afterLabel={<button className="absolute right-4 z-[1] bottom-[15px]"><EyeSlashIcon /></button>}
                                />
                            </div>
                            <div>
                                <InputField
                                    label={<p>Confirm <br className="max-md:hidden" /> Password</p>}
                                    labelClassName={"min-w-[100px] text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                    parentClassName="!flex-row items-center relative max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                    type="password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    placeholder=""
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    error={errors.confirmPassword}
                                    afterLabel={<button className="absolute right-4 z-[1] bottom-[15px]"><EyeSlashIcon /></button>}
                                />
                            </div>
                        </section>
                        <div className="flex gap-3 justify-center self-start mt-7 text-lg font-semibold whitespace-nowrap max-md:flex-col max-md:w-full">
                            <button className="grow justify-center px-9 py-5 text-white bg-green-500 rounded-md max-md:px-5 max-md:py-3 hover:opacity-70">
                                Save Changes
                            </button>
                            <button className="grow justify-center px-12 py-5 rounded-md bg-zinc-100 text-zinc-800 max-md:px-5 max-md:py-3 hover:opacity-70">
                                Cancel
                            </button>
                        </div>
                        <h2 className="pb-6 mt-12 text-3xl font-bold whitespace-nowrap border-b-2 border-green-500 border-solid text-zinc-800 max-md:mt-10 max-md:max-w-full max-md:text-[24px] max-md:pb-[24px] max-md:mt-[36px] max-md:leading-[29px]">
                            Address
                        </h2>
                        <section className="grid md:grid-cols-1 gap-8 mt-[50px] max-md:mt-[36px]">
                            <InputField
                                label={<p>Address</p>}
                                labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                type="text"
                                name="address"
                                id="address"
                                placeholder=""
                                value={formData.address}
                                onChange={handleChange}
                                error={errors.address}
                            />
                        </section>
                        <section className="grid md:grid-cols-3 gap-8 mt-[10px] max-md:gap-[16px]">
                            <div className="flex gap-4">
                                <div className="min-w-[100px] max-md:hidden"></div>
                                <InputField
                                    labelClassName={"hidden"}
                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                    parentClassName="max-md:m-[0px]"
                                    type="text"
                                    name="city"
                                    id="city"
                                    placeholder="City"
                                    value={formData.city}
                                    onChange={handleChange}
                                    error={errors.city}
                                />
                            </div>
                            <SelectField
                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                parentClass="max-md:m-[0px]"
                                options={[{ label: "State 1", value: "State 1" }, { label: "State 3", value: "State 3" }]}
                            />
                            <InputField
                                labelClassName={"hidden"}
                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                type="text"
                                name="postalCode"
                                id="postalCode"
                                placeholder="Zip Code"
                                value={formData.postalCode}
                                onChange={handleChange}
                                error={errors.postalCode}
                            />
                        </section>
                        <section className="grid md:grid-cols-1 gap-8 mt-[10px]">
                            <InputField
                                label={<p>Phone</p>}
                                labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] max-w-[484px]"
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder=""
                                value={formData.phone}
                                onChange={handleChange}
                                error={errors.phone}
                            />
                        </section>
                        <section className="flex gap-5 justify-between mt-7 w-full text-lg font-semibold whitespace-nowrap max-md:flex-wrap max-md:max-w-full max-md:flex-col max-md:gap-[12px]">
                            <div className="flex gap-3 justify-center max-md:flex-col">
                                <button className="grow justify-center px-9 py-5 text-white bg-green-500 rounded-md max-md:px-5 max-md:py-3 hover:opacity-70">
                                    Save Changes
                                </button>
                                <button className="grow justify-center px-12 py-5 rounded-md bg-zinc-100 text-zinc-800 max-md:px-5 max-md:py-3 hover:opacity-70">
                                    Cancel
                                </button>
                            </div>
                        </section>
                    </main>
                </div>
            </main>

            <Footer hideCopyright={true} />
        </>
    );
};

export default SBM05_3;
import { AboutBlock } from "./AboutBlock";
import InfoSection from "./InfoSection";
import React, { useMemo } from "react";
import whyUsImage from "../../../assets/img/whyUs.png";
import findValue from "../../../assets/helpers/findValue";
import getImageUrl from "../../../assets/helpers/getImageUrl";

const generateData = (blocks) => {
    return {
        whyChoose: {
            title: findValue(blocks, "why_label"),
            header: findValue(blocks, "why_title"),
            description: [findValue(blocks, "why_text")],
            button: findValue(blocks, "why_button"),
            benefits: (findValue(blocks, "why_list")?.data || []).map(item => ({
                title: item.title,
                text: item.text
            })),
            img: findValue(blocks, "why_image"),
        },
    };
};

export const WhyChose = ({ spacing = "pb-[100px] max-md:pb-[80px]", withBlock = false, blocks }) => {
    const data = useMemo(() => generateData(blocks), [blocks]);

    return (
        <InfoSection
            background={"bg-white"}
            topSpacing={spacing}
        >
            <img src={getImageUrl(data.whyChoose.img)} alt="Why Us" className="w-full lg:w-[620px] max-md:w-[80%] m-auto h-auto wow fadeInLeft" data-wow-delay={"100ms"} />

            <AboutBlock
                title={data.whyChoose.title}
                header={data.whyChoose.header}
                description={data.whyChoose.description}
                headerStyle={"max-w-[750px]"}
                button={data.whyChoose.button}
            >
                <div className="flex flex-col gap-4 lg:gap-[14px] my-4 w-full">
                    {data.whyChoose.benefits.map((item, index) => (
                        <div key={index}
                            className="flex items-center gap-4 lg:gap-5 px-4 py-3 bg-[#EBF0F3] rounded-xl max-w-full lg:max-w-[100%]"
                        >
                            <div className="min-w-[43px] w-[43px] h-[43px] bg-white rounded-full flex justify-center items-center text-lg lg:text-[31px] font-medium">
                                {index + 1}
                            </div>
                            <div>
                                <p className="text-sm lg:text-lg font-semibold mb-2">{item.title}</p>
                                <span className="text-xs lg:text-sm font-medium">{item.text}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </AboutBlock>
        </InfoSection>
    );
};

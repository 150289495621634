import React, {useState} from "react";

import BreadCrumbWhite from "../../../../components/BreadCrumbWhite";
import SelectField from "../../../../components/base/SelectField";
import {Footer} from "../../../../components/parts/Footer";
import {HeroSectionSBM01} from "../../base/HeroSectionSBM01";

export const FirstInterviewVideo = ({crumbs, onNext, onBack}) => {
    const [interviewer, setInterviewer] = useState()
    const interviewers = [
        {
            value: "interviewer_01",
            label: "Interviewer 01"
        },
        {
            value: "interviewer_02",
            label: "Interviewer 02"
        },
    ]
    const handleSelect = (value) => {
        setInterviewer(value)
    }
    return (
        <>
            <BreadCrumbWhite
                crumbs={crumbs} />
            <section className='flex flex-col w-full flex-auto pt-10 pb-20 lg:pt-20 lg:pb-24 mx-auto max-w-[1440px] px-5'>
                <HeroSectionSBM01 />

                <div className="flex max-lg:flex-col justify-center gap-[12px] md:gap-[32px] self-stretch w-full">
                    <div className="my-auto font-semibold text-zinc-800 text-[26px] max-md:text-[16px]">
                        Assign to
                    </div>
                    <div className='w-full max-w-[560px]'>
                        <SelectField
                            inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 w-full"
                            name="department"
                            id="department"
                            parentClass=''
                            value={interviewer}
                            onChange={handleSelect}
                            options={interviewers}
                        />
                    </div>
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}
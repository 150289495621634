import React, { useState, useRef, useEffect } from 'react'
import useClickOutside from '../customHooks/useClickOutside'
import { createPortal } from 'react-dom';


const DropDown = ({ children, disabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropSytle, setDropStyle] = useState({})
    const dropRef = useRef()
    const dropdownNode = useClickOutside(() => {
        setIsOpen(false);
    })
    const handler = () => setIsOpen(!isOpen)
    useEffect(() => {
        if (!isOpen) {
            setDropStyle({})
            return 
        }
        const rect = dropdownNode.current.getBoundingClientRect()
        const dropList = parseFloat(getComputedStyle(dropRef.current, null).getPropertyValue('width'))
        setDropStyle( {
            top: rect.top + rect.height,
            left: rect.right - dropList
        })
        window.addEventListener('scroll', handler)
        return () => { window.removeEventListener('scroll', handler)}
    }, [isOpen])
    return (
        <div className={`dropdown ${isOpen ? 'active' : ''}`} ref={dropdownNode}>
            <button type='button' onClick={handler} disabled={!disabled} className={`flex items-center gap-2.5 justify-center px-3.5 py-2 rounded-md border border-solid hover:bg-gray-100 bg-${disabled ? 'white' : '[#F4F4F3]'} border-zinc-300`}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.97938 19.75L7.58721 16.617C7.24127 16.5123 6.86752 16.3483 6.46596 16.1252C6.06369 15.9013 5.72135 15.6615 5.43896 15.4058L2.54971 16.6354L0.529297 13.1146L3.02638 11.2318C2.9946 11.0353 2.96824 10.8331 2.9473 10.6251C2.92707 10.4178 2.91696 10.2163 2.91696 10.0206C2.91696 9.83858 2.92707 9.64719 2.9473 9.44642C2.96752 9.24564 2.99388 9.01958 3.02638 8.76825L0.529297 6.88542L2.54971 3.40575L5.41838 4.61583C5.74194 4.34644 6.09221 4.10342 6.46921 3.88675C6.84477 3.67008 7.21057 3.50253 7.56663 3.38408L7.9783 0.25H12.0202L12.4124 3.40467C12.8277 3.54983 13.1945 3.71703 13.513 3.90625C13.8323 4.09547 14.1605 4.33164 14.4978 4.61475L17.4499 3.40575L19.4703 6.88542L16.8898 8.83108C16.949 9.05497 16.9822 9.26008 16.9895 9.44642C16.9967 9.6342 17.0003 9.81872 17.0003 10C17.0003 10.1683 16.9931 10.3456 16.9786 10.5319C16.9649 10.719 16.9331 10.945 16.8833 11.2101L19.4226 13.1146L17.4022 16.6354L14.4978 15.3853C14.1605 15.6684 13.8211 15.9114 13.4795 16.1143C13.1371 16.3173 12.7814 16.4776 12.4124 16.5953L12.0202 19.75H7.97938ZM9.97055 12.7083C10.726 12.7083 11.3662 12.4458 11.8913 11.9208C12.4164 11.3957 12.6789 10.7554 12.6789 10C12.6789 9.24456 12.4164 8.60431 11.8913 8.07925C11.3662 7.55419 10.726 7.29167 9.97055 7.29167C9.21077 7.29167 8.56944 7.55419 8.04655 8.07925C7.52366 8.60431 7.26221 9.24456 7.26221 10C7.26221 10.7554 7.52366 11.3957 8.04655 11.9208C8.56944 12.4458 9.21077 12.7083 9.97055 12.7083Z" fill={`${disabled ? '#37B34A' : '#C4C4C4'}`} />
                </svg>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.7795 5.08266C11.7464 5.00271 11.6903 4.93437 11.6184 4.88628C11.5465 4.83819 11.4619 4.81252 11.3753 4.8125L2.62534 4.8125C2.53877 4.81243 2.45411 4.83805 2.3821 4.88612C2.31009 4.93419 2.25396 5.00254 2.22082 5.08253C2.18768 5.16251 2.17902 5.25053 2.19593 5.33545C2.21284 5.42036 2.25456 5.49834 2.31581 5.55953L6.69081 9.93453C6.73145 9.97521 6.7797 10.0075 6.83281 10.0295C6.88592 10.0515 6.94285 10.0628 7.00034 10.0628C7.05784 10.0628 7.11477 10.0515 7.16788 10.0295C7.22099 10.0075 7.26924 9.97521 7.30988 9.93453L11.6849 5.55953C11.746 5.49831 11.7877 5.42033 11.8045 5.33545C11.8214 5.25056 11.8126 5.16259 11.7795 5.08266Z" fill="#999999" />
                </svg>
            </button>
            <div className="dropdown__body" style={dropSytle} ref={dropRef}>
                <ul className='dropdown__body-list'>
                    {children}
                </ul>
            </div>

        </div>

    )
}

export default DropDown
import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import SelectBox from "../../components/base/SelectBox";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";


export const AboutFormSection = ({ payload, setPayload }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        title: "Education",
        firstCategory: "High School:",
        secondCategory: "College:",
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setFormData(prevState => ({ ...prevState, [name]: value }));
        
        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
        console.log(formData);
    }
    const options = [
        // {
        //     label: "Edit the title / texts...",
        //     handler: () => setIsEditing(true)
        // },
        {
            label: "Edit high school section...",
            handler: () => setIsEditing(true)
        },
        {
            label: "Edit colleges section...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.education_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        education_active: !payload.applicationForm.education_active
                    }
                });
            }
        },
        {
            label: "The 2 choice setup is fixed...",
            handler: () => { }
        },
    ]

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => { setIsModal(false); setIsEditing(false) }} formWrapClass="m-auto">
            {/*  className="flex justify-between" */}
            <div>
                <EditableInput
                    className="font-bold lg:text-[26px] text-[24px]"
                    type="text"
                    name="education_title"
                    id="education_title"
                    value={payload.applicationForm.education_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {/* {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton>
                        :
                        <ButtonOptions options={options} />
                } */}
            </div>
            { payload.applicationForm.education_active ? 
                <div className='flex flex-col gap-7'>
                    <div>
                        <EditableInput
                            className="font-bold lg:text-[22px] text-[20px]"
                            type="text"
                            name="education_high_school_title"
                            id="education_high_school_title"
                            value={payload.applicationForm.education_high_school_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <div className="max-w-[560px] max-lg:flex max-lg:flex-col lg:grid lg:grid-cols-3 gap-[16px]">
                            <EditableInput
                                className="grey-input-custom col-span-3 !h-[54px] !py-[13px]"
                                type="text"
                                name="education_high_school_name"
                                id="education_high_school_name"
                                value={payload.applicationForm.education_high_school_name}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <EditableInput
                                className="grey-input-custom !h-[54px] !py-[13px]"
                                type="text"
                                name="education_high_school_city"
                                id="education_high_school_city"
                                value={payload.applicationForm.education_high_school_city}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <div className="min-w-[176px] h-[54px] input-ap">
                                <div className="relative">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute right-[18px] top-[19px] pointer-events-none">
                                        <g clip-path="url(#clip0_236_482)">
                                            <path d="M11.7795 5.08266C11.7464 5.00271 11.6903 4.93437 11.6184 4.88628C11.5465 4.83819 11.4619 4.81252 11.3753 4.8125H2.62534C2.53877 4.81243 2.45411 4.83805 2.3821 4.88612C2.31009 4.93419 2.25396 5.00254 2.22082 5.08253C2.18768 5.16251 2.17902 5.25053 2.19593 5.33545C2.21284 5.42036 2.25456 5.49834 2.31581 5.55953L6.69081 9.93453C6.73145 9.97521 6.7797 10.0075 6.83281 10.0295C6.88592 10.0515 6.94285 10.0628 7.00034 10.0628C7.05784 10.0628 7.11477 10.0515 7.16788 10.0295C7.22099 10.0075 7.26924 9.97521 7.30988 9.93453L11.6849 5.55953C11.746 5.49831 11.7877 5.42033 11.8045 5.33545C11.8214 5.25056 11.8126 5.16259 11.7795 5.08266Z" fill="#999999"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_236_482"><rect width="14" height="14" fill="white"></rect></clipPath>
                                        </defs>
                                    </svg>
                                    <EditableInput
                                        className="grey-input-custom !h-[54px] !py-[13px]"
                                        type="text"
                                        name="education_high_school_state"
                                        id="education_high_school_state"
                                        value={payload.applicationForm.education_high_school_state}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </div>
                            </div>
                            <EditableInput
                                className="grey-input-custom !h-[54px] !py-[13px]"
                                type="text"
                                name="education_high_school_postal_code"
                                id="education_high_school_postal_code"
                                value={payload.applicationForm.education_high_school_postal_code}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <div className='col-span-3 mt-2'>
                                {/* <h2 className="text-[22px] font-bold">Did you graduate</h2> */}
                                <EditableInput
                                    className="text-[22px] font-bold"
                                    type="text"
                                    name="education_high_school_did_you_graduate_text"
                                    id="education_high_school_did_you_graduate_text"
                                    value={payload.applicationForm.education_high_school_did_you_graduate_text}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <div className="mt-4 flex items-center gap-6">
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-yes11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">Yes</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_high_school_did_you_graduate_choices"
                                            id="education_high_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_high_school_did_you_graduate_choices[0]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-no11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">No</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_high_school_did_you_graduate_choices"
                                            id="education_high_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_high_school_did_you_graduate_choices[1]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <EditableInput
                            className="font-bold text-[22px]"
                            type="text"
                            name="education_college_school_title"
                            id="education_college_school_title"
                            value={payload.applicationForm.education_college_school_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <div className="max-w-[560px] max-lg:flex max-lg:flex-col lg:grid lg:grid-cols-3 gap-[16px]">
                            <EditableInput
                                className="grey-input-custom col-span-3 !h-[54px] !py-[13px]"
                                type="text"
                                name="education_college_school_name"
                                id="education_college_school_name"
                                value={payload.applicationForm.education_college_school_name}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <EditableInput
                                className="grey-input-custom !h-[54px] !py-[13px]"
                                type="text"
                                name="education_college_school_city"
                                id="education_college_school_city"
                                value={payload.applicationForm.education_college_school_city}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <div className="min-w-[176px] h-[54px] input-ap">
                                <div className="relative">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute right-[18px] top-[19px] pointer-events-none">
                                        <g clip-path="url(#clip0_236_482)">
                                            <path d="M11.7795 5.08266C11.7464 5.00271 11.6903 4.93437 11.6184 4.88628C11.5465 4.83819 11.4619 4.81252 11.3753 4.8125H2.62534C2.53877 4.81243 2.45411 4.83805 2.3821 4.88612C2.31009 4.93419 2.25396 5.00254 2.22082 5.08253C2.18768 5.16251 2.17902 5.25053 2.19593 5.33545C2.21284 5.42036 2.25456 5.49834 2.31581 5.55953L6.69081 9.93453C6.73145 9.97521 6.7797 10.0075 6.83281 10.0295C6.88592 10.0515 6.94285 10.0628 7.00034 10.0628C7.05784 10.0628 7.11477 10.0515 7.16788 10.0295C7.22099 10.0075 7.26924 9.97521 7.30988 9.93453L11.6849 5.55953C11.746 5.49831 11.7877 5.42033 11.8045 5.33545C11.8214 5.25056 11.8126 5.16259 11.7795 5.08266Z" fill="#999999"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_236_482"><rect width="14" height="14" fill="white"></rect></clipPath>
                                        </defs>
                                    </svg>
                                    <EditableInput
                                        className="grey-input-custom !h-[54px] !py-[13px]"
                                        type="text"
                                        name="education_college_school_state"
                                        id="education_college_school_state"
                                        value={payload.applicationForm.education_college_school_state}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </div>
                            </div>
                            {/* <input type="text" className="grey-input-custom" placeholder="Postal Code" /> */}
                            <EditableInput
                                className="grey-input-custom !h-[54px] !py-[13px]"
                                type="text"
                                name="education_college_school_postal_code"
                                id="education_college_school_postal_code"
                                value={payload.applicationForm.education_college_school_postal_code}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <div className='col-span-3 mt-2'>
                                {/* <h2 className="text-[22px] font-bold">Did you graduate</h2> */}
                                <EditableInput
                                    className="text-[22px] font-bold"
                                    type="text"
                                    name="education_college_school_did_you_graduate_text"
                                    id="education_college_school_did_you_graduate_text"
                                    value={payload.applicationForm.education_college_school_did_you_graduate_text}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <div className="mt-4 flex items-center flex-wrap gap-6">
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium max-md:flex-1 max-md:min-w-[45%] lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-yes11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">Yes</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_college_school_did_you_graduate_choices"
                                            id="education_college_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_college_school_did_you_graduate_choices[0]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium max-md:flex-1 max-md:min-w-[45%] lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-no11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">No</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_college_school_did_you_graduate_choices"
                                            id="education_college_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_college_school_did_you_graduate_choices[1]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium max-md:flex-1 max-md:min-w-[45%] lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-degree11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">Degree</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_college_school_did_you_graduate_choices"
                                            id="education_college_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_college_school_did_you_graduate_choices[2]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium max-md:flex-1 max-md:min-w-[45%] lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-major11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">Major</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_college_school_did_you_graduate_choices"
                                            id="education_college_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_college_school_did_you_graduate_choices[3]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : null }
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-between">
                <EditableInput
                    className="font-bold lg:text-[26px] text-[24px]"
                    type="text"
                    name="education_title"
                    id="education_title"
                    value={payload.applicationForm.education_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton>
                        :
                        <ButtonOptions options={options} />
                }
            </div>
            { payload.applicationForm.education_active ? 
                <div className='flex flex-col gap-7'>
                    <div>
                        <EditableInput
                            className="font-bold lg:text-[22px] text-[20px]"
                            type="text"
                            name="education_high_school_title"
                            id="education_high_school_title"
                            value={payload.applicationForm.education_high_school_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <div className="max-w-[560px] max-lg:flex max-lg:flex-col lg:grid lg:grid-cols-3 gap-[16px]">
                            <EditableInput
                                className="grey-input-custom col-span-3 !h-[54px] !py-[13px]"
                                type="text"
                                name="education_high_school_name"
                                id="education_high_school_name"
                                value={payload.applicationForm.education_high_school_name}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <EditableInput
                                className="grey-input-custom !h-[54px] !py-[13px]"
                                type="text"
                                name="education_high_school_city"
                                id="education_high_school_city"
                                value={payload.applicationForm.education_high_school_city}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <div className="min-w-[176px] h-[54px] input-ap">
                                <div className="relative">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute right-[18px] top-[19px] pointer-events-none">
                                        <g clip-path="url(#clip0_236_482)">
                                            <path d="M11.7795 5.08266C11.7464 5.00271 11.6903 4.93437 11.6184 4.88628C11.5465 4.83819 11.4619 4.81252 11.3753 4.8125H2.62534C2.53877 4.81243 2.45411 4.83805 2.3821 4.88612C2.31009 4.93419 2.25396 5.00254 2.22082 5.08253C2.18768 5.16251 2.17902 5.25053 2.19593 5.33545C2.21284 5.42036 2.25456 5.49834 2.31581 5.55953L6.69081 9.93453C6.73145 9.97521 6.7797 10.0075 6.83281 10.0295C6.88592 10.0515 6.94285 10.0628 7.00034 10.0628C7.05784 10.0628 7.11477 10.0515 7.16788 10.0295C7.22099 10.0075 7.26924 9.97521 7.30988 9.93453L11.6849 5.55953C11.746 5.49831 11.7877 5.42033 11.8045 5.33545C11.8214 5.25056 11.8126 5.16259 11.7795 5.08266Z" fill="#999999"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_236_482"><rect width="14" height="14" fill="white"></rect></clipPath>
                                        </defs>
                                    </svg>
                                    <EditableInput
                                        className="grey-input-custom !h-[54px] !py-[13px]"
                                        type="text"
                                        name="education_high_school_state"
                                        id="education_high_school_state"
                                        value={payload.applicationForm.education_high_school_state}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </div>
                            </div>
                            <EditableInput
                                className="grey-input-custom !h-[54px] !py-[13px]"
                                type="text"
                                name="education_high_school_postal_code"
                                id="education_high_school_postal_code"
                                value={payload.applicationForm.education_high_school_postal_code}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <div className='col-span-3 mt-2'>
                                {/* <h2 className="text-[22px] font-bold">Did you graduate</h2> */}
                                <EditableInput
                                    className="text-[22px] font-bold"
                                    type="text"
                                    name="education_high_school_did_you_graduate_text"
                                    id="education_high_school_did_you_graduate_text"
                                    value={payload.applicationForm.education_high_school_did_you_graduate_text}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <div className="mt-4 flex items-center gap-6">
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-yes11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">Yes</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_high_school_did_you_graduate_choices"
                                            id="education_high_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_high_school_did_you_graduate_choices[0]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-no11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">No</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_high_school_did_you_graduate_choices"
                                            id="education_high_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_high_school_did_you_graduate_choices[1]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <EditableInput
                            className="font-bold text-[22px]"
                            type="text"
                            name="education_college_school_title"
                            id="education_college_school_title"
                            value={payload.applicationForm.education_college_school_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <div className="max-w-[560px] max-lg:flex max-lg:flex-col lg:grid lg:grid-cols-3 gap-[16px]">
                            <EditableInput
                                className="grey-input-custom col-span-3 !h-[54px] !py-[13px]"
                                type="text"
                                name="education_college_school_name"
                                id="education_college_school_name"
                                value={payload.applicationForm.education_college_school_name}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <EditableInput
                                className="grey-input-custom !h-[54px] !py-[13px]"
                                type="text"
                                name="education_college_school_city"
                                id="education_college_school_city"
                                value={payload.applicationForm.education_college_school_city}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <div className="min-w-[176px] h-[54px] input-ap">
                                <div className="relative">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute right-[18px] top-[19px] pointer-events-none">
                                        <g clip-path="url(#clip0_236_482)">
                                            <path d="M11.7795 5.08266C11.7464 5.00271 11.6903 4.93437 11.6184 4.88628C11.5465 4.83819 11.4619 4.81252 11.3753 4.8125H2.62534C2.53877 4.81243 2.45411 4.83805 2.3821 4.88612C2.31009 4.93419 2.25396 5.00254 2.22082 5.08253C2.18768 5.16251 2.17902 5.25053 2.19593 5.33545C2.21284 5.42036 2.25456 5.49834 2.31581 5.55953L6.69081 9.93453C6.73145 9.97521 6.7797 10.0075 6.83281 10.0295C6.88592 10.0515 6.94285 10.0628 7.00034 10.0628C7.05784 10.0628 7.11477 10.0515 7.16788 10.0295C7.22099 10.0075 7.26924 9.97521 7.30988 9.93453L11.6849 5.55953C11.746 5.49831 11.7877 5.42033 11.8045 5.33545C11.8214 5.25056 11.8126 5.16259 11.7795 5.08266Z" fill="#999999"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_236_482"><rect width="14" height="14" fill="white"></rect></clipPath>
                                        </defs>
                                    </svg>
                                    <EditableInput
                                        className="grey-input-custom !h-[54px] !py-[13px]"
                                        type="text"
                                        name="education_college_school_state"
                                        id="education_college_school_state"
                                        value={payload.applicationForm.education_college_school_state}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                    />
                                </div>
                            </div>
                            {/* <input type="text" className="grey-input-custom" placeholder="Postal Code" /> */}
                            <EditableInput
                                className="grey-input-custom !h-[54px] !py-[13px]"
                                type="text"
                                name="education_college_school_postal_code"
                                id="education_college_school_postal_code"
                                value={payload.applicationForm.education_college_school_postal_code}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <div className='col-span-3 mt-2'>
                                {/* <h2 className="text-[22px] font-bold">Did you graduate</h2> */}
                                <EditableInput
                                    className="text-[22px] font-bold"
                                    type="text"
                                    name="education_college_school_did_you_graduate_text"
                                    id="education_college_school_did_you_graduate_text"
                                    value={payload.applicationForm.education_college_school_did_you_graduate_text}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <div className="mt-4 flex items-center flex-wrap gap-6">
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium max-md:flex-1 max-md:min-w-[45%] lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-yes11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">Yes</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_college_school_did_you_graduate_choices"
                                            id="education_college_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_college_school_did_you_graduate_choices[0]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium max-md:flex-1 max-md:min-w-[45%] lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-no11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">No</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_college_school_did_you_graduate_choices"
                                            id="education_college_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_college_school_did_you_graduate_choices[1]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium max-md:flex-1 max-md:min-w-[45%] lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-degree11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">Degree</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_college_school_did_you_graduate_choices"
                                            id="education_college_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_college_school_did_you_graduate_choices[2]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                    <label className="flex items-center cursor-pointer radio-green-container font-medium max-md:flex-1 max-md:min-w-[45%] lg:max-w-[calc(25%-20px)]">
                                        <input type="radio" className="radio-green-sub mr-[10px]" name="work-us" id="radio-major11" />
                                        {/* <span className="font-semibold text-[18px] leading-[22px]">Major</span> */}
                                        <EditableInput
                                            className="font-semibold text-[18px] leading-[22px]"
                                            type="text"
                                            name="education_college_school_did_you_graduate_choices"
                                            id="education_college_school_did_you_graduate_choices"
                                            value={payload.applicationForm.education_college_school_did_you_graduate_choices[3]}
                                            // onChange={handleChange}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : null }
        </form>
    )
}
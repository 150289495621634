import {NavLink} from "react-router-dom";

export const AboutBlock = ({
                               title = "",
                               header = "Lorem ipsum dolor sit amet, consectetur",
                               description = null,
                               button = "",
                               contentCentered = false,
                               headerStyle = "max-w-[550px]",
                               descriptionStyle = "max-w-[530px]",
                               children
                           }) => {
    return (
        <section
            className={`w-full flex flex-col gap-[14px] font-['Raleway'] ${contentCentered ? "items-center text-center" : ""}`}>
            {title && <span className="text-sm sm:text-sm md:text-lg font-semibold text-[#0056B3]">{title}</span>}
            <h3 className={`text-2xl sm:text-3xl md:text-5xl font-bold ${headerStyle}`}>{header}</h3>
            {description && <div>
                {description?.map((item, index) => (
                    <p key={index} className={`text-sm md:text-base font-medium ${descriptionStyle}`}>{item}</p>
                ))}
            </div>}
            {children}
            {button &&
                <NavLink
                to="/request-demo"
                className="flex justify-center items-center font-semibold text-[20px] max-md:text-sm p-[10px] bg-[#37B34A] hover:bg-green-600 rounded-md text-white w-fit h-fit">
                {button}
            </NavLink>
            }
        </section>
    );
};

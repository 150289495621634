import * as React from "react";
import { NavLink } from "react-router-dom";

const Breadcrumbs = ({ links = [], colorStyle = "" }) => {
    const breadcrumbItems = links;
    const activeStyle = colorStyle === "secondary" ? "text-[#06D6A0]" : "text-[#37B34A]"
    return (
        <nav aria-label="Breadcrumb">
            <ol className={`text-xl font-bold ${colorStyle === "secondary" ? "text-[#333333]" : "text-white max-lg:leading-[22px] max-lg:text-lg"}  max-md:max-w-full`}>
                {
                    breadcrumbItems?.map((item) => {
                        return <React.Fragment key={item.name}>
                            <li className={`inline ${item.isCurrent && links.length > 1 ? activeStyle : ""}`}>
                                {!item.isCurrent ?
                                    <NavLink to={item?.to} className="hover:text-green-600">{item.name}</NavLink> : <span className="cursor-default">{item.name}</span>
                                }
                            </li>
                            {!item.isCurrent ? <li className="inline mx-2">/</li> : null}
                        </React.Fragment>
                    })
                }
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import RejectCandidate from "../../../components/modals/RejectCandidate";
import { USER_TYPES } from "../../../data/enums";
import { getSubscriberRole } from "../../../redux/slices/applicationSlice";

export const TabsSBM01 = ({ activeLink }) => {
    const [rejectPopup, setRejectPopup] = useState(false);
    const { jobId, applicationId } = useParams();
    const role = useSelector(getSubscriberRole);

    if (!jobId || !applicationId) {
        return null
    }
    return (
        <>
            <nav className="flex mt-9 overflow-auto w-full gap-5 items-center self-start text-xl font-semibold text-center whitespace-nowrap text-zinc-800">
                <NavLink to={`/SBM01_10_14(2)/${jobId}/${applicationId}`}
                    className={({ isActive }) => `flex items-center justify-center px-5 h-[46px] ${activeLink === "report-card" ? 'border-green-500' : 'border-white'}  border-solid border-b-[5px] hover:bg-green-200`}
                >
                    Report Card
                </NavLink>
                <NavLink to={`/SBM01_10_14_2_1/${jobId}/${applicationId}`}
                    className={({ isActive }) => `flex items-center justify-center px-5 h-[46px] ${activeLink === "history" ? 'border-green-500' : 'border-white'}  border-solid border-b-[5px] hover:bg-green-200`}
                >
                    History
                </NavLink>
                {role !== USER_TYPES.committee && (
                    <>
                        <NavLink to={`/SBM01_10_14_4/${jobId}/${applicationId}`}
                            className={({ isActive }) => `flex items-center justify-center px-5 h-[46px] ${activeLink === "reference" ? 'border-green-500' : 'border-white'}  border-solid border-b-[5px] hover:bg-green-200`}
                        >
                            Reference
                        </NavLink>
                        <button type='button' onClick={() => setRejectPopup(true)}
                            className={`flex items-center justify-center px-5 h-[46px] border-white  border-solid border-b-[5px] hover:bg-green-200`}
                        >
                            Reject
                        </button>
                        <NavLink to={`/SBM01_10_14_3/${jobId}/${applicationId}`}
                            className={({ isActive }) => `flex items-center justify-center px-5 h-[46px] ${activeLink === "offer-letter" ? 'border-green-500' : 'border-white'}  border-solid border-b-[5px] hover:bg-green-200`}
                        >
                            Offer Letter
                        </NavLink>
                    </>
                )
                }
            </nav>
            {rejectPopup && <RejectCandidate open={rejectPopup} rejectPopupPayload={{ jobId, applicationId }} handleClose={() => setRejectPopup(false)} />}

        </>
    )
}
import { NavLink } from "react-router-dom";
import InfoSection from "./InfoSection";
import { AboutBlock } from "./AboutBlock";
import React from "react";

const HeroSolutions = ({ header = "", description = "", total = null, image, button }) => {
    return (
        <InfoSection
            withBlock
            background={"bg-white"}
            topSpacing="pt-[57px] pb-[110px] max-md:py-[80px]"
        >
            <div className="w-full flex flex-col gap-[20px] font-['Raleway']">
                <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold max-w-[550px]">{header}</h3>
                <p className="text-sm md:text-base font-medium max-w-[450px]">{description}</p>

                {total && (
                    <div className="flex gap-[20px] items-center mb-2">
                        <div className="flex flex-col gap-[5px] items-center bg-[#0056B3] text-white px-[13px] py-[13px] rounded-[12px]">
                            <span className="font-bold text-[41px] max-sm:text-[32px]">{total.candidates}</span>
                            <span className="font-medium text-[12px]">{total.label}</span>
                        </div>
                        <div>
                            <p className="font-semibold text-[29px] mb-1 max-w-[300px] max-sm:text-[20px]">{total.title}</p>
                            <span className="font-medium text-base">{total.subTitle}</span>
                        </div>
                    </div>
                )}

                <NavLink
                    to="/request-demo"
                    className="flex justify-center items-center font-semibold text-[23px] max-md:text-sm py-[10px] px-[20px] bg-[#37B34A] hover:bg-green-600 rounded-md text-white w-fit h-fit"
                >
                    {button}
                </NavLink>
            </div>

            {image && (
                <img src={image} className="h-auto w-full max-md:w-[80%] m-auto wow fadeInDown" alt="solutions" />
            )}
        </InfoSection>
    );
};

export default HeroSolutions;

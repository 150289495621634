import { configureStore } from '@reduxjs/toolkit';
import applicationSlice from './slices/applicationSlice';
import companiesSlice from './slices/companiesSlice';
import jobsSlice from './slices/jobsSlice';
import supportSlice from './slices/supportSlice';
import solutionSlice from './slices/solutionSlice';
import supportSolutionsSlice from "./slices/supportSolutionsSlice";
import publicSolutionsSlice from "./slices/publicSolutionsSlice";
import communicationsSlice from './slices/communicationsSlice';
import jobRatingsSlice from './slices/jobRatingsSlice';
import jobApplicationDocumentsSlice from './slices/jobApplicationDocumentsSlice';

export const store = configureStore({
    reducer: {
        application: applicationSlice,
        companies: companiesSlice,
        jobs: jobsSlice,
        support: supportSlice,
        solution: solutionSlice,
        supportSolutions: supportSolutionsSlice,
        publicSolutions: publicSolutionsSlice,
        communications: communicationsSlice,
        jobRatings: jobRatingsSlice,
        jobApplicationDocuments: jobApplicationDocumentsSlice,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['application/setSocket',],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['application.socket', "meta.arg.body", "meta.arg"],
                // Ignore these paths in the state
                ignoredPaths: ['application.socket'],
            },
        }),
});
import {useSelector} from "react-redux";
import {getCurrentPage, getCurrentPageLoading} from "../../redux/slices/applicationSlice";
import findValue from "../../assets/helpers/findValue";
import PageLoader from "../../components/PageLoader";
import {ClientsComments} from "../../components/parts/publickPageParts/ClientsComments";
import React from "react";
import HeroWithSlider from "../../components/parts/publickPageParts/HeroWithSlider";
import InfoSection from "../../components/parts/publickPageParts/InfoSection";
import {AboutBlock} from "../../components/parts/publickPageParts/AboutBlock";
import aboutUsImage from "../../assets/img/home/aboutUs.png";
import teamImage from "../../assets/img/home/team.png";
import teamChillImage from "../../assets/img/home/teamChill.png";
import heroImage from "../../assets/img/home/hero.png";
import {JoinTeam} from "../../components/parts/publickPageParts/JoinTeam";
import {NavLink} from "react-router-dom";
import blocksImage from "../../assets/img/home/blocks.png";
import {useAnimation} from "../../components/customHooks/useAnimation";
import getImageUrl from "../../assets/helpers/getImageUrl";


// const data = {
//     hero: {
//         header: "Become a Part of Team, <br/> Redefine Hiring, Redefine Your Career",
//         description: "Be part of a forward-thinking team that’s transforming how businesses hire. <br/> Your career with EmployJoy starts here.",
//         buttons: [{
//             text: "Join the Revolution",
//             link: "/request-demo",
//             bgColor: "bg-black"
//         }]
//     },
//     aboutUs: {
//         title: "Our Mission",
//         header: "Connecting talent, redefining opportunities.",
//         description: ["At EmployJoy, our mission is to revolutionize the hiring process by leveraging cutting-edge AI technology to match the best talent with the right opportunities. We believe in creating an efficient, bias-free, and streamlined recruitment experience for both recruiters and candidates."],
//         content: [
//             {
//                 title: "Authenticity",
//                 description: "Be genuine in all interactions. Build trust through transparency.",
//                 image: require('../../assets/img/home/Authenticity.png')
//             },
//             {
//                 title: "Outcome Obsessed",
//                 description: "Focus on results and deliver value in every task and decision.",
//                 image: require('../../assets/img/home/OutcomeObsessed.png')
//             },
//             {
//                 title: "Team First",
//                 description: "Prioritize collaboration and support your team to achieve shared goals.",
//                 image: require('../../assets/img/home/TeamFirst.png')
//             },
//             {
//                 title: "Show Grit",
//                 description: "Stay resilient and driven, overcoming challenges with perseverance.",
//                 image: require('../../assets/img/home/ShowGrit.png')
//             }
//         ]
//     },
//     categories: {
//         title: "Jobs by Categories ",
//         header: "Evaluate top talent, pre-screened by our AI.",
//         content: [
//             {
//                 title: "Project Manager",
//                 image: require('../../assets/img/home/projectManager.png')
//             },
//             {
//                 title: "Team Leader",
//                 image: require('../../assets/img/home/teamLeader.png')
//             },
//             {
//                 title: "AI Specialist",
//                 image: require('../../assets/img/home/aideveloper.png')
//             },
//             {
//                 title: "Client Service Specialist",
//                 image: require('../../assets/img/home/clientService.png')
//             },
//             {
//                 title: "Financial Management",
//                 image: require('../../assets/img/home/financialManager.png')
//             },
//             {
//                 title: "Backend Developer",
//                 image: require('../../assets/img/home/backendDeveloper.png')
//             }
//         ]
//     },

//     joinTeam: {
//         title: "Join Our Team",
//         header: "Start your journey with EmployJoy.ai",
//         description: [
//             "Join a dynamic team that’s transforming recruitment through AI innovation."
//         ],
//         button: "Join the Team Today",
//         img: teamChillImage
//     }
// }

const generateSolutionsPageData = (blocks) => {
    return {
        hero: {
            header: findValue(blocks, 'career_hero_title'),
            description: findValue(blocks, 'career_hero_desc'),
            buttons: [{
                text: findValue(blocks, 'career_hero_btn'),
                link: "/request-demo",
                bgColor: "bg-black"
            }],
            imageIcon1: findValue(blocks, 'career_hero_icon1'),
            imageIcon2: findValue(blocks, 'career_hero_icon2'),
            imageIcon3: findValue(blocks, 'career_hero_icon3'),
            imageIcon4: findValue(blocks, 'career_hero_icon4'),
            imageCrusel: findValue(blocks, 'career_hero_carousel')?.data || []
        },
        aboutUs: {
            title: findValue(blocks, 'career_mission_label'),
            header: findValue(blocks, 'career_mission_title'),
            description: [findValue(blocks,'career_mission_desc')],
            content: findValue(blocks,'career_mission_list')?.data || [],
            button:  findValue(blocks, 'career_mission_btn'),
        },
        categories: {
            title: findValue(blocks, 'career_job_label'),
            header: findValue(blocks, 'career_job_title'),
            content: findValue(blocks, 'career_job_list')?.data || [],
        },

        roles: {
            title: findValue(blocks, 'career_roles_label'),
            header: findValue(blocks, 'career_roles_title'),
            description: findValue(blocks, 'career_roles_desc'),
            button: "Join the Team Today",
            cards: findValue(blocks, 'career_roles_list')?.data || [] ,

        },
        joinTeam: {
            title: findValue(blocks, 'career_join_label'),
            header: findValue(blocks, 'career_join_title'),
            description: findValue(blocks, 'career_join_desc'),
            button: findValue(blocks, 'career_join_btn'),
            buttonLink: findValue(blocks, 'career_join_url'),
            image: findValue(blocks, 'career_join_img')
        },
        testimonials: {
            title: findValue(blocks, "career_testimonials_label"),
            header: findValue(blocks, "career_testimonials_title"),
            testimonials: (findValue(blocks, "career_testimonials_list")?.data || []).map(testimonial => ({
                image: getImageUrl(testimonial.career_testimonials_list_item_img),
                text: testimonial.career_testimonials_list_item_job,
                name: testimonial.career_testimonials_list_item_name,
                job: testimonial.career_testimonials_list_item_text
            })),
            button: findValue(blocks, 'career_testimonials_button'),
        },
    };
};


const Career = () => {
    const currentPage = useSelector(getCurrentPage);
    const isLoading = useSelector(getCurrentPageLoading);
    const data = generateSolutionsPageData(currentPage?.blocks || []);

    console.log("currentPage", currentPage)

    if (isLoading) return <PageLoader/>;
    if(!data) return null
    return (
        <div className="public-page">
            {/*{!currentPage ? <PageLoader/> :*/}
                <>
                    <HeroWithSlider
                        header={data.hero.header}
                        description={data.hero.description}
                        buttons={data.hero.buttons}
                        images={{data: data.hero.imageCrusel.map(image => ({corusel_item_image: image['career_hero-carousel_item_img']}))}}
                    >
                        <div className="absolute w-full top-0 left-0 h-full z-[-1]">
                            <img loading="lazy" src={getImageUrl(data.hero.imageIcon1)}
                                 className="absolute top-0 left-0 wow fadeInDown h-[60px] rounded-full" alt="Group1"/>
                            <img loading="lazy" src={getImageUrl(data.hero.imageIcon2)}
                                 className="absolute bottom-0 left-[12%]  wow fadeInUp h-[60px] rounded-full" alt="Group2"/>
                            <img loading="lazy" src={getImageUrl(data.hero.imageIcon3)}
                                 className="absolute top-[-20px] right-[-30px]  wow fadeInRight h-[60px] rounded-full" alt="Group3"/>
                            <img loading="lazy" src={getImageUrl(data.hero.imageIcon4)}
                                 className="absolute bottom-[40px] right-[50px]  wow fadeInUp h-[60px] rounded-full" alt="Group4"/>
                        </div>
                    </HeroWithSlider>
                    <InfoSection
                        withBlock
                        background={"bg-white"}
                        topSpacing={'py-[110px] max-md:py-[80px]'}
                        containerStyle="grid grid-cols-2 gap-[70px] max-md:gap-[40px] items-center max-lg:grid-cols-1"
                    >
                        <AboutBlock
                            title={data.aboutUs.title}
                            header={data.aboutUs.header}
                            description={[data.aboutUs.description]}
                            button={data.aboutUs.button}
                            headerStyle="max-w-[700px]"
                        />
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-[24px] justify-center">
                            {data.aboutUs?.content?.map((item, index) => (
                                <div key={index}
                                     className={`flex flex-col gap-3 py-[30px] px-[26px] card-shadow rounded-[21px] w-full justify-self-center wow ${index > 2 ? "fadeInDown" : "fadeInUp"}`}>
                                    <img loading="lazy" src={getImageUrl(item.career_mission_list_item_img) }
                                         className="w-[50px] h-[50px]" alt={item.career_mission_list_item_title}/>
                                    <p className="text-[26px] max-xl:text-[18px] font-semibold">{item.career_mission_list_item_title}</p>
                                    <p className="text-base max-xl:text-sm font-medium text-[#5F5D5D]">{item.career_mission_list_item_desc}</p>
                                </div>
                            ))}
                        </div>
                    </InfoSection>

                    {/*<div className="pb-[110px]">*/}
                    {/*<div className="container">*/}
                    {/*        <AboutBlock*/}
                    {/*            title={data.categories.title}*/}
                    {/*            header={data.categories.header}*/}
                    {/*            contentCentered*/}
                    {/*            headerStyle="max-w-[800px]"*/}
                    {/*        />*/}
                    {/*        <div className="flex flex-wrap gap-[25px] mt-[35px] justify-start max-lg:justify-center ">*/}
                    {/*            {data.categories?.content.map((item, index) => (*/}
                    {/*                <div key={index}*/}
                    {/*                     className="flex gap-3 py-[30px] h-[62px] px-[10px] items-center justify-center rounded-[10px] w-[300px] bg-[#EBF0F3]">*/}
                    {/*                    <img loading="lazy" src={getImageUrl(item.career_job_list_item_img)}*/}
                    {/*                         className="w-[45px] h-[45px]" alt={item.career_job_list_item_text}/>*/}
                    {/*                    <p className="text-base md:text-[20px] font-bold">{item.career_job_list_item_text}</p>*/}
                    {/*                </div>*/}
                    {/*            ))}*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    <JoinTeam
                        header={data.roles.header}
                        title={data.roles.title}
                        description={data.roles.description}
                        sliderCards={data.roles.cards}
                    />

                    <ClientsComments
                        withBlock topSpacing={'py-[110px]'}
                        title={data.testimonials.title}
                        header={data.testimonials.header}
                        testimonials={data.testimonials.testimonials}
                        contentStyle={"!justify-start"}
                    />

                    <InfoSection
                        topSpacing={'py-[55px]'}
                    >
                        <AboutBlock
                            title={data.joinTeam.title}
                            header={data.joinTeam.header}
                            description={[data.joinTeam.description]}
                        >
                            <NavLink to={data.joinTeam.buttonLink}
                                     className="flex justify-center items-center font-semibold text-lg  md:text-2xl bg-[#007BFF] hover:opacity-90 rounded-md text-white h-[49px] min-w-[206px] px-[20px] w-fit">
                                {data.joinTeam.button}
                            </NavLink>
                        </AboutBlock>
                        <div className="flex items-center justify-center h-auto max-md:h-[300px] ">
                            <img src={getImageUrl(data.joinTeam.image)} alt="candidate" className="absolute max-h-[440px] bottom-0 wow fadeInRight"/>
                        </div>
                    </InfoSection>
                    <div className={`bg-top bg-no-repeat bg-white relative w-full h-[110px] bg-contain`}
                         style={{backgroundImage: `url(${blocksImage})`}}>
                    </div>
                </>
            {/*// }*/}
        </div>
    )
}

export default Career

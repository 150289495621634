import React, {useState, useEffect} from "react";

export const AdditionalInputs = ({ className="", btnName='Add New', isEditing, withNum, isArrayData = false, data = [], setData }) => {
    const [additionInputs, setAdditionInputs] = useState(isArrayData ? data : {})


    const handleRemoveAdditional = (key = null, index) => {
        if(isArrayData){
            setAdditionInputs(additionInputs.filter((item, itemIndex) => { return itemIndex !== index }));
        } else {
            const a = {...additionInputs}
            delete a[key]
            setAdditionInputs(a);
        }
    }

    const addAdditional = () => {
        if(isArrayData){
            setAdditionInputs(prev => ([...prev, '']))
        } else {
            let randomNumber = Math.floor(Math.random() * 100) + 1;
            setAdditionInputs(prev => ({...prev, [randomNumber]: ''}))
        }
    }

    const handleChangeAdditional = (e, idx) => {
        const { name, value } = e.target;
        if(isArrayData){
            setAdditionInputs([...additionInputs].map((item, index) => { return index === idx ? value : item }));
        } else {
            setAdditionInputs(prev => ({...prev, [name]: value}));   
        }
    };

    useEffect(()=>{
        if(isArrayData){
            setData(additionInputs);
        }
    }, [additionInputs]);

    return (
        <>
            <div className={`flex flex-col gap-[18px] mt-[18px] ${className}`}>
                {(isArrayData ? additionInputs : Object.entries(additionInputs)).map((el,idx) => <div className="flex items-center gap-2" key={idx}>
                    <div data-count={idx + 1} className={`w-full ${withNum ? 'with-num' : ''}`}>
                        <input
                            className={`grey-input-custom`}
                            type="text"

                            name={isArrayData ? "title" + idx : el[0]}
                            id={"title" + idx}
                            value={isArrayData ? el : el[1].title}
                            onChange={(e)=>{handleChangeAdditional(e, idx)}}
                            disabled={!isEditing}
                        />
                    </div>

                    <div onClick={() => handleRemoveAdditional(el?.[0], idx)} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <path d="M17.5413 6.87109L12.9447 11.4666L8.34922 6.87109L6.81738 8.40293L11.4129 12.9984L6.81738 17.5939L8.34922 19.1258L12.9447 14.5303L17.5413 19.1258L19.0731 17.5939L14.4776 12.9984L19.0731 8.40293L17.5413 6.87109Z" fill="#FF0000"/>
                        </svg>
                    </div>
                </div>)}
            </div>

            <div onClick={addAdditional} className="text-[18px] font-bold text-[#37B34A] mt-[32px] cursor-pointer">
                {btnName}
            </div>
        </>
    )
}
import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";


export const EmergencyContactForm = ({ payload, setPayload }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false);

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }

    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.name_of_emergency_contact_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        name_of_emergency_contact_active: !payload.applicationForm.name_of_emergency_contact_active
                    }
                });
            }
        },
    ]

    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <div className='w-full'>
                    <div>
                        <EditableInput
                            className="font-bold lg:text-[22px] text-[18px]"
                            type="text"
                            name="name_of_emergency_contact_title"
                            id="name_of_emergency_contact_title"
                            value={payload.applicationForm.name_of_emergency_contact_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                    </div>
                    <div className='max-w-[560px] w-full'>
                        <div className="grid lg:grid-cols-2 w-full gap-[16px]">
                            <EditableInput
                                className="grey-input-custom !py-[13px] !min-h-[54px]"
                                type="text"
                                name="name_of_emergency_contact_full_name"
                                id="name_of_emergency_contact_full_name"
                                value={payload.applicationForm.name_of_emergency_contact_full_name}
                                onChange={handleChange}
                                disabled={!isEditing}
                                placeholder="Full Name"
                            />
                            <EditableInput
                                className="grey-input-custom !py-[13px] !min-h-[54px]"
                                type="text"
                                name="name_of_emergency_contact_email"
                                id="name_of_emergency_contact_email"
                                value={payload.applicationForm.name_of_emergency_contact_email}
                                onChange={handleChange}
                                disabled={!isEditing}
                                placeholder="Email"
                            />
                            <EditableInput
                                className="grey-input-custom !py-[13px] !min-h-[54px]"
                                type="text"
                                name="name_of_emergency_contact_phone_number"
                                id="name_of_emergency_contact_phone_number"
                                value={payload.applicationForm.name_of_emergency_contact_phone_number}
                                onChange={handleChange}
                                disabled={!isEditing}
                                placeholder="Phone Number"
                            />
                            <EditableInput
                                className="grey-input-custom !py-[13px] !min-h-[54px]"
                                type="text"
                                name="name_of_emergency_contact_relationship"
                                id="name_of_emergency_contact_relationship"
                                value={payload.applicationForm.name_of_emergency_contact_relationship}
                                onChange={handleChange}
                                disabled={!isEditing}
                                placeholder="Relationship"
                            />
                        </div>
                    </div>
                </div>
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit} className='flex justify-between items-start' >
            <div className='w-full'>
                <div className="flex justify-between mb-3">
                    <EditableInput
                        className="font-bold lg:text-[22px] text-[18px]"
                        type="text"
                        name="name_of_emergency_contact_title"
                        id="name_of_emergency_contact_title"
                        value={payload.applicationForm.name_of_emergency_contact_title}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                    <div className='flex items-center gap-6'>
                        {
                            isEditing ?
                            <PrimaryButton
                                type="submit"
                                className={"px-9 lg:h-[52px] lg:text-lg"}
                            >
                                Save
                            </PrimaryButton>
                            :
                            <ButtonOptions options={options} />
                        }
                    </div>
                </div>
                {payload.applicationForm.name_of_emergency_contact_active && <div className='max-w-[560px] w-full'>
                    <div className="grid lg:grid-cols-2 w-full gap-[16px]">
                        <EditableInput
                            className="grey-input-custom !py-[13px] !min-h-[54px]"
                            type="text"
                            name="name_of_emergency_contact_full_name"
                            id="name_of_emergency_contact_full_name"
                            value={payload.applicationForm.name_of_emergency_contact_full_name}
                            onChange={handleChange}
                            disabled={!isEditing}
                            placeholder="Full Name"
                        />
                        <EditableInput
                            className="grey-input-custom !py-[13px] !min-h-[54px]"
                            type="text"
                            name="name_of_emergency_contact_email"
                            id="name_of_emergency_contact_email"
                            value={payload.applicationForm.name_of_emergency_contact_email}
                            onChange={handleChange}
                            disabled={!isEditing}
                            placeholder="Email"
                        />
                        <EditableInput
                            className="grey-input-custom !py-[13px] !min-h-[54px]"
                            type="text"
                            name="name_of_emergency_contact_phone_number"
                            id="name_of_emergency_contact_phone_number"
                            value={payload.applicationForm.name_of_emergency_contact_phone_number}
                            onChange={handleChange}
                            disabled={!isEditing}
                            placeholder="Phone Number"
                        />
                        <EditableInput
                            className="grey-input-custom !py-[13px] !min-h-[54px]"
                            type="text"
                            name="name_of_emergency_contact_relationship"
                            id="name_of_emergency_contact_relationship"
                            value={payload.applicationForm.name_of_emergency_contact_relationship}
                            onChange={handleChange}
                            disabled={!isEditing}
                            placeholder="Relationship"
                        />
                    </div>
                </div>}
            </div>
        </form>
    )
}

import React, {useEffect, useState} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from "../components/parts/Footer";
import SupportFilters from "../components/parts/SupportFilters";
import {useDispatch} from "react-redux";
import {fetchSupportSolutionsPages, fetchSupSolCategories} from "../redux/thunks/supportThunks";

function SubscriptionTracker({ data }) {
	const navigate = useNavigate()

	const handleClick = () => {
		navigate("/SUP1_1")
	}
	return (
		<>
			<div className="w-full flex flex-col gap-7 mt-9 lg:hidden">
				{data.map((post, index) => (
					<div className="flex flex-col gap-3" key={index}>
						<div className="flex items-baseline justify-between gap-4">
							<div className="text-base font-bold w-2/5 text-wrap text-[#333333]">Company Name</div>
							<div className="text-lg text-[#003578] font-bold text-wrap text-right">{post.name}</div>
						</div>
						<div className="flex items-baseline justify-between gap-4">
							<div className="text-base font-bold w-2/5 text-wrap text-[#333333]">Date Subscription Started</div>
							<div className="text-lg text-[#003578] font-bold text-wrap text-right">{post.startDate}</div>
						</div>
					</div>
				))}
			</div>
			<div className="overflow-x-auto max-lg:hidden">
				<table className="w-full mt-8">
					<thead className="text-3xl text-zinc-800">
						<tr>
							<th className="px-5 py-2 text-left">Company Name</th>
							<th className="px-5 py-2 text-center">Date Subscription Started</th>
						</tr>
					</thead>
					<tbody className="text-2xl text-sky-900">
						{data.map((subscription, index) => (
							<tr
								key={index}
								onClick={handleClick}
								className="cursor-pointer"
							>
								<td className="px-5 py-2 text-left">
									<div className="flex items-center gap-[20px]">
										<div className="w-[12px] h-[12px] bg-[#37B34A]" />
										<div className="font-[700] text-[26px]">{subscription.name}</div>
									</div>
								</td>
								<td className="px-5 py-2 text-center font-[700] text-[26px]">{subscription.startDate}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
}

function PostsPage() {
	const subscriptions = [
		{ name: "McDonald Chicago", status: "active", industry: "Maid Service / House Cleaning", location: "Location 1", startDate: "Nov 1, 2023" },
		{ name: "Ram Chicago", status: "active", industry: "Retail", location: "Location 1", startDate: "Nov 2, 2023" },
		{ name: "Sam’s Sushi", status: "active", industry: "Maid Service / House Cleaning", location: "Location 2", startDate: "Nov 3, 2023" },
		{ name: "Koi", status: "active", industry: "Retail", location: "Location 2", startDate: "Nov 5, 2023" },
	];
	const [filteredData, setFilteredData] = useState(subscriptions)

	return (
		<section className="flex justify-center items-center px-4 lg:py-12 pt-10 pb-20 mt-1.5 w-full text-zinc-800 max-md:px-5 max-md:max-w-full">
			<div className="flex flex-col items-center lg:my-10 w-full max-w-[1400px] max-md:max-w-full">
				<h1 className="text-4xl font-bold max-md:text-center">Current Subscribers</h1>
				<p className="mt-2 text-center max-md:max-w-full">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
				<div className="flex flex-col self-stretch py-9 px-8 lg:px-16 mt-11 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:px-5 max-md:mt-10 max-md:max-w-full">
					{/*  justify-between */}
					{/* <div className="flex gap-5 w-full max-md:flex-col">
						<div className="w-full md:max-w-[256px]">
							<SelectBox options={industryOptions}
								onSelect={val => setFilters(prev => ({ ...prev, industry: val }))}
								value={filters.industry} svg={2}
							/>
						</div>

						<div className="w-full md:max-w-[256px]">
							<SelectBox options={locationOptions}
								onSelect={val => setFilters(prev => ({ ...prev, location: val }))}
								value={filters.location} svg={2}
							/>
						</div>
						<div className="w-full md:max-w-[256px]">
							<SelectBox options={statusOptions}
								onSelect={val => setFilters(prev => ({ ...prev, status: val }))}
								value={filters.status} svg={2}
							/>
						</div>
						<Search
							onSubmit={(val) => setFilters(prev => ({ ...prev, search: val }))}
							parentClassName="w-full h-[54px]"
						/>
					</div> */}
					<SupportFilters data={subscriptions} setData={setFilteredData} industry location status search />
					<div className="shrink-0 mt-12 h-px bg-neutral-200 max-md:mt-10 max-md:max-w-full" />

					{
						filteredData.length ?
							<SubscriptionTracker data={filteredData} />
							:
							<div className='text-4xl pt-5 md:text-5xl font-bold text-sky-950 text-center'>No Subscribers yet</div>
					}
				</div>
			</div>
		</section>
	);
}


function NavigationItem({ children, onClick, isSelected, route }) {
	const baseClasses = `lg:px-5 px-4 py-2 cursor-pointer border-b-4  whitespace-nowrap`
	return (
		<>
			{/* <div
				onClick={onClick}
				className={`lg:px-5 px-4 py-2 cursor-pointer border-b-4 ${isSelected ? 'border-green-500' : 'border-transparent'} whitespace-nowrap`}
			>
				{children}
			</div> */}
			<NavLink
				to={route}
				className={({ isActive }) => (isActive ? `border-green-500 ${baseClasses}` : `border-transparent ${baseClasses}`)}
			>
				{children}
			</NavLink>
		</>
	);
}

function NavigationBar() {
	const navigationItems = [
		{ id: 1, text: "Current Subscribers", route: "/SUP01" },
		{ id: 2, text: "Update Public Solution Page", route: "/SUP01_2" },
		{ id: 3, text: "Update Subscriber Support Center", route: "/SUP01_3" },
		{ id: 4, text: "Public Home Pages", route: "/SUP01_4" },
		{ id: 5, text: "Subscribe", route: "/Sup1_5_2" },
	];


	return (
		<nav className="lg:mt-14 mt-8 flex overflow-x-auto no-scrollbar gap-2 lg:gap-5 px-4 py-2 justify-start items-center lg:text-xl text-lg font-semibold text-zinc-800 snap-x md:justify-center md:snap-none md:flex-wrap">
			{navigationItems.map((item) => (
				<NavigationItem
					key={item.id}
					route={item.route}
				>
					{item.text}
				</NavigationItem>
			))}
		</nav>
	);
}




const SUP01 = () => {
	const dispatch = useDispatch()



	return (
		<>
			<BreadCrumbWhite crumbs={[['EJ Support', ''], 'Current Subscribers']} />
			<NavigationBar />
			<PostsPage />
			<Footer hideCopyright={true} />
		</>
	);
};

export default SUP01;

import React, { useEffect } from 'react'
import GetInTouchWithOurSupport from '../../components/parts/GetInTouchWithOurSupport'
import FAQSection from '../../components/parts/FAQSection'
import PageLoader from '../../components/PageLoader';
import { useSelector } from 'react-redux';
import { getCurrentPage } from '../../redux/slices/applicationSlice';
import findValue from '../../assets/helpers/findValue';
import { useLocation } from 'react-router-dom';

const SecurityPage = () => {
	const currentPage = useSelector(getCurrentPage);

	const getValue = (key) => findValue(currentPage?.blocks, key);
	const pageItemsData = getValue('content_page_items')?.data || [];


	const title = getValue('content_page_title') || [];
	const description = getValue('content_page_description') || [];

	if (!currentPage) return <PageLoader />
	return (
		<>
			<FAQSection faqcontent={pageItemsData} {...{ title, description }} />
			<GetInTouchWithOurSupport showDecoreBg={false} topSpacing='pt-[55px]' />
		</>
	)
}

export default SecurityPage

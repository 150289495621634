import React from 'react';
import { Link } from 'react-router-dom';

const SolutionProblems = ({ data, bottomSpase = 'pb-[70px]' }) => {
    return (
        <section className={`pt-[70px] ${bottomSpase} bg-[#EBF0F3] font-['Raleway']`}>
            <div className="container">
                <div className={`flex flex-col gap-[7px] text-[18px] mb-[32px]`}>
                    <span className="text-lg font-semibold text-[#0056B3]">{data.title}</span>
                    <h3 className="text-[45px] max-md:text-[32px] font-bold max-w-[550px] leading-[48px]">
                        {data.header || 'Solutions that solve your hiring challenges.'}
                    </h3>
                </div>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[20px] lg:gap-[40px]">
                    {data.items.map((item, index) => (
                        <div
                            key={index}
                            className="card-shadow py-[35px] px-[25px] flex flex-col items-start max-sm:py-[25px] max-sm:px-[20px] wow fadeInUp"
                        >
                            <h4 className="text-[32px] leading-[40px] max-sm:text-[26px] max-sm:leading-[34px] font-semibold mb-[8px]">
                                {item.title}
                            </h4>
                            <div className="mb-[14px] flex-auto">{item.text}</div>
                            <img src={item.image} className="mb-[23px]" alt={item.title} />
                            <Link
                                to="/request-demo"
                                className="flex gap-[8px] w-full text-[24px] max-sm:text-[20px] items-center font-bold hover:opacity-70"
                            >
                                <span className="underline">{item.button}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                                    <path d="M21.7071 8.7071C22.0976 8.31658 22.0976 7.68342 21.7071 7.29289L15.3431 0.928931C14.9526 0.538406 14.3195 0.538407 13.9289 0.928931C13.5384 1.31946 13.5384 1.95262 13.9289 2.34314L19.5858 8L13.9289 13.6569C13.5384 14.0474 13.5384 14.6805 13.9289 15.0711C14.3195 15.4616 14.9526 15.4616 15.3431 15.0711L21.7071 8.7071ZM8.74228e-08 9L21 9L21 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#003578" />
                                </svg>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SolutionProblems;

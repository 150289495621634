import girlImage from "../../../assets/img/girl.png";
import polygonIcon from "../../../assets/img/home/polygon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { Autoplay, EffectCards } from "swiper/modules";
import "../../../assets/css/Swiper/styles.css";
import getImageUrl from "../../../assets/helpers/getImageUrl";

export const CandidateCards = ({ data }) => {
    const defaultImage = girlImage;

    const candidates = data?.map(candidateData => ({
        name: candidateData.home_slider_fullname,
        position: candidateData.home_slider_job,
        description: candidateData.home_slider_text,
        colTitle1: candidateData.home_slider_col_1_title,
        colTitle2: candidateData.home_slider_col_2_title,
        skills: candidateData?.home_slider_col_2_items?.replace(/[\[\]"]/g, '').replaceAll(",", ', ') || "",
        experience: candidateData.home_slider_col_1_text,
        image: candidateData.home_slider_image,
        button: candidateData.home_slider_button,
        label: candidateData.home_slider_label
    }));

    return (
        <Swiper
            effect={'cards'}
            cardsEffect={{
                perSlideRotate: 0,
                perSlideOffset: 15,
                opacity: true
            }}
            autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
            grabCursor={true}
            modules={[EffectCards, Autoplay]}
            className="mySwiper"
        >
            {candidates?.map((candidate, idx) => (
                <SwiperSlide key={idx}>
                    <div className="flex w-full relative pl-9 text-black container font-['Raleway']">
                        <div
                            className="max-w-[503px] min-h-[610px] flex flex-col rounded-3xl bg-white shadow-[0px_0px_31.15px_0px_rgba(0,0,0,0.15)]">
                            <div className="px-9 py-5 mt-[50px] max-sm:mt-[35px] border-t-[#707072] border-t-[0.3px]">
                                <div className="flex w-full justify-between mb-5">
                                    <img src={getImageUrl( candidate.image)} alt={candidate.name} className="w-max-[150px] h-[138px] max-sm:w-[75px] max-sm:h-[103px]"/>
                                    <div className="flex flex-col gap-[5px] items-center">
                                        <div
                                            className="flex justify-center items-center text-base font-bold bg-[#37B34A] rounded-md text-white min-w-[90px] px-[10px] py-[5px]">
                                            {candidate.button}
                                        </div>
                                        <span className="font-medium text-sm max-sm:text-xs">{candidate.label}</span>
                                    </div>
                                </div>
                                <p className="text-[54px] max-sm:text-[40px] font-extrabold">{candidate.name}</p>
                                <p className="text-4xl max-sm:text-2xl font-normal">{candidate.position}</p>
                                <p className="text-lg max-sm:text-base font-medium my-4">{candidate.description}</p>
                                <div className="flex gap-[30px] max-sm:flex-col">
                                    <div className="text-sm font-medium flex flex-col">
                                        <p className="flex items-center">
                                            <img src={polygonIcon} alt="polygonIcon" className="mr-2"/>
                                            <span className="font-bold text-xl max-sm:text-base">{candidate.colTitle1}</span>
                                        </p>
                                        <span className="ml-5 font-medium text-base max-sm:text-sm">{candidate.experience}</span>
                                    </div>

                                    <div className="text-sm font-medium flex flex-col">
                                        <p className="flex items-center">
                                            <img src={polygonIcon} alt="polygonIcon" className="mr-2"/>
                                            <span className="font-bold text-xl max-sm:text-base">{candidate.colTitle2}</span>
                                        </p>
                                        <span className="ml-5 font-medium text-base max-sm:text-sm">{candidate.skills}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

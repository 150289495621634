import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

const PrimaryLink = ({ 
    to, className, children, ...props
}) => {
    return <Link
        className={classNames('text-xl text-[#37B34A] font-normal hover:underline underline-offset-4', className)}
        to={to}
        {...props}
    >
        {children}
    </Link>
};

export default PrimaryLink;
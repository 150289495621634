import { useNavigate } from "react-router-dom";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import ContactForm from '../components/parts/ContactForm';

const SBM02_5_1 = () => {
  const navigate = useNavigate()
  return (
    <>
      <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Job Setup', 'New Job Details', "Description", "Preview", "Pipelines", "Qualification Attributes and Interview Questions", "Application Form", "Contact Us"]} />
      <ContactForm onSubmit={() => navigate("/SBM02_5_1_1")} />
    </>
  )
}

export default SBM02_5_1

import React from "react";
import { useSelector } from "react-redux";
import BreadCrumbWhite from "../../../components/BreadCrumbWhite";
import { Footer } from "../../../components/parts/Footer";
import ModalGrop from "../../../components/parts/ModalGrop";
import { getSubscriberRole } from "../../../redux/slices/applicationSlice";
import { HiringProcessSBM01 } from "../base/HiringProcessSBM01";
import { NextStepSectionSBM01 } from "../base/NextStepSectionSBM01";
import { TabsSBM01 } from "../base/TabsSBM01";

export const ElevenStepsWrapper = ({ children, onNext, onBack, crumbs = [], activePin, tagModal, fullName = "Marc Spector" }) => {
    const subscriberRole = useSelector(getSubscriberRole);
    const { job, isLoading, application } = useSelector(state => state.jobs)

    return (
        <>
            <BreadCrumbWhite crumbs={crumbs} />

            <div className='flex-auto'>
                <div
                    className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto max-md:pt-[40px] max-md:pb-[80px]'>
                    <header
                        className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full max-md:gap-[40px]">
                        <div>
                            {
                                !isLoading ?
                                    <p className="text-lg font-semibold text-sky-900">{job?.jobTitle} / Candidates</p>
                                    :
                                    null
                            }
                            <h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950">{fullName}</h2>
                        </div>
                        <div className="flex flex-wrap gap-2 my-auto">
                            <ModalGrop tagModal={true} selectedApplicant={application} />
                        </div>
                    </header>

                    {
                        subscriberRole !== "interviewer" ?
                            <TabsSBM01 activeLink={"report-card"} />
                            :
                            null
                    }

                    <HiringProcessSBM01 activeTab={activePin} />


                    {children}

                    <NextStepSectionSBM01
                        next={onNext}
                        prev={onBack}
                    />
                </div>
            </div>


            <Footer hideCopyright={true} />
        </>
    )
}
import React from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import {useSearchParams} from "react-router-dom";


const MainContent = ({status}) => {
	return (
		<section className="flex flex-col items-center w-full px-5 py-6 lg:py-12 text-zinc-800 md:px-[20px] md:max-w-full">
			<div className="flex flex-col items-center text-center max-w-full w-[1197px] my-0 md:my-10">
				<h1 className="text-[40px] leading-[52px] font-bold uppercase lg:leading-tight lg:text-6xl">Article has been {status ? status: 'submitted'}</h1>
				<div className="self-center mt-8 h-1 bg-[#37B34A] w-[78px]" />
				<p className="mt-8 text-lg lg:text-2xl leading-7 lg:leading-10 text-center md:mx-2.5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque.</p>
			</div>
		</section>
	);
};

const AccountDeactivationConfirmationPage = () => {
	const [searchParams] = useSearchParams();
	console.log(searchParams.get('id'))
	const id = searchParams.get('id');
	const status = searchParams.get('status');

	return (
		<>
			<BreadCrumbWhite crumbs={[['EJ Support', '/SUP01'], ['Public Solution Page List', '/SUP01_2'], ['Individual Input Page', `/SUP01_2_1/${id ? id : ''}`], 'Article Submitted']} />
			<main className="flex relative flex-col pt-[40px] pb-9 z-[1] max-w-[1240px] w-full m-auto flex-1">
				<div className="flex flex-col justify-center bg-white my-auto">
					<MainContent status={status} />
				</div>
			</main>
		</>
	);
};

export default AccountDeactivationConfirmationPage;
import * as React from "react";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { useSelector } from "react-redux";
import { getCurrentPage, getCurrentPageLoading } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import PageLoader from "../components/PageLoader";


const Confirmation = ({ colorStyle = "", text = '', title }) => {

  return (
    <div className={`${colorStyle === "white" ? "text-white" : "text-[#333333]"} max-w-[1162px] px-4 w-full flex-auto py-28 lg:py-14 mx-auto flex flex-col gap-[32px] items-center justify-center`}>
      <h1 className="lg:text-[70px] lg:leading-[80px] text-[40px] leading-[52px] font-bold text-center uppercase">{title || 'Thank You'}</h1>
      <div className="w-[78px] h-[4px] bg-[#37B34A]" />
      <h2 className="lg:text-4xl lg:leading-[40px] text-pretty text-lg leading-7 font-normal text-center">
        {text}
      </h2>
    </div>
  )
}



const ThankPage = ({ hideCrumbs }) => {
  const breadcrumbItems = [
    { name: 'Home', to: "/HOM1_1", isCurrent: false },
    { name: 'Thank You', to: "/thank_you", isCurrent: true },
  ];
  const currentPage = useSelector(getCurrentPage);
  const isLoading = useSelector(getCurrentPageLoading);
  const getValue = (key) => findValue(currentPage?.blocks, key);
  
  if (isLoading) {
    return <PageLoader />
  }

  return (<>
    <>
      {
        !hideCrumbs &&
        <Breadcrumbs
          links={breadcrumbItems}
        />
      }
      {
        <Confirmation colorStyle="white" title={getValue("title_content_block")} text={getValue("description_title_content_block")} />
      }
    </>

  </>)
};


export default ThankPage;

import * as React from "react";
import Breadcrumbs from "../components/base/Breadcrumbs";
import Confirmation from "../components/Confirmation";
import { useSelector } from "react-redux";
import { getCurrentPage } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import PageLoader from "../components/PageLoader";

const HOM1_10_1 = () => {
  const breadcrumbItems = [
    { name: 'Home', to: "/HOM1_1", isCurrent: false },
    { name: 'Contact', to: "/HOM1_10", isCurrent: false },
    { name: 'Thank You', to: "/HOM1_10_1", isCurrent: true },
  ];
  const currentPage = useSelector(getCurrentPage);
  const getValue = (key) => findValue(currentPage?.blocks, key);
  console.log(currentPage?.blocks)

  return (<>
    <>
      <Breadcrumbs
        links={breadcrumbItems}
      />
      {
        currentPage ?
        <Confirmation colorStyle="white" title={getValue('content_title')} text={getValue('content_description')}/>
        :
        <PageLoader color="light" />
      }
    </>

  </>)
};


export default HOM1_10_1;
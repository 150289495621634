import React from 'react'
import blocksImage from "../../../assets/img/home/blocks.png";

const InfoSection = ({
                         contentCenter = true,
                         topSpacing = 'pt-[110px]',
                         withBlock = false,
                         background = "bg-[#EBF0F3]",
                         containerStyle = "grid grid-cols-2 gap-[70px] max-md:gap-[40px] items-center max-md:grid-cols-1",
                         children
                     }) => {
    return (
        <section
            className={`bg-top bg-no-repeat font-['Raleway'] ${topSpacing} ${background} ${contentCenter && 'flex justify-center w-full'} relative`}
            style={{backgroundImage: `url(${withBlock && blocksImage})`}}
        >
            <div className={`container ${containerStyle}`}>
                {children}
            </div>
        </section>
    )
}

export default InfoSection

import React, { useEffect, useState } from 'react';
import SelectBox from '../../../components/base/SelectBox';
import InputField from '../../../components/base/InputField';
import { useOutletContext } from 'react-router-dom';
import api from '../../../api';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/slices/applicationSlice';
import { toast } from 'react-toastify';
import PageLoader from '../../../components/PageLoader';
import Editor from '../../../components/CKEditor';
import CreateSignatureModal from '../../../components/modals/CreateSignatureModal';


const SBM01_10_14_3 = () => {

	return (
			<div className='flex-auto'>
				<div className='flex flex-col gap-11 max-w-[1440px] px-5 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
                <OfferLetterPage />

				</div>
			</div>
	)
}

const employmentTypes = [
	{
		label: 'Full Time'
	},
	{
		label: 'Part Time',
        value: 'Part-time'
	},
	{
		label: 'Contract'
	},
];

const payPeriods = [
	{
		label: 'Hour'
	},
	{
		label: 'Day',
        value: 'Day'
	},
	{
		label: 'Week'
	},
	{
		label: 'Month'
	}
];

const payFrequencies = [
	{
		label: 'Daily'
	},
	{
		label: 'Weekly'
	},
	{
		label: 'Biweekly',
        value: 'bi-weekly'
	},
	{
		label: 'Monthly'
	}
];

const replaceHtmlSign = (html, id, src) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const signatureDiv = tempDiv.querySelector(`#${id}`);
    const image = new Image();
    image.src = 'https://ej.webstaginghub.com' + src;
    image.id = id
    image.style = "width: 206px; height: 70px; object-fit: contain"
    signatureDiv.parentNode.replaceChild(image, signatureDiv);
    return tempDiv.innerHTML
}

function OfferLetterPage() {
    const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
    const [initialHtml, setInitialHtml] = useState(null)
    const [offerMode, setOfferMode] = useState('edit')
    const [payload, setPayload] = useState(null);
    useEffect(() => {
        if(!applicationId) return;
        api.jobApplications.getOfferMeetings(applicationId).then(response => {
            console.log(response)
            if(!response.success) return
            setInitialHtml(response.data.offerLetter)
            if(response.data.signApplicant && response.data.signCompany && response.data.pdf) {
                setOfferMode('view-pdf');
				setPayload(response.data);
            }
            else if(response.data.signApplicant || response.data.signCompany) {
				const {employmentType,
					payRate,
					payPeriod,
					payFrequency,
					startDate,
					recruterName,
					candidateName,
					signApplicant,
					signCompany,
					offerLetter
				} = response.data
				const variables = { employmentType, payRate, payPeriod, payFrequency, startDate, recruterName, candidateName };
				let resultStr = offerLetter;
				for (const key in variables) {
					const regex = new RegExp(`{{${key}}}`, 'g'); // Create a regex to match the placeholder
					resultStr = resultStr.replace(regex, variables[key] || key);  // Replace all occurrences
				}
				console.log(resultStr);
				if (signApplicant) {
                    resultStr = replaceHtmlSign(resultStr, 'candidate-sign', signApplicant)
				}
                if (signCompany) {
                    resultStr = replaceHtmlSign(resultStr, 'company-sign', signCompany)
				}
				setOfferMode('view-candidate')
				setPayload({...response.data, offerLetter: resultStr})
			} else {
				setPayload(response.data);
			}
        });
    }, [applicationId])
    const user = useSelector(getUser)
	const [templates, setTemplates] = useState([]);
	const [selectedTemplateId, setSelectedTemplateId] = useState(null)
    const companyId = user?.company?.id || user?.companies?.[0]?.company?.id
    useEffect(() => {
		if(!companyId) return
		api.offerTemplates.getAll(companyId).then(response => {
			if(!response.success) {
				return;
			}
			setTemplates(response.data)
		})
        api.companyTeam.getTeamMembers(companyId).then(response => {
			if (!response.success || !response?.data?.length) {
				return;
			}
			setInterviewers(response.data.map(member => ({ value: `${member?.user?.firstName} ${member?.user?.lastName}`, label: `${member?.user?.firstName} ${member?.user?.lastName}` })))
		})
	}, [companyId])
    const [interviewers, setInterviewers] = useState([]);
    const [base64Image, setBase64Image] = useState(null);
    const errorFieldNames = {
		interviewType: "Interview Type",
		duration: "Duration",
		date: "Select Available Start Times",
		times: "Meeting Times",
		employmentType: "Employment Type",
		startDate: "Start Date",
		payRate: "Pay Rate",
		payPeriod: "Pay Period",
		payFrequency: "Pay Frequency",
		recruterName: "Recruiter Name",
		offerLetter: "Offer Letter",
		interviewerId: "Interviewer"
	};
    const saveOffer = async () => {
		let haveError = false;
		Object.keys(errorFieldNames).forEach((fieldName) => {
			if (fieldName === "payRate") {
				if (+payload[fieldName] === 0) {
					haveError = true;
					toast.error(`${errorFieldNames[fieldName]} is required`);
				}
			} else if (fieldName === "interviewerId") {
				if (payload[fieldName] === null) {
					// haveError = true;
					// toast.error(`${errorFieldNames[fieldName]} is required`);
				}
			} else if (!payload[fieldName]?.length) {
				haveError = true;
				toast.error(`${errorFieldNames[fieldName]} is required`);
			}
		});

		if (haveError) {
			console.log('payload:', payload);
			return false;
		}

		let res = await api.jobApplications.createOfferMeetings(applicationId, payload);

		if (res?.success) {
			toast.success(res?.message || 'Successfully')
		} else {
			toast.error(res?.message || 'Something went wrong')
		}
	}
    const [emailLoading, setEmailLoading] = useState(false);
    const handleEmailLetter = async() => {
		setEmailLoading(true)
		const response = await api.jobApplications.sendOfferEmail(applicationId);
		setEmailLoading(false);
		toast(response.message, {
			type: response.success ? 'success' : 'error'
		})
		console.log(response)
	}
    const [rteInstanse, setRteInstanse] = useState(null);
	const [createSignatureModalOpen, setCreateSignatureModalOpen] = useState(false);
    const handleSign = async() => {
		if (!base64Image) {
			toast.error("Sign first")
			return
		}

		const formdata = new FormData();
		const blobFile = setSignatureFile(base64Image);
		formdata.append("signature", blobFile);

		const response = await api.jobApplications.signSignatureCompany(applicationId, formdata)
		toast(response.message, {
			type: response.success ? 'success' : 'error'
		})
	}
    const setSignatureFile = (imgBase64) => {
        function DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            return new Blob([ia], { type: mimeString })
        }

        const file = DataURIToBlob(imgBase64);

        return file;
    }
    const insertImage = (blockId, imageUrl) => {
        var iframe = rteInstanse.iframe
        var iframeContent = iframe.contentDocument || iframe.contentWindow.document;
        var paragraph = iframeContent.getElementById(blockId);

        if (paragraph) {
            console.log(paragraph);
            rteInstanse.selectControl(paragraph)
			// var img= rteInstanse.insertByTagName("IMG");
			// img.src=imageUrl;
			// rteInstanse.insertHTML(`<img id='${blockQuery}' src='${imageUrl}'></img>`);
            rteInstanse.insertImageByUrl(imageUrl);
			var img = iframeContent.querySelector(`img[src="${imageUrl}"]`);
			console.log(img)
			img.id = blockId
			img.style = "width: 206px; height: 70px; object-fit: contain"
        }

    };

    if(!payload) return <p className="text-xl font-semibold text-center">There are no offer yet</p>

	return (
		<div className="flex gap-12 max-md:flex-col max-md:gap-0">
			{offerMode !== 'view-pdf' &&
				<aside className="flex flex-col max-md:ml-0 max-md:w-full">
					<div className="flex flex-col px-8 lg:py-14 py-9 text-lg rounded-md border border-solid border-zinc-300">
						<h2 className="text-xl font-bold text-zinc-800">Select Template</h2>
						<SelectBox
							options={templates.map(item => ({label: item.label, value: item.id}))}
							className="mt-4 !h-[54px]"
							value={selectedTemplateId}
							onSelect={(value) => {
								setSelectedTemplateId(value);
								const template  = templates.find(({id}) => id === value);
								console.log(template.content);
								setPayload((prev) => ({
									...prev,
									employmentType: template.employmentType,
									payRate: template.payRate,
									payPediod: template.payPeriod,
									payFrequency: template.payFrequency,
									recruterName: template.recruterName,
									offerLetter: template.content
								}))
							}}
							placeholder='Select Offer Template...'
						/>
						<h2 className="mt-8 text-xl font-bold text-zinc-800">Employment Type {payload.employmentType}</h2>
						<SelectBox
							options={employmentTypes}
							className="mt-4 !h-[54px]"
							value={payload.employmentType}
							onSelect={(value) => {
								setPayload((prev) => ({
									...prev,
									employmentType: value
								}))
							}}
						/>

						<hr className="shrink-0 mt-8 h-px bg-zinc-300" />

						<h2 className="mt-8 text-xl font-bold text-zinc-800">Start Date</h2>
						<InputField
							type='date' inputBg='bg-white border border-solid border-[#DEDED9] mt-3.5'
							value={payload.startDate}
							onChange={(e) => {
								setPayload((prev) => ({
									...prev,
									startDate: e.target.value
								}))
							}}
						/>

						<hr className="shrink-0 mt-8 h-px bg-zinc-300" />

						<h2 className="mt-8 text-xl font-bold text-zinc-800">Compensation</h2>
						<div className="flex gap-3.5 justify-center mt-8">
							<div className="flex flex-col flex-1 justify-center">
								<div className="text-neutral-500">Pay Rate</div>
								<InputField
									inputBg='bg-white border border-solid border-[#DEDED9]'
									value={payload.payRate}
									onInput={(e) => {
										setPayload((prev) => ({
											...prev,
											payRate: e.target.value
										}))
									}}
								/>
							</div>
							<div className="mb-[14px] flex items-center lg:mb-[18px] h-[54px] mt-auto text-neutral-500">per</div>
							<div className="flex flex-col flex-1 justify-center">
								<div className="text-neutral-500">Pay Period</div>
								<SelectBox
									options={payPeriods}
									className="mb-[18px] !h-[54px]"
									value={payload.payPeriod}
									onSelect={(value) => {
										setPayload((prev) => ({
											...prev,
											payPeriod: value
										}))
									}}
								/>
							</div>
						</div>

						<div className="text-neutral-500">Pay Frequency</div>
						<SelectBox
							options={payFrequencies}
							className="mt-3.5 !h-[54px]"
							value={payload.payFrequency}
							onSelect={(value) => {
								setPayload((prev) => ({
									...prev,
									payFrequency: value
								}))
							}}
						/>


						<div className="mt-4 lg:mt-6 text-neutral-500">Recruiter Name</div>
						<SelectBox
							options={interviewers}
							className="mt-3.5 !h-[54px]"
							value={payload.recruterName}
							onSelect={(value) => {
								setPayload((prev) => ({
									...prev,
									recruterName: value
								}))
							}}
							placeholder={'Select recruiter...'}
						/>

						<hr className="shrink-0 mt-8 h-px bg-zinc-300" />
						{(offerMode === 'edit' && !base64Image) && <button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={saveOffer}
						>
							Save Offer Letter
						</button>}
						{(offerMode === 'view-candidate' || base64Image) && <button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={(e) => {
								setOfferMode('edit');
		                        setPayload(prev => ({...prev, offerLetter: initialHtml}))
								setBase64Image(null)
							}}
						>
							Edit Offer Letter
						</button>}
						<button
							onClick={handleEmailLetter}
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-3.5 font-bold text-center text-white whitespace-nowrap bg-sky-900 rounded-md max-md:px-5"
						>
							{emailLoading ? <PageLoader color='light' className='h-[40px]'/> : 'Email Offer Letter'}

						</button>
					</div>
				</aside>
			}
			<main className="flex flex-col w-full mx-auto">
				<div className="flex flex-col grow self-stretch w-full">
					<h1 className="text-3xl font-bold leading-10 text-zinc-800 max-md:max-w-full">Offer Letter</h1>
					{offerMode === 'edit' &&
						<Editor
						wrprClassNames="my-4"
						data={payload.offerLetter}
						onChange={val => setPayload((prev) => ({
							...prev,
							offerLetter: val
						}))}
						setInstanse={rte => setRteInstanse(rte)}
						readOnly={false}
					/>}
					{offerMode === 'view-candidate' &&
						<Editor
						wrprClassNames="my-4"
						data={payload.offerLetter}
						onChange={val => setPayload((prev) => ({
							...prev,
							offerLetter: val
						}))}
						setInstanse={rte => setRteInstanse(rte)}
						readOnly={true}
					/>}
					{offerMode === 'view-pdf' &&
						<iframe
							src={process.env.REACT_APP_API_URL + payload.pdf}
							width="100%"  className="mt-[36px] h-screen"
						/>
					}
					{offerMode !== 'view-pdf' && payload.signApplicant &&
					<div className="flex items-center justify-center gap-5">
						<button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={(e) => {
								e.preventDefault();
								setCreateSignatureModalOpen(true)
							}}
						>
							Sign
						</button>
						<button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={handleSign}
						>
							Complete and Continue
						</button>
					</div>
					}

					{createSignatureModalOpen ? <CreateSignatureModal
						open={createSignatureModalOpen}
						handleClose={()=>{ setCreateSignatureModalOpen(false); }}
						setSignature={(base64Image)=>{
							setBase64Image(base64Image)
							insertImage('company-sign', base64Image)
						}}
					/> : null }
				</div>
			</main>
		</div>


	);
}


export default SBM01_10_14_3

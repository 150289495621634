import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { array, number, object, string } from "yup";
import api from "../../../api";
import { FitScaleEvaluationTable } from "../../../components/base/FitScaleEvaluationTable";
import InputError from "../../../components/base/InputError";
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';

const Form = ({ index, register, control, errors, handleRemove }) => {

	return (
		<section className="pt-[60px] border-t border-t-[#DBDBDB] flex justify-between gap-[20px] max-md:flex-col">
			<div className="max-w-[800px] w-full">
				<h2 className="font-bold text-[22px] mb-[24px]">Milestone {index + 1}:</h2>

				<div className="mb-[24px] flex flex-col md:flex-row md:items-center">
					<span className="text-[16px] max-md:mb-[12px] md:text-xl font-semibold w-[208px]">What is it</span>
					<div className="max-w-[652px] w-full">
						<input
							type="text"
							className="grey-input-custom"
							{...register(`milestones.${index}.whatIsIt`)}
						/>
						<InputError message={errors?.[index]?.whatIsIt?.message} />
					</div>
				</div>

				<div className="mb-[24px] flex flex-col md:flex-row md:items-center">
					<span className="text-[16px] max-md:mb-[12px] md:text-xl font-semibold w-[208px]">How to measure</span>
					<div className="max-w-[652px] w-full">
						<input
							type="text"
							className="grey-input-custom"
							{...register(`milestones.${index}.howToMeasure`)}
						/>
						<InputError message={errors?.[index]?.howToMeasure?.message} />
					</div>
				</div>

				<div className="mb-[24px] flex flex-col md:flex-row md:items-center">
					<span className="text-[16px] max-md:mb-[12px] md:text-xl font-semibold w-[208px]">Timeline</span>
					<div className="max-w-[652px] w-full">
						<input
							type="date"
							className="grey-input-custom"
							placeholder="04/06/2023"
							{...register(`milestones.${index}.timeline`)}
						/>
						<InputError message={errors?.[index]?.timeline?.message} />
					</div>
				</div>

				<div className="mb-[24px] flex flex-col md:flex-row md:items-center">
					<span className="text-[16px] max-md:mb-[20px] md:text-xl font-semibold w-[208px]">Achieved or Not</span>
					<Controller
						name={`milestones.${index}.achieved`}
						control={control}
						render={({ field }) => <FitScaleEvaluationTable
							labelLow="Poor fit"
							labelHigh="Perfectly Fit"
							value={field.value}
							onChange={(achieved) => field.onChange(achieved)}
						/>}
					/>

				</div>
			</div>
			<button
				className="text-lg font-bold text-white flex items-center h-[52px] justify-center px-9 py-5 bg-[#D70000] rounded-md max-md:px-5 max-md:w-full max-md:mt-[24px]"
				onClick={handleRemove}
			>
				Remove
			</button>

		</section>
	)
}

const TrainingMilestones = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	useEffect(() => {
		setActiveStep(12)
	}, [])

	const schema = object({
		milestones: array().of(object({
			whatIsIt: string().required('This field is required'),
			howToMeasure: string().required('This field is required'),
			timeline: string().required('This field is required'),
			achieved: number().required('This field is required').min(0),
		})).required()
	});

	const { register, control, handleSubmit, formState: { errors }, setValue } = useForm({
		resolver: yupResolver(schema),
	});

	console.log('ERRORS', errors.milestones);

	const { fields, append, remove } = useFieldArray({
		control,
		name: "milestones",
		keyName: null
	});

	const onSubmit = async (data) => {
		console.log('FORM DATA', data.milestones);
		const created = [];
		const updated = [];
		data.milestones.forEach((item) => {
			if (item.id) {
				updated.push(item);
			} else {
				created.push(item);
			}
		})
		if (created.length) {
			const response = await api.trainingMilestones.createTrainingMilestones(applicationId, {
				"milestones": created
			});
			console.log(response);
		}
		if (updated.length) {
			const response = await api.trainingMilestones.updateTrainingMilestones(applicationId, {
				"milestones": updated
			});
			console.log(response);
		}
		navigate(`/SBM01_10_14_2_1/${jobId}/${applicationId}`)
	};


	useEffect(() => {
		api.trainingMilestones.getAllTrainingMilestones(applicationId).then(response => {
			if (!response.success) {
				return;
			}
			setValue('milestones', response.data)
			// setMilestones(response.data);
		})
	}, [applicationId])


	const handlePrev = () => {
		navigate(`/SBM01_10_6/${jobId}/${applicationId}`)
	}

	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
				<section className=" ">
					<h2 className="font-bold text-[28px] mdLtext-3xl">How to set up your milestones:</h2>
					<div className="flex items-center mt-[32px]">
						<span className="green-box"></span>
						<span className="ml-[16px] font-semibold text-[18px] md:text-xl">Must be measurable.</span>
					</div>
					<div className="flex items-center mt-[32px]">
						<span className="green-box"></span>
						<span className="ml-[16px] font-semibold text-[18px] md:text-xl">Must be meaningful training progress</span>
					</div>
					<div className="flex items-center mt-[32px]">
						<span className="green-box"></span>
						<span className="ml-[16px] font-semibold text-[18px] md:text-xl">Must have a clear and reasonable timeline</span>
					</div>

				</section>
				{fields.map((item, index) => <Form
					key={index}
					index={index}
					register={register}
					control={control}
					errors={errors?.milestones}
					handleRemove={async () => {
						if (item.id) {
							const response = await api.trainingMilestones.deleteTrainingMilestone(applicationId, item.id);
							console.log(response);
						}
						remove(index);
					}}
				/>)}

				{/* {newMilestones.map((item, index) =>  <Form num={index + 1 + milestones.length}/>)} */}

				<div className="flex justify-end pb-[60px] border-b border-b-[#DBDBDB]">
					<button
						onClick={() => {
							append({
								"whatIsIt": "",
								"howToMeasure": "",
								"timeline": "",
								"achieved": 1
							})
						}}
						className="justify-center py-[15px] max-md:mt-[20px] max-md:flex-auto px-[36px] font-bold text-white bg-sky-900 rounded-md max-md:px-5" tabIndex="0">
						Add a Milestone
					</button>
				</div>
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={handleSubmit(onSubmit)} />
		</div>
	)
}

export default TrainingMilestones

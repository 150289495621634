import React from 'react';
import ContactUsForm from './ContactUsForm';
import blocksImage from "../../assets/img/home/blocks.png";

const ContactSection = ({data, withBlock = true, topSpacing = "py-[110px]"}) => {
    console.log("data.description", data.description)

    return (
        <section
            className={` bg-top bg-no-repeat max-md:py-[55px] font-['Raleway'] ${topSpacing}`}
            style={{backgroundImage: `url(${withBlock && blocksImage})`}}
        >
            <div className="container">
                <div className="flex flex-col items-center text-center gap-[7px] text-[18px] mb-[42px]">
                    <span className="text-lg font-semibold text-[#0056B3]">{data.title}</span>
                    <h3 className="text-[45px] max-md:text-[32px] font-bold max-w-[830px]">
                        {data.header}
                    </h3>
                    <p
                        className="font-bold"
                        dangerouslySetInnerHTML={{__html: data.description}}
                    ></p>
                </div>
                <div className="max-w-[660px] w-full mx-auto">
                    <ContactUsForm data={data.form}/>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;

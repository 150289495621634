import React from 'react';
import { NavLink } from 'react-router-dom';
import { AboutBlock } from './AboutBlock';
import getImageUrl from "../../../assets/helpers/getImageUrl";
import blocksImage from "../../../assets/img/home/blocks.png";

export const HowItWorks = ({ data, topSpacing="pt-[50px] pb-[110px]", withBlock = false}) => {

    return (
        <section className={`w-full relative font-['Raleway'] bg-top bg-no-repeat ${topSpacing}`}
        style={{ backgroundImage: `url(${withBlock && blocksImage})` }}
        >
            <div className="flex flex-col items-center container relative z-10">
                <AboutBlock
                    title={data.title}
                    header={data.header}
                    contentCentered
                    headerStyle={"max-w-[670px]"}
                />
                <div className="flex flex-wrap justify-center gap-6 mx-auto mt-[50px]">
                    {data.steps.map((step, index) => (
                        <div
                            key={index}
                            className="flex flex-col justify-between items-start border-2 rounded-[21px] p-4 hover:border-blue-600 border-gray-200 min-w-[250px] max-w-[300px] flex-grow"
                            style={{ flexBasis: 'calc(25% - 24px)', maxWidth: '300px' }}
                        >
                            <div className="flex w-full justify-between mb-[18px]">
                                <div className="font-medium text-base p-[10px] rounded-[7px] bg-[#D0CFCF] h-[29px] flex items-center">
                                    {step.step}
                                </div>
                                <img src={getImageUrl(step.icon)} alt={step.title} className="w-[70px] h-[70px]" />
                            </div>
                            <h3 className="text-2xl font-semibold">{step.title}</h3>
                            <p className="text-sm font-medium min-h-[90px] border-t-2 border-gray-200 mt-3 pt-2 flex items-center">
                                {step.description}
                            </p>
                        </div>
                    ))}
                </div>
                <NavLink
                    to="/request-demo"
                    className="flex justify-center mt-[45px] items-center font-semibold text-lg sm:text-xl md:text-2xl bg-[#37B34A] hover:bg-green-600 rounded-md text-white h-[47px] w-[206px]"
                >
                    {data.buttonText}
                </NavLink>
            </div>
        </section>
    );
};

import React, { useState } from 'react'
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import { Footer } from '../components/parts/Footer'
import MilestoneBar from "../components/parts/MilestoneVisualization"
import { Link } from 'react-router-dom';



// Milestone Component
const Milestone = ({ colorClass, text, imgSrc, imgAlt }) => (
    <div className="flex items-center gap-3 py-1.5">
        <div className={`shrink-0 w-3 h-3 ${colorClass} rounded-full`} />
        <div className="flex-auto font-semibold">{text}</div>
        {
            imgSrc && <img src={imgSrc} alt={imgAlt} className="object-cover absolute inset-0 size-full" />
        }
    </div>
);


const SBM04_1 = () => {

    const [job, setJob] = useState()
    const [timePeriod, setTimePeriod] = useState()
    const hadleSelectJob = () => {
        setJob()
    }
    const handleSelectTimePerio = () => {
        setTimePeriod()
    }

    const jobs = [
        { value: 'cleaning_technician', label: 'Cleaning Technician' },
        { value: 'job_2', label: 'job 2' },
        { value: 'job_3', label: 'job 3' },
    ];
    const milestones = [
        { colorClass: "bg-rose-800", text: "Clarification" },
        { colorClass: "bg-red-600", text: "1st Interview" },
        { colorClass: "bg-orange-400", text: "Post Interview Survey" },
        { colorClass: "bg-orange-300", text: "2nd Interview" },
        { colorClass: "bg-amber-200", text: "Doc Review" },
        { colorClass: "bg-yellow-100", text: "Rating of the Job" },
        { colorClass: "bg-lime-200", text: "AI Evaluation" },
        { colorClass: "bg-lime-300", text: "Final Hiring Decisions" },
        { colorClass: "bg-green-400", text: "Training Insights" },
        { colorClass: "bg-green-600", text: "Offer Meeting" },
        { colorClass: "bg-emerald-800", text: "Training Milestones" },
    ];


    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Insights', 'Pipelines']} />
            <section className="flex flex-auto justify-center pb-12 max-md:pb-[80px] w-full text-zinc-800">
                <div className="flex flex-col items-center w-full max-w-[1432px] px-4">
                    <div className="flex flex-col w-full lg:py-11 py-9">
                        <div className="w-full flex max-md:flex-col gap-3 lg:gap-5 justify-between text-lg max-md:flex-wrap">
                            <div className="flex flex-col flex-1 max-w-[550px]">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="Job"
                                    name="job"
                                    id="job"
                                    value={job}
                                    onChange={hadleSelectJob}
                                    options={jobs}
                                />
                            </div>
                            <div className="flex flex-col flex-1 max-w-[550px] whitespace-nowrap">
                                <InputField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="Time Period"
                                    labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                    type="date" name="timePeriod" id="timePeriod"
                                    value={timePeriod}
                                    onChange={handleSelectTimePerio}
                                />
                            </div>
                        </div>
                        
                    </div>
                    <div className="flex flex-col justify-center w-full">
                        <header className="flex gap-5 justify-between p-9 lg:px-11 lg:py-14 w-full bg-[#F7F7F7] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                            <h1 className="font-bold flex-auto my-auto lg:text-3xl lg:leading-10 text-[28px] leading-9 text-green-500 montserrat">Pipeline Summary</h1>
                            <div className="flex flex-col text-lg text-stone-500 leading-[22px]">
                                <p><span className="font-bold">Opened:</span><span className="font-[400]"> Apr 6, 2023</span></p>
                                <p className="mt-[20px]"><span className="font-bold">Status:</span> <span className="font-[400]">Open</span></p>
                            </div>
                        </header>
                        <section className="justify-center lg:px-10 max-lg:mt-9 lg:py-10 py-9 px-[20px] w-full border-r border-b border-l max-lg:border-t border-solid border-[#DEDED9]">
                            <div className="flex gap-5 max-lg:flex-col">
                                <div className="flex flex-col w-full lg:w-[27%]">
                                    <div className="grow max-md:px-[12px]">
                                        {milestones.map((milestone, index) => (
                                            <Milestone key={index} {...milestone} />
                                        ))}
                                    </div>
                                </div>
                                <div className="flex flex-auto">
                                    <div className="flex flex-auto grow justify-center items-center text-lg font-bold text-white whitespace-nowrap max-md:max-w-full">
                                        <MilestoneBar />
                                    </div>
                                </div>
                                <div className='flex items-start lg:justify-end lg:w-[27%]'>
                                    <Link to={"/SBM04_1_1"} className="flex items-center justify-center w-full lg:max-w-[188px] px-4 h-[60px] mt-5 lg:mt-11 text-[18px] font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md hover:opacity-70">Export CSV</Link>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM04_1
import * as React from "react";
import Confirmation from "../components/Confirmation";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { useSelector } from "react-redux";
import { getCurrentPage } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import { useLocation } from "react-router-dom";

const HOM1_5_1 = ({text}) => {
  const { state } = useLocation();
  const currentPage = useSelector(getCurrentPage);
  const getValue = (key) => findValue(currentPage?.blocks, key);
  console.log(text, currentPage)
  const breadcrumbItems = [
    { name: 'Home', to: "/HOM1_1", isCurrent: false },
    { name: 'Book a Demo', to: "/HOM1_5", isCurrent: false },
    { name: 'Thank You', to: "/HOM1_5_1", isCurrent: true },
  ];


  return (
    <div>
      <Breadcrumbs
        links={breadcrumbItems}
      />
          <Confirmation colorStyle="white" title={getValue("title_content_block")}  text={getValue("description_title_content_block")}/>
    </div>
  )
};

export default HOM1_5_1;

import React from 'react'
import PageFAQSecion from '../../components/parts/PageFAQSecion'
import GetInTouchWithOurSupport from '../../components/parts/GetInTouchWithOurSupport'
import RequestDemoHero from '../../components/parts/RequestDemoHero'
import ExpectAfterSubmition from '../../components/parts/ExpectAfterSubmition'
import { useSelector } from 'react-redux'
import { getCurrentPage } from '../../redux/slices/applicationSlice'
import findValue from '../../assets/helpers/findValue'
import PageLoader from '../../components/PageLoader'

const RequestDemo = () => {

    const currentPage = useSelector(getCurrentPage);
    const getValue = (key) => findValue(currentPage?.blocks, key);

    console.log("currentPage", currentPage)

    if (!currentPage) return <PageLoader />
    return (
        <div>
            <RequestDemoHero getValue={getValue} />
            <ExpectAfterSubmition getValue={getValue} />
            {/*<PageFAQSecion />*/}
            {/*<GetInTouchWithOurSupport />*/}
        </div>
    )
}

export default RequestDemo

import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useOutletContext} from 'react-router-dom';
import {FitScaleEvaluationTable} from "../../../components/base/FitScaleEvaluationTable";
import InputField from "../../../components/base/InputField";
import PrimaryButton from "../../../components/base/PrimaryButton";
import TextareaField from "../../../components/base/TextareaField";
import {
    createJobRatingsFetch,
    getAllJobRatingsFetch,
    updateJobRatingsFetch
} from "../../../redux/thunks/jobRatingsThunks";
import {NextStepSectionSBM01} from "../base/NextStepSectionSBM01";

import {toast} from "react-toastify";
import api from "../../../api";
import SelectBox from "../../../components/base/SelectBox";
import {QuestionTypes} from "../../AP_01_Form/parts/questionTypes";


const SelectQuestion = ({config, number, value, onChange, error, labelClassName = ''}) => {
    const answerTemplate = JSON.parse(config.answerTemplate);

    return <div className='flex gap-[24px] w-full flex-col max-lg:gap-[16px]'>
        <div
            className={`flex-auto my-auto text-lg font-semibold leading-7 text-[#333333] max-md:max-w-full ${error ? 'text-red-600' : null} ${labelClassName}`}>
            {number && number + '.'}
            {config.question}
            {config.isRequired && <span className="text-teal-500">*</span>}
        </div>
        <SelectBox
            value={value}
            options={answerTemplate.map(item => ({value: item, label: item}))}
            onSelect={onChange}
        />
    </div>
}

const JobAdditionalQuestions = ({jobId, applicationId, application}) => {
    const [formData, setFormData] = useState(application?.assessments?.recuterInput || {})
    const [isLoading, setIsLoading] = useState()
    const questionsData = [
        {
            "id": 32,
            "question": "Is Resume Submitted?",
            "answerType": "yes-no",
            "isRequired": true,
            "questionCode": "Q32",
            "answerTemplate": "",
            "answerValue": ""
        },
        {
            "id": 33,
            "question": "Drug Test",
            "answerType": "select",
            "isRequired": true,
            "questionCode": "Q33",
            "answerTemplate": "[\"Pass\", \"Fail\", \"Invalid\"]",
            "answerValue": ""
        },
        {
            "id": 34,
            "question": "Own a Car?",
            "answerType": "yes-no",
            "isRequired": true,
            "questionCode": "Q34",
            "answerTemplate": "",
            "answerValue": ""
        },
        // {
        //     "id": 35,
        //     "question": "Zone",
        //     "answerType": "select",
        //     "isRequired": true,
        //     "questionCode": "Q35",
        //     "answerTemplate": "[\"Zone 1\", \"Zone 2\", \"Zone 3\", \"Zone 4\", \"Zone 5\"]",
        //     "answerValue": ""
        // },

        {
            "id": 37,
            "question": "Light Up when Discuss about the Job?",
            "answerType": "yes-no",
            "isRequired": true,
            "questionCode": "Q37",
            "answerTemplate": "",
            "answerValue": ""
        },
        {
            "id": 38,
            "question": "Do you personally like this candidate?",
            "answerType": "yes-no",
            "isRequired": true,
            "questionCode": "Q38",
            "answerTemplate": "",
            "answerValue": ""
        },
        {
            "id": 145,
            "question": "Industry of the last job",
            "answerType": "select",
            "isRequired": true,
            "questionCode": "Q31",
            "answerTemplate": "[\"Retail\", \"Dog Walker\", \"Preschool\", \"Sales\", \"Hotel Housekeeper\", \"Customer Service Phone\", \"Driver\", \"Security\", \"Home Cleaning\", \"Food Service\", \"Receptionist\", \"Bank Clerk\", \"Warehouse\", \"Commercial Cleaning\", \"Manufacturing\", \"Home Health Aid\", \"Cook\", \"Pharmacy Tech\", \"Writer\", \"USPS\", \"Manager\", \"Care Giver\", \"Dog Trainer\", \"Courier\", \"Customer Service Counter\", \"Hair Dresser\", \"Custodian\", \"Other\"]",
            "answerValue": ""
        },
        {
            "id": 36,
            "question": "Segment",
            "answerType": "select",
            "isRequired": true,
            "questionCode": "Q36",
            "answerTemplate": "[\"Inexperienced & Searching\", \"Without Strong Support\", \"With Strong Support\", \"Experienced\"]",
            "answerValue": ""
        },
    ]


    console.log("questionsData", questionsData)

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name.toLowerCase().replaceAll(" ", "_").replaceAll("?", "")]: {
                title: name,
                value: value
            }
        }))
    }
    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
                assessments: {
                    ...application?.assessments || {},
                    recuterInput: formData
                }
            })
            if (resp.success) {
                toast.success("Saved")
            } else {
                toast.error(resp.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        setIsLoading(false)
    }
    return (
        <>
            <div className="border-b border-neutral-200 pb-[24px] md:pb-9">
                <span className="text-[28px] md:text-3xl font-bold">Recruiter Input</span>
            </div>
            <div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
                {questionsData.map((item, index) => {
                    const Question = QuestionTypes[item.answerType];
                    if (!Question) return null
                    return (
                        <Question
                            key={item.id}
                            error={null}
                            config={item}
                            number={index + 1}
                            value={formData?.[item?.question.toLowerCase().replaceAll(" ", "_").replaceAll("?", "")]?.value}
                            className="mt-[0px]"
                            onChange={(value) => handleChange(item?.question, value)}
                            labelClassName="!text-[16px] !leading-[20px]"
                        />)
                })}
            </div>
            <div className="flex justify-end">
                <PrimaryButton
                    isLoading={isLoading}
                    className="justify-center max-md:w-full lg:!h-[52px] lg:!text-lg px-[36px] font-bold text-white bg-sky-900 rounded-md max-md:px-5"
                    onClick={handleSubmit}
                >
                    Save
                </PrimaryButton>
            </div>
        </>
    );
};


const RatingOfTheJob = () => {
    const navigate = useNavigate()
    const {job, application, jobId, applicationId, setActiveStep} = useOutletContext()
    const dispatch = useDispatch();
    const {jobRatings, isLoading} = useSelector(state => state.jobRatings);
    useEffect(() => {
        setActiveStep(6)
    }, [])

    const [formData, setFormData] = useState({
        "question": "What is the applicant's rating of the job?",
        "score": "",
        "comment": "",
        "reasonsNotHigher": [
            "",
            "",
            ""
        ],
        "reasonsNotLower": [
            "",
            "",
            ""
        ]
    })
    const handleChange = (name, value) => {
        setFormData(prev => ({...prev, [name]: value}))
    }
    const handleArrayChange = (arrayName, index, event) => {
        const newArray = [...formData[arrayName]];
        newArray[index] = event.target.value;
        handleChange(arrayName, newArray);
    };

    useEffect(() => {
        dispatch(getAllJobRatingsFetch({applicationId}))
    }, [])

    useEffect(() => {
        if (!jobRatings?.length) return
        setFormData(prev => ({...prev, ...jobRatings[0]}))
    }, [jobRatings])


    const handlePrev = () => {
        navigate(`/SBM01_10_3/${jobId}/${applicationId}`)
    }
    const handleNext = () => {
        navigate(`/scores/${jobId}/${applicationId}`)
    }

    const handleSubmit = () => {
        if (jobRatings?.length) {
            dispatch(updateJobRatingsFetch({
                applicationId,
                body: {
                    items: [formData]
                }
            }))
        } else {
            dispatch(createJobRatingsFetch({
                applicationId,
                body: {
                    items: [formData]
                }
            }))
        }
    }

    console.log("formData", formData)

    return (
        <div className='flex-auto'>
            <div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
                <div className="border-b border-neutral-200 pb-[24px] md:pb-9">
                    <span className="text-[28px] md:text-3xl font-bold">Rating of the Job</span>
                </div>
                <div className="flex flex-col gap-8 pb-[50px] border-b border-neutral-200">
                    <div>
                        <span className="font-semibold text-[18px] block mb-8">{formData.question}</span>
                        <FitScaleEvaluationTable
                            labelLow="Poor Fit"
                            labelHigh="Perfectly Fit"
                            value={formData.score}
                            onChange={(value) => handleChange("score", value)}
                        />
                    </div>

                    <div className="max-w-[640px] w-full">
                        <label className="font-semibold text-[18px] mb-4 block">Comments</label>
                        <TextareaField
                            className="grey-textarea !bg-white !h-[164px]"
                            value={formData.comment}
                            onChange={e => handleChange("comment", e.target.value)}
                        />
                    </div>

                    <div className="max-w-[640px] w-full">
                        <label className="font-semibold text-[18px] mb-4 block">3 reasons why the rating does not go
                            higher</label>
                        {formData.reasonsNotHigher?.map((item, index) => (
                            <InputField
                                key={index}
                                className={`!bg-white grey-input-custom ${index < 2 ? "mb-4" : ""}`}
                                value={item}
                                onChange={(e) => handleArrayChange('reasonsNotHigher', index, e)}

                            />
                        ))}
                    </div>

                    <div className="max-w-[640px] w-full">
                        <label className="font-semibold text-[18px] mb-4 block">3 reasons why the rating does not go
                            lower</label>
                        {formData.reasonsNotLower?.map((item, index) => (
                            <InputField
                                key={index}
                                className={`!bg-white grey-input-custom ${index < 2 ? "mb-4" : ""}`}
                                value={item}
                                onChange={(e) => handleArrayChange('reasonsNotLower', index, e)}
                            />
                        ))}
                    </div>

                    <div className="flex justify-end">
                        <PrimaryButton
                            isLoading={isLoading}
                            className="justify-center max-md:w-full lg:!h-[52px] lg:!text-lg px-[36px] font-bold text-white bg-sky-900 rounded-md max-md:px-5"
                            onClick={handleSubmit}
                        >
                            Save
                        </PrimaryButton>
                    </div>

                </div>
                <JobAdditionalQuestions jobId={jobId} applicationId={applicationId} application={application}/>

            </div>
            <NextStepSectionSBM01 prev={handlePrev} next={handleNext}/>
        </div>
    )
}

export default RatingOfTheJob

import { createSlice, current } from '@reduxjs/toolkit';
import { deleteDocumentByIdFetch, getAllDocumentsFetch, getAllDocumentsOfApplicationFetch, sendToApplicantLinkOnDocumentsReviewFetch, uploadDocumentsToAppicationFetch } from '../thunks/jobApplicationDocumentsThunks';

const initialState = {
    isLoading: false,
    documents: []
}

const jobApplicationDocumentsSlice = createSlice({
    name: 'jobApplicationDocumentsSlice',
    initialState,
    reducers: {},

    extraReducers(builder) {
        builder.addCase(getAllDocumentsOfApplicationFetch.fulfilled, (state, action) => {
            state.documents = action.payload;
            state.isLoading = false
        });
        builder.addCase(getAllDocumentsOfApplicationFetch.pending, (state, action) => {
            state.isLoading = true
            state.documents = null
        });
        builder.addCase(getAllDocumentsOfApplicationFetch.rejected, (state, action) => {
            state.isLoading = false
            state.documents = null
        });

        builder.addCase(uploadDocumentsToAppicationFetch.fulfilled, (state, action) => {
            state.documents = state.documents.concat(action.payload);
            state.isLoading = false
        });
        builder.addCase(uploadDocumentsToAppicationFetch.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(uploadDocumentsToAppicationFetch.rejected, (state, action) => {
            state.isLoading = false
        });

        builder.addCase(deleteDocumentByIdFetch.fulfilled, (state, action) => {
            const documentId = action.payload?.id;
            state.documents = state.documents.filter(doc => doc?.id !== documentId);
            state.isLoading = false
        });
        builder.addCase(deleteDocumentByIdFetch.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(deleteDocumentByIdFetch.rejected, (state, action) => {
            state.isLoading = false
        });

        builder.addCase(getAllDocumentsFetch.fulfilled, (state, action) => {
            state.documents = action.payload;
            state.isLoading = false
        });
        builder.addCase(getAllDocumentsFetch.pending, (state, action) => {
            state.isLoading = true
            state.documents = null
        });
        builder.addCase(getAllDocumentsFetch.rejected, (state, action) => {
            state.isLoading = false
            state.documents = null
        });
    }
})

export const {
} = jobApplicationDocumentsSlice.actions;

export default jobApplicationDocumentsSlice.reducer
import React from "react"
import logo from "../assets/img/logo.png"
import SelectBox from "../components/base/SelectBox"
import FileInputField from "../components/base/FileInputField"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { RegisterApplicantSteps } from "../components/parts/RegisterApplicantSteps";
import { useSelector } from "react-redux";

const AP_46 = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const applicationId = searchParams.get('applicationId')
    const { pathname } = useLocation();
    const jobLink = pathname.split('/the-next-step')[0]
    const breadcrumbItems = [
        {
            name: 'Job',
            to: jobLink,
            isCurrent: false
        },
        {
            name: 'The Next Step',
            isCurrent: true
        },
    ]
    const { job: {applicationForm} } = useSelector(state => state.jobs);
    console.log(applicationForm)

    return (<>
        <Breadcrumbs links={breadcrumbItems} colorStyle="secondary" />
        <main className="flex gap-[60px] max-md:flex-col max-md:gap-0 pt-[28px] pb-[90px]">
            <RegisterApplicantSteps step={7} />
            <section className="flex flex-col max-md:ml-0 max-md:w-full">
                <div className="flex flex-col  text-zinc-800 max-md:mt-10 max-md:max-w-full">
                    <h1 className="text-[40px] md:text-[3.125rem] font-bold leading-[3.375rem] text-[#333333]">
                        We Are Delighted to Receive Your Application!
                    </h1>
                    <p className="text-lg font-bold text-[#333333] mt-[24px]">
                        Your Application ID is <span className="text-[#06D6A0]">{applicationId}</span>
                    </p>
                    {applicationForm.the_next_step_active && <>
                    <h2 className="text-[28px] md:text-3xl font-bold text-[#333333] mt-[24px]">
                        {applicationForm.the_next_step_title}
                    </h2>
                    <p className="mt-[24px] text-lg font-light leading-[30px] text-[#333333]">
                        {applicationForm.the_next_step_text}
                    </p>
                    <div className="flex flex-col pt-11 mt-11 border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                        <h2 className="text-[22px] md:text-[1.375rem] font-bold text-[#333333]">
                            {applicationForm.the_next_step_you_should_hear_title}
                        </h2>
                        <p className="text-lg text-[#333333] mt-[24px] font-light">
                            {applicationForm.the_next_step_you_should_hear_text}
                        </p>
                    </div></>}
                    <footer className="flex flex-col justify-center mt-11 text-lg font-bold text-center text-white whitespace-nowrap border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                        <div className="flex gap-5 justify-between pt-10 max-md:flex-wrap max-md:max-w-full">
                            <button
                                className="flex items-center h-[52px] justify-center px-12 py-5 bg-gray-500 rounded-md max-lg:flex-auto max-lg:px-2"
                                onClick={(e) => { e.preventDefault(); navigate(`${jobLink}/you-must-submit-your-resume-here`) }}
                            >Previous</button>
                            <button
                                className="flex items-center h-[52px] justify-center px-9 py-5 bg-teal-500 rounded-md max-lg:flex-auto max-lg:px-2"
                                onClick={(e) => { e.preventDefault();
                                    localStorage.removeItem(`job-${id}-answers`);
                                    localStorage.removeItem(`job-${id}-steps`);
                                    navigate('/thank_you'); }}
                            >Save & Continue</button>
                        </div>
                    </footer>
                </div>
            </section>
        </main>
    </>)
}


export default AP_46

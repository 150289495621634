import wow from "wow.js";
import { useLayoutEffect } from "react";

export function useAnimation() {
    useLayoutEffect(() => {
        new wow({
            offset: 50,
            mobile: false,
            live: true
        }).init();
    }, [])

}
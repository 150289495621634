import React from 'react'
import bannerLightBg from '../../assets/img/banner-light.png';
import { Link } from 'react-router-dom';
import findValue from '../../assets/helpers/findValue';

const BannerLight = ({currentPage}) => {
    return (
        <div className="flex flex-col justify-center text-center relative">
            <div className="flex overflow-hidden flex-col items-center py-20 w-full min-h-[402px] max-md:px-5 max-md:max-w-full mx-auto max-w-[1080px]">
                <h2 className="relative mt-2.5 lg:text-[50px] lg:leading-[52px] text-[40px] font-bold text-center leading-[49px] w-full">
                    {findValue(currentPage?.blocks, 'title_footer_page') || 'Lorem ipsum dolor sit amet consectetur'}
                </h2>
                <div className="mt-6 w-full text-lg lg:leading-[28px] leading-[30px] text-center text-stone-500 max-md:max-w-full">
                    {findValue(currentPage?.blocks, 'description_footer_page') ||
                    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare
                    scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida
                    imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit
                    non convallis magna scelerisque.`}
                </div>
                <Link
                to={"/HOM1_5"}
                    tabIndex="0"
                    className="relative flex justify-center items-center px-16 py-6 max-md:py-3 mt-11 lg:mt-7 lg:text-2xl text-[19px] max-lg:w-full font-bold whitespace-nowrap bg-[#37B34A] rounded-md w-[276px] max-md:px-5 h-[70px] max-md:h-[60px] text-white hover:opacity-70"
                >
                    {findValue(currentPage?.blocks, 'button_footer_page') || 'Book A Demo'}
                </Link>
            </div>
            <img
                src={bannerLightBg}
                className="object-cover absolute inset-0 size-full -z-10 max-md:left-[0px]"
                alt='banner'
            />
        </div>
    );
}

export default BannerLight
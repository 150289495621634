import { createSlice } from '@reduxjs/toolkit';
import { fetchAllIndustries, getCompanyPublicInfo } from '../thunks/companiesThunks';

const initialState = {
    company: null,
    industries: [],
    isLoading: false,
}

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
    },

    extraReducers(builder) {
        builder.addCase(getCompanyPublicInfo.fulfilled, (state, action) => {
            state.company = action.payload;
            state.isLoading = false
        });

        builder.addCase(getCompanyPublicInfo.pending, (state, action) => {
            state.isLoading = true
            state.company = null
        });

        builder.addCase(getCompanyPublicInfo.rejected, (state, action) => {
            state.isLoading = false
            state.company = null
        });

        builder.addCase(fetchAllIndustries.fulfilled, (state, action) => {
            state.industries = action.payload;
            state.isLoading = false
        });

        builder.addCase(fetchAllIndustries.pending, (state, action) => {
            state.isLoading = true
            state.industries = []
        });

        builder.addCase(fetchAllIndustries.rejected, (state, action) => {
            state.isLoading = false
            state.industries = []
        });
    }
})

export const {
} = companiesSlice.actions;

export default companiesSlice.reducer
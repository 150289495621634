import BreadCrumbWhite from "../components/BreadCrumbWhite";
import React, { useEffect, useRef, useState } from "react";
import { HiringProcess } from "../components/base/ProgressStepsSBM";
import { Link } from "react-router-dom";
import ContactUsLinkBlock from "../components/parts/ContactUsLinkBlock";
import moveCursorToEnd from "../assets/helpers/moveCursorToEnd";
import OptionList from "../components/parts/OptionList";

const NextStepSection = () => {
    return (
        <section className="w-full lg:w-[1026px] mx-auto">
            <div className="flex gap-5 justify-between self-stretch w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <Link to={'/SBM02_5'} className="flex justify-center items-center px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px]">
                    Back
                </Link>
                {/*<button className="flex justify-center items-center py-[15px] px-[64px] bg-green-500 rounded-md max-md:px-5">*/}
                {/*    Next*/}
                {/*</button>*/}
            </div>
        </section>
    );
};
const SBM02_6 = () => {
    const stepsData = [
        { stepNumber: "1", label: "New Job Details", isActive: true },
        { stepNumber: "2", label: "Description", isActive: true },
        { stepNumber: "3", label: "Preview", isActive: true },
        { stepNumber: "4", label: "Pipelines", isActive: true },
        { stepNumber: "5", label: "Qualification Attributes and Interview Questions", isActive: true },
        // { stepNumber: "5", label: "Qualification Attributes", isActive: true },
        // { stepNumber: "6", label: "Interview Questions", isActive: true },
        { stepNumber: "6", label: "Application Form", isActive: true },
        { stepNumber: "7", label: "Disposition Status", isActive: true }
    ];
    const [list, setList] = useState([
        {
            title: 'Too far for vehicle owner',
            actionRemove: true,
            actionEdit: false,
        }, {
            title: 'Too far for public transportation',
            actionRemove: true,
            actionEdit: false,
        }, {
            title: 'Sensitive to travel',
            actionRemove: true,
            actionEdit: false,
        }, {
            title: 'Pet issues',
            actionRemove: true,
            actionEdit: false,
        }, {
            title: 'Client Service issues',
            actionRemove: true,
            actionEdit: false,
        }, {
            title: 'Physicality issues',
            actionRemove: true,
            actionEdit: false,
        }, {
            title: 'No show – 1st Interview',
            actionRemove: true,
            actionEdit: false,
        }, {
            title: 'No response to invitation – 1st Interview',
            actionRemove: true,
            actionEdit: false,
        }, {
            title: 'New Category...',
            actionRemove: false,
            actionEdit: false,
        },
    ]);

    const mob = document.body.offsetWidth < 768;



    return (
        <>
            <BreadCrumbWhite crumbs={[
                'Subscriber Dashboard',
                'Jobs Setup',
                'New Job Details',
                "Description",
                "Preview",
                "Pipelines",
                "Qualification Attributes and Interview Questions",
                "Application Form",
                "Disposition Status"
            ]} />

            <div className='flex-auto'>
                <div className='flex flex-col gap-[60px] max-md:gap-[40px] max-w-[1432px] px-4 w-full pt-14 pb-24 max-md:pb-[80px] max-md:pt-[24px] mx-auto'>
                    <section className="flex w-full text-center overflow-auto py-4 max-md:pb-[0px]">
                        {/* <HiringProcess stepsData={(mob ? stepsData.slice(6, 8) : stepsData)} /> */}
                        <HiringProcess stepsData={stepsData} />
                    </section>

                    <div className='text-center flex gap-3 items-center justify-center flex-col max-md:gap-[8px]'>
                        <span className='text-[40px] font-bold max-md:text-[36px]'>Let’s Get Started</span>
                        <span className='text-[18px] max-md:text-[16px] leading-[30px] max-md:leading-[22px]'>These details serve as the foundation of your job.</span>
                    </div>

                    <div className="p-11 pb-[70px] border border-neutral-200 rounded-[6px] lg:w-[1026px] mx-auto max-md:py-[36px] max-md:px-[32px]">
                        <p className="font-semibold text-3xl pb-11 max-md:text-[28px]">Disposition Status</p>

                        <ul>
                            {list.map((el, idx) =>
                                <OptionList key={idx} el={el} editAction={el.actionEdit} idx={idx} removeAction={(e) => {
                                    e.preventDefault();
                                    setList((prev) => [...prev].filter((item, index) => index !== idx));
                                }} />
                            )}
                        </ul>

                        <div className="mb-[32px]">
                            <a
                                href="#" className="text-7 font-bold text-[#37B34A] underline hover:opacity-70"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setList((prev) => [...prev, { title: '', actionEdit: true }]);
                                }}
                            >Add a New Category</a>
                        </div>
                        <ContactUsLinkBlock to="/SBM02_6_1" hr={false} />
                    </div>

                    <NextStepSection />
                </div>
            </div>
        </>
    )
}

export default SBM02_6


import React, { useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from '../components/parts/Footer';
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import Checkbox from '../components/base/Checkbox';
import TextareaField from '../components/base/TextareaField';
import { Link } from 'react-router-dom';
import { HiringProcess } from '../components/base/ProgressStepsSBM';


// const ProgressBar = ({ completed, containerClasses = "" }) => {
//     const containerStyles = {
//         height: 4,
//         width: '100%',
//         backgroundColor: "#EFEEF0",
//     }

//     const fillerStyles = {
//         height: '100%',
//         width: `${completed}%`,
//         backgroundColor: '#003578',
//         borderRadius: 'inherit',
//         textAlign: 'right',
//         transition: 'width 1s ease-in-out',
//     }

//     const labelStyles = {
//         padding: 5,
//         color: 'white',
//         fontWeight: 'bold'
//     }

//     return (
//         <div style={containerStyles} className={containerClasses}>
//             <div style={fillerStyles}>
//                 <span style={labelStyles}>{`${completed}%`}</span>
//             </div>
//         </div>
//     );
// }

// const StepIndicatorItem = ({ stepNumber, label, isActive }) => {
//     const baseStyles = "justify-center items-center self-center w-10 h-10 text-xl leading-8 font-bold text-white border-4 border-white border-solid shadow-md rounded-[360px]";
//     const activeStyles = "bg-green-500";
//     const inactiveStyles = "bg-neutral-200";

//     return (
//         <div className="flex flex-col flex-auto max-w-40 px-2">
//             <div className={`${baseStyles} ${isActive ? activeStyles : inactiveStyles}`}>
//                 {stepNumber}
//             </div>
//             <div className="mt-3.5 text-base font-semibold text-neutral-600">
//                 {label.split("\n").map((line, index, arr) => (
//                     <React.Fragment key={index}>
//                         {line}{index < arr.length - 1 && <br />}
//                     </React.Fragment>
//                 ))}
//             </div>
//         </div>
//     );
// };

// const HiringProcess = () => {
//     const mob = document.body.offsetWidth < 768;
//     const steps = [
//         { stepNumber: "1", label: "New Job Details", isActive: true },
//         { stepNumber: "2", label: "Description", isActive: true },
//         { stepNumber: "3", label: "Preview", isActive: false },
//         { stepNumber: "4", label: "Pipelines", isActive: false },
//         { stepNumber: "5", label: "Qualification Attributes", isActive: false },
//         { stepNumber: "6", label: "Interview Questions", isActive: false },
//         { stepNumber: "7", label: "Application Form", isActive: false },
//         { stepNumber: "8", label: "Disposition Status", isActive: false },
//     ];
//     const activeCount = (mob ? steps.slice(1, 3) : steps).filter(step => step.isActive)
//     const progress = (activeCount.length / (mob ? steps.slice(1, 3) : steps).length) * 100;

//     return (
//         <div className="relative flex shrink-0 flex-auto justify-between text-center">
//             <ProgressBar
//                 containerClasses='absolute z-[-1] top-[18px]'
//                 completed={progress}
//             />
//             {(mob ? steps.slice(1, 3) : steps).map((step, index) => (
//                 <StepIndicatorItem
//                     key={index}
//                     stepNumber={step.stepNumber}
//                     label={step.label}
//                     isActive={step.isActive}
//                 />
//             ))}
//         </div>
//     );
// };

const NextStepSection = () => {
    return (
        <section>

            <hr className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className="flex gap-5 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <Link to={'/SBM02_1_1'} className="flex items-center justify-center px-12 h-[52px] bg-gray-400 rounded-md max-md:px-5 max-md:flex-1">
                    Back
                </Link>
                <Link to={'/SBM02_1_4'} className="flex items-center justify-center px-16 h-[52px] bg-green-500 rounded-md max-md:px-5 max-md:flex-1">
                    Next
                </Link>
            </div>
        </section>
    );
};


const SBM02_1_2 = () => {
    const steps = [
        { stepNumber: "1", label: "New Job Details", isActive: true },
        { stepNumber: "2", label: "Description", isActive: true },
        { stepNumber: "3", label: "Preview", isActive: false },
        { stepNumber: "4", label: "Pipelines", isActive: false },
        { stepNumber: "5", label: "Qualification Attributes and Interview Questions", isActive: false },
        // { stepNumber: "5", label: "Qualification Attributes", isActive: false },
        // { stepNumber: "6", label: "Interview Questions", isActive: false },
        { stepNumber: "6", label: "Application Form", isActive: false },
        { stepNumber: "7", label: "Disposition Status", isActive: false },
    ];
    const [formData, setFormData] = useState({
        jobTitle: '',
        industry: '',
        timezone: '',
        manageRole: '',
        employeeType: '',
        country: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
    });
    const [agree, setAgree] = useState(false);
    const timezones = [
        { value: "usa", label: "USA" }
    ];
    const manageRoles = [
        { value: "no", label: "No - will not manage other employees" }
    ];
    const employeeTypes = [
        { value: "full_time", label: "Full Time - Salary" }
    ];
    const countries = [
        { value: "united_states", label: "United States" }
    ];
    const cities = [
        { value: "chicago", label: "Chicago" }
    ];
    const states = [
        { value: "illionis", label: "Illionis" }
    ];
    const zipCodes = [
        { value: "76567", label: "76567" }
    ];


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    const [description, setDescription] = useState('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi. Mauris lobortis condimentum nisi, et fermentum risus luctus eget. Cras scelerisque enim et massa scelerisque in dapibus justo rutrum. Duis sit amet mauris et dui dignissim posuere. Sed quis massa et risus pulvinar rhoncus quis sit amet magna. Vivamus ac rhoncus arcu. \n\nQuisque sit amet tortor nulla, sit amet tempus metus. Vivamus placerat scelerisque sapien vitae aliquet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris massa eros, porttitor sed tempus in, pretium mattis sapien. Praesent fringilla odio vitae est vehicula facilisis. Vestibulum accumsan molestie dictum. Integer nec sodales leo. Phasellus tincidunt massa vel purus malesuada id dapibus tortor iaculis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris interdum, dui eget congue venenatis, libero neque porta quam, nec facilisis enim nisl in tortor. Suspendisse suscipit ligula id ipsum gravida euismod. Duis mollis tempor neque a pulvinar.')
    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Job Setup', "New Job Details", "Description"]} />
            <div className="flex flex-col md:gap-11 max-w-[1432px] px-4 w-full pt-[24px] lg:pt-14 pb-24 mx-auto">
                <section className="flex w-full text-center overflow-auto py-4">
                    <HiringProcess stepsData={steps} />
                </section>
                <div className="flex flex-col items-center my-10 w-full">
                    <div className='mx-auto text-center flex flex-col gap-2 mb-11'>
                        <div className="text-4xl font-bold text-zinc-800">
                            Create the Job Description
                        </div>
                        {/*<p className='max-w-[783px] mx-auto'>*/}
                        {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum.*/}
                        {/*    Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.*/}
                        {/*    Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra.*/}
                        {/*</p>*/}
                    </div>
                    <div className="flex flex-col p-[44px] w-full bg-white rounded-md border border-solid border-[#D6D6D6] max-md:px-[32px] max-md:py-[36px]">
                        <h2 className='text-[30px] font-bold text-[#333333] max-md:text-[28px] mb-9 lg:mb-11'>Job Details</h2>
                        <TextareaField
                            className='!bg-white py-[40px] px-[36px] border border-solid border-[#D6D6D6] rounded-none max-md:p-[20px] max-md:mb-[0px]'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            height={330}
                        />
                    </div>
                </div>
                <NextStepSection />
            </div>
        </>
    )
}

export default SBM02_1_2

import React, {useState, useEffect, Fragment} from "react";
import api from "../../api";

const mockData = {
    stepTitle: "Qualification Attributes and Interview Questions",
    stepDescription: "We evaluate candidates based on 20+ Qualification Attributes (QA), the key qualifications required by the position. For each QA, we have some highly tested interview questions to evaluate the candidate’s level of competence. These QAs and interview questions are battle tested by our AI algorithm to maximize its effectiveness.",
    title: "QAs and Interview questions:",
    items: [
        {
            title: "Sample QA: Client Service",
            description: "Sample interview question: From a scale of 1 to 7 (1 is totally disagree and 7 is totally agree), how much you agree with this statement: Mary spends 15 min with a customer but fails to make a sale. Her time was wasted."
        },
        {
            title: "Sample QA: Safety",
            description: "Sample interview question: From a scale of 1 to 7 (1 is totally disagree and 7 is totally agree), how much you agree with this statement: I believe many workplace safety rules exist merely to satisfy government regulations"
        },
        {
            title: "Sample QA: Stability",
            description: "Sample interview question:",
            questions: [{
                title: "Which situation applies to you the most?",
                options: [
                    {a: "Relocating/ Moving in 6 to 8 months"},
                    {b: "Graduating from school in 6 to 8 months"},
                    {c: "Availability to work will decrease due to personal situations in 6 to 8 months."},
                    {d: "None of these apply"}
                ]
            }]
        },

    ]
}

const Step5with6 = ({payload, setPayload, industriesList, setQaPresetAttributesSelected}) => {

    const selectedIndustrie = industriesList.find(item => +item.id === +payload?.industrieId);

    const [data, setData] = useState(null);

    const getQaPresetAttributesListByIndustryId = async (id) => {
        let res = await api.jobs.getQaPresetAttributesListByIndustryId(payload.industrieId);
        setData(res.data)
        const item = res.data?.[0];
        setPayload({...payload, qaPresetId: item.id || 1});
        setQaPresetAttributesSelected(item);
    }

    useEffect(() => {
        if (payload.industrieId && selectedIndustrie) {
            getQaPresetAttributesListByIndustryId();
        }
    }, [selectedIndustrie]);


    return (
        <>
            <div className='text-center flex gap-3 items-center justify-center flex-col lg:mb-[60px]'>
                <span
                    className='lg:text-[40px] text-4xl font-bold'>{mockData.stepTitle}</span>
                <span className='lg:text-[18px] text-base leading-[22px] lg:leading-[30px]'>
                    {mockData.stepDescription}
                </span>
            </div>

            <div className="lg:border border-neutral-200 lg:px-[60px] lg:py-[50px]">
                <section
                    className="flex flex-col border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
                    <h2
                        className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800"
                    >
                        {mockData.title}
                    </h2>
                    <div className="mt-11 max-md:mt-10 max-md:max-w-full flex gap-5 flex-col">
                        {mockData.items.map((item, index) => (
                            <div className="flex gap-6 justify-between max-md:flex-wrap max-md:max-w-full" key={index}>
                                <div className="flex flex-col flex-1 max-md:max-w-full">
                                    <div
                                        className="text-xl font-bold leading-8 max-md:max-w-full"
                                    >
                                        {item.title}
                                    </div>
                                    <div
                                        className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full"
                                    >
                                        {item.description}
                                    </div>
                                    {item.questions && item.questions.map((question, index) => <div
                                        className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full"
                                        key={index}
                                    >
                                        <span className='lg:text-[18px] text-base leading-[22px] lg:leading-[50px]'>
                                            {question.title}
                                        </span>
                                        {question.options && question.options.map((option, index) => <div
                                            className=" lg:leading-[30px] whitespace-pre-wrap mt-1.5 ml-3 text-lg font-light leading-8 max-md:max-w-full"
                                            key={index}
                                        >

                                            {Object.keys(option) + ") "}
                                            {Object.values(option)}
                                        </div>)}
                                    </div>)}
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </>
    )
}

export default Step5with6

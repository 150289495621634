import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const getPagesList = createAsyncThunk(
    '/get-pages-list',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.support.pages();
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getPageById = createAsyncThunk(
    '/get-pages-by-id',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.support.pages(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const updatePagePresetById = createAsyncThunk(
    '/update-pages-by-id',
    async (payload, { rejectWithValue }) => {
        console.log(payload);
        try {
            const res = await api.support.updatePagePreset(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const updatePageDataById = createAsyncThunk(
    '/update-page-data-by-id',
    async (payload, { rejectWithValue }) => {
        console.log(payload);
        try {
            const res = await api.support.updatePageData(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);



// SUPPORT SOLUTIONS

export const fetchSupportSolutionsPages = createAsyncThunk(
    '/fetchSupportSolutionsPages',
    async (_, { rejectWithValue }) => {
        
        try {
            const res = await api.supportSolutions.getSupSolPages();
            
            console.log(res, 'res')
            
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchSupportSolutionsOnePages = createAsyncThunk(
    '/fetchSupportSolutionsOnePages',
    async (id, { rejectWithValue }) => {

        try {
            const res = await api.supportSolutions.getSupSolOnePage(id);

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateSupSolPage = createAsyncThunk(
    '/fetchCreateSupSolPage',
    async (payload, { rejectWithValue }) => {

        try {
            const res = await api.supportSolutions.createSupSolPage(payload);

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUpdateSupSolPage = createAsyncThunk(
    '/fetchUpdateSupSolPage',
    async (payload, { rejectWithValue }) => {

        try {
            const res = await api.supportSolutions.updateSupSolPage(payload.data, payload.id);

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchDeleteSupSolPage = createAsyncThunk(
    '/fetchDeleteSupSolPage',
    async (id, { rejectWithValue }) => {

        try {
            const res = await api.supportSolutions.deleteSupSolPage(id);

            console.log(res, 'res')

            if (res.success) {
                return id;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchSupSolCategories = createAsyncThunk(
    '/fetchSupSolCategories',
    async (_, { rejectWithValue }) => {

        try {
            const res = await api.supportSolutions.getSupSolCategories();

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateSupSolCategories = createAsyncThunk(
    '/fetchCreateSupSolCategories',
    async (payload, { rejectWithValue }) => {

        try {
            const res = await api.supportSolutions.createSupSolCategory(payload);

            console.log(res, 'res cat')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);


export const fetchSupSolTags = createAsyncThunk(
    '/fetchSupSolTags',
    async (_, { rejectWithValue }) => {

        try {
            const res = await api.supportSolutions.getSupSolTags(_);

            console.log(res, 'res tags')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateSupSolTags = createAsyncThunk(
    '/fetchCreateSupSolTags',
    async (payload, { rejectWithValue }) => {

        try {
            const res = await api.supportSolutions.createSupSolTag(payload);

            console.log(res, 'res create tags')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
import React, {useEffect, useState} from "react";
import {FitScaleEvaluationTable} from "../../../../components/base/FitScaleEvaluationTable";
import TextareaField from "../../../../components/base/TextareaField";
import SelectField from "../../../../components/base/SelectField";
import {NextStepSectionSBM01} from "../../base/NextStepSectionSBM01";
import {TotalScore} from "../../base/TotalScoreSBM01";

export const StepTwo = () => {

    return (
        <>
            <InterviewSection />
            <EvaluationResults />
        </>
    )
}

const ScoreCard = ({ category, score }) => (
    <div className="flex gap-5 justify-between max-w-[400px] w-full">
        <div className="my-auto font-bold">{category}</div>
        <div className="flex gap-2 justify-center px-5 py-4 min-w-[96px] bg-white border border-solid border-zinc-300 max-md:px-5">{score}</div>
    </div>
);

const EvaluationResults = () => {
    const scores = [
        { category: "Stability", score: "90%" },
        { category: "Fitness for the Job", score: "90%" },
        { category: "Physicality", score: "90%" },
        { category: "Open Mindedness", score: "90%" },
        { category: "Quality", score: "90%" },
        { category: "Coachability", score: "90%" }
    ];

    return (
        <main className="flex flex-col items-start text-lg">
            <section className="flex gap-5 max-w-full whitespace-nowrap text-zinc-800 w-[400px]">
                <TotalScore title={"Total Score"} score={90} />
            </section>
            <div className='flex justify-between max-w-[900px]'>
                <section className="flex flex-wrap gap-y-6 max-w-[900px] mt-7 max-md:mt-[16px] w-full text-zinc-800 max-md:gap-y-[16px]">
                    {scores.map((score, index) => (
                        <div
                            className={`w-full lg:w-1/2 flex ${index % 2 !== 0 ? "lg:justify-end" : ""}`}
                            key={index}
                        >
                            <ScoreCard category={score.category} score={score.score} />
                        </div>
                    ))}
                </section>
            </div>
            <footer className="flex gap-5 justify-between self-stretch mt-12 w-full text-center max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                <button className="max-md:flex-1 px-2 py-3 md:py-5 min-w-[150px] font-semibold text-green-500 underline hover:opacity-70" tabIndex="0">
                    See More
                </button>
                <button className="justify-center max-md:flex-1 min-w-[150px] px-2 py-3 md:py-5 font-bold text-white bg-sky-900 rounded-md max-md:px-5 hover:opacity-70" tabIndex="0">
                    Save
                </button>
            </footer>
        </main>
    );
};

const InterviewQuestion = ({ question, fitRating, isRequired, onChange, index }) => {
    const [formData, setFormData] = useState({
        question: question,
        fitRating: fitRating,
        comment: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        if (formData, onChange) {
            onChange(formData)
        }
    }, [formData])

    return (
        <>
            <div className="mt-12 text-lg font-semibold text-zinc-800 max-md:mt-10 max-md:max-w-full">
                {index ? index : ""}. {question}{" "}
                {isRequired && <span className="text-red-600">*</span>}
            </div>
            <FitScaleEvaluationTable
                value={formData.fitRating}
                onChange={(value) => setFormData(prevState => ({ ...prevState, fitRating: value }))}
            />
            <div className='mt-9 max-w-[640px] w-full'>
                <div className="text-lg font-semibold text-zinc-800 w-full">
                    Comments
                </div>
                <TextareaField
                    value={formData.comment}
                    onChange={handleChange}
                    name="comment"
                    id="comment"
                    inputBg='shrink-0 mt-2 lg:mt-4 max-lg:!h-[150px] max-w-full bg-white border border-solid border-zinc-300 h-[164px] w-[640px] !rounded-[0px]'
                />
            </div>
        </>
    )
};

const InterviewSection = () => {
    const [questions, setQuestions] = useState([
        {
            question: "Lorem ipsum dolor sit amet?",
            fitRating: 1,
            comment: "",
            isRequired: true
        },
        {
            question: "Lorem ipsum dolor sit amet?",
            fitRating: 1,
            comment: "",
            isRequired: true
        },
        {
            question: "Lorem ipsum dolor sit amet?",
            fitRating: 1,
            comment: "",
            isRequired: true
        },
    ])
    const [recruiter, setRecuter] = useState()

    const recruiters = [
        {
            value: "recruiter_1",
            label: "Recruiter 1"
        },
        {
            value: "recruiter_2",
            label: "Recruiter 2"
        },
    ]
    const handleSelect = (value) => {
        setRecuter(value)
    }

    const handleChange = (formData) => {
        const { question, fitRating, comment } = formData
        const questionIndex = questions.findIndex(item => item.question === formData.question);

        if (questionIndex !== -1) {
            const newData = [...questions];
            newData[questionIndex] = { question, fitRating, comment };
            setQuestions(newData);
        } else {
            setQuestions(prev => [...prev, { question, fitRating, comment }]);
        }
    };

    return (
        <div className="flex flex-col items-stretch">
            <div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
                2<sup>st</sup> Interview
            </div>
            <div className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />

            <div className="flex max-lg:flex-col -mb-4 justify-start gap-2 lg:gap-24 self-stretch mt-9 w-full">
                <div className="my-auto lg:text-lg font-semibold text-zinc-800">
                    List of recruiter names
                </div>
                <SelectField
                    inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 max-w-[815px] w-full"
                    name="department"
                    id="department"
                    value={recruiter}
                    onChange={handleSelect}
                    options={recruiters}
                />
            </div>
            {
                questions.map((question, idx) => (
                    <InterviewQuestion
                        key={idx}
                        index={idx + 1}
                        {...question}
                        onChange={handleChange}
                    />
                ))
            }
        </div>
    );
};
import {useSelector} from "react-redux";
import {getCurrentPage, getCurrentPageLoading} from "../../redux/slices/applicationSlice";
import PageLoader from "../../components/PageLoader";
import React from "react";
import HeroSolutions from "../../components/parts/publickPageParts/HeroSolutions";
import CategoryCard from "../../components/parts/publickPageParts/CategoryCard";
import {AboutBlock} from "../../components/parts/publickPageParts/AboutBlock";
import {WhyChose} from "../../components/parts/publickPageParts/WhyChose";
import TopPicked from "../../components/parts/publickPageParts/TopPicked";
import {RecruitSmarter} from "../../components/parts/publickPageParts/RecruitSmarter";
import InfoSection from "../../components/parts/publickPageParts/InfoSection";
import findValue from "../../assets/helpers/findValue";
import getImageUrl from "../../assets/helpers/getImageUrl";
import teamImage from "../../assets/img/home/team.png";

const generateSolutionsPageData = (blocks) => {
    return {
        hero: {
            header: findValue(blocks, "hero_title"),
            description: [findValue(blocks, "hero_desc")],
            total: {
                title: findValue(blocks, "hero_hires_title"),
                subTitle: findValue(blocks, "hero_hires_text"),
                candidates: findValue(blocks, "hero_hires_count"),
                label: findValue(blocks, "hero_hires_label"),
            },
            image: findValue(blocks, "hero_image") ? getImageUrl(findValue(blocks, "hero_image")) : null,
            button: findValue(blocks, "hero_button")
        },
        categories: {
            title: findValue(blocks, "jobs_label"),
            header: findValue(blocks, "jobs_title"),
            cards: (findValue(blocks, "jobs_list")?.data || []).map(item => ({
                title: item.title,
                jobs: item.label,
                description: item.text,
                button: item.button,
                img: item.image ? getImageUrl(item.image) : null
            }))
        },
        topPicked: {
            label: findValue(blocks, "top_picked_label"),
            title: findValue(blocks, "top_picked_title"),
            categories: (findValue(blocks, "top_picked_categories")?.data || []).map(category => ({
                title: category.title,
                img: getImageUrl(category.image),
            })),
            cads: findValue(blocks, "top_picked_list")?.data || []
        },
        pricing: {
            title: findValue(blocks, "pricing_label"),
            header: findValue(blocks, "pricing_title"),
            description: [findValue(blocks, "pricing_text")],
            button: findValue(blocks, "pricing_button"),
            img: findValue(blocks, 'pricing_image'),
            features: (findValue(blocks, "pricing_list")?.data || []).map(item => ({
                img: item.image,
                text: item.text,
            }))
        },
        candidate: {
            title: findValue(blocks, "select_candidate_label"),
            header: findValue(blocks, "select_candidate_title"),
            description: [findValue(blocks, "select_candidate_text")],
            img: findValue(blocks, "select_candidate_image") ? getImageUrl(findValue(blocks, "select_candidate_image")) : null,
            button: findValue(blocks, "select_candidate_button")
        }
    };
};


const Solutions = () => {
    const currentPage = useSelector(getCurrentPage);
    const isLoading = useSelector(getCurrentPageLoading);
    const data = generateSolutionsPageData(currentPage?.blocks || []);

    if (isLoading) return <PageLoader/>;

    return (
        <div className="public-page">
            <HeroSolutions
                header={data.hero.header}
                description={data.hero.description}
                total={data.hero.total}
                image={data.hero.image}
                button={data.hero.button}
            />

            <section className="w-full pt-[70px] pb-[85px] flex flex-col gap-9 relative font-['Raleway'] bg-[#EBF0F3]">
                <div className="w-full container">
                    <AboutBlock
                        title={data.categories.title}
                        header={data.categories.header}
                        headerStyle={"max-w-[850px]"}
                        contentCentered
                    />
                    <div
                        className="grid grid-cols-4 max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-y-[39px] gap-x-[23px] mt-[40px]">
                        {data.categories.cards.map((category, idx) => (
                            <CategoryCard
                                key={idx}
                                {...category}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <WhyChose
                blocks={currentPage?.blocks}
                spacing="pt-[136px] pb-[70px]"
                withBlock
            />

            <TopPicked
                label={data.topPicked.label}
                title={data.topPicked.title}
                categories={data.topPicked.categories}
                users={data.topPicked.cads}
            />

            <RecruitSmarter
                title={data.pricing.title}
                header={data.pricing.header}
                description={data.pricing.description}
                button={data.pricing.button}
                features={data.pricing.features}
                spacing={"py-[100px] max-md:py-[80px]"}
                withBlock={false}
                image={data?.pricing?.img}
            />

            <InfoSection topSpacing="py-[60px]">
                <AboutBlock
                    title={data.candidate.title}
                    header={data.candidate.header}
                    description={data.candidate.description}
                    button={data.candidate.button}
                />
                <div className="flex items-center justify-center h-auto max-md:h-[320px]">
                    <img src={data.candidate.img || teamImage} alt="candidate" className="absolute bottom-0 wow bounceInUp"/>
                </div>
            </InfoSection>
        </div>
    );
};

export default Solutions;

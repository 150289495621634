import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import PrimaryButton from '../../components/base/PrimaryButton';
import { updatePagePresetById } from '../../redux/thunks/supportThunks';
import { uuidv4 } from '../../utils';
import PageEditorBlocks from './PageEditorBlocks';

const HOM_pages_template = () => {
    const dispath = useDispatch()
    const data = useOutletContext()


    const [blocks, setBlocks] = useState(data.blocks)
    const [updatedBlocks, setUpdatedBlocks] = useState([])

    const handleInputChange = (key, value, id) => {
        const copyData = JSON.parse(JSON.stringify(blocks));

        copyData.forEach(block => {
            if (block.blockPresets.length) {
                block.blockPresets.forEach(blockPreset => {
                    if (blockPreset.key === key) {
                        blockPreset.value = value;
                    }
                });
            }
        });
        setBlocks(copyData);

        const copyUpdatedBlocks = [...updatedBlocks];
        if (!copyUpdatedBlocks.includes(id)) {
            copyUpdatedBlocks.push(id);
        }
        setUpdatedBlocks(copyUpdatedBlocks);
    };

    useEffect(() => {
      if (data.blocks) setBlocks(data.blocks)
    }, [data.blocks])

    const handleListChange = (key, value, id, preset) => {
        const copyData = JSON.parse(JSON.stringify(blocks));

        copyData.forEach(block => {
            if (block.blockPresets.length) {
                block.blockPresets.forEach(blockPreset => {
                    if (blockPreset.id === id) {
                        if (blockPreset?.value && blockPreset?.value?.data?.length) {
                            const current = blockPreset.value.data[preset.index]
                            current[key] = value
                        }
                    }
                });
            }
        });
        setBlocks(copyData);
        const copyUpdatedBlocks = [...updatedBlocks];
        if (!copyUpdatedBlocks.includes(id)) {
            copyUpdatedBlocks.push(id);
        }
        setUpdatedBlocks(copyUpdatedBlocks);
    }

    const handleSubmit = async () => {
        if (!updatedBlocks.length) return false;
        const removeBlockPreset = id => {
            setUpdatedBlocks(prev => prev.filter(bu => bu !== id))
        };

        try {
            for (const blockPresetId of updatedBlocks) {
                const blockPreset = blocks.reduce((res, block) => {
                    const blockPreset = (block?.blockPresets || []).find(bps => bps.id === blockPresetId);

                    if (blockPreset) return blockPreset;

                    return res;
                }, false);


                if (!blockPreset) {
                    removeBlockPreset(blockPresetId);
                    continue;
                }

                const formData = new FormData();
                if (blockPreset.type !== 'list_items') {
                    if (blockPreset.type === 'image') {
                        formData.append('images', blockPreset.value);
                    } else {
                        formData.append('value', blockPreset.value);
                    }
                } else {
                    const images = [];

                    const dataValueToUpdate = _.cloneDeep((blockPreset?.value?.data || [])).map(dataItem => {
                        for (const schema of blockPreset?.value?.schema || []) {
                            console.log(typeof dataItem[schema.key], "schema");
                            if (schema.type === 'image') {
                                if (dataItem[schema.key] instanceof File || dataItem[schema.key] instanceof Blob) {
                                    const name = `${uuidv4()}_uploading`;
                                    images.push({ file: dataItem[schema.key], name });

                                    dataItem[schema.key] = name;
                                }
                            }
                        }

                        return dataItem;
                    });

                    formData.append('value', JSON.stringify({
                        data: dataValueToUpdate,
                        schema: blockPreset?.value?.schema
                    }));

                    images.forEach((image, i) => {
                        formData.append(`images`, image.file, image.name);
                    });
                }
                formData.forEach((value, key) => {
                    console.log(`${key}: ${value}`);
                });
                dispath(updatePagePresetById({ presetId: blockPresetId, body: formData }))

                removeBlockPreset(blockPresetId);
            }

            console.log('Saved Presets', 'Presets has been saved');
        } catch (err) {
            console.error(err);
        }
    }

    const parsedItemsList = (data) => {
        return data.data.reduce((result, dataItem, index) => {
            let items = data.schema.map(schema => {
                const value = dataItem[schema.key];

                return {
                    key: schema.key,
                    type: schema.type,
                    index,
                    value
                };
            });

            result.push(items);
            return result;
        }, []);
    };

    return (
        <>
            {
                blocks && blocks.length ?
                    <div>
                        {blocks.map((block) => (
                            <div key={block.id} className="whitespace-normal mb-8">
                                <h2 className="text-4xl font-bold mb-6">{block.name}</h2>
                                {block.blockPresets.length && block.blockPresets.map((preset, index) => (
                                    <React.Fragment key={preset?.id || index}> {
                                        preset &&
                                        <div className="mb-4">
                                            {(preset.type === 'list_items') ?
                                                <div className={preset.value.schema.map(item => item.type).includes('html') ? "" : "grid grid-cols-1 lg:grid-cols-3 gap-9"}>
                                                    {parsedItemsList(preset.value).map((listBlock, i) => (
                                                        <div className="flex flex-col mb-4" key={i}>
                                                            {listBlock.map((item, j) => (
                                                                <PageEditorBlocks key={j} data={{ ...item, id: preset.id }} preset={preset.value} handleInputChange={handleListChange} />
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                <PageEditorBlocks data={preset} handleInputChange={handleInputChange} />
                                            }
                                        </div>
                                    }
                                    </React.Fragment>
                                ))}
                            </div>
                        ))}
                        <PrimaryButton
                            onClick={handleSubmit}
                            className={"px-6"}
                        >
                            Update Blocks
                        </PrimaryButton>
                    </div>
                    :
                    <div className='text-4xl pt-5 md:text-5xl font-bold text-sky-950 text-center'>No Found</div>
            }
        </>
    )
}

export default HOM_pages_template

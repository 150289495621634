import React, { useEffect } from 'react'
import PublickPagesFooter from '../components/parts/PublickPagesFooter'
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderNew } from '../components/parts/publickPageParts/HeaderNew'
import { useAnimation } from '../components/customHooks/useAnimation'

const NewDesignPublicPagesLayout = ({ overflow = "overflow-hidden" }) => {

	const { pathname } = useLocation();
	useAnimation()
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div className='flex flex-col min-h-[100dvh] shrink-0'>
			<HeaderNew colorStyle={'black'} />
			<main className={`flex-auto max-w-full ${overflow}`}>
				<Outlet />
			</main>
			<PublickPagesFooter />
		</div>
	)
}

export default NewDesignPublicPagesLayout

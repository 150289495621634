import { createSlice } from '@reduxjs/toolkit';
import { getPublicSolutionPageByIdFetch, getPublicSolutionPagesFetch, getSupportSolutionPageByIdFetch, getSupportSolutionPagesFetch } from '../thunks/solutionThunks';

const initialState = {
    isLoading: false,
    data: [],
    categories: [],
    error: ""
}

const getCategories = (data) => {
    const categories = data.map(item => item.category)
    const res = []

    categories.forEach((catergory => {
        if (catergory && !res.find(el => el.id === catergory.id)) {
            res.push(catergory)
        }
    }))
    return res
}

const solutionSlice = createSlice({
    name: 'solution',
    initialState,
    reducers: {
    },

    extraReducers(builder) {
        builder.addCase(getPublicSolutionPagesFetch.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (!payload?.success) {
                state.error = payload?.message
            }
            state.data = payload
            if (payload.length) {
                state.categories = getCategories(payload)
            }
        });
        builder.addCase(getPublicSolutionPagesFetch.pending, (state, { payload }) => {
            state.isLoading = true
        });
        builder.addCase(getPublicSolutionPagesFetch.rejected, (state, { payload }) => {
            state.isLoading = false
            state.error = "something went wrong"
        });
        //
        builder.addCase(getSupportSolutionPagesFetch.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (!payload?.success) {
                state.error = payload?.message
            }
            state.data = payload
            if (payload.length) {
                state.categories = getCategories(payload)
            }
        });
        builder.addCase(getSupportSolutionPagesFetch.pending, (state, { payload }) => {
            state.isLoading = true
        });
        builder.addCase(getSupportSolutionPagesFetch.rejected, (state, { payload }) => {
            state.isLoading = false
            state.error = "something went wrong"
        });
        //
        builder.addCase(getSupportSolutionPageByIdFetch.fulfilled, (state, { payload }) => {
            state.isLoading = false
            console.log("PAYLOAD", payload);

            if (!payload?.success) {
                state.error = payload?.message
            }
            state.article = payload
        });
        builder.addCase(getSupportSolutionPageByIdFetch.pending, (state, { payload }) => {
            state.isLoading = true
        });
        builder.addCase(getSupportSolutionPageByIdFetch.rejected, (state, { payload }) => {
            state.isLoading = false
            state.error = "something went wrong"
        });
        //
        builder.addCase(getPublicSolutionPageByIdFetch.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (!payload?.success) {
                state.error = payload?.message
            }
            state.article = payload
        });
        builder.addCase(getPublicSolutionPageByIdFetch.pending, (state, { payload }) => {
            state.isLoading = true
        });
        builder.addCase(getPublicSolutionPageByIdFetch.rejected, (state, { payload }) => {
            state.isLoading = false
            state.error = "something went wrong"
        });
    }
})

export const {
} = solutionSlice.actions;

export default solutionSlice.reducer
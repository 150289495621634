
import React, { useEffect, useRef, useState } from "react";
import ContactUsLinkBlock from "../../components/parts/ContactUsLinkBlock";
import OptionList from "../../components/parts/OptionList";

const Step8 = ({ setContactFormOpen, payload, setPayload, bottomPaste = null }) => {
    const [list, setList] = useState(payload.dispositionStatus ? payload.dispositionStatus.map(item => ({
        title: item,
        actionRemove: true,
        actionEdit: false,
    })) : []);

    const mob = document.body.offsetWidth < 768;

    useEffect(()=>{
        setPayload({
            ...payload,
            dispositionStatus: list.map(item => item.title)
        });
    }, [list])

    return (
        <>
            <div className='text-center flex gap-3 items-center justify-center flex-col max-md:gap-[8px]'>
                <span className='text-[40px] font-bold max-md:text-[36px]'>Let’s Get Started</span>
                <span className='text-[18px] max-md:text-[16px] leading-[30px] max-md:leading-[22px]'>These details serve as the foundation of your job.</span>
            </div>

            <div className="p-11 pb-[70px] border border-neutral-200 rounded-[6px] lg:w-[1026px] mx-auto max-md:py-[36px] max-md:px-[32px]">
                <p className="font-semibold text-3xl pb-11 max-md:text-[28px]">Disposition Status</p>

                <ul>
                    {list.map((el, idx) => 
                        <OptionList
                            key={idx + el.title} el={el} editAction={el.actionEdit} idx={idx} 
                            removeAction={(e) => {
                                e.preventDefault();
                                setList((prev) => [...prev].filter((item, index) => index !== idx));
                            }}
                            change={(value)=>{
                                setList((prev) => {
                                    return [...prev].map((item, index) => index === idx ? {...item, title: value} : item);
                                });
                            }}
                        />
                    )}
                </ul>

                <div className="mb-[32px]">
                    <a
                        href="#" className="text-7 font-bold text-[#37B34A] underline hover:opacity-70"
                        onClick={(e) => {
                            e.preventDefault();
                            setList((prev) => [...prev, { title: '', actionEdit: true }]);
                        }}
                    >Add a New Category</a>
                </div>

                <ContactUsLinkBlock setContactFormOpen={setContactFormOpen} hr={false} topPaste={bottomPaste} />
            </div>
        </>
    )
}

export default Step8


import React, { useEffect, useState } from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import { Footer } from "../components/parts/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUser } from "../redux/slices/applicationSlice";
import { fetchAllIndustries, fetchBuildCompanies, fetchUpdateBuildCompanies } from "../redux/thunks/companiesThunks";
import SelectBox from "../components/base/SelectBox";
import { updateUserFetch } from "../redux/thunks/applicationThunks";
import TextareaField from "../components/base/TextareaField";
import { toast } from "react-toastify";

const SBM05_1_1 = () => {

    const dispatch = useDispatch()
    const me = useSelector(getUser)

    console.log(me, 'me')

    const [crumbs, setCrumbs] = useState(['Subscriber Dashboard', 'Settings', 'Admin Settings'])
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDemo, setIsDemo] = React.useState({ isDemo: false });
    const [isOwner, setIsOwner] = useState(false)

    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        // topHiringChallenges: '',
        // login: '',
        // password: '',
        // cardNumber: '',
        // expirationDate: '',
        // cvv: '',
        // postalCode: '',
    });

    const [companyData, setCompanyData] = React.useState({
        name: '',
        companySize: '',
        description: '',
        industrieId: '',
    })

    useEffect(() => {
        if (me) {
            // setFormData(prev => ({
            //     ...prev,
            //     email: me.email,
            //     firstName: me.firstName,
            //     lastName: me.lastName,
            // }))

            if (me.companies.length && me.companies[0]?.role === 'admin') {
                setIsOwner(true)
                setCompanyData(prev => ({
                    name: me.company.name,
                    description: me.company.description,
                    industrieId: me.company?.industrieId || me.company.industry.id,
                    companySize: me.company.companySize,
                }))
                // setCrumbs(prev => ([...prev, 'Company Info']))
            } else {
                // setCrumbs(prev => ([...prev, 'Account Info']))
            }
        }
    }, [me])


    const [industryOptions, setIndustryOptions] = React.useState([
        { label: "Choose", value: '' }
    ])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
        setErrors(prevState => ({ ...prevState, [name]: false }))
    };
    const handleChangeCompany = (e) => {
        const { name, value } = e.target;
        setCompanyData(prevState => ({ ...prevState, [name]: value }));
        setErrors(prevState => ({ ...prevState, [name]: false }))
    };

    useEffect(() => {

        const req = async () => {

            const inds = await dispatch(fetchAllIndustries())
            setIndustryOptions([{ label: "Choose", value: '' }, ...inds.payload.map(el => ({ label: el.name, value: el.id }))])
        }

        req()

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault()

        let fields = []

        if (isOwner) {
            fields = [...fields, ...Object.entries(companyData)]
        }

        console.log(fields)

        let anyErrors = false

        fields.forEach(el => {
            if (!el[1]) {
                setErrors(prevState => ({ ...prevState, [el[0]]: true }))
                anyErrors = true
            }
        })


        if (anyErrors) return
        console.log('hello')
        setIsLoading(true)

        // const userReq = await dispatch(updateUserFetch(formData))
        // console.log(userReq, 'userReq')

        if (isOwner) {
            const companyReq = await dispatch(fetchUpdateBuildCompanies({ ...companyData, id: me.company.id }))
            console.log(companyReq, 'userReq')
            if(!companyReq.payload.success) {
                toast.error(companyReq.payload.message)
            } else {
                toast.success('Changes Saved successfully!')
            }
        }

        setIsLoading(false)

    }

    return (
        <>
            <BreadCrumbWhite crumbs={crumbs} />
            <main className="flex relative flex-col pt-10 pb-20 lg:py-[90px] z-[1] max-w-[1272px] px-4 w-full m-auto flex-1">
                <div className="flex flex-col justify-center bg-white">
                    <section className="flex flex-col justify-between items-center px-8 py-9 lg:px-16 lg:py-11 bg-white rounded-md shadow-def">
                        <form className="w-full" onSubmit={handleSubmit}>
                            <div className="grid lg:grid-cols-2 lg:gap-[60px]">
                                <div className="w-full">
                                    {/* <InputField
                                        label="Work Email" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        type="email" name="email" id="email" placeholder="youremailaddress@mail.com"
                                        value={formData.email} onChange={handleChange} error={errors.email} disabled={isLoading}
                                    /> */}
                                    {/* <InputField
                                        label="First Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        name="firstName" id="firstName" placeholder="First name" disabled={isLoading}
                                        value={formData.firstName} onChange={handleChange} error={errors.firstName}
                                    /> */}
                                    {/* <InputField
                                        label="Last Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        name="lastName" id="lastName" placeholder="Last name" disabled={isLoading}
                                        value={formData.lastName} onChange={handleChange} error={errors.lastName}
                                    /> */}
                                    {isOwner && <InputField
                                        label="Company Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        name="name" id="name" placeholder="Company name" disabled={isLoading}
                                        value={companyData.name} onChange={handleChangeCompany} error={errors.name}
                                    />}

                                    {isOwner && <SelectBox options={[{ label: "Choose", value: '' }, {
                                        value: '1-10',
                                        label: '1-10 Employees'
                                    },
                                    { value: '11-50', label: '11-50 Employees' },
                                    { value: '51-200', label: '51-200 Employees' }]} label="Company Size"
                                        onSelect={val => {
                                            setCompanyData(prevState => ({ ...prevState, 'companySize': val }))
                                            setErrors(prevState => ({ ...prevState, 'companySize': false }));

                                        }}
                                        className={'!mt-[10px] '}
                                        value={companyData.companySize} svg={2}
                                        error={errors.companySize} disabled={isLoading}
                                    />}

                                    {isOwner && <div className="lg:gap-5 mt-[18px]">
                                        {/* <InputField
                                            label="Job Title"
                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                            name="jobTitle" id="jobTitle" placeholder="Job title" disabled={isLoading}
                                            value={companyData.jobTitle} onChange={handleChangeCompany} error={errors.jobTitle}
                                        /> */}


                                        <SelectBox options={industryOptions} label="Industry"
                                            onSelect={val => {
                                                setCompanyData(prevState => ({ ...prevState, 'industrieId': val }))
                                                setErrors(prevState => ({ ...prevState, 'industrieId': false }));

                                            }}
                                            className={'!mt-[10px] '}
                                            value={companyData.industrieId} svg={2}
                                            error={errors.industrieId} disabled={isLoading}
                                        />
                                    </div>}
                                    {isOwner && <TextareaField
                                        wrapperClassName={"mt-5"}
                                        label="Company Description" 
                                        inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                        name="description" id="description" placeholder="Company name" disabled={isLoading}
                                        value={companyData.description} onChange={handleChangeCompany} error={errors.description}
                                        
                                    />}
                                </div>
                                <div className="w-full">
                                    <div className="flex flex-col gap-[16px] flex-1 mt-[18px] mb-9 lg:mb-[52px]">
                                        <label className="text-[18px] font-bold text-zinc-800 leading-[18px]">Top Hiring Challenges</label>
                                        <ul className="text-[18px] leading-[22px] list-disc ml-[18px]">
                                            <li className="mb-[13px]">Plan Level 01</li>
                                            <li className="mb-[13px]">Plan Level 02</li>
                                            <li>Plan Level 03</li>
                                        </ul>
                                    </div>

                                    <label className="text-[18px] font-bold text-zinc-800 leading-[18px] mb-[16px] block">Credit Card Info</label>
                                    <InputField
                                        label="Card Number" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                        type="text" name="cardNumber" id="cardNumber" placeholder="0000-0000-0000-0000"
                                        value={formData.cardNumber} onChange={handleChange} error={errors.cardNumber} disabled={isLoading}
                                    />
                                    <div className="grid lg:grid-cols-3 lg:gap-5 pb-[0px]">
                                        <InputField
                                            label="Expiration Date" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                            type="date" name="expirationDate" id="expirationDate" placeholder="11/30/2023"
                                            value={formData.expirationDate} onChange={handleChange} disabled={isLoading}
                                        />
                                        <InputField
                                            label="CVV" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                            type="text" name="cvv" id="cvv" placeholder="000"
                                            value={formData.cvv} onChange={handleChange} disabled={isLoading}
                                        />
                                        <InputField
                                            label="Postal Code" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                            type="text" name="postalCode" id="postalCode" placeholder="123456"
                                            value={formData.postalCode} onChange={handleChange} disabled={isLoading}
                                        />
                                    </div>
                                    <div className="pb-[0px]">
                                        <InputField
                                            label="Name on Credit Card" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                            name="name" id="name" placeholder=""
                                            value={formData.name} onChange={handleChange}
                                            disabled={isLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center gap-[22px] mt-[24px]">
                                <button className={`${isLoading ? 'disabled' : ''} min-w-[236px] max-lg:w-full text-[19px] lg:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 h-[60px] lg:h-[64px] rounded-md bg-[#37B34A] hover:opacity-70`}>
                                    Save
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
            </main>
            <Footer hideCopyright={true} />
        </>
    );
};

export default SBM05_1_1;
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { EditableInput } from "./EditableInput";
import { useEffect, useState } from "react";
import { ModalForm } from "./ModalForm";


export const InputSection = ({ title, activeField, titleField, payload, setPayload, }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);
    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }
    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm[activeField] ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        [activeField]: !payload.applicationForm[activeField]
                    }
                });
            }
        },
        // {
        //     label: "The 2 choice setup is fixed...",
        //     handler: () => { }
        // },
    ]

    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <div className='w-full'>
                    <div className='max-w-[560px] w-full'>
                        <EditableInput
                            className="font-semibold lg:text-lg !text-[16px]"
                            type="text"
                            name={titleField}
                            id={titleField}
                            value={payload.applicationForm[titleField]}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        { payload.applicationForm[activeField] ?
                        <div className='w-full'>
                            <input className='grey-input-custom' />
                        </div> : null }
                    </div>
                </div>
            </ModalForm>
        )
    }


    return (
        <form onSubmit={handleSubmit} className='flex justify-between items-start relative' >
            <div className='w-full'>
                <div className="flex justify-between">
                    <EditableInput
                        className="font-semibold lg:text-lg !text-[16px] pr-12"
                        type="text"
                        name={titleField}
                        id={titleField}
                        value={payload.applicationForm[titleField]}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                    {
                        isEditing ?
                            <PrimaryButton
                                type="submit"
                                className={"px-9 lg:h-[52px] lg:text-lg"}
                            >
                                Save
                            </PrimaryButton>
                            :
                            <ButtonOptions className={"!absolute right-0"} options={options} />
                    }
                </div>
                { payload.applicationForm[activeField] ? 
                <div className='max-w-[560px] w-full'>
                    <div className='w-full'>
                        <input className='grey-input-custom' />
                    </div>
                </div> : null }
            </div>
        </form>
    )
}
export const formPath = '/SBM01_form'

export const stepsPath = {
    '1/12': 'clarifications',
    '2/12': 'firstInterview',
    '3/12': 'firstInterviewSurvey',
    '4/12': 'secondInterview',
    '5/12': 'documentReview',
    '6/12': 'ratingOfTheJob',
    '7/12': 'scores',
    '8/12': 'aIRecommendation',
    '9/12': 'finalHiringDecision',
    '10/12': 'trainingInsights',
    '11/12': 'offerMeeting',
    '12/12': 'trainingMilestones',
    '1/4': 'application',
    '2/4': 'communications',
    '3/4': 'assessment',
    '4/4': 'resume',
}

export const crumbsConfig = {
    'sub': ['Subscriber Dashboard', ''],
    'jobs': ['Jobs', '/SBM01'],
    'applicant': ['Applicants', '/SBM01_2'],
    'candidate': ["Candidates", '/SBM01_10'],
    'spector': ["Marc Spector", ''],
    'report': ["Report Card", ''],
    'history': ["History", ''],
    'search': ["Search Result", '']
}

import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../api";

export const fetchPublicSolutionsPages = createAsyncThunk(
    '/fetchPublicSolutionsPages',
    async (_, { rejectWithValue }) => {

        try {
            const res = await api.publicSolutions.getPubSolPages();

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchPublicSolutionsOnePages = createAsyncThunk(
    '/fetchPublicSolutionsOnePages',
    async (id, { rejectWithValue }) => {

        try {
            const res = await api.publicSolutions.getPubSolOnePage(id);

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreatePubSolPage = createAsyncThunk(
    '/fetchCreatePubSolPage',
    async (payload, { rejectWithValue }) => {

        try {
            const res = await api.publicSolutions.createPubSolPage(payload);

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUpdatePubSolPage = createAsyncThunk(
    '/fetchUpdatePubSolPage',
    async (payload, { rejectWithValue }) => {

        try {
            const res = await api.publicSolutions.updatePubSolPage(payload.data, payload.id);

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchDeletePubSolPage = createAsyncThunk(
    '/fetchDeletePubSolPage',
    async (id, { rejectWithValue }) => {

        try {
            const res = await api.publicSolutions.deletePubSolPage(id);

            console.log(res, 'res')

            if (res.success) {
                return id;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchPubSolCategories = createAsyncThunk(
    '/fetchPubSolCategories',
    async (_, { rejectWithValue }) => {

        try {
            const res = await api.publicSolutions.getPubSolCategories();

            console.log(res, 'res')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreatePubSolCategories = createAsyncThunk(
    '/fetchCreatePubSolCategories',
    async (payload, { rejectWithValue }) => {

        try {
            const res = await api.publicSolutions.createPubSolCategory(payload);

            console.log(res, 'res cat')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);


export const fetchPubSolTags = createAsyncThunk(
    '/fetchPubSolTags',
    async (_, { rejectWithValue }) => {

        try {
            const res = await api.publicSolutions.getPubSolTags(_);

            console.log(res, 'res tags')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreatePubSolTags = createAsyncThunk(
    '/fetchCreatePubSolTags',
    async (payload, { rejectWithValue }) => {

        try {
            const res = await api.publicSolutions.createPubSolTag(payload);

            console.log(res, 'res create tags')

            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
import React from "react";
import { AboutBlock } from "./AboutBlock";
import { CandidateCard } from "./CandidateCard";
import getImageUrl from "../../../assets/helpers/getImageUrl";

const TopPicked = ({
    label,
    title,
    categories,
    users
}) => {
    console.log( users)
    return (
        <section className="w-full pt-[70px] pb-[15px] flex flex-col gap-9 relative font-['Raleway']">
            <div className="w-full container">
                <AboutBlock
                    title={label}
                    header={title}
                    headerStyle={"max-w-[800px]"}
                    contentCentered
                />
            </div>
            <div className="container !max-w-[1390px]">
                <div className="w-full pb-4 max-sm:pb-2 flex gap-[12px] whitespace-nowrap items-center overflow-auto scroll-style mb-[40px]">
                    {categories.map((category, idx) => (
                        <div
                            key={idx}
                            className="flex gap-[6px] items-center justify-center cursor-pointer bg-[#EBF0F3] hover:text-white hover:bg-[#007BFF] px-[12px] rounded-[8px] h-[52px] min-w-max"
                        >
                            <img src={category.img} alt={category.title} className="w-full h-full max-w-[39px] max-h-[39px]" />
                            <span className="text-[17px] font-bold">{category.title}</span>
                        </div>
                    ))}
                </div>
                <div className="w-full lg:px-[44px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-[49px] gap-y-[40px]">
                    {users?.map(user => (
                        <CandidateCard
                            name = {user?.name}
                            label={user?.label}
                            description={user?.text}
                            experienceTitle={user?.col_1_title}
                            experience={user?.col_1_text}
                            skillsTitle={user?.col_2_title}
                            skills={JSON.parse(user?.col_2_items)}
                            image={getImageUrl(user?.image)}
                            status={user?.badge}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TopPicked;

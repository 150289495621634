import React, {useEffect, useState} from 'react';
import blocksImage from "../../assets/img/home/blocks.png";
import classNames from 'classnames';
import {useSelector} from "react-redux";
import findValue from "../../assets/helpers/findValue";
import getImageUrl from "../../assets/helpers/getImageUrl";

const GetInTouchWithOurSupport = ({
                                      showSubHeader = false,
                                      contentCenter = false,
                                      topSpacing = 'pt-[110px]',
                                      showDecoreBg = true,
                                      data,
                                      withCards = true
                                  }) => {
    const contactPage = useSelector((state) => state.application.pages.find(page => page.url === "/contact-us"));

    const getValue = (key) => findValue(contactPage?.blocks, key);
    const [content, setContent] = useState(data);


    useEffect(() => {
        if (!data) {
            setContent({
                header: getValue("contact_title"),
                title: getValue("contact_text"),
                cards: (getValue("contact_cards")?.data || []).map(card => ({
                    title: card.title,
                    icon: getImageUrl(card.icon),
                    text: card.text,
                    link: card.link,
                }))
            })
        }
    }, []);

    return (
        <section
            className={`pb-[110px] bg-top bg-no-repeat font-['Raleway'] ${topSpacing} max-md:py-[55px]`}
            style={{backgroundImage: `${showDecoreBg ? `url(${blocksImage})` : 'none'}`}}
        >
            <div className="container">
                <div
                    className={`flex flex-col gap-[17px] text-[18px] mb-[48px] ${contentCenter ? 'items-center text-center' : ''}`}>
                    {showSubHeader &&
                        <span className="text-lg font-semibold text-[#0056B3]">{content?.subHeader}</span>}
                    <h3 className="text-[48px] max-md:text-[32px] font-bold max-w-[830px]">{content?.header}</h3>
                    <p>{content?.title}</p>
                </div>
                {withCards && <div className={classNames(`flex gap-[24px] flex-wrap`, {'justify-center': contentCenter})}>
                    {content?.cards?.map((card, index) => (
                        <a key={index} href="#"
                           className="card-shadow px-[25px] py-[45px] max-w-[310px] max-sm:max-w-full w-full block hover:bg-[#EBF0F3] wow fadeInUp">
                            <img className='mb-[20px]' src={card?.icon} alt={`${card?.title} icon`}/>
                            <div className="flex flex-col gap-[10px]">
                                <h4 className='text-[26px] font-semibold leading-normal'>{card?.title}</h4>
                                <p className='text-[15px]'>{card?.text}</p>
                                <div className='text-[#003578] underline text-[15px] font-semibold'
                                     dangerouslySetInnerHTML={{__html: card?.link}}></div>
                            </div>
                        </a>
                    ))}
                </div>}
            </div>
        </section>
    );
};

export default GetInTouchWithOurSupport;

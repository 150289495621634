const Confirmation = ({colorStyle = "", text = '', title}) => {
  
  return (
    <div className={`${colorStyle === "white" ? "text-white" : "text-[#333333]"} max-w-[1162px] px-4 w-full flex-auto py-28 lg:py-14 mx-auto flex flex-col gap-[32px] items-center justify-center`}>   
      <h1 className="lg:text-[70px] lg:leading-[80px] text-[40px] leading-[52px] font-bold text-center uppercase">{title || 'Thank You'}</h1>
      <div className="w-[78px] h-[4px] bg-[#37B34A]"/>
      <h2 className="lg:text-2xl lg:leading-[40px] text-pretty text-lg leading-7 font-normal text-center">
          {text ? text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque.'}
      </h2>
    </div>
  )
}

export default Confirmation;
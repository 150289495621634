import classNames from "classnames"

const InputError = ({ message = null, className }) => (
    <p 
        className={classNames("text-red-600 font-medium mt-2 line-clamp-1", className)}
    >
        {message}
    </p>
);

export default InputError
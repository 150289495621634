import { createSlice } from '@reduxjs/toolkit'
import { fetchLogout, fetchPageByPath, fetchPages, getUserFetch, updateUserFetch } from '../thunks/applicationThunks';
import { useSelector } from 'react-redux';
import api from '../../api';
import { fetchUpdateBuildCompanies } from "../thunks/companiesThunks";

const initialState = {
    authorized: null,
    user: null,
    token: null,
    currentPage: null,
    pageLoading: false,
    pages: []
}

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setAuthorized: (state, action) => {
            state.authorized = action.payload;
        },

        setUser: (state, action) => {
            state.user = action.payload;
        },

        setToken: (state, action) => {
            state.token = action.payload;
        },

        setLogOut: (state, action) => {
            localStorage.removeItem('authToken')

            state.authorized = null
            state.user = null
            state.token = null
        }
    },

    extraReducers(builder) {
        builder.addCase(getUserFetch.fulfilled, (state, action) => {
            state.user = action.payload;
            state.authorized = true;
            state.modal = {
                show: false,
                name: '',
                payload: {}
            }
        });

        builder.addCase(getUserFetch.pending, (state, action) => {
            state.authorized = null;
        });

        builder.addCase(getUserFetch.rejected, (state, action) => {
            localStorage.removeItem('authToken');
            state.authorized = false;
            state.token = null;
        });

        builder.addCase(fetchPageByPath.fulfilled, (state, action) => {
            state.pageLoading = false
            state.currentPage = action.payload;
        });

        builder.addCase(fetchPageByPath.pending, (state, action) => {
            state.pageLoading = true
            state.currentPage = null
        });

        builder.addCase(fetchPageByPath.rejected, (state, action) => {
            state.pageLoading = false
            state.currentPage = null
        });

        builder.addCase(fetchPages.fulfilled, (state, action) => {
            state.pages = action.payload;
        });

        builder.addCase(fetchPages.pending, (state, action) => {
            state.pages = []
        });

        builder.addCase(fetchPages.rejected, (state, action) => {
            state.pages = []
        });
        builder.addCase(updateUserFetch.fulfilled, (state, action) => {
            state.user = { ...state.user, ...action.payload };
        });

        builder.addCase(fetchUpdateBuildCompanies.fulfilled, (state, action) => {
            if (action.payload.data) {
                state.user = { ...state.user, company: action.payload.data };
            }
        })

        builder.addCase(fetchLogout.fulfilled, (state, action) => {
            localStorage.removeItem('authToken')

            state.authorized = null
            state.user = null
            state.token = null
        });

    }
})

export const {
    setUser, setAuthorized, setToken, setLogOut
} = applicationSlice.actions;

export const getUserCompanyId = (state) => state.application.user?.companies?.length ? state.application.user?.companies[0].company?.id : state.application.user?.company?.id;
export const getUser = (state) => state.application.user;
export const getSubscriberRole = (state) => state.application.user?.companies.length ? state.application.user?.companies[0].role : null;
export const getAuthorized = (state) => state.application.authorized;
export const getToken = (state) => state.application.token;
export const getCurrentPage = (state) => state.application.currentPage;
export const getPages = (state) => state.application.pages;
export const getCurrentPageLoading = (state) => state.application.pageLoading;

export default applicationSlice.reducer
import {useSelector} from "react-redux";
import {getCurrentPage} from "../../redux/slices/applicationSlice";
import findValue from "../../assets/helpers/findValue";
import PageLoader from "../../components/PageLoader";
import {AiTools} from "../../components/parts/publickPageParts/AiTools";
import {TotalBlock} from "../../components/parts/publickPageParts/TotalBlock";
import {HowItWorks} from "../../components/parts/publickPageParts/HowItWorks";
import {ClientsComments} from "../../components/parts/publickPageParts/ClientsComments";
import React, {useMemo} from "react";
import HeroWithSlider from "../../components/parts/publickPageParts/HeroWithSlider";
import InfoSection from "../../components/parts/publickPageParts/InfoSection";
import {AboutBlock} from "../../components/parts/publickPageParts/AboutBlock";
import heroImage from "../../assets/img/home/hero.png";
import aboutUsImage from "../../assets/img/home/aboutUs.png";
import getImageUrl from "../../assets/helpers/getImageUrl";
import teamImage from "../../assets/img/home/team.png";

const generateDataFromBlocks = (blocks) => {
    return {
        whatBelieve: {
            title: findValue(blocks, "why_believe_label"),
            header: findValue(blocks, "why_believe_title"),
            description: [findValue(blocks, "why_believe_desc")],
            content: (findValue(blocks, "why_bileve_list")?.data || []).map(item => ({
                title: item.why_believe_item,
                description: item.why_believe_item_desc
            })),
            button: findValue(blocks, "why_believe_btn")
        },
        aboutUs: {
            title: findValue(blocks, "why_about_label"),
            header: findValue(blocks, "why_about_title"),
            description: [findValue(blocks, "why_about-desc")],
            img: getImageUrl(findValue(blocks, "why_about_image")),
            button: findValue(blocks, "why_about_btn")
        },
        aiTools: {
            title: findValue(blocks, "why_features_label"),
            header: findValue(blocks, "why_features_title"),
            buttonText: findValue(blocks, "why_features_btn"),
            tools: (findValue(blocks, "features_list")?.data || []).map(item => ({
                title: item.why_features_item_text || item.title,
                image: item.features_item_image || item.image || "",
                altText: item.why_features_item_text || item.title
            }))
        },
        totalBlock: {
            stats: (findValue(blocks, "why_features_stat")?.data || []).map(item => ({
                title: item.why_features_stat_item_title,
                count: item.why_features_stat_item_desc
            }))
        },
        howItWorks: {
            title: findValue(blocks, "why_works_label"),
            header: findValue(blocks, "why_works_title"),
            buttonText: findValue(blocks, "why_works_btn"),
            steps: (findValue(blocks, "why_works_list")?.data || []).map((item, index) => ({
                step: item.why_works_list_item_chip,
                icon: item.why_works_list_item_image,
                title: item.why_works_list_item_title,
                description: item.why_works_list_item_desc,
            }))
        },
        candidate: {
            title: findValue(blocks, "why_candidate_label"),
            header: findValue(blocks, "why_candidate_title"),
            description: [findValue(blocks, "why_candidate_desc")],
            button: findValue(blocks, "why_candidate_btn"),
            img: findValue(blocks, "why_candidate_image") ? getImageUrl(findValue(blocks, "why_candidate_image")) : teamImage
        },
        testimonials: {
            title: findValue(blocks, "why_testimonials_label"),
            header: findValue(blocks, "why_testimonials_title"),
            testimonials: (findValue(blocks, "why_testimonials_list")?.data || []).map(testimonial => ({
                image: getImageUrl(testimonial.why_testimonials_item_image),
                text: testimonial.why_testimonials_item_text,
                name: testimonial.why_testimonials_item_name,
                job: testimonial.why_testimonials_item_job
            })),
            button: findValue(blocks, "why_testimonials_button"),
        }
    };
};

const WhyEmployJoy = () => {
    const currentPage = useSelector(getCurrentPage);
    const data = useMemo(() => generateDataFromBlocks(currentPage?.blocks), [currentPage?.blocks]);

    return (
        <div className="public-page">
            {!currentPage ? (
                <PageLoader/>
            ) : (
                <>
                    <HeroWithSlider
                        header={findValue(currentPage?.blocks, "why_top_title")}
                        description={findValue(currentPage?.blocks, "why_top_description")}
                        buttons={[
                            {
                                text: findValue(currentPage?.blocks, "why_top_button2"),
                                link: "/request-demo",
                                bgColor: "bg-[#36B34A]"
                            }
                        ]}
                        images={findValue(currentPage?.blocks, "why_courusel_top")}
                    >
                        <div className="flex justify-center items-center">
                            <img
                                src={getImageUrl(findValue(currentPage?.blocks, "why_top_image"))}
                                alt="hero" className="max-w-[1000px] h-[337px] max-md:h-[150px] wow fadeIn"/>
                        </div>
                    </HeroWithSlider>

                    <InfoSection withBlock background={"bg-white"} topSpacing={"py-[110px] max-md:py-[80px]"}>
                        <AboutBlock
                            title={data.whatBelieve.title}
                            header={data.whatBelieve.header}
                            description={data.whatBelieve.description}
                            button={data.whatBelieve.button}
                        />
                        <div className="flex flex-col gap-[17px]">
                            {data.whatBelieve.content.map((item, index) => (
                                <div key={index} className="flex flex-col gap-[8px]">
                                    <p className="text-2xl md:text-[28px] font-semibold">{item.title}</p>
                                    <p className="text-sm md:text-lg font-medium">{item.description}</p>
                                </div>
                            ))}
                        </div>
                    </InfoSection>

                    <InfoSection topSpacing={"py-[71px] max-md:py-[40px]"}>
                        <AboutBlock
                            title={data.aboutUs.title}
                            header={data.aboutUs.header}
                            description={data.aboutUs.description}
                            button={data.aboutUs.button}
                        />
                        <div className="flex items-center justify-center wow fadeInRight">
                            <img src={data.aboutUs.img} alt="aboutUs"/>
                        </div>
                    </InfoSection>

                    <AiTools background={"bg-white"} data={data.aiTools} withBlock/>
                    <TotalBlock data={data.totalBlock}/>
                    <HowItWorks data={data.howItWorks}/>

                    <InfoSection topSpacing={"py-[55px]"}>
                        <AboutBlock
                            title={data.candidate.title}
                            header={data.candidate.header}
                            description={data.candidate.description}
                            button={data.candidate.button}
                        />
                        <div className="flex items-center justify-center h-auto max-md:h-[320px] ">
                            <img src={data.candidate.img} alt="candidate"
                                 className="absolute bottom-0 wow bounceInUp max-h-[350px] max-w-[500px]"/>
                        </div>
                    </InfoSection>

                    <ClientsComments
                        topSpacing="py-[70px]"
                        title={data.testimonials.title}
                        header={data.testimonials.header}
                        testimonials={data.testimonials.testimonials}
                        buttonText={data.testimonials.button}
                    />
                </>
            )}
        </div>
    );
};

export default WhyEmployJoy;

import { useState } from "react";

export const YesNoTextQuestion = ({ config, number, value, onChange, error, className = '', labelClassName = '', disabled, showAnswer = true , description= "", sendDescription=false}) => {
    const [answer, setAnswer] = useState(value?.toString()?.toLowerCase() === "no"
        ? "no" : !!value?.length ? "yes" : "")


    console.log("sendDescription", sendDescription)

    return (
        <div className={`flex gap-[24px] w-full flex-col max-lg:gap-[16px] ${className}`}>
            <label htmlFor={config.id} className={`mb-[16px] text-lg font-semibold leading-[30px] max-md:max-w-full max-md:text-[18px] max-md:leading-[24px] ${labelClassName} ${error ? 'text-red-600' : null}`}>
                {number ? number + '. ' : null}
                {config.question}
                {config.isRequired && <span className="text-teal-500">*</span>}
            </label>
            <div className="flex items-center justify-between gap-[16px]">

                <label
                    className="cursor-pointer custopm-radio flex items-center font-medium"
                >
                    <input
                        type="radio"
                        className="radio-green-sub aspect-square w-5 mr-[10px]"
                        onChange={() => {
                            if (!onChange) return
                            setAnswer("no")
                            onChange("No")
                        }}
                        checked={answer === "no"}
                        hidden
                    />
                    <span className="check-box-fake mr-[12px]"></span>
                    <p>No</p>
                </label>

                <label
                    className="cursor-pointer custopm-radio flex items-center font-medium"
                >
                    <input
                        type="radio"
                        className="radio-green-sub aspect-square w-5 mr-[10px]"
                        onChange={() => {
                            if (!onChange) return
                            setAnswer("yes")
                            onChange("")
                        }}
                        checked={answer === "yes"}
                        hidden
                    />
                    <span className="check-box-fake mr-[12px]"></span>
                    <p>Yes</p>
                </label>

            </div>
            {answer === "yes" && showAnswer && <textarea name={config.id} value={description || value} onChange={(e) => onChange && onChange(e.target.value, "desc")} disabled={disabled} className="grey-textarea !p-[14px] lg:!h-[82px]"></textarea>}
        </div>
    )

}

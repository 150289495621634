import React, { useState } from 'react'

const ExpectAfterSubmition = ({ getValue }) => {
    const [activeStep, setActiveStep] = useState(1)
    const steps = getValue('demo_submission_list')
    ?.data?.map(({demo_submission_list_item_title, demo_submission_list_item_desc}) => ({title: demo_submission_list_item_title, text: demo_submission_list_item_desc}))
    
    return (
        <section className='pt-[56px] pb-[110px] max-lg:pb-[56px] '>
            <div className="container">
                <div className={`flex flex-col gap-[7px] text-[18px] mb-[48px]`}>
                    <span className="text-lg font-semibold text-[#0056B3]">{getValue('demo_submission_label')}</span>
                    <h3 className="text-[45px] max-md:text-[32px] font-bold max-w-[480px] leading-[48px]">{getValue('demo_submission_title')}</h3>
                </div>
                <div className="flex overflow-auto scroll-style pb-2">
                    {steps?.map((step, index) => (<div className="flex flex-col flex-1 min-w-[200px]">
                        <div className="relative mb-[12px] w-full">
                            <div
                                className={`w-[50px] h-[50px] border  rounded-full flex items-center justify-center relative z-[1] ${index <= activeStep ? 'bg-[#36B34A] border-[#36B34A]' : 'border-[#676767] bg-white'}`}
                            >
                                {index > activeStep && <span className='text-[35px] text-[#676767] '>{index + 1}</span>}
                                {index <= activeStep && <svg xmlns="http://www.w3.org/2000/svg" className='w-full h-full' viewBox="0 0 53 53" fill="none">
                                    <circle cx="26.254" cy="26.254" r="26.254" fill="#36B34A" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.7672 16.8237C40.5985 17.7138 40.5985 19.1568 39.7672 20.0468L22.6515 38.3712L14.7602 29.9226C13.9289 29.0325 13.9289 27.5895 14.7602 26.6995C15.5915 25.8095 16.9393 25.8095 17.7706 26.6995L22.6515 31.9251L36.7568 16.8237C37.5881 15.9337 38.9359 15.9337 39.7672 16.8237Z" fill="white" />
                                </svg>}
                            </div>
                            {index !== (steps.length - 1) &&
                                <span className={`absolute  w-full top-1/2 translate-y-[-50%] left-0  ${index <= activeStep ? 'h-[5px] bg-[#36B34A] ' : 'h-[2px] bg-[#676767]'}`}></span>
                            }
                        </div>
                        <div className="pr-[20px]">
                            <h5 className='text-[20px] font-semibold'>{step.title}</h5>
                            <p className='text-[11px] font-medium text-[#676767]'>{step.text}</p>
                        </div>
                    </div>))}
                </div>
            </div>
        </section>
    )
}

export default ExpectAfterSubmition

import React, {useState} from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { HiringProcess } from "../components/base/ProgressStepsSBM";
import InputField from "../components/base/InputField";
import { Footer } from "../components/parts/Footer";
import ModalGrop from "../components/parts/ModalGrop";

const ActionButton = ({ text, onClick }) => (
    <button type="button" className="w-[110px] justify-center text-green-500 text-lg h-[50px] lg:w-[120px] font-semibold rounded-md border border-green-500 border-solid" onClick={onClick}>
        {text}
    </button>
)

const TabButton = ({ onClick = () => { }, isActive, label, id }) => {
	return (
		<button
			type='button'
			onClick={() => onClick(id)}
			className={`flex items-center justify-center px-5 h-[46px] ${isActive ? 'border-green-500' : 'border-white'}  border-solid border-b-[5px] hover:bg-green-200`}
		>
			{label}
		</button>
	)
}

const NextStepSection = () => {
    return (
        <section className="">
            <div className=" flex gap-4 justify-between self-stretch w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <button className="flex justify-center max-lg:flex-auto py-[15px] px-[64px] bg-[#B2B2B2] rounded-md max-md:px-5">
                    Back
                </button>
                <button className="flex justify-center max-lg:flex-auto items-center py-[15px] px-[64px] bg-green-500 rounded-md max-md:px-5">
                    Next
                </button>
            </div>
        </section>
    );
};


const SBM01_10_15 = () => {

    const stepsData = [
        { stepNumber: "1", label: "Clarifications", isActive: true },
        { stepNumber: "2", label: "1st Interview", isActive: true },
        { stepNumber: "3", label: "Post 1st Interview\nSurvey", isActive: true },
        { stepNumber: "4", label: "2nd Interview", isActive: true },
        { stepNumber: "5", label: "Document\nReview", isActive: true },
        { stepNumber: "6", label: "Rating of\nThe Job", isActive: true },
        { stepNumber: "7", label: "AI\nRecommendation", isActive: true },
        { stepNumber: "8", label: "Final Hiring Decision", isActive: false },
        { stepNumber: "9", label: "Training Insights", isActive: false },
        { stepNumber: "10", label: "Offer Meeting", isActive: false },
        { stepNumber: "11", label: "Training\nMilestones", isActive: false },
    ];
   
    const tabsData = [
        { label: 'Report Card', id: 1 },
        { label: 'History', id: 2 },
        { label: 'Reference', id: 3 },
        { label: 'Reject', id: 4 },
        { label: 'Offer Letter', id: 5 },
    ];

    const [activeTab, setActiveTab] = useState(tabsData.length ? tabsData[0].id : null);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <>
            <BreadCrumbWhite crumbs={[
                'Subscriber Dashboard',
                'Jobs',
                'Applicants',
                "Candidates",
                "Marc Spector",
                "Report Card",
                "Document Review",
                "AI Evaluation"
            ]} />

            <div className='flex-auto'>
                <div className='flex flex-col gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
                    <div>
                        <header className="flex gap-10 justify-between w-full whitespace-nowrap flex-wrap">
                            <div>
                                <h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950">Marc Spector</h2>
                            </div>
                            <div className="lg:flex flex-wrap grid grid-cols-3 max-lg:w-full gap-2 my-auto">
                                <ModalGrop />
                            </div>
                        </header>

                        <nav className="flex overflow-auto w-full gap-5 items-center self-start text-xl font-semibold text-center whitespace-nowrap text-zinc-800 mt-10 lg:mt-11">
                            {tabsData.map(tab => (
                                <TabButton
                                    key={tab.id}
                                    id={tab.id}
                                    label={tab.label}
                                    isActive={activeTab === tab.id}
                                    onClick={handleTabClick}
                                />
                            ))}
                        </nav>
                    </div>

                    <section className="flex w-full text-center overflow-auto py-4">
                        <HiringProcess stepsData={stepsData} />
                    </section>

                    <div className="border-b border-neutral-200 pb-9">
                        <span className="text-3xl font-bold">Final Hiring Decisions</span>
                    </div>

                    <div className="pb-[60px] border-b border-neutral-200">
                        <span className="block text-[22px] font-bold mb-4">Top 3 Strengths:</span>

                        <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end">
                            <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                                <div className="green-box mr-4"></div>
                                    Category 1:
                            </div>

                            <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                                <InputField label={'Positive Variance %'} 
                                    labelClassName={'font-semibold text-[#6F6F6F]'} 
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                                />
                            </div>

                        </div>
                        <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
                            <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                                <div className="green-box mr-4"></div>
                                    Category 1:
                            </div>

                            <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                                <InputField label={'Positive Variance %'} 
                                    labelClassName={'font-semibold text-[#6F6F6F]'} 
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                                />
                            </div>

                        </div>
                        <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
                            <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                                <div className="green-box mr-4"></div>
                                    Category 1:
                            </div>

                            <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                                <InputField label={'Positive Variance %'} 
                                    labelClassName={'font-semibold text-[#6F6F6F]'} 
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                                />
                            </div>

                        </div>


                    </div>

                    <div className="pb-[60px] border-b border-neutral-200">
                        <span className="block text-[22px] font-bold mb-4">Top 3 Weaknesses:</span>

                        <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end">
                            <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                                <div className="green-box mr-4"></div>
                                    Category 1:
                            </div>

                            <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                                <InputField label={'Positive Variance %'} 
                                    labelClassName={'font-semibold text-[#6F6F6F]'} 
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                                />
                            </div>

                        </div>
                        <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
                            <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                                <div className="green-box mr-4"></div>
                                    Category 1:
                            </div>

                            <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                                <InputField label={'Positive Variance %'} 
                                    labelClassName={'font-semibold text-[#6F6F6F]'} 
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                                />
                            </div>

                        </div>
                        <div className="flex max-lg:justify-between gap-6 lg:gap-[114px] items-end mt-7">
                            <div className="text-xl whitespace-nowrap lg:text-xl font-semibold flex items-center">
                                <div className="green-box mr-4"></div>
                                    Category 1:
                            </div>

                            <div className="w-[180px] whitespace-nowrap lg:w-[200px]">
                                <InputField label={'Positive Variance %'} 
                                    labelClassName={'font-semibold text-[#6F6F6F]'} 
                                    inputBg="bg-[#FFFFFF] border border-[#DEDED9]"
                                    parentClassName={'!mb-0'}
                                />
                            </div>

                        </div>


                    </div>

                    <div className="pb-[60px] border-b border-neutral-200">
                        <span className="block text-3xl font-bold mb-7">AI Recommendations:</span>

                        <div className="flex items-center">
                            <div className="lg:text-xl text-base font-bold w-[240px]">
                                High Standard:
                            </div>

                            <div className="flex items-center gap-3">
                                <button className="bg-[#37B34A] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                                    <span className="font-bold text-[#FFFFFF]">Hire</span>
                                </button>
                                <div className="italic text-[#999999] font-light">or</div>
                                <button className="bg-[#B2B2B2] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                                    <span className="font-bold text-[#FFFFFF]">Not Hire</span>
                                </button>
                            </div>

                        </div>
                        <div className="flex items-center mt-7">
                            <div className="lg:text-xl text-base font-bold w-[240px]">
                                Medium Standard:
                            </div>

                            <div className="flex items-center gap-3">
                                <button className="bg-[#37B34A] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                                    <span className="font-bold text-[#FFFFFF]">Hire</span>
                                </button>
                                <div className="italic text-[#999999] font-light">or</div>
                                <button className="bg-[#B2B2B2] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                                    <span className="font-bold text-[#FFFFFF]">Not Hire</span>
                                </button>
                            </div>

                        </div>
                        <div className="flex items-center mt-7">
                            <div className="lg:text-xl text-base font-bold w-[240px]">
                                Average Standard:
                            </div>

                            <div className="flex items-center gap-3">
                                <button className="bg-[#37B34A] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                                    <span className="font-bold text-[#FFFFFF]">Hire</span>
                                </button>
                                <div className="italic text-[#999999] font-light">or</div>
                                <button className="bg-[#B2B2B2] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                                    <span className="font-bold text-[#FFFFFF]">Not Hire</span>
                                </button>
                            </div>

                        </div>
                        <div className="flex items-center mt-7">
                            <div className="lg:text-xl text-base font-bold w-[240px]">
                                Final Recommendation:
                            </div>

                            <div className="flex items-center gap-3">
                                <button className="bg-[#37B34A] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                                    <span className="font-bold text-[#FFFFFF]">Hire</span>
                                </button>
                                <div className="italic text-[#999999] font-light">or</div>
                                <button className="bg-[#B2B2B2] rounded-[4px] w-20 lg:w-[90px] h-[38px] flex items-center justify-center">
                                    <span className="font-bold text-[#FFFFFF]">Not Hire</span>
                                </button>
                            </div>

                        </div>


                    </div>

                    <NextStepSection />


                </div>
                <Footer hideCopyright={true} />
            </div>
        </>
    )
}

export default SBM01_10_15
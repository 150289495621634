import React, { useEffect, useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";
import { Footer } from '../components/parts/Footer';

import { ReactComponent as SearchIcon } from "../assets/img/search-icon-grey.svg";
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../components/PageLoader';
import { getUser } from '../redux/slices/applicationSlice';
import { JobDetails } from './SBM01';

const SBM01_1 = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const searchString = searchParams.get('search');
    const user = useSelector(getUser)
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        department: '',
        location: '',
        status: '',
        search: searchString
    });
    const { companyJobs, isLoading } = useSelector(state => state.jobs)
    console.log(companyJobs)
    const resultJobs = companyJobs?.filter(({jobTitle}) => jobTitle.toLowerCase().includes(searchString.toLowerCase()));
    // useEffect(() => {
    //     console.log(companyJobs)
    //     if(!user?.company?.id || companyJobs?.length) return
    //     dispatch(getJobsByCompanyFetch({companyId: user.company.id}))
    //     // dispatch(getAllJobsFetch({companyId: user.company.id}))

    // }, [companyJobs, user, dispatch])

    const jobData = {
        department: "Cleaning Technician",
        location: "Chicago",
        dateStarted: "10/23/2023",
        volumeOfApplicants: 10,
        numberOfOffers: 5,
    };

    const departments = [
        { value: 'all_departments', label: 'All Departments' },
        { value: 'department_2', label: 'Department 2' },
        { value: 'department_3', label: 'Department 3' },
    ];
    const locations = [
        { value: 'all_locations', label: 'All Locations' },
        { value: 'location_2', label: 'Location 2' },
        { value: 'location_3', label: 'Location 3' },
    ];
    const status = [
        { value: 'active', label: 'Active' },
        { value: 'disabled', label: 'Disabled' },
        { value: 'Pendinp', label: 'Pending' },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    const keyDownHendler = (e) => {
        const value = e.target.value;
        const keyCode = e.code;
        if (keyCode === "Enter" && value.length > 3) {
            setSearchParams({'search': value})
        }
    }


    if(!searchString) {
        return <Navigate to='/SBM01'/>
    }

    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Jobs', 'Search Result']} />
            <section className="flex justify-center flex-auto py-9 lg:py-11 w-full text-zinc-800">
                <div className="flex flex-col items-center w-full max-w-[1432px] px-4">
                    <div className='mx-auto text-center flex flex-col gap-2 mb-11'>
                        <div className="text-3xl font-bold leading-10 text-zinc-800">
                            Current Job Openings
                        </div>
                        {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                    </div>
                    <div className="flex flex-col text-lg w-full bg-white px-8 py-9 lg:py-[50px] lg:px-[60px] rounded-md border border-solid border-zinc-300">
                        <div className="flex flex-wrap lg:flex-nowrap gap-3 lg:gap-8 w-full text-neutral-400">
                            <div className="flex items-center w-full lg:w-64">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    name="department"
                                    id="department"
                                    parentClass=''
                                    value={formData.department}
                                    onChange={handleChange}
                                    options={departments}
                                />
                            </div>
                            <div className="flex items-center w-full lg:w-64">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    name="location"
                                    id="location"
                                    parentClass=''
                                    value={formData.location}
                                    onChange={handleChange}
                                    options={locations}
                                />
                            </div>
                            <div className="flex text-gr items-center w-full lg:w-64">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    name="status"
                                    id="status"
                                    parentClass=''
                                    value={formData.status}
                                    onChange={handleChange}
                                    options={status}
                                />
                            </div>
                            <div className="flex items-center lg:ml-auto w-full lg:w-80">
                                <div
                                    className="flex items-center w-full bg-white rounded-md border border-solid border-zinc-300 outline-none pr-4"
                                >
                                    <input
                                        type="search"
                                        className="flex-1 px-4 py-2 rounded-md outline-none h-[54px]"
                                        placeholder="Cleaning Technician"
                                        onChange={handleChange}
                                        onKeyDown={keyDownHendler}
                                        id="search"
                                        name='search'
                                        value={formData.search}
                                    />
                                    <span className="flex">
                                        <SearchIcon />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="shrink-0  my-9 lg:my-[50px] h-px border border-solid bg-neutral-200 border-neutral-200" />
                        {
                            !isLoading && resultJobs.length ?
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-9">
                                    {
                                        resultJobs.map(job => (
                                            <JobDetails key={job.id} data={job} />
                                        ))
                                    }
                                </div>
                            : isLoading
                                ?
                                <PageLoader />
                                :
                                <h2 className='text-center text-4xl font-bold'>No jobs found...</h2>
                        }
                    </div>
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM01_1

import { useNavigate } from "react-router-dom";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import ContactForm from '../components/parts/ContactForm';

const SBM02_7_2_1 = () => {
  const navigate = useNavigate()
  return (
    <>
      <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Job Setup', "Current Job Details", "Pipelines", "Contact Us"]} />
      <ContactForm onSubmit={() => navigate("/SBM02_7_2_1_1")} />
    </>
  )
}

export default SBM02_7_2_1
import { Footer } from "../components/parts/Footer"
import { Header } from "../components/parts/Header"
import Breadcrumbs from "../components/base/Breadcrumbs"
import BannerLight from "../components/parts/BannerLight"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { getCurrentPage } from "../redux/slices/applicationSlice"
import findValue from "../assets/helpers/findValue"
import getImageUrl from "../assets/helpers/getImageUrl"
import PageLoader from "../components/PageLoader"


const HOM1_2 = () => {
	const currentPage = useSelector(getCurrentPage);
	const getValue = (key) => findValue(currentPage?.blocks, key);
	console.log(currentPage?.blocks);

	const investorImages = [
		{ src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dfe98099890d0fc4421ca0a63765c63207adf1cde0b7801f240b7aed862a559?apiKey=e59c4f0875424a15b5751797686f8863&", alt: "Investor Logo 1" },
		{ src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0a15781d30ca802e3e2c6c3c8b8c107363d205ad788a86edc31c7bb08dd66d32?apiKey=e59c4f0875424a15b5751797686f8863&", alt: "Investor Logo 2" },
	];

	function ContentSection({ title, content, imageSrc, altText }) {
		return (
			//  lg:w-[45%]
			<section className="flex flex-col items-start text-left text-balance text-white lg:w-[calc(50%-50px)] max-md:text-center max-md:items-center">
				<img src={imageSrc} alt={altText} className="max-w-full  min-w-[121px]" loading="lazy" />
				<h2 className="mt-6 text-4xl font-bold max-md:max-w-full max-md:text-center">{title}</h2>
				<p className="mt-4 text-lg lg:leading-8 leading-7 max-md:max-w-full">{content}</p>
			</section>
		);
	}


	const InvestorCard = ({ imageSrc, altText }) => (
		<div className="flex flex-col w-full ">
			<div className="w-full aspect-[2] relative">
				<div className="h-full flex items-center justify-center bg-white z-10 relative">
					<img src={imageSrc} alt={altText} className="max-w-full w-full " loading="lazy" />
				</div>
				<div className="w-full aspect-[2] absolute top-[16px] left-[-16px] rounded-[7px] border border-solid border-green-500"></div>
			</div>
		</div>
	);

	const contentData = [
		{
			title: "Authenticity",
			content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.",
			imageSrc: require("../assets/img/hom1_2_cards_img_1.png"),
			altText: "Describe Image Here",
		},
		{
			title: "Outcome Obsessed",
			content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.",
			imageSrc: require("../assets/img/hom1_2_cards_img_2.png"),
			altText: "Describe Image Here",
		},
		{
			title: "Team First",
			content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.",
			imageSrc: require("../assets/img/hom1_2_cards_img_3.png"),
			altText: "Describe Image Here",
		},
		{
			title: "Show Grit",
			content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.",
			imageSrc: require("../assets/img/hom1_2_cards_img_4.png"),
			altText: "Describe Image Here",
		},
	];

	const ButtonDemo = ({ className = "", value }) => (
		<Link
			to={"/HOM1_5"}
			tabIndex="0"
			className={`relative flex justify-center items-center px-16 py-6 max-md:py-3 mt-11 lg:mt-[60px] lg:text-[22px] text-[19px] font-bold whitespace-nowrap bg-[#37B34A] border-[#37B34A] border-solid border-[2px] rounded-md lg:w-[276px] w-full max-md:px-5 h-[70px] max-md:h-[60px] text-white hover:opacity-70 ${className}`}
		>
			{value}		
		</Link>
	);

	return (
		<>
			<div className="box-border flex relative flex-col shrink-0 min-h-[100dvh]">
				<Header />
				{!currentPage ? <PageLoader /> : <>
				<section className="
                box-border w-full bg-center bg-no-repeat bg-cover
                bg-[url(https://cdn.builder.io/api/v1/image/assets%2F9106ba04c02e4dceae488e75e2ac4862%2F14a2333ed5504c789df293d766cde515)] h-full
                lg:pt-[120px]
                pt-[74px]
				lg:-mt-[120px]
				-mt-[75px]
            ">
					<div className="w-full max-w-[1280px] mx-auto px-5">
						<div className="justify-between w-full pt-6 lg:pt-[60px] pb-20 lg:pb-[100px]">
							<div className="flex gap-16 max-lg:flex-col max-md:gap-11">
								<article className="flex flex-col lg:w-[56%] w-full">
									<header className="flex flex-col text-xl font-bold text-white">
										<Breadcrumbs links={[{ name: "Home", to: "/HOM1_1", isCurrent: false }, { name: "Why EmployJoy", to: "/HOM1_2", isCurrent: true }]} />
										<h1 className="mt-11 text-5xl max-md:text-[40px] max-md:leading-[48px] max-md:text-center">
											{findValue(currentPage?.blocks, 'title_top_page')}
										</h1>
										<div className="mt-6 h-1 bg-[#37B34A] w-[78px] max-md:mx-[auto]" />
										<p className="mt-6 leading-9 font-normal max-md:text-center">
											{findValue(currentPage?.blocks, 'description_top_page')}
										</p>
									</header>
								</article>
								<aside className="flex flex-col lg:w-[44%] w-full">
									{currentPage && <img loading="lazy" 
									src={getImageUrl(getValue('image_top_page'))} alt="Relevant description" className="w-full aspect-square" />}
								</aside>
							</div>

							<main className="text-4xl font-bold  max-md:max-w-full pt-[60px] text-white text-center">
								<blockquote className="lg:text-[40px] lg:leading-[48px] text-[36px] leading-10 font-bold  mb-4">
									"{findValue(currentPage?.blocks, 'quote_top_page')}"</blockquote>
								<cite className="self-center mb-8 text-xl font-semibold whitespace-nowrap">
									{findValue(currentPage?.blocks, 'sign_top_page')}
								</cite>
							</main>
						</div>
					</div>
				</section>
				<section className="max-w-full w-[1280px] px-5 mx-auto">
					<section className="flex max-lg:flex-col gap-[50px] py-[96px] max-md:pb-[80px]">
						<div className="w-full md:w-2/5">
							{currentPage && <img
								loading="lazy"
								src={getImageUrl(getValue('image_center_page'))}
								alt=""
								className="w-full h-full aspect-[0.93]"
							/>}
						</div>
						<div className="w-full md:w-3/5">
							<div className="flex flex-col justify-between px-5">
								<h2 className="text-[40px] font-bold leading-[48px] text-zinc-800 md:text-4xl md:leading-10">
									{findValue(currentPage?.blocks, 'title_center_page')}
								</h2>
								<div className="mt-7 h-1 bg-[#37B34A] w-[78px]" />
								<p className="mt-7 text-lg leading-8 text-stone-500">
									{findValue(currentPage?.blocks, 'description_center_page')}
								</p>
								<ButtonDemo value={findValue(currentPage?.blocks, 'btn_center_page')} />
							</div>
						</div>
					</section>
					<section className="z-10 relative flex flex-col justify-center items-center  font-bold text-center text-white bg-[#37B34A] rounded-md p-10 lg:px-[100px] lg:py-[50px]">
						<header className="self-stretch md:mx-5 lg:text-4xl text-[28px] leading-[34px] lg:leading-[50px] md:mr-2.5 md:max-w-full">
							{findValue(currentPage?.blocks, 'description_green_center_page')}
						</header>
						<div className="mt-9 bg-white h-[94px] rounded-full w-[94px]">
							{currentPage && <img
								loading="lazy"
								src={getImageUrl(findValue(currentPage?.blocks, 'icon_green_center_page'))}
								alt=""
								className="w-full h-full rounded-full"
							/>}
						</div>
						<p className="mt-5 text-2xl whitespace-nowrap">
							{findValue(currentPage?.blocks, 'sign_green_center_page')}
						</p>
						<p className="mt-1.5 text-lg whitespace-nowrap">
						{findValue(currentPage?.blocks, 'text_green_center_page')}
						</p>
					</section>
				</section>

				<div className="flex flex-col justify-center py-[120px] bg-[#01224C] pt-[430px] max-md:pt-[350px] -mt-[350px] lg:mt-[-200px] lg:pt-[100px] max-md:pb-[80px]">
					<div className="max-w-full w-[1240px] mx-auto">
						<section className="flex flex-col items-center px-16 pt-12 mt-44 w-full max-md:px-5 max-md:mt-0 max-md:max-w-full max-md:pt-[80px]">
							<h2 className="self-center text-5xl font-bold text-white whitespace-nowrap leading-[52px] max-md:text-4xl">
								{findValue(currentPage?.blocks, 'title_investors')}
							</h2>
							<div className="mt-16 max-md:mt-10 w-full">
								<div className="grid lg:grid-cols-2 gap-[50px] w-full">
									{findValue(currentPage?.blocks, 'items_investors')?.data?.map((img, index) => (
										<InvestorCard key={index} imageSrc={getImageUrl(img.image_investors)} altText={img?.alt} />
									))}
								</div>
							</div>
						</section>
						<section className="flex flex-col items-center w-full max-md:px-5 max-md:mt-0 max-md:max-w-full">
							<h2 className="self-center mt-32 text-5xl font-bold text-white leading-[52px] max-md:max-w-full max-md:text-4xl max-md:mt-[100px]">
								{findValue(currentPage?.blocks, 'title_believe')}
							</h2>
							<div className="mt-16 max-md:mt-10 max-md:max-w-full">
								<div className="flex flex-wrap gap-y-[60px] gap-x-[100px] lg:justify-between">
									{findValue(currentPage?.blocks, 'items_believe')?.data?.map(({ description_block_believe, image_block_believe, title_block_believe }, index) => (
										<ContentSection
											key={index}
											title={title_block_believe}
											content={description_block_believe}
											imageSrc={getImageUrl(image_block_believe)}
										/>
									))}
								</div>
							</div>
							<ButtonDemo className="!bg-[#01224C]" value={findValue(currentPage?.blocks, 'button_believe')}/>
						</section>
					</div>
				</div>
				<BannerLight currentPage={currentPage} />
				</>}
				<Footer />

			</div>




		</>
	)
}

export default HOM1_2
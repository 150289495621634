import React, {useState} from 'react';
import InputGroup from '../base/InputGroup';
import {ReactComponent as FullName} from '../../assets/img/fullnameinput-icon.svg';
import {ReactComponent as Email} from '../../assets/img/email-input-icon.svg';
import {ReactComponent as Company} from '../../assets/img/company-icon-input.svg';
import TextareaField from '../base/TextareaField';
import CheckBoxBlueStyle from '../base/CheckBoxBlueStyle';
import {Link, useNavigate} from 'react-router-dom';
import {isValidPhoneNumber} from 'react-phone-number-input';
import {InputPhoneCodeMask} from '../base/InputPhoneCodeMask';
import {fetchDemoApplication} from "../../redux/thunks/companiesThunks";
import api from "../../api";
import {toast} from "react-toastify";

const ContactUsForm = ({data}) => {
    const [formData, setFormData] = useState({
        email: '',
        fullName: '',
        companyName: '',
        message: '',
        phone: ''
    });
    const [agree, setAgree] = useState(true)
    const [formErrors, setFormErrors] = useState({})
    const navigate = useNavigate()


    const inputHandler = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value,

        }))
        setFormErrors(prevState => ({...prevState, [name]: false}));
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const fields = Object.entries(formData)
        let anyErrors = false

        fields.forEach(el => {
            if (!el[1]) {
                setFormErrors(prevState => ({...prevState, [el[0]]: "Required field"}))
                anyErrors = true
            }
        })
        if (!agree) {
            setFormErrors(prevState => ({...prevState, agree: "You must agree the Privacy Policy"}))
            return
        }
        if (!isValidPhoneNumber('+' + String(formData.phone))) {
            setFormErrors(prevState => ({...prevState, phone: "Invalid Phone"}))
            return
        }
        if (!(/\S+@\S+\.\S+/.test(formData.email))) {
            setFormErrors(prevState => ({...prevState, email: "Invalid Email"}))
            return
        }

        if (anyErrors) return


        const data = {
            email: formData?.email,
            phone: formData?.phone,
            message: formData?.message,
            companyName: formData?.companyName,
            firstName: formData?.fullName?.split(" ")[0],
            lastName: formData?.fullName?.split(" ")[1]
        }

        const response = await api.users.publicContactUs(data);
        if (response.success) {
            toast.success('Message sent successfully!')
            navigate("/HOM1_5_1")
        } else {
            toast.error( response?.errors?.[0]?.msg || response.message || 'Something went wrong')
        }
    }


    return (
        <form className='flex flex-col gap-[30px]' onSubmit={handleSubmit}>
            <InputGroup
                label={data.fullName.label}
                value={formData.fullName}
                placeholder={data.fullName.placeholder}
                icon={<FullName/>}
                onChange={({target}) => {
                    inputHandler('fullName', target.value)
                }}
                error={formErrors?.fullName}
            />
            <InputGroup
                label={data.email.label}
                value={formData.email}
                placeholder={data.email.placeholder}
                icon={<Email/>}
                onChange={({target}) => {
                    inputHandler('email', target.value)
                }}
                error={formErrors?.email}
            />
            <InputPhoneCodeMask
                value={formData.phone}
                label={data.phone.label}
                onChange={(value) => inputHandler('phone', value)}
                placeholder={data?.phone?.placeholder}
            />
            {formErrors?.phone && <p className="text-red-500 text-sm mt-1">{formErrors.phone}</p>}
            <InputGroup
                label={data.company.label}
                value={formData.companyName}
                placeholder={data.company.placeholder}
                icon={<Company/>}
                onChange={({target}) => {
                    inputHandler('companyName', target.value)
                }}
                error={formErrors?.companyName}
            />
            <TextareaField
                labelClassName='text-[22px] font-bold leading-[24px] max-md:text-[16px] max-md:leading-[18px]'
                label={data.message.label}
                inputBg='bg-[#EBF0F3]'
                placeholder={data.message.placeholder}
                height={174}
                wrapperSpace=''
                className='text-[20px] max-md:text-[16px]'
                value={formData.message}
                onChange={({target}) => {
                    inputHandler('message', target.value)
                }}
                error={formErrors?.message}
            />
            <CheckBoxBlueStyle
                checked={agree}
                onChange={() => {
                    setFormErrors(prevState => ({...prevState, agree: false}));
                    setAgree(!agree)
                }}
                text={
                    <p className='leading-7' dangerouslySetInnerHTML={{__html: data?.policy}}/>
                }
            />
            {formErrors?.agree && <p className="text-red-500 text-sm mt-1">You must agree the Privacy Policy</p>}

            <button
                type="submit"
                className={`w-full px-[10px] py-[14px] text-[30px] font-bold bg-[#0056B3] hover:bg-green-600 rounded-md text-white max-md:text-[18px]}`}
                // disabled={canSendCheck()}
            >
                {data.buttonText}
            </button>
        </form>
    );
};

export default ContactUsForm;

import React from "react";
import { useState } from "react";
import ModalTemplate from '../base/ModalTemplate';
import InputFieldHorisontal from '../base/InputFieldHorisontal';
import Button from "../base/Button";
import PrimaryButton from "../base/PrimaryButton";
import api from "../../api";
import { Device } from "@twilio/voice-sdk";
import CommunicationTable from "../../pages/SBM01/parts/CommunicationTable";
function NumberButton({ number, ...props }) {
    return (
        <button {...props} className="grow justify-center px-7 py-5 rounded-xl border border-green-500 border-solid text-3xl font-semibold text-center text-green-500 whitespace-nowrap">
            {number}
        </button>
    );
}

const CALL_STATUSES = {
    pending: 'Waiting for call',
    calling: 'Attempting to call',
    progress: 'Call in progress',
    disconnected: 'Call disconnected'
}

const CallModal = ({ selectedApplicant, open, handleClose, tableHeding = false, tableHedingButton = false, footerBorderTop = true, data }) => {
    console.log(selectedApplicant)
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        callStatus: '',
        calling: ''
    });
    const [callStatus, setCallStatus] = useState(CALL_STATUSES.pending)
    const [callState, setCallState] = useState(null)
    const [dropdown, setDropdown] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const numbers = [
        [1, 2, 3],
        [4, 5, 6],
        [7, 8, 9],
        ["", 0, ""],
    ];
    const handerNUmbers = (e) => {
        const value = e.target.dataset.value
        setFormData(prevState => ({ ...prevState, calling: prevState.calling + value }))
    }

    const removeNumber = (e) => {
        setFormData(prevState => ({ ...prevState, calling: String(prevState.calling).replace(/.$/, "") }))
    }
    const closeDisconnect = () => {
        callState && callState.disconnect();
        handleClose();
    }
    const handleCall = async() => {
        if(buttonText === 'Hang Up') {
            closeDisconnect()
            return
        }
        const response = await api.communications.getCallToken(selectedApplicant.id);
        if(!response.success) {
            //handle error
            return
        }
        const device = new Device(response.data.token, {
            logLevel:1,
            // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
            // providing better audio quality in restrained network conditions.
            codecPreferences: ["opus", "pcmu"],
        });
        addDeviceListeners(device);
        device.register();
        setCallStatus(CALL_STATUSES.calling)
        const call = await device.connect({
            params: {
                To: selectedApplicant.phone,
                tsid: response.data.tsid
            }
        });
        setCallState(call)
        call.on("accept", (call) => {
            setCallStatus(CALL_STATUSES.progress);
            // callButton.disabled = true;
            // outgoingCallHangupButton.classList.remove("hide");
            // volumeIndicators.classList.remove("hide");
            // bindVolumeIndicators(call);
        });
        call.on("disconnect", (call) => {
            setCallStatus(CALL_STATUSES.disconnected);
        });
        call.on("cancel", (call) => {
            setCallStatus(CALL_STATUSES.disconnected);
        });
  
        // outgoingCallHangupButton.onclick = () => {
        //   log("Hanging up ...");
        //   call.disconnect();
        // };
    }
    function addDeviceListeners(device) {
        device.on("registered", function () {
            //   log("Twilio.Device Ready to make and receive calls!");
            // callControlsDiv.classList.remove("hide");
        });
    
        device.on("error", function (error) {
        //   log("Twilio.Device Error: " + error.message);
        });
    
        // device.on("incoming", handleIncomingCall);
    
        // device.audio.on("deviceChange", updateAllAudioDevices.bind(device));
    
        // Show audio selection UI if it is supported by the browser.
        // if (device.audio.isOutputSelectionSupported) {
        //   audioSelectionDiv.classList.remove("hide");
        // }
      }
      const buttonText = (callStatus === CALL_STATUSES.pending || callStatus === CALL_STATUSES.disconnected) ? 'Call' : 'Hang Up'
    return (
        <ModalTemplate open={open} onClick={closeDisconnect} modalSize="max-w-[772px]">
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">Call</h2>
                <button className="btn btn-close" onClick={closeDisconnect}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            <div className="modal__body">
                <div className="w-full">
                    <div className="flex flex-col gap-[16px] mb-[32px]">
                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            label="Name"
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="text" name="name" id="name"
                            value={selectedApplicant.fullName}
                            readOnly
                            onChange={handleChange}
                            placeholder="John Doe"
                        />
                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            label="Phone Number"
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="text" name="phone" id="phone"

                            value={selectedApplicant.phone}
                            readOnly
                            onChange={handleChange}
                            placeholder="123-453-1232"
                        />
                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            label="Call Status"
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="text" name="phone" id="phone"

                            value={callStatus}
                            readOnly
                            onChange={handleChange}
                            placeholder="123-453-1232"
                        />
                        {/* <div className="flex max-md:flex-col md:items-center gap-[16px] flex-1">
                            <label htmlFor="call-status" className="min-w-[140px] text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]">Call Status</label>
                            <div className="flex relative flex-auto">
                                <div className={`numbers-dropdoun absolute top-0 left-0 w-[48px] h-full 
                                    ${dropdown ? 'dropdoun--active' : ''} `}
                                >
                                    <button className="w-full h-full flex items-center justify-center relative" onClick={() => {
                                        setDropdown(!dropdown)
                                    }}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="10.5" y="3" width="3" height="3" rx="1.5" fill="#37B34A" />
                                            <rect x="10.5" y="8" width="3" height="3" rx="1.5" fill="#37B34A" />
                                            <rect x="10.5" y="13" width="3" height="3" rx="1.5" fill="#37B34A" />
                                            <rect x="10.5" y="18" width="3" height="3" rx="1.5" fill="#37B34A" />
                                            <rect x="16" y="3" width="3" height="3" rx="1.5" fill="#37B34A" />
                                            <rect x="16" y="8" width="3" height="3" rx="1.5" fill="#37B34A" />
                                            <rect x="16" y="13" width="3" height="3" rx="1.5" fill="#37B34A" />
                                            <rect x="5" y="3" width="3" height="3" rx="1.5" fill="#37B34A" />
                                            <rect x="5" y="8" width="3" height="3" rx="1.5" fill="#37B34A" />
                                            <rect x="5" y="13" width="3" height="3" rx="1.5" fill="#37B34A" />
                                        </svg>
                                    </button>
                                    {dropdown && <div className="numbers-dropdoun__list flex flex-col gap-2 justify-between bg-white rounded-xl">
                                        {numbers.map((row, rowIndex) => (
                                            <div key={rowIndex} className="flex gap-2">
                                                {row.map((number, colIndex) => (
                                                    <React.Fragment key={`${rowIndex}-${colIndex}`}>
                                                        {number === "" ? (
                                                            colIndex === 0 ? (
                                                                <button type="button" onClick={removeNumber} className="flex flex-1 justify-center items-center px-6 py-3.5 rounded-xl bg-zinc-500">
                                                                    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <mask id="mask0_314_3188" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="2" y="6" width="29" height="20">
                                                                            <path d="M9.83366 7.33398L3.16699 16.0007L9.83366 24.6673H29.8337V7.33398H9.83366Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M14.5 12.668L21.1667 19.3346M21.1667 12.668L14.5 19.3346" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </mask>
                                                                        <g mask="url(#mask0_314_3188)">
                                                                            <path d="M0.5 0H32.5V32H0.5V0Z" fill="white" />
                                                                        </g>
                                                                    </svg>

                                                                </button>
                                                            ) : (
                                                                <a href={`${formData.calling.length > 0 ? `callto:${formData.calling}` : '#'}`} className="flex flex-1 justify-center items-center px-6 py-4 bg-green-500 rounded-xl">
                                                                    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M24.8211 18.7958C24.5561 18.5135 23.6202 17.6787 21.8997 16.5742C20.1665 15.46 18.8884 14.7653 18.5217 14.6033C18.4894 14.589 18.4538 14.5838 18.4187 14.5881C18.3837 14.5925 18.3504 14.6064 18.3227 14.6282C17.7321 15.0888 16.7378 15.9348 16.6845 15.9805C16.3402 16.2756 16.3402 16.2756 16.0583 16.1837C15.5627 16.0212 14.023 15.2036 12.6814 13.8589C11.3397 12.5142 10.48 10.9364 10.3175 10.4413C10.2246 10.159 10.2246 10.159 10.5206 9.81467C10.5663 9.76135 11.4129 8.76705 11.8734 8.17698C11.8953 8.14919 11.9091 8.11597 11.9135 8.0809C11.9179 8.04583 11.9127 8.01022 11.8983 7.97791C11.7363 7.61076 11.0416 6.33311 9.9275 4.59995C8.82148 2.87998 7.98766 1.94409 7.70531 1.67901C7.6794 1.65456 7.64738 1.63755 7.61261 1.62977C7.57785 1.622 7.54163 1.62375 7.50777 1.63483C6.52083 1.97399 5.56858 2.40675 4.66402 2.92721C3.7908 3.43483 2.96409 4.01854 2.19352 4.67155C2.16662 4.69442 2.14645 4.72417 2.13518 4.75763C2.12391 4.79109 2.12195 4.82699 2.12953 4.86147C2.23566 5.35608 2.74297 7.42084 4.31719 10.2808C5.9234 13.1997 7.03652 14.6953 9.39531 17.0459C11.7541 19.3966 13.2968 20.5767 16.2188 22.1829C19.0788 23.7572 21.1446 24.265 21.6382 24.3701C21.6727 24.3776 21.7087 24.3756 21.7422 24.3644C21.7757 24.3531 21.8056 24.333 21.8286 24.3061C22.4815 23.5356 23.065 22.7088 23.5724 21.8356C24.0928 20.931 24.5255 19.9788 24.8648 18.9919C24.8757 18.9583 24.8773 18.9224 24.8697 18.8879C24.862 18.8534 24.8452 18.8217 24.8211 18.7958Z" fill="white" />
                                                                    </svg>
                                                                </a>
                                                            )
                                                        ) : (
                                                            <NumberButton number={number} data-value={number} onClick={handerNUmbers} />
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                                <input
                                    type="number"
                                    id="calling"
                                    name="calling"
                                    value={formData.calling}
                                    onChange={handleChange}
                                    placeholder="Calling"
                                    className={`rounded-md min-h-[46px] text-[16px] px-[16px] pl-[48px] focus:outline-none focus:ring-2 focus:ring-green-500 h-[54px] w-full bg-white border border-solid border-zinc-300`}
                                />
                            </div>
                        </div> */}
                    </div>
                    {tableHeding && <div className="flex items-center justify-between gap-2">
                        <div className="text-[18px] md:text-[20px] font-semibold leading-8 text-zinc-800">
                            Communication History
                        </div>
                        {tableHedingButton && <Button className={'text-white bg-green-500 md:min-w-[122px] min-h-[52px] text-[18px]'}> Dial </Button>}
                    </div>}
                    <div className="flex flex-col mt-[16px] gap-[32px]">
                        {/* {data.map(dataItem =>
                            <div className="flex flex-col self-stretch  " key={dataItem.name}>
                                <h2 className="w-full text-[18px] font-semibold text-stone-500 max-md:max-w-full mb-[10px]">{dataItem.name}</h2>
                                <div className="overflow-auto whitespace-nowrap">
                                    <table className="w-full">
                                        <thead>
                                            <tr>
                                                <th className=" border border-solid border-zinc-300 font-semibold p-[11px] bg-neutral-100 text-stone-500 text-center">Date</th>
                                                <th className=" border border-solid border-zinc-300 font-semibold p-[11px] bg-neutral-100 text-stone-500 text-center">Time</th>
                                                <th className=" border border-solid border-zinc-300 font-semibold p-[11px] bg-neutral-100 text-stone-500 text-center">Content</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataItem.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className=" border border-solid border-zinc-300 p-[11px] text-[16px] text-center">
                                                        {item.date}
                                                    </td>
                                                    <td className=" border border-solid border-zinc-300 p-[11px] text-[16px] text-center">
                                                        {item.time}
                                                    </td>
                                                    <td className=" border border-solid border-zinc-300 p-[11px] text-[16px] text-center">
                                                        {dataItem.name === 'Call' ?
                                                            <button type="button" className="flex justify-center gap-1.5 w-full">
                                                                <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.125 11V8.25L12.4167 9.625L14.7083 11L12.4167 12.375L10.125 13.75V11Z" fill="#37B34A" stroke="#37B34A" strokeWidth="1.5" strokeLinejoin="round" />
                                                                    <path d="M5.66633 16.8337C6.43157 17.6009 7.34088 18.2093 8.34202 18.624C9.34316 19.0387 10.4164 19.2514 11.5 19.25C16.0563 19.25 19.75 15.5563 19.75 11C19.75 6.44371 16.0563 2.75 11.5 2.75C9.22208 2.75 7.15958 3.67354 5.66633 5.16633C4.90642 5.92625 3.25 7.79167 3.25 7.79167" stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M3.25 4.125V7.79167H6.91667" stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="text-[#333333] font-bold">Replay</div>
                                                            </button>
                                                            :
                                                            <div className="flex justify-center gap-1.5 w-full">
                                                                <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.5 19.25C16.0563 19.25 19.75 15.5563 19.75 11C19.75 6.44365 16.0563 2.75 11.5 2.75C6.94365 2.75 3.25 6.44365 3.25 11C3.25 15.5563 6.94365 19.25 11.5 19.25Z" stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M10.8125 10.3125C10.9948 10.3125 11.1697 10.3849 11.2986 10.5139C11.4276 10.6428 11.5 10.8177 11.5 11V14.4375C11.5 14.6198 11.5724 14.7947 11.7014 14.9236C11.8303 15.0526 12.0052 15.125 12.1875 15.125" stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M11.1562 8.25C11.7258 8.25 12.1875 7.78829 12.1875 7.21875C12.1875 6.64921 11.7258 6.1875 11.1562 6.1875C10.5867 6.1875 10.125 6.64921 10.125 7.21875C10.125 7.78829 10.5867 8.25 11.1562 8.25Z" fill="#37B34A" />
                                                                </svg>
                                                                <div className="text-[#333333] font-bold">Details</div>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )} */}
                        <CommunicationTable type='call' isSorted={false}/>
                    </div>
                </div>
            </div>
            <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                <Button className={'text-gray-400 max-md:w-full min-w-[122px] min-h-[52px] text-[18px]'} onClick={closeDisconnect} >
                    <span className="underline" >Cancel</span>
                </Button>
                <PrimaryButton className={`w-[122px] !h-[52px] !text-[18px] gap-2 ${buttonText === 'Call' ? 'bg-green-500' : 'bg-red-500' }`} onClick={handleCall} >
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M24.8211 18.7958C24.5561 18.5135 23.6202 17.6787 21.8997 16.5742C20.1665 15.46 18.8884 14.7653 18.5217 14.6033C18.4894 14.589 18.4538 14.5838 18.4187 14.5881C18.3837 14.5925 18.3504 14.6064 18.3227 14.6282C17.7321 15.0888 16.7378 15.9348 16.6845 15.9805C16.3402 16.2756 16.3402 16.2756 16.0583 16.1837C15.5627 16.0212 14.023 15.2036 12.6814 13.8589C11.3397 12.5142 10.48 10.9364 10.3175 10.4413C10.2246 10.159 10.2246 10.159 10.5206 9.81467C10.5663 9.76135 11.4129 8.76705 11.8734 8.17698C11.8953 8.14919 11.9091 8.11597 11.9135 8.0809C11.9179 8.04583 11.9127 8.01022 11.8983 7.97791C11.7363 7.61076 11.0416 6.33311 9.9275 4.59995C8.82148 2.87998 7.98766 1.94409 7.70531 1.67901C7.6794 1.65456 7.64738 1.63755 7.61261 1.62977C7.57785 1.622 7.54163 1.62375 7.50777 1.63483C6.52083 1.97399 5.56858 2.40675 4.66402 2.92721C3.7908 3.43483 2.96409 4.01854 2.19352 4.67155C2.16662 4.69442 2.14645 4.72417 2.13518 4.75763C2.12391 4.79109 2.12195 4.82699 2.12953 4.86147C2.23566 5.35608 2.74297 7.42084 4.31719 10.2808C5.9234 13.1997 7.03652 14.6953 9.39531 17.0459C11.7541 19.3966 13.2968 20.5767 16.2188 22.1829C19.0788 23.7572 21.1446 24.265 21.6382 24.3701C21.6727 24.3776 21.7087 24.3756 21.7422 24.3644C21.7757 24.3531 21.8056 24.333 21.8286 24.3061C22.4815 23.5356 23.065 22.7088 23.5724 21.8356C24.0928 20.931 24.5255 19.9788 24.8648 18.9919C24.8757 18.9583 24.8773 18.9224 24.8697 18.8879C24.862 18.8534 24.8452 18.8217 24.8211 18.7958Z" fill="white" />
                                                                    </svg>
                   <p>
                        {buttonText}
                    </p> 
                </PrimaryButton>
            </div>
        </ModalTemplate>
    )
};

export default CallModal

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import CategoryCard from './publickPageParts/CategoryCard';

const JobsCategoriesSlider = ({ data }) => {
    return (
        <section className={`py-[55px] lg:pb-[150px] overflow-hidden font-['Raleway']`}>
            <div className="container">
                <div className="flex flex-col gap-[7px] text-[18px] mb-[32px] text-center items-center">
                    <span className="text-lg font-semibold text-[#0056B3]">{data.title}</span>
                    <h3 className="text-[45px] max-md:text-[32px] font-bold max-w-[700px] leading-[48px]">
                        {data.header}
                    </h3>
                </div>
                <Swiper
                    spaceBetween={22}
                    slidesPerView="auto"
                    autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
                    modules={[Autoplay]}
                    className="swiper-with-buttons !overflow-visible"
                >
                    {data.categories.map((category, idx) => (
                        <SwiperSlide key={idx} className="!h-auto !w-[307px]">
                            <CategoryCard
                                className="h-full"
                                title={category.title}
                                jobs={category.jobs}
                                description={category.description}
                                img={category.image}
                                button={category.button}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default JobsCategoriesSlider;

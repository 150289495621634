import {Footer} from "../../../../components/parts/Footer";
import React, {useEffect, useState} from "react";
import {NextStepSectionSBM01} from "../../base/NextStepSectionSBM01";
import {HeroSectionSBM01} from "../../base/HeroSectionSBM01";
import {SalesAssociateHeaderSBM01} from "../../base/SalesAssociateHeaderSBM01";
import {FitScaleEvaluationTable} from "../../../../components/base/FitScaleEvaluationTable";
import TextareaField from "../../../../components/base/TextareaField";
import SelectField from "../../../../components/base/SelectField";
import {TotalScore} from "../../base/TotalScoreSBM01";

export const StepTwo = ({onNext, onBack}) => {

    return (
        <>
            <SalesAssociateHeaderSBM01 name="Marc Spector" />

            <section className='flex flex-col w-full flex-auto pt-10 pb-20 lg:pt-20 lg:pb-24 mx-auto max-w-[1440px] px-5'>
                <HeroSectionSBM01 />
                <InterviewSection />
                <NextStepSectionSBM01 next={onNext} prev={onBack} />
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}


const InterviewQuestion = ({ question, fitRating, isRequired, onChange, index }) => {
    const [formData, setFormData] = useState({
        question: question,
        fitRating: fitRating,
        comment: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        if (formData, onChange) {
            onChange(formData)
        }
    }, [formData])

    return (
        <>
            <div className="mt-7 mb-4 lg:mt-10 text-base lg:text-lg font-semibold text-zinc-800 max-md:mt-[36px] max-md:max-w-full">
                {index ? index : ""}. {question}{" "}
                {isRequired && <span className="text-red-600">*</span>}
            </div>
            <FitScaleEvaluationTable
                labelLow="Poor fit"
                labelHigh="Perfect fit"
                value={formData.fitRating}
                onChange={(value) => setFormData(prevState => ({ ...prevState, fitRating: value }))}
            />
            <div className='mt-9 max-w-[640px] w-full'>
                <div className="text-lg font-semibold text-zinc-800 w-full leading-[20px] max-md:text-[16px]">
                    Comments
                </div>
                <TextareaField
                    labelClassName={"hidden"}
                    value={formData.comment}
                    onChange={handleChange}
                    name="comment"
                    id="comment"
                    inputBg='shrink-0 mt-2 lg:mt-4 max-lg:!h-[150px] max-w-full bg-white border border-solid border-zinc-300 h-[164px] w-[640px] !rounded-[0px]'
                />
            </div>
        </>
    )
};

const InterviewSection = () => {
    const [questions, setQuestions] = useState([
        {
            question: "Lorem ipsum dolor sit amet?",
            fitRating: 1,
            comment: "",
            isRequired: true
        },
        {
            question: "Lorem ipsum dolor sit amet?",
            fitRating: 1,
            comment: "",
            isRequired: true
        },
        {
            question: "Lorem ipsum dolor sit amet?",
            fitRating: 1,
            comment: "",
            isRequired: true
        },
    ])
    const [interviewer, setInterviewer] = useState()

    const interviewers = [{
        value: "marc_spector",
        label: "Marc Spector"
    }]
    const handleSelect = (value) => {
        setInterviewer(value)
    }

    const handleChange = (formData) => {
        const { question, fitRating, comment } = formData
        const questionIndex = questions.findIndex(item => item.question === formData.question);

        if (questionIndex !== -1) {
            const newData = [...questions];
            newData[questionIndex] = { question, fitRating, comment };
            setQuestions(newData);
        } else {
            setQuestions(prev => [...prev, { question, fitRating, comment }]);
        }
    };
    return (
        <div className="flex flex-col items-stretch">
            <div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
                2<sup>nd</sup> Interview
            </div>
            <div className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full max-md:mt-[24px]" />
            <div className="flex max-lg:flex-col -mb-4 justify-start gap-2 lg:gap-24 self-stretch mt-9 w-full">
                <div className="my-auto lg:text-lg font-semibold text-zinc-800">
                    Interviewer Name
                </div>
                <SelectField
                    inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 max-w-[815px] w-full"
                    name="department"
                    id="department"
                    value={interviewer}
                    onChange={handleSelect}
                    options={interviewers}
                />
            </div>
            {
                questions.map((question, idx) => (
                    <InterviewQuestion
                        key={idx}
                        index={idx + 1}
                        {...question}
                        onChange={handleChange}
                    />
                ))
            }
            <TotalScore title="Total Score" score={90} />
        </div>
    );
};

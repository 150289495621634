import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import PrimaryButton from "../components/base/PrimaryButton";
import SelectBox from "../components/base/SelectBox";
import { Footer } from "../components/parts/Footer";
import { getPageById, updatePageDataById } from "../redux/thunks/supportThunks";
import PageLoader from "../components/PageLoader";

const PublicHomeEditorLayout = () => {

    const { currentPage, isLoading } = useSelector(state => state.support)
    const { id } = useParams()
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        name: '',
        status: '',
        url: ''
    })

    const handleChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }))

    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData);
        dispatch(updatePageDataById({ pageId: id, body: formData }))
    }

    useEffect(() => {
        if (!id) return
        const fetch = async () => {
            const res = await dispatch(getPageById(id)).unwrap()
            if (res) {
                setFormData({
                    name: res?.name ? res.name : '',
                    status: res?.status ? res.status : '',
                    url: res?.url ? res.url : ''
                })
            }
        }
        fetch()
    }, [id])

    const postsData = [
        {
            category: "Home public pages",
            title: "Why EmployJoy",
            date: "Nov 23, 2023",
        },
        {
            category: "Home public pages",
            title: "Solution",
            date: "Oct 1, 2023",
        },
        {
            category: "Home public pages",
            title: "Book A Demo",
            date: "Oct 1, 2023",
        },
        {
            category: "Home public pages",
            title: "Login",
            date: "Oct 1, 2023",
        },
    ];
    const [filteredData, setFilteredData] = useState(postsData)

    const statusOptions = [
        { label: "Publish", value: 'publish' },
        { label: "Draft", value: 'draft' },
    ]

    return (
        <>
            <BreadCrumbWhite crumbs={['EJ Support', 'Public Home Page List']} />

            <section className="flex-auto flex justify-center items-center px-4 lg:py-12 pt-10 pb-20 mt-1.5 w-full text-zinc-800">
                <div className="flex flex-col items-center lg:my-10 w-full max-w-[1400px] max-md:max-w-full">
                    <h2 className="lg:text-[40px] text-4xl leading-[44px] text-center lg:leading-[40px] mb-[8px] font-bold text-zinc-800">List of Pages</h2>
                    <p className="text-[18px] text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                    <div className="flex flex-col self-stretch py-9 px-8 lg:px-16 mt-11 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                        {/* <SupportFilters data={postsData} setData={setFilteredData} category search searchField="title" /> */}

                        {/* <div className="shrink-0 mt-12 h-px bg-neutral-200 max-md:mt-10 max-md:max-w-full" /> */}
                        {
                            !isLoading ?
                                currentPage ?
                                    <div className="flex-auto">
                                        <form className="flex flex-col self-stretch py-9">
                                            <InputField
                                                label="Name*"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"
                                                name="name"
                                                id="name"
                                                placeholder="Page name"
                                                value={formData.name}
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            />
                                            <InputField
                                                label="URL*"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"
                                                name="url"
                                                id="url"
                                                placeholder="Page url"
                                                value={formData.url}
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            />
                                            <div className="w-full">
                                                <SelectBox
                                                    label={"Status"}
                                                    options={statusOptions}
                                                    onSelect={(val) => handleChange("status", val)}
                                                    value={formData.status} svg={2}
                                                />
                                            </div>
                                            <div className="mt-8">
                                                <PrimaryButton className={"w-full lg:w-[250px]"} onClick={handleSubmit}>Update page</PrimaryButton>
                                            </div>
                                        </form>
                                        <Outlet context={currentPage} />
                                    </div>
                                    :
                                    <div className='text-4xl pt-5 md:text-5xl font-bold text-sky-950 text-center'>No Found</div>
                                :
                                <PageLoader />
                        }
                    </div>
                </div >
            </section >

            <Footer hideCopyright={true} />
        </>
    )
}


export default PublicHomeEditorLayout
import { EditableInput } from "./EditableInput";
import InputField from "../../components/base/InputField";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ReactComponent as CalendarIcon } from "../../assets/img/calendar-ico.svg";
import React, { useEffect, useState } from "react";
import { ModalForm } from "./ModalForm";

export const DateFormSection = ({ payload, setPayload, }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isModal, setIsModal] = useState(false)

    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.what_is_your_earliest_date_to_start_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        what_is_your_earliest_date_to_start_active: !payload.applicationForm.what_is_your_earliest_date_to_start_active
                    }
                });
            }
        },
    ]

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);



    const [date, setDate] = useState({
        inputsTitle: "What is your earliest date to start?",
        month: "Month",
        day: "Day",
        year: "Year",
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
        console.log(date);
    }

    const handleChangeDate = (field, value) => {
        setDate(prev => ({ ...prev, [field]: value }))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };

    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <div className='flex flex-col max-w-[580px] w-full items-stretch'>
                    <EditableInput
                        className="text-[22px] font-bold "
                        type="text"
                        name="what_is_your_earliest_date_to_start_title"
                        id="what_is_your_earliest_date_to_start_title"
                        value={payload.applicationForm.what_is_your_earliest_date_to_start_title}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                    { payload.applicationForm.what_is_your_earliest_date_to_start_active ? 
                        <div className="flex items-center gap-3.5 mt-6 text-lg whitespace-nowrap text-neutral-400 max-md:grid max-md:grid-cols-2">
                            <div className='w-full flex-1 flex flex-col max-md:col-span-3'>
                                <EditableInput
                                    className="text-[16px] md:text-[18px]  text-zinc-400 leading-[18px] mb-[10px]"
                                    type="text"
                                    name="what_is_your_earliest_date_to_start_month"
                                    id="what_is_your_earliest_date_to_start_month"
                                    value={payload.applicationForm.what_is_your_earliest_date_to_start_month}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <input type="text" className='border border-solid border-zinc-300 rounded-md px-[18px] text-[18px] focus:outline-none focus:ring-2 focus:ring-green-500 h-[54px]' />
                            </div>

                            <div className="shrink-0 mt-4 w-2 h-px bg-zinc-300 max-lg:hidden" />
                            <div className='w-full flex-1 flex flex-col'>
                                <EditableInput
                                    className="text-[16px] md:text-[18px]  text-zinc-400 leading-[18px] mb-[10px]"
                                    type="text"
                                    name="what_is_your_earliest_date_to_start_day"
                                    id="what_is_your_earliest_date_to_start_day"
                                    value={payload.applicationForm.what_is_your_earliest_date_to_start_day}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <input type="text" className='border border-solid border-zinc-300 rounded-md px-[18px] text-[18px] focus:outline-none focus:ring-2 focus:ring-green-500 h-[54px]' />
                            </div>

                            <div className="shrink-0 mt-4 w-2 h-px bg-zinc-300 max-lg:hidden" />
                            <div className='w-full flex-1 flex flex-col'>
                                <EditableInput
                                    className="text-[16px] md:text-[18px]  text-zinc-400 leading-[18px] mb-[10px]"
                                    type="text"
                                    name="what_is_your_earliest_date_to_start_year"
                                    id="what_is_your_earliest_date_to_start_year"
                                    value={payload.applicationForm.what_is_your_earliest_date_to_start_year}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <input type="text" className='border border-solid border-zinc-300 rounded-md px-[18px] text-[18px] focus:outline-none focus:ring-2 focus:ring-green-500 h-[54px]' />
                            </div>
                            <div className='flex mb-[18px] mt-9 max-lg:hidden'>
                                <CalendarIcon />
                            </div>
                        </div>
                    : null }
                </div>
            </ModalForm>
        )
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex justify-between">
                    <EditableInput
                        className="text-[22px] font-bold "
                        type="text"
                        name="what_is_your_earliest_date_to_start_title"
                        id="what_is_your_earliest_date_to_start_title"
                        value={payload.applicationForm.what_is_your_earliest_date_to_start_title}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                    {
                        isEditing ?
                            <PrimaryButton
                                type="submit"
                                className={"px-9 lg:h-[52px] lg:text-lg"}
                            >
                                Save
                            </PrimaryButton> :
                            <ButtonOptions options={options} />
                    }
                </div>
                { payload.applicationForm.what_is_your_earliest_date_to_start_active ? 
                    <div className='flex flex-col max-w-[580px] w-full items-stretch'>
                        <div className="flex items-center gap-3.5 mt-6 text-lg whitespace-nowrap text-neutral-400 max-md:grid max-md:grid-cols-2">
                            <div className='w-full flex-1 flex flex-col max-md:col-span-3'>
                                <EditableInput
                                    className="text-[16px] md:text-[18px]  text-zinc-400 leading-[18px] mb-[10px]"
                                    type="text"
                                    name="what_is_your_earliest_date_to_start_month"
                                    id="what_is_your_earliest_date_to_start_month"
                                    value={payload.applicationForm.what_is_your_earliest_date_to_start_month}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <input type="text" className='border border-solid border-zinc-300 rounded-md px-[18px] text-[18px] focus:outline-none focus:ring-2 focus:ring-green-500 h-[54px]' />
                            </div>

                            <div className="shrink-0 mt-4 w-2 h-px bg-zinc-300 max-lg:hidden" />
                            <div className='w-full flex-1 flex flex-col'>
                                <EditableInput
                                    className="text-[16px] md:text-[18px]  text-zinc-400 leading-[18px] mb-[10px]"
                                    type="text"
                                    name="what_is_your_earliest_date_to_start_day"
                                    id="what_is_your_earliest_date_to_start_day"
                                    value={payload.applicationForm.what_is_your_earliest_date_to_start_day}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <input type="text" className='border border-solid border-zinc-300 rounded-md px-[18px] text-[18px] focus:outline-none focus:ring-2 focus:ring-green-500 h-[54px]' />
                            </div>

                            <div className="shrink-0 mt-4 w-2 h-px bg-zinc-300 max-lg:hidden" />
                            <div className='w-full flex-1 flex flex-col'>
                                <EditableInput
                                    className="text-[16px] md:text-[18px]  text-zinc-400 leading-[18px] mb-[10px]"
                                    type="text"
                                    name="what_is_your_earliest_date_to_start_year"
                                    id="what_is_your_earliest_date_to_start_year"
                                    value={payload.applicationForm.what_is_your_earliest_date_to_start_year}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <input type="text" className='border border-solid border-zinc-300 rounded-md px-[18px] text-[18px] focus:outline-none focus:ring-2 focus:ring-green-500 h-[54px]' />
                            </div>
                            <div className='flex mb-[18px] mt-9 max-lg:hidden'>
                                <CalendarIcon />
                            </div>
                        </div>
                    </div>
                : null }
            </form>
        </>
    )
}